import React from "react";
import { Modal, Button } from "react-bootstrap";
import BusinessStatusForm from "./BusinessStatusForm";


const BusinessStatusModal = props => {
    const {
        selectedStatus,
        ...rest
    } = props;
    return (
        <Modal
            {...rest}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Business Status
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BusinessStatusForm selectedStatus={selectedStatus} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BusinessStatusModal;
