import React from "react";
import { Row, Col, Form } from "react-bootstrap";
const Hour = (props) => {
  const { businessHour } = props;
  const dayInWeeks = [
    "Sunday",
    "Monday",
    "TuesDay",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
 
  return (
    <div className="shadow-none p-3 mb-5 bg-light rounded">
      <h4>Business Hour</h4>

      {dayInWeeks.map((day, dayIndex) => {
        return (
          <Row key={dayIndex} className="shadow-sm p-2 mb-2 bg-white rounded">
            <Col
              md={4}
              className="day-of-weeks"
              style={{
                color: businessHour[dayIndex].isOpen ? "#007BFF" : "#ADB5BD",
              }}
            >
              {day}
            </Col>
            <Col md={2}>
              <Form.Check
                type="switch"
                id={"custom-switch-" + dayIndex}
                label={businessHour[dayIndex].isOpen ? "Open" : "Closed"}
                name="isOpen"
                readOnly
                checked={businessHour[dayIndex].isOpen}
              />
            </Col>
            <Col md={6}>
                { businessHour[dayIndex].isOpen && businessHour[dayIndex].shifts.map((shift, shiftIndex) => {
                    return <Row key={shiftIndex}>
                                <Col><span className="font-weight-bold p-0 m-0">Open:</span> {shift.open}</Col>
                                <Col><span className="font-weight-bold p-0 m-0">Close:</span> {shift.close}</Col>
                            </Row> 
                    
                })}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default Hour;
