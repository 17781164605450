// export const GET_BUSINESS_REPORT_GENERAL_STARTED = "GET_BUSINESS_REPORT_GENERAL_STARTED"
// export const GET_BUSINESS_REPORT_GENERAL_SUCCESS = "GET_BUSINESS_REPORT_GENERAL_SUCCESS"
// export const GET_BUSINESS_REPORT_GENERAL_FAILURE = "GET_BUSINESS_REPORT_GENERAL_FAILURE"

// export const GET_BUSINESS_REPORT_MONTHS_STARTED = "GET_BUSINESS_REPORT_MONTHS_STARTED"
// export const GET_BUSINESS_REPORT_MONTHS_SUCCESS = "GET_BUSINESS_REPORT_MONTHS_SUCCESS"
// export const GET_BUSINESS_REPORT_MONTHS_FAILURE = "GET_BUSINESS_REPORT_MONTHS_FAILURE"

export const GET_BUSINESS_PAYMENT_LIST_STARTED = "GET_BUSINESS_PAYMENT_LIST_STARTED"
export const GET_BUSINESS_PAYMENT_LIST_SUCCESS = "GET_BUSINESS_PAYMENT_LIST_SUCCESS"
export const GET_BUSINESS_PAYMENT_LIST_FAILURE = "GET_BUSINESS_PAYMENT_LIST_FAILURE"


export const CREATE_BUSINESS_PAYMENT_STARTED = "CREATE_BUSINESS_PAYMENT_STARTED"
export const CREATE_BUSINESS_PAYMENT_SUCCESS = "CREATE_BUSINESS_PAYMENT_SUCCESS"
export const CREATE_BUSINESS_PAYMENT_FAILURE = "CREATE_BUSINESS_PAYMENT_FAILURE"


export const GET_BUSINESS_APPOINTMENT_COUNT_STARTED = "GET_BUSINESS_APPOINTMENT_COUNT_STARTED"
export const GET_BUSINESS_APPOINTMENT_COUNT_SUCCESS = "GET_BUSINESS_APPOINTMENT_COUNT_SUCCESS"
export const GET_BUSINESS_APPOINTMENT_COUNT_FAILURE = "GET_BUSINESS_APPOINTMENT_COUNT_FAILURE"

export const CREATE_BUSINESS_PAYMENT_INTENT_STARTED = "CREATE_BUSINESS_PAYMENT_INTENT_STARTED"
export const CREATE_BUSINESS_PAYMENT_INTENT_SUCCESS = "CREATE_BUSINESS_PAYMENT_INTENT_SUCCESS"
export const CREATE_BUSINESS_PAYMENT_INTENT_FAILURE = "CREATE_BUSINESS_PAYMENT_INTENT_FAILURE"

export const CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_STARTED = "CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_STARTED"
export const CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_SUCCESS = "CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_SUCCESS"
export const CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_FAILURE = "CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_FAILURE"

export const GET_BUSINESS_PAYMENT_DETAIL_STARTED = "GET_BUSINESS_PAYMENT_DETAIL_STARTED"
export const GET_BUSINESS_PAYMENT_DETAIL_SUCCESS = "GET_BUSINESS_PAYMENT_DETAIL_SUCCESS"
export const GET_BUSINESS_PAYMENT_DETAIL_FAILURE = "GET_BUSINESS_PAYMENT_DETAIL_FAILURE"

export const GET_BUSINESS_PAYMENT_PROFILE_STARTED = "GET_BUSINESS_PAYMENT_PROFILE_STARTED"
export const GET_BUSINESS_PAYMENT_PROFILE_SUCCESS = "GET_BUSINESS_PAYMENT_PROFILE_SUCCESS"
export const GET_BUSINESS_PAYMENT_PROFILE_FAILURE = "GET_BUSINESS_PAYMENT_PROFILE_FAILURE"

export const CREATE_BUSINESS_PAYMENT_PROFILE_STARTED = "CREATE_BUSINESS_PAYMENT_PROFILE_STARTED"
export const CREATE_BUSINESS_PAYMENT_PROFILE_SUCCESS = "CREATE_BUSINESS_PAYMENT_PROFILE_SUCCESS"
export const CREATE_BUSINESS_PAYMENT_PROFILE_FAILURE = "CREATE_BUSINESS_PAYMENT_PROFILE_FAILURE"

export const UPDATE_BUSINESS_PAYMENT_PROFILE_STARTED = "UPDATE_BUSINESS_PAYMENT_PROFILE_STARTED"
export const UPDATE_BUSINESS_PAYMENT_PROFILE_SUCCESS = "UPDATE_BUSINESS_PAYMENT_PROFILE_SUCCESS"
export const UPDATE_BUSINESS_PAYMENT_PROFILE_FAILURE = "UPDATE_BUSINESS_PAYMENT_PROFILE_FAILURE"

export const REFUND_PAYMENT_STARTED = "REFUND_PAYMENT_STARTED"
export const REFUND_PAYMENT_SUCCESS = "REFUND_PAYMENT_SUCCESS"
export const REFUND_PAYMENT_FAILURE = "REFUND_PAYMENT_FAILURE"


export const DELETE_PAYMENT_STARTED = "DELETE_PAYMENT_STARTED"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"
export const DELETE_PAYMENT_FAILURE = "DELETE_PAYMENT_FAILURE"


export const SEND_PAYMENT_DECLINE_REMINDER_STARTED = "SEND_PAYMENT_DECLINE_REMINDER_STARTED"
export const SEND_PAYMENT_DECLINE_REMINDER_SUCCESS = "SEND_PAYMENT_DECLINE_REMINDER_SUCCESS"
export const SEND_PAYMENT_DECLINE_REMINDER_FAILURE = "SEND_PAYMENT_DECLINE_REMINDER_FAILURE"

export const GET_COMPLETED_APPOINTMENTS_STARTED = "GET_COMPLETED_APPOINTMENTS_STARTED"
export const GET_COMPLETED_APPOINTMENTS_SUCCESS = "GET_COMPLETED_APPOINTMENTS_SUCCESS"
export const GET_COMPLETED_APPOINTMENTS_FAILURE = "GET_COMPLETED_APPOINTMENTS_FAILURE"

export const GET_BUSINESS_VOID_PAYMENT_PROFILE_STARTED = "GET_BUSINESS_VOID_PAYMENT_PROFILE_STARTED"
export const GET_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS = "GET_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS"
export const GET_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE = "GET_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE"

export const CREATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED = "CREATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED"
export const CREATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS = "CREATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS"
export const CREATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE = "CREATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE"

export const UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED = "UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED"
export const UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS = "UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS"
export const UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE = "UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"