import {
    SEARCH_BUSINESS_STARTED,
    SEARCH_BUSINESS_SUCCESS,
    SEARCH_BUSINESS_FAILURE,
    CLEAR_SEARCH_RESULT,
    ACCESS_PARTNER_DASHBOARD_STARTED,
    ACCESS_PARTNER_DASHBOARD_SUCCESS,
    ACCESS_PARTNER_DASHBOARD_FAILURE,
    REMOVE_ERROR_MESSAGE,
}
    from "../../constants/business/business-search"


const initialState = {
    businesses: [],
    countbusinesses: 0,
    isBusinessTokenGranted: false,
    loading: false,
    error: null,
    message: null,
    component: "BusinessSearchForm" //use this to define in which component  message or error should be displayed
};

export default function searchBusiness(state = initialState, action) {
    switch (action.type) {
        case SEARCH_BUSINESS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessSearchForm"
            }
        case SEARCH_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                businesses: action.payload.businesses,
                countbusinesses: action.payload.countbusinesses,
                message: null,
                error: null,
                component: "BusinessSearchForm"
            }
        case SEARCH_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessSearchForm"
            }
        case CLEAR_SEARCH_RESULT:
            return {
                ...state,
                loading: false,
                businesses: [],
                countbusinesses: 0,
                message: null,
                error: null,
                component: "BusinessSearchForm"
            }
        case ACCESS_PARTNER_DASHBOARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case ACCESS_PARTNER_DASHBOARD_SUCCESS:
            return {
                ...state,
                isBusinessTokenGranted: true,
                loading: false,
                error: null,
                message: null,
            }
        case ACCESS_PARTNER_DASHBOARD_FAILURE:
            return {
                ...state,
                isBusinessTokenGranted: false,
                loading: false,
                error: action.payload.error,
                message: null,
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        default:
            return {
                ...state
            }
    }
}