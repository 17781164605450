import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CountryProvinceCityDropdown from "../common/country-province-city-dropdown/CountryProvinceCityDropdown";
import { countryCodes, TIME_ZONES, LANGUAGE_LIST } from "../../utils/utility";
const languageList = LANGUAGE_LIST();
class CreateNewBusinessForm extends Component {

    render() {
        const { businessTypes, business } = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={business.name}
                            placeholder="Business Name"
                            onChange={this.props.handleChangeBusiness}
                            required
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Form.Label>Business Type</Form.Label>
                        <span className="business-type-tags-container">
                            {business.businessType.map((type) => {
                                return (
                                    <div
                                        key={type}
                                        className="business-type-tag bg-dark"
                                    >
                                        {
                                            businessTypes.filter(
                                                (typeObject) =>
                                                    typeObject._id === type
                                            )[0].title
                                        }
                                        <button
                                            onClick={() =>
                                                this.props.handleRemoveBusinessType(
                                                    type
                                                )
                                            }
                                            type="button"
                                            className="close"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">
                                                &times;
                                            </span>
                                        </button>
                                    </div>
                                );
                            })}
                        </span>
                        <Form.Control
                            as="select"
                            name="businessType"
                            required
                            onChange={this.props.handleChangeBusiness}
                        >
                            <option value="">Select Business Type</option>
                            {businessTypes.map((type) => {
                                return (
                                    <option key={type._id} value={type._id}>
                                        {type.title}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Form.Label>Full Business Address</Form.Label>
                        <Form.Control
                            type="text"
                            name="address"
                            value={business.address}
                            placeholder="Business Address including city, province, postal code"
                            required
                            onChange={this.props.handleChangeBusiness}
                        />
                    </Col>
                </Row>
                <CountryProvinceCityDropdown
                    handleChangeCityProvince={
                        this.props.hangleChangeProvinceCity
                    }
                />
                <Row className="mt-3">
                    <Col>
                        <Form.Label>Time Zone</Form.Label>
                        <Form.Control
                            as="select"
                            value={business.timeZone}
                            onChange={this.props.handleChangeBusiness}
                            name="timeZone"
                            required
                        >
                            <option value="">
                            </option>
                            {TIME_ZONES.filter(timeZone => timeZone.country === this.props.country).map(timeZone => (
                                <option value={timeZone.value} key={timeZone.value}>
                                    {timeZone.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <Form.Label>URL Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="urlName"
                            value={business.urlName}
                            placeholder="example queen-nail-winnipeg"
                            required
                            onChange={this.props.handleChangeBusiness}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Language</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={this.props.handleChangeBusiness}
                            value={business.language}
                            name="language"
                        >
                            {languageList.map((language) => {
                                return (
                                    <option key={language} value={language}>
                                        {language}
                                    </option>
                                );
                            })}
                            <option value='French'>French</option>

                        </Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3" style={{ marginBottom: "20px" }}>
                    <Col>
                        <Form.Label>Phone</Form.Label>
                        <InputGroup>
                            <Form.Control
                                as="select"
                                disabled
                                value={business.country}
                                style={{ maxWidth: '100px' }}
                            >
                                {countryCodes.map(country =>
                                    <option value={country.code} key={country.code}>{country.label}</option>
                                )}
                            </Form.Control>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={business.phone}
                                placeholder="Business Phone"
                                required
                                onChange={this.props.handleChangeBusiness}
                            />
                        </InputGroup>


                    </Col>
                    <Col>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            required
                            placeholder="Email"
                            name="email"
                            value={business.email}
                            onChange={this.props.handleChangeBusiness}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Required activation</Form.Label>
                        <Form.Control
                            as="select"
                            name="isFirstLogin"
                            onChange={this.props.handleChangeBusiness}
                            value={business.isFirstLogin}
                        >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </Form.Control>
                    </Col>
                    {/* {console.log(business.isFirstLogin)} */}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateNewBusinessForm)
);
