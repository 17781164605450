import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Container,
    Form,
    ListGroup,
    InputGroup,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { createBusinessPaymentIntent, getBusinessCompletedAppointment } from "../../../redux/actions/business-payment/business-payment"
import moment from 'moment'
import Loading from "../../../layouts/Loading/Loading";
import DateRangePicker from "react-bootstrap-daterangepicker";


class WeeklyPaymentForm extends Component {
    state = {
        from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        pricePerBooking: 1.99,
        commission: {
            value: 0,
            pricePerBooking: 1.99,
            dashbookingCompletedAppointments: 0,
            from: "",
            to: "",
            completedAppointmentList: []
        },
        provinceName: null,
        subtotal: 0.0,
        taxes: [{ name: "", rate: 0.0 }],
        totalTax: 0.0,
        note: "",
        discount: {
            type: "percentage",
            value: 0.0
        },
        total: 0.0,
        disableSubmitButton: false,
    };

    componentDidMount() {
        const { paymentProfile } = this.props
        this.getTaxes();
        this.props.getBusinessCompletedAppointment(this.props.business._id, this.state.from, this.state.to)
        this.setState({
            pricePerBooking: paymentProfile.pricePerBooking,
        }, () => {
            this.calculateTotal();
        })
    };

    componentDidUpdate(prevProps) {
        const { completedAppointments } = this.props
        if (prevProps.completedAppointments !== completedAppointments) {
            this.calculateTotal();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { commission, taxes, subtotal, discount, total, note } = this.state

        let payment = { commission, taxes, subtotal, discount, total, note }
        payment.business = this.props.business._id
        this.setState({
            disableSubmitButton: true
        })
        this.props.createBusinessPaymentIntent(payment)
    };

    handleChangeNote = value => {
        this.setState({
            note: value,
            disableSubmitButton: false,
        });
    };

    calculateTotal = () => {
        let { pricePerBooking, commission, subtotal, taxes, discount, total, from, to } = this.state;

        commission.pricePerBooking = pricePerBooking
        commission.dashbookingCompletedAppointments = this.props.completedAppointments.length
        commission.from = from
        commission.to = to
        commission.completedAppointmentList = this.props.completedAppointments
        commission.value = parseFloat(pricePerBooking * this.props.completedAppointments.length)

        subtotal = commission.value

        const totalDiscount =
            discount.type === "amount"
                ? discount.value
                : (subtotal * discount.value) / 100;

        const totalTax = taxes.reduce(
            (total, tax) =>
                total + (tax.rate * (subtotal - totalDiscount)) / 100,
            0
        );

        total = subtotal + totalTax - totalDiscount;

        this.setState({
            commission,
            subtotal,
            totalDiscount,
            totalTax,
            total,
            disableSubmitButton: false,
        });

    };

    getTaxes = () => {
        const { provinces, business } = this.props;
        const province = provinces.filter(
            pro => pro._id === business.province
        )[0];

        let taxes = [];

        if (province && province.taxes.length > 0) {
            province.taxes.forEach(provinceTax => {
                const tax = {
                    name: provinceTax.name.toUpperCase(),
                    rate: provinceTax.rate,
                };
                taxes = [...taxes, tax];
            });
            taxes = taxes.filter(tax => tax.rate !== 0);
        }

        this.setState({ taxes, provinceName: province && province.name });
    };

    handleChangeDiscountType = () => {
        let { discount } = this.state;
        if (discount.type === "percentage") {
            discount.type = "amount";
        } else {
            discount.type = "percentage";
        }

        this.setState({
            discount
        });
        this.calculateTotal();
    };

    handleChangeRate = (value) => {
        this.setState({ rate: value }, () => {
            this.calculateTotal();
        });
    }

    handleChangePricePerBooking = (value) => {
        this.setState({ pricePerBooking: value }, () => {
            this.calculateTotal();
        });
    }


    handleChangeDiscount = value => {
        const { discount } = this.state;
        discount.value = value;
        if (!isNaN(parseFloat(value))) {
            discount.value = parseFloat(value);
            this.calculateTotal();
        }
        this.setState({ discount, disableSubmitButton: false });
    };

    handleChangeFrom = value => {
        this.setState({ from: value, disableChooseWeekButton: false })
    }
    handleChangeTo = value => {
        this.setState({ to: value, disableChooseWeekButton: false })
    }

    chooseWeek = (event, picker) => {
        let { from, to } = this.state;

        from = picker.startDate.format("YYYY-MM-DD");
        to = picker.endDate.format("YYYY-MM-DD");

        this.setState({ from, to }, () => {
            this.props.getBusinessCompletedAppointment(this.props.business._id, from, to)
        });

    };

    render() {
        const { from, to, pricePerBooking, commission, provinceName, taxes, discount, note, disableSubmitButton,
            total, subtotal, totalTax, totalDiscount } = this.state
        const { loadingCompleteAppointment } = this.props
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <h4>
                            Weekly Payment
                        </h4>
                    </Col>
                </Row>
                <Row className="justify-content-md-center" style={{ margin: "10px 0 20px 0" }}>
                    <Col md="auto">
                        <DateRangePicker
                            initialSettings={{
                                startDate: from,
                                endDate: to,
                                maxDate: moment(),
                                opens: "center",
                                showDropdowns: true,
                                autoApply: true,
                                locale: { format: "YYYY-MM-DD" },
                                ranges: {
                                    'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    'Last week': [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')],
                                },
                            }}
                            onHide={this.chooseWeek}
                        >
                            <Button variant="outline-primary">
                                <div>From: {moment(from).format("MMM DD, YYYY")}</div>
                                <div>To: {moment(to).format("MMM DD, YYYY")}</div>
                            </Button>
                        </DateRangePicker>
                    </Col>
                </Row>

                {!loadingCompleteAppointment ?
                    <Form >
                        <Form.Group >
                            <Form.Label>Price per booking ${commission.pricePerBooking} on {commission.dashbookingCompletedAppointments} Dash appointments - ${(commission.pricePerBooking * commission.dashbookingCompletedAppointments).toFixed(2)}</Form.Label>
                            <Form.Control
                                value={pricePerBooking}
                                onChange={(e) =>
                                    this.handleChangePricePerBooking(e.target.value)
                                }
                                type="number"
                                placeholder="$ Price"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label controlid='subtotal'>Subtotal</Form.Label>
                            <Form.Control
                                type='number'
                                readOnly
                                placeholder={"$ " + subtotal.toFixed(2)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label controlid='discount'>
                                Discount ($ or %){" "}
                                {totalDiscount > 0 && "( - $" + totalDiscount + ")"}
                            </Form.Label>
                            <InputGroup className='mb-2'>
                                <InputGroup.Prepend
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleChangeDiscountType()}
                                >
                                    <InputGroup.Text>
                                        {discount.type === "percentage" ? "%" : "$"}
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type='number'
                                    id='discount'
                                    onChange={e =>
                                        this.handleChangeDiscount(e.target.value)
                                    }
                                    value={discount.value}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label controlid='taxes'>
                                Taxes in {provinceName}{" "}
                                {totalTax > 0 && "($ " + totalTax.toFixed(2) + ")"}
                            </Form.Label>
                            <ListGroup
                                style={{ cursor: "default" }}
                                flush='true'
                                horizontal
                            >
                                {taxes.map(tax => {
                                    return (
                                        <ListGroup.Item
                                            variant='light'
                                            key={tax.name}
                                        >
                                            {tax.name}: {tax.rate}%
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label controlid='total'>Total</Form.Label>
                            <Form.Control
                                id='total'
                                readOnly
                                value={"$ " + total.toFixed(2)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label controlid='note'>Note</Form.Label>
                            <Form.Control
                                as='textarea'
                                id='note'
                                rows={3}
                                onChange={e =>
                                    this.handleChangeNote(e.target.value)
                                }
                                value={note}
                            />
                        </Form.Group>

                        <Button disabled={disableSubmitButton} onClick={(e) => this.handleSubmit(e)} className='mb-2'>
                            Create a payment
                        </Button>

                    </Form>
                    : <div style={{ marginTop: "500px" }}><Loading /></div>
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    business: state.business.business,
    paymentProfile: state.businessReport.paymentProfile,
    completedAppointments: state.businessReport.completedAppointments,
    loadingCompleteAppointment: state.businessReport.loadingCompleteAppointment,
    provinces: state.province.provinces,
});

const mapDispatchToProps = dispatch => {
    return {
        createBusinessPaymentIntent: payment => dispatch(createBusinessPaymentIntent(payment)),
        getBusinessCompletedAppointment: (business, from, to) => dispatch(getBusinessCompletedAppointment(business, from, to)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyPaymentForm);
