import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
export const AccountantNavigation = () => {
    return (
        <Nav className="mr-auto">
            {/* <Nav.Link href="/accounts">Accounts</Nav.Link> */}
            <Nav.Link href="/businesses">Businesses</Nav.Link>
            <NavDropdown title="Clients" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/clients/search">
                    Search
                </NavDropdown.Item>
                <NavDropdown.Item href="/clients/statistics">
                    Statistics
                </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/payments">Payments</Nav.Link>
        </Nav>
    );
};
