import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux"
const BusinessReportNavigation = (props) => {
    const user = useSelector((state) => state.authenticate.user)
    const userRole = user.role
    const paymentProfile = useSelector(state => state.businessReport.paymentProfile)
    const isVoidChequePayment = paymentProfile.paymentType === "Void Cheque"
    return (
        <Nav variant='pills' className='flex-column' style={{ position: "sticky", top: "20px" }} >

            <Nav.Item>
                <Nav.Link eventKey='paymentProfile' onClick={() => props.handleChangeTab("paymentProfile")}><span className="oi oi-person" /> Payment Profile</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
                <Nav.Link eventKey='general' onClick={() => props.handleChangeTab("general")}><span className="oi oi-home" /> General</Nav.Link>
            </Nav.Item> */}
            {/* {["admin", "developer", "accountant"].includes(userRole) && !isVoidChequePayment && <Nav.Item>
                <Nav.Link eventKey='weeklyPayment' onClick={() => props.handleChangeTab("weeklyPayment")}><span className="oi oi-calendar" /> Weekly Payment</Nav.Link>
            </Nav.Item>} */}

            {["admin", "developer", "accountant"].includes(userRole) && <Nav.Item>
                <Nav.Link eventKey='newMonthlyPayment' onClick={() => props.handleChangeTab("newMonthlyPayment")}><span className="oi oi-credit-card" /> New Monthly Payment</Nav.Link>
            </Nav.Item>}
            {/*             
            {["admin", "developer", "accountant"].includes(userRole) && <Nav.Item>
                <Nav.Link eventKey='monthlyPayment' onClick={() => props.handleChangeTab("monthlyPayment")}><span className="oi oi-credit-card" /> Monthly Payment</Nav.Link>
            </Nav.Item>} */}
            {/* {["admin", "developer", "accountant"].includes(userRole) && isVoidChequePayment && <Nav.Item>
                <Nav.Link eventKey='weeklyPaymentVoid' onClick={() => props.handleChangeTab("weeklyPaymentVoid")}><span className="oi oi-credit-card" /> Weekly Payment Void</Nav.Link>
            </Nav.Item>} */}

            <Nav.Item>
                <Nav.Link eventKey='paymentHistory' onClick={() => props.handleChangeTab("paymentHistory")}><span className="oi oi-clock" /> Payment History</Nav.Link>
            </Nav.Item>

            {["admin", "developer", "accountant"].includes(userRole) && isVoidChequePayment && <Nav.Item>
                <Nav.Link eventKey='paymentProfileVoid' onClick={() => props.handleChangeTab("paymentProfileVoid")}><span className="oi oi-people" /> Void Check Profile</Nav.Link>
            </Nav.Item>}

            <Nav.Item>
                <Nav.Link style={{ fontWeight: "bold", fontSize: "20px", marginTop: "20px" }} eventKey='businessDetails' href={`/businesses/${props.businessId}`}><span className="oi oi-external-link"></span> Business Details</Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default BusinessReportNavigation;