import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col } from "react-bootstrap";
import { updateBusinessOnlineStatus } from "../../../redux/actions/business/business";

class ActivateContainer extends Component {
    handleChangeOnlineStatus = (businessId) => {
        this.props.updateBusinessOnlineStatus(businessId)
    }

    render() {
        const { business } = this.props
      
        return (
            <div>
                <Row>
                    <Col>
                        <Form.Check
                            type="switch"
                            id="active"
                            label="Active"
                            name="active"
                            defaultChecked={business.online}
                            onClick={() => this.handleChangeOnlineStatus(business._id)}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
    return {
        updateBusinessOnlineStatus: (_id) => dispatch(updateBusinessOnlineStatus(_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateContainer)
