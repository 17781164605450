export const GET_ALL_BUSINESS_TYPE_STARTED = "GET_ALL_BUSINESS_TYPE_STARTED"
export const GET_ALL_BUSINESS_TYPE_SUCCESS = "GET_ALL_BUSINESS_TYPE_SUCCESS"
export const GET_ALL_BUSINESS_TYPE_FAILURE = "GET_ALL_BUSINESS_TYPE_FAILURE"


export const UPDATE_BUSINESS_TYPE_STARTED = "UPDATE_BUSINESS_TYPE_STARTED"
export const UPDATE_BUSINESS_TYPE_SUCCESS = "UPDATE_BUSINESS_TYPE_SUCCESS"
export const UPDATE_BUSINESS_TYPE_FAILURE = "UPDATE_BUSINESS_TYPE_FAILURE"

export const ADD_BUSINESS_TYPE_STARTED = "ADD_BUSINESS_TYPE_STARTED"
export const ADD_BUSINESS_TYPE_SUCCESS = "ADD_BUSINESS_TYPE_SUCCESS"
export const ADD_BUSINESS_TYPE_FAILURE = "ADD_BUSINESS_TYPE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"