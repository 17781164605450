import React from "react";
import { Form } from "react-bootstrap";

export const ProvinceDropDown = props => {
    const provinces = props.provinces;
    const province = props.city ?props.city.province : props.province
   
    const city = props.city._id || null
   
    return (
        <Form.Control
            as='select'
            name='province'
            required
            custom='true'
            value={province}
            onChange={props.handleChange}
            disabled={city ? false : true}
           
        >
            <option value="">Select Province</option>
            {provinces.map(province => {
                return (
                    <option key={province._id} value={province._id}>
                        {province.name}
                    </option>
                );
            })}
        </Form.Control>
    );
};
