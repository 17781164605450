import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    Container,
    Button,
    Card,
    Badge
} from "react-bootstrap";
import {
    getAllNotes,
    addNewNote,
    updateNote,
    deleteSelectedNote
} from "../../../redux/actions/business/note";
import { connect } from "react-redux";
import Loading from "../../../layouts/Loading/Loading";
import "moment-timezone";
import NoteModal from "./NoteModal";

class NoteInfoContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            note: "",
            noteModalShow: false,
            selectedNote: null
        };
    }
    componentDidMount() {
        if (this.props.business) {
            const businessId = this.props.business._id;
            this.props.getAllNotes(businessId);
        }
    }
    handleChange = e => {
        let field = e.target.name;
        let value = e.target.value;
        if (field === "note") {
            if (this.state.selectedNote) {
                let { selectedNote } = this.state;
                selectedNote.note = value;
                this.setState({
                    selectedNote
                });
            } else
                this.setState({
                    note: value
                });
        }
    };
    handleAddNote = e => {
        e.preventDefault();

        const { note } = this.state;
        const { business } = this.props;
        if (note !== "") {
            const newNote = {
                note: note,
                email: "",
                employeeAccount: "",
                business: business._id
            };
            this.props.addNewNote(newNote);
            this.setState({
                note: ""
            });
        }
    };
    formatDate = notes => {
        if (notes) {
            notes = notes.map(note => {
                var moment = require("moment");
                let createdDate = "";
                let updatedDate = "";
                if (note.createdAt !== "") {
                    createdDate = moment(note.createdAt)
                        .tz("America/Winnipeg")
                        .format("YYYY-MM-DD HH:mm:ss");
                }
                if (note.updatedAt !== "") {
                    updatedDate = moment(note.updatedAt)
                        .tz("America/Winnipeg")
                        .format("YYYY-MM-DD HH:mm:ss");
                }

                if (
                    createdDate !== "" &&
                    updatedDate !== "" &&
                    createdDate !== updatedDate
                ) {
                    note.createdAt = createdDate;
                    note.updatedAt = updatedDate;
                } else {
                    note.createdAt = createdDate;
                    note.updatedAt = "";
                }
                return note;
            });
        }
        return notes;
    };
    openCloseModal = e => {
        this.setState({
            noteModalShow: !this.state.noteModalShow,
            selectedNote: null
        });
    };
    openCloseModalForUpdate = note => {
        this.setState({
            noteModalShow: true,
            selectedNote: note
        });
    };
    updateSelectedNote = e => {
        e.preventDefault();
        if (this.state.selectedNote !== null) {
            this.props.updateNote(this.state.selectedNote);
            this.openCloseModal();
        }
    };
    render() {
        const { loading, notes } = this.props;
        this.formatDate(notes);
        if (loading) {
            return <Loading />;
        }
        return (
            <div style={{ marginBottom: "100px" }}>
                <Container>
                    <Form style={{ width: "100%" }}>
                        <Row>
                            <Col lg={10} className='mt-3 '>
                                <Form.Group controlId='exampleForm.ControlTextarea1'>
                                    <Form.Label>
                                        <h3>Notes</h3>
                                    </Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows='3'
                                        name='note'
                                        value={this.state.note}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={1}>
                                <Button
                                    variant='success'
                                    type='submit'
                                    onClick={this.handleAddNote}
                                    style={{
                                        border: 0,
                                        marginTop: 70,
                                        font: "1.4em sans-serif"
                                    }}
                                >
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                {notes.length !== 0 && (
                    <Container>
                        <Row
                            style={{
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            {notes.map((note, index) => {
                                return (
                                    <div key={index} style={{ width: "100%" }}>
                                        <Row
                                            style={{
                                                marginLeft: 10,
                                                width: "90%"
                                            }}
                                        >
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginBottom: 10
                                                }}
                                            >
                                                <Card.Header
                                                    as='h6'
                                                >
                                                    <Row>
                                                        <Col
                                                            md={9}
                                                            className='mt-2'
                                                        >
                                                            {note.createdAt}
                                                            <span style={{ color: "#a6a6a6", marginLeft: "10px", fontSize: "14px" }}>created by </span>
                                                            <a href={"mailto:" + note.email}  >
                                                                {note.email}
                                                            </a>
                                                            {note.updatedAt && (
                                                                <div style={{ marginTop: "7px" }}>
                                                                    <Badge
                                                                        pill='true'
                                                                        variant='info'
                                                                    >
                                                                        Last Modifed at{" "}
                                                                        {note.updatedAt}
                                                                    </Badge>
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col md={3}>
                                                            <Row className='float-right'>
                                                                <Col>
                                                                    <Button
                                                                        size="sm"
                                                                        variant='link'
                                                                        onClick={() =>
                                                                            this.openCloseModalForUpdate(
                                                                                Object.assign(
                                                                                    {},
                                                                                    note
                                                                                )
                                                                            )
                                                                        }
                                                                    >
                                                                        <span
                                                                            className='oi oi-pencil'
                                                                            title='icon name'
                                                                            aria-hidden='true'
                                                                        ></span>{" "}
                                                                    </Button>
                                                                </Col>
                                                                <Col>
                                                                    <Button
                                                                        size="sm"
                                                                        variant='link'
                                                                        className='text-danger'
                                                                        onClick={() => {
                                                                            const selectedNote = Object.assign(
                                                                                {},
                                                                                note
                                                                            );
                                                                            const confirmed = window.confirm(
                                                                                "Are your sure to delete this note ?"
                                                                            );
                                                                            if (
                                                                                confirmed
                                                                            ) {
                                                                                this.props.deleteSelectedNote(
                                                                                    selectedNote._id
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className='oi oi-trash'
                                                                            title='icon name'
                                                                            aria-hidden='true'
                                                                        ></span>{" "}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    {note.note}
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                        <NoteModal
                                            show={this.state.noteModalShow}
                                            onHide={this.openCloseModal}
                                            handleChange={this.handleChange}
                                            selectedNote={
                                                this.state.selectedNote
                                            }
                                            updateSelectedNote={
                                                this.updateSelectedNote
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </Row>
                    </Container>
                )}
            </div>
        );
    }
};

const mapStateToProps = state => ({
    notes: state.note.notes,
    loading: state.note.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getAllNotes: businessId => dispatch(getAllNotes(businessId)),
        addNewNote: note => dispatch(addNewNote(note)),
        updateNote: note => dispatch(updateNote(note)),
        deleteSelectedNote: noteId => dispatch(deleteSelectedNote(noteId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteInfoContainer);
