import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { formatPhoneNumber } from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber"
import { getAllEmployees } from "../../../redux/actions/admin/employee";
import queryString from "query-string";
class SearchForm extends Component {
    state = {
        search: {
            name: "",
            email: "",
            phone: "",
            active: "",
            role: ""
        },
        inputChanged: false,
        formSubmited: false,

    };

    componentDidMount() {
        const queryObject = queryString.parse(this.props.location.search)
        let { search } = this.state
        for (const [key, value] of Object.entries(queryObject)) {
            if (key !== "page" && value) {
                search[key] = value
            }
        }
        this.setState({
            search
        })
    }

    handleChange = e => {
        let { search } = this.state;
        let field = e.target.name;
        let value = e.target.value;
        if (field === "phone") {
            value = formatPhoneNumber(value, "CA")
        }
        search[field] = value
        this.setState({
            search,
            inputChanged: true
        })
    };
    handleSubmit = (e) => {
        e.preventDefault()
        const { search } = this.state
        let query = "?page=1";
        for (let [key, value] of Object.entries(search)) {
            if (value) {
                query += `&${key}=${value}`
            }
        }
        this.props.history.push(query)
        this.props.getAllEmployees(query)
        this.setState({
            formSubmited: true
        })
    }

    handleResetSearchForm = (e) => {
        const query = "?page=1"
        const { formSubmited } = this.state

        this.setState({
            search: {
                name: "",
                email: "",
                phone: "",
                active: "",
                role: ""
            },
            inputChanged: false,
            formSubmited: false
        })
        if (formSubmited) {
            this.props.getAllEmployees(query)
            this.props.history.push(query)
        }

    }
    render() {
        let { search, inputChanged } = this.state
        return (
            <Form md={12} onSubmit={this.handleSubmit}>
                <h3>Search</h3>
                <Form.Group>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Label> Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={search.name}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label> Email</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='Email'
                                name='email'
                                value={search.email}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Label> Phone</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Phone'
                                name='phone'
                                value={search.phone}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label> Active</Form.Label>
                            <Form.Control
                                as='select'
                                name='active'
                                custom='true'
                                value={search.active}
                                onChange={this.handleChange}
                            >
                                <option ></option>
                                <option value='true'>Active</option>
                                <option value='false'>Inactive</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Label> Role</Form.Label>
                            <Form.Control
                                as='select'
                                name='role'
                                custom='true'
                                onChange={this.handleChange}
                                value={search.role}
                            >
                                <option ></option>
                                <option value='dashbooking_employee'>
                                    Employee
                                </option>
                                <option value='dashbooking_accountant'>
                                    Accountant
                                </option>
                                <option value='dashbooking_developer'>
                                    Developer
                                </option>
                                <option value='dashbooking_admin'>
                                    Admin
                                </option>
                            </Form.Control>
                        </Col>
                        <Col md={6} className='mt-4'>
                            <Button variant='primary' type="submit" className='mr-3' disabled={!inputChanged ? true : false}>
                                {" "}
                                <span
                                    className='oi oi-magnifying-glass'
                                    title='icon name'
                                    aria-hidden='true'
                                ></span>{" "}
                                Search
                            </Button>
                            <Button variant='secondary' onClick={this.handleResetSearchForm}>
                                <span
                                    className='oi oi-x'
                                    title='icon name'
                                    aria-hidden='true'
                                ></span>{" "}
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
        getAllEmployees: query => dispatch(getAllEmployees(query))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
