export const ADD_STAFF_STARTED = "ADD_STAFF_STARTED"
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS"
export const ADD_STAFF_FAILURE = "ADD_STAFF_FAILURE"

export const GET_ALL_STAFFS_STARTED = "GET_ALL_STAFFS_STARTED"
export const GET_ALL_STAFFS_SUCCESS = "GET_ALL_STAFFS_SUCCESS"
export const GET_ALL_STAFFS_FAILURE = "GET_ALL_STAFFS_FAILURE"

export const UPDATE_STAFF_STARTED = "UPDATE_STAFF_STARTED"
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS"
export const UPDATE_STAFF_FAILURE = "UPDATE_STAFF_FAILURE"

export const UPDATE_STAFF_SERVICES_STARTED = "UPDATE_STAFF_SERVICES_STARTED"
export const UPDATE_STAFF_SERVICES_SUCCESS = "UPDATE_STAFF_SERVICES_SUCCESS"
export const UPDATE_STAFF_SERVICES_FAILURE = "UPDATE_STAFF_SERVICES_FAILURE"

export const DELETE_STAFF_STARTED = "DELETE_STAFF_STARTED"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAILURE = "DELETE_STAFF_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"