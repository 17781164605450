import {
    GET_BUSINESS_SUMMARY_STARTED,
    GET_BUSINESS_SUMMARY_SUCCESS,
    GET_BUSINESS_SUMMARY_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/business-summary";

const initialState = {
    business: {},
    businessHour: {},
    staffs: [],
    staffHours: [],
    categories: [],
    services: [],
    taxes: [],
    loading: false,
    error: null,
    message: null,
    component: null
};

export default function businessSummary(state = initialState, action) {
    switch (action.type) {
        case GET_BUSINESS_SUMMARY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessPreview"
            };
        case GET_BUSINESS_SUMMARY_SUCCESS:
            return {
                ...state,
                business: action.payload.businessData.business,
                businessHour: action.payload.businessData.businessHour,
                staffs: mapOrder(
                    action.payload.businessData.staffs,
                    action.payload.businessData.business.staffs,
                    "_id"
                ),
                staffHours: action.payload.businessData.staffHours,
                categories: mapOrder(
                    action.payload.businessData.categories,
                    action.payload.businessData.business.serviceCategories,
                    "_id"
                ),
                services: action.payload.businessData.services,
                taxes: action.payload.businessData.taxes,
                error: null,
                message: null,
                component: "BusinessPreview",
                loading: false,
            };
        case GET_BUSINESS_SUMMARY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessPreview"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}

/**
 * Sort array of objects based on another array
 */

function mapOrder(array, order, key) {
    array.sort(function(a, b) {
        var A = a[key],
            B = b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        } else {
            return -1;
        }
    });
    return array;
}
