import {
    GET_CACELLATION_SETTINGS_STARTED,
    GET_CACELLATION_SETTINGS_SUCCESS,
    GET_CACELLATION_SETTINGS_FAILURE,
    UPDATE_CACELLATION_SETTINGS_STARTED,
    UPDATE_CACELLATION_SETTINGS_SUCCESS,
    UPDATE_CACELLATION_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from '../../constants/cancellation-settings/cancellationSettings'

import { LOGOUT } from "../../constants/employee-account/login";
import API from "../../../utils/API/API";

export const getCancellationSettings = (businessId) => {
    return dispatch => {
        dispatch(getCancellationSettingsStarted())
        API.get(`/api/cancellation-settings/get/${businessId}`)
            .then(res => {
                dispatch(getCancellationSettingsSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, getCancellationSettingsFailure);
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const getCancellationSettingsStarted = () => ({
    type: GET_CACELLATION_SETTINGS_STARTED
})

const getCancellationSettingsSuccess = (cancellationSettings) => ({
    type: GET_CACELLATION_SETTINGS_SUCCESS,
    payload: { cancellationSettings }
})

const getCancellationSettingsFailure = (error) => ({
    type: GET_CACELLATION_SETTINGS_FAILURE,
    payload: { error }
})

export const updateCancellationSettings = (cancellationSettings) => {
    return dispatch => {
        dispatch(updateCancellationSettingsStarted())
        API.post(`/api/cancellation-settings/update`, cancellationSettings)
            .then(res => {
                dispatch(updateCancellationSettingsSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, updateCancellationSettingsFailure);
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const updateCancellationSettingsStarted = () => ({
    type: UPDATE_CACELLATION_SETTINGS_STARTED
})

const updateCancellationSettingsSuccess = (cancellationSettings) => ({
    type: UPDATE_CACELLATION_SETTINGS_SUCCESS,
    payload: { cancellationSettings }
})

const updateCancellationSettingsFailure = (error) => ({
    type: UPDATE_CACELLATION_SETTINGS_FAILURE,
    payload: { error }
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
