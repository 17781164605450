import {
    CREATE_BUSINESS_STARTED,
    CREATE_BUSINESS_SUCCESS,
    CREATE_BUSINESS_FAILURE,

    GET_BUSINESS_DETAIL_STARTED,
    GET_BUSINESS_DETAIL_SUCCESS,
    GET_BUSINESS_DETAIL_FAILURE,

    UPDATE_BUSINESS_GENERAL_INFO_STARTED,
    UPDATE_BUSINESS_GENERAL_INFO_SUCCESS,
    UPDATE_BUSINESS_GENERAL_INFO_FAILURE,

    UPDATE_BUSINESS_LOCATION_STARTED,
    UPDATE_BUSINESS_LOCATION_SUCCESS,
    UPDATE_BUSINESS_LOCATION_FAILURE,

    UPDATE_BUSINESS_ABOUT_STARTED,
    UPDATE_BUSINESS_ABOUT_SUCCESS,
    UPDATE_BUSINESS_ABOUT_FAILURE,

    UPDATE_BUSINESS_SCORE_STARTED,
    UPDATE_BUSINESS_SCORE_SUCCESS,
    UPDATE_BUSINESS_SCORE_FAILURE,

    UPLOAD_DISPLAY_IMAGE_STARTED,
    UPLOAD_DISPLAY_IMAGE_SUCCESS,
    UPLOAD_DISPLAY_IMAGE_FAILURE,

    REMOVE_DISPLAY_IMAGE_STARTED,
    REMOVE_DISPLAY_IMAGE_SUCCESS,
    REMOVE_DISPLAY_IMAGE_FAILURE,

    UPLOAD_IMAGE_STARTED,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,

    UPDATE_SENDER_PHONES_FAILURE,
    UPDATE_SENDER_PHONES_STARTED,
    UPDATE_SENDER_PHONES_SUCCESS,

    REMOVE_IMAGE_STARTED,
    REMOVE_IMAGE_SUCCESS,
    REMOVE_IMAGE_FAILURE,

    UPDATE_BUSINESS_IMAGES_STARTED,
    UPDATE_BUSINESS_IMAGES_SUCCESS,
    UPDATE_BUSINESS_IMAGES_FAILURE,

    UPDATE_BUSINESS_ACTIVE_FEATURES_STARTED,
    UPDATE_BUSINESS_ACTIVE_FEATURES_SUCCESS,
    UPDATE_BUSINESS_ACTIVE_FEATURES_FAILURE,

    UPDATE_BUSINESS_ONLINE_STATUS_STARTED,
    UPDATE_BUSINESS_ONLINE_STATUS_SUCCESS,
    UPDATE_BUSINESS_ONLINE_STATUS_FAILURE,

    UPDATE_BUSINESS_SOCIAL_MEDIA_STARTED,
    UPDATE_BUSINESS_SOCIAL_MEDIA_SUCCESS,
    UPDATE_BUSINESS_SOCIAL_MEDIA_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/business/business";

const initialState = {
    businesses: [],
    business: null,
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function business(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SENDER_PHONES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case UPDATE_SENDER_PHONES_SUCCESS:
            return {
                ...state,
                business: {
                    ...state.business,
                    senderPhones: action.payload.data.senderPhones,
                    loading: false,
                    error: null,
                    message: "Sender phones updated successfully"
                },
            }
        case UPDATE_SENDER_PHONES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            }
        case CREATE_BUSINESS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "CreateNewBusinessForm"
            };
        case CREATE_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.business,
                error: null,
                message: "Business was created successfully!",
                component: "CreateNewBusinessForm"
            };
        case CREATE_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "CreateNewBusinessForm"
            };
        case GET_BUSINESS_DETAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessDetailContainer"
            };
        case GET_BUSINESS_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.business,
                error: null,
                message: null,
                component: "BusinessDetailContainer"
            };
        case GET_BUSINESS_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessDetailContainer"
            };
        case UPDATE_BUSINESS_GENERAL_INFO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "GeneralInfoForm"
            };
        case UPDATE_BUSINESS_GENERAL_INFO_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null,
                message: "Business general information was updated",
                component: "GeneralInfoForm"
            };
        case UPDATE_BUSINESS_GENERAL_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "GeneralInfoForm"
            };
        case UPDATE_BUSINESS_LOCATION_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "LocationInfoForm"
            }
        case UPDATE_BUSINESS_LOCATION_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null,
                message: "Business location information was updated",
                component: "LocationInfoForm"
            }
        case UPDATE_BUSINESS_LOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "LocationInfoForm"
            }

        case UPDATE_BUSINESS_ABOUT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "AboutInfoForm"
            }
        case UPDATE_BUSINESS_ABOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.business,
                error: null,
                message: "Business about information was updated",
                component: "AboutInfoForm"
            }
        case UPDATE_BUSINESS_ABOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "AboutInfoForm"
            }

        case UPDATE_BUSINESS_SCORE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ScoreInfoForm"
            }
        case UPDATE_BUSINESS_SCORE_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.business,
                error: null,
                message: "Business Score updated",
                component: "ScoreInfoForm"
            }
        case UPDATE_BUSINESS_SCORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ScoreInfoForm"
            }
        case UPLOAD_DISPLAY_IMAGE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ImageContainer"
            }
        case UPLOAD_DISPLAY_IMAGE_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null,
                message: "Display image uploaded",
                component: "ImageContainer"
            }
        case UPLOAD_DISPLAY_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ImageContainer"
            }
        case REMOVE_DISPLAY_IMAGE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ImageContainer"
            }
        case REMOVE_DISPLAY_IMAGE_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null,
                message: "Display image deleted",
                component: "ImageContainer"
            }
        case REMOVE_DISPLAY_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ImageContainer"
            }
        case UPLOAD_IMAGE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ImageContainer"
            }
        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null,
                message: "Image uploaded",
                component: "ImageContainer"
            }
        case UPLOAD_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ImageContainer"
            }
        case REMOVE_IMAGE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ImageContainer"
            }
        case REMOVE_IMAGE_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null,
                message: "Image deleted",
                component: "ImageContainer"
            }
        case REMOVE_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ImageContainer"
            }
        case UPDATE_BUSINESS_IMAGES_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "ImageTable"
            }
        case UPDATE_BUSINESS_IMAGES_SUCCESS:

            return {
                ...state,
                business: {
                    ...state.business,
                    images: action.payload.businessImages.images
                },
                loading: false,
                error: null,
                message: null,
                component: "ImageTable"
            }
        case UPDATE_BUSINESS_IMAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ImageTable"
            }
        case UPDATE_BUSINESS_ONLINE_STATUS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessActivateForm"
            }
        case UPDATE_BUSINESS_ONLINE_STATUS_SUCCESS:
            return {
                ...state,
                business: {
                    ...state.business,
                    online: action.payload.data.online
                },
                loading: false,
                message: `${action.payload.data.name} is now ${action.payload.data.online ? 'ONLINE' : 'OFFLINE'}`,
            }
        case UPDATE_BUSINESS_ONLINE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case UPDATE_BUSINESS_ACTIVE_FEATURES_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "BusinessActiveFeaturesForm"
            }
        case UPDATE_BUSINESS_ACTIVE_FEATURES_SUCCESS:
            return {
                ...state,
                business: {
                    ...state.business,
                    activeFeatures: action.payload.data.business.activeFeatures
                },
                loading: false,
                message: `${action.payload.data.formattedFeature} is now ${action.payload.data.business.activeFeatures.includes(action.payload.data.updateFeature) ? 'ON' : 'OFF'}`,
            }
        case UPDATE_BUSINESS_ACTIVE_FEATURES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        case UPDATE_BUSINESS_SOCIAL_MEDIA_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            }
        case UPDATE_BUSINESS_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                business: {
                    ...state.business,
                    socialMedia: action.payload.data.business.socialMedia
                },
                loading: false,
                message: "Business media has been updated!",
            }
        case UPDATE_BUSINESS_SOCIAL_MEDIA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }


        // case REMOVE_BUSINESS_ACCOUNT_STARTED:
        //     return {
        //         ...state,
        //         loading: true,
        //         error: null,
        //         message: null,
        //         component: "AccountContainer"
        //     }
        // case REMOVE_BUSINESS_ACCOUNT_SUCCESS: 
        //     return {
        //         ...state,
        //         loading: false,
        //         error: null,
        //         message: "Account was removed from the business",
        //         business: action.payload.business,
        //         component: "AccountContainer"
        //     }
        // case REMOVE_BUSINESS_ACCOUNT_FAILURE: 
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload.error,
        //         message: null,
        //         component: "AccountContainer"
        //     }

        // case GET_BUSINESS_ACCOUNT_STARTED: 
        //     return {
        //         ...state,
        //         loading: true,
        //         error: null,
        //         message: null,
        //         component: "AccountContainer.jsx"
        //     }
        // case GET_BUSINESS_ACCOUNT_SUCCESS:
        //     return {
        //         ...state,
        //         business: {
        //             ...state.business,
        //             account: action.payload.account
        //         },
        //         loading: false,
        //         error: null,
        //         message: null,
        //         component: "AccountContainer.jsx"
        //     }
        // case GET_BUSINESS_ACCOUNT_FAILURE: 
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload.error,
        //         message: null,
        //         component: "AccountContainer.jsx"
        //     }

        // case UPDATE_BUSINESS_ACCOUNT_STARTED: 
        //     return {
        //         ...state,
        //         loading: true, 
        //         error: null,
        //         message: null,
        //         component: "AccountForm"
        //     }
        // case UPDATE_BUSINESS_ACCOUNT_SUCCESS:
        //     return {
        //         ...state, 
        //         business: {
        //             ...state.business,
        //             account: action.payload.account
        //         },
        //         loading: false,
        //         error: null,
        //         message: "Business account was saved!",
        //         component: "AccountForm"
        //     }
        // case UPDATE_BUSINESS_ACCOUNT_FAILURE: 
        //     return {
        //         ...state,
        //         loading: false, 
        //         error: action.payload.error,
        //         message: null,
        //         component: "AccountForm"
        //     }
        default:
            return {
                ...state
            };
    }
}
