import React from "react";
import { Row, Col, Form, Alert, Button } from "react-bootstrap";

const ClientAuthenticationForm = (props) => {
    const { client,
            blockReasonOnChange,
            blockClientAccount,
            updateClientActive
        } = props;

    return (
        <div className="mt-2">
            <h5>Authentication</h5>
            <div className="m-2">
                <Row className="mt-2">
                    <Col>
                        <Form.Check
                            type="switch"
                            id="phone-verified-switch"
                            label="Phone Verified"
                            name="phoneVerified"
                            readOnly
                            checked={client.account.phoneVerified}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            type="switch"
                            id="active"
                            label="Active"
                            name="active"
                            defaultChecked={client.account.active}
                            onClick={() => updateClientActive(client.account._id)}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Label>Failed Attempts</Form.Label>
                        <Form.Control
                            type="text"
                            name="failedAttempts"
                            placeholder="Failed Attempts"
                            value={client.account.failedAttempts}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Label>Block Reason</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="blockReason"
                            placeholder="Block Reason"
                            value={client.account.blockReason}
                            onChange={(e) => blockReasonOnChange(e.target.value)}
                        />
                    </Col>
                </Row>
                {client.account.block && (
                    <Row className="mt-2">
                        <Col>
                            <Alert variant="danger">
                                This user is blocked!
                            </Alert>
                        </Col>
                    </Row>
                )}
                <Row className="mt-2">
                    <Col>
                        <Button
                            onClick={() => {
                                if (window.confirm("Are you sure you want to block this user?")) {
                                    blockClientAccount(client.account._id);
                                }
                            }}
                            variant={client.account.block ? "info" : "danger"}
                        >
                            <span className={client.account.block ? "oi oi-lock-unlocked" : "oi oi-lock-locked"}/>{" "}
                            {client.account.block
                                ? "Unblock this user"
                                : "Block this user"}{" "}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ClientAuthenticationForm;