import React from "react";
import { Table, Form } from "react-bootstrap";
const Taxes = (props) => {
    const { taxes } = props;
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Rate</th>
                    <th>Default</th>
                </tr>
            </thead>
            <tbody>
                {taxes.map((tax) => (
                    <tr key={tax._id}>
                        <td>{tax.name}</td>
                        <td>{tax.rate} %</td>
                        <td>
                            <Form.Check
                                inline={true}
                                type="switch"
                                id="isDefault"
                                label={
                                    tax.default
                                        ? "Default"
                                        : " "
                                }
                                
                                checked={tax.default}
                                readOnly
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default Taxes;
