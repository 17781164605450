import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Col, Row } from "react-bootstrap";
import {
    updateProvince,
    addProvince,
} from "../../../redux/actions/admin/province";
import ErrorSuccess from "../../ErrorSuccess/ErrorSuccess";
import Loading from "../../../layouts/Loading/Loading";
import { TIME_ZONES, countryCodes, LANGUAGE_LIST } from "../../../utils/utility";

const languageList = LANGUAGE_LIST();

class ProvinceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: {
                name: "",
                country: "",
                taxes: [],
                timeZone: "",
                language: "",
                currency: "",
            },
            displayMessage: false,
        };
    }

    componentDidMount() {
        if (this.props.selectedProvince) {
            this.setState({
                province: this.props.selectedProvince,
            });
        }
    }

    addMoreTax = () => {
        let { province } = this.state;
        const newTax = {
            name: "",
            rate: "",
        };
        province.taxes = [...province.taxes, newTax];
        this.setState({ province });
    };

    removeTax = (index) => {
        let { province } = this.state;
        province.taxes.splice(index, 1);
        this.setState({ province });
    };

    handleChange = (e) => {
        let { province } = this.state;
        const field = e.target.name;
        let value = e.target.value;
        if (e.target.name === "country") {
            const selectedCountry = countryCodes.filter(
                (ctr) => ctr.country === e.target.value
            )[0];
            if (selectedCountry) {
                province.currency = selectedCountry.currency;
                province.language = selectedCountry.language
            }
        }

        province[field] = value;
        this.setState({ province });
    };

    handleChangeTax = (e, index) => {
        const field = e.target.name;
        let value = e.target.value;

        let province = this.state.province;

        province.taxes[index][field] = value;

        this.setState({ province });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { province } = this.state;

        if (province._id) {
            this.props.updateProvince(province);
        } else {
            this.props.addProvince(province);
        }
        this.setState({
            displayMessage: true,
        });
    };

    render() {
        const { province, displayMessage } = this.state;
        const { component, error, message, loading } = this.props;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Row className='mt-2'>
                        <Col md={12}>
                            <Form.Label>Province Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Province'
                                name='name'
                                value={province.name}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as='select'
                                name='country'
                                value={province.country}
                                onChange={this.handleChange}
                                required
                            >
                                <option>Select Country</option>
                                {countryCodes.map(ctr => <option key={ctr.code} value={ctr.country}>{ctr.country}</option>)}

                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Row>
                            <Col>
                                <Form.Label> Time Zone</Form.Label>
                                <Form.Control
                                    as='select'
                                    onChange={this.handleChange}
                                    value={province.timeZone}
                                    name='timeZone'
                                >
                                    <option
                                        value=""
                                        key=""
                                    >
                                        Select Time Zone
                                    </option>
                                    {TIME_ZONES.filter(
                                        (timeZone) =>
                                            timeZone.country ===
                                            province.country
                                    ).map((timeZone) => (
                                        <option
                                            value={timeZone.value}
                                            key={timeZone.value}
                                        >
                                            {timeZone.label}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label> Language</Form.Label>
                                <Form.Control
                                    as='select'
                                    onChange={this.handleChange}
                                    value={province.language}
                                    name='language'
                                >
                                    <option value=''></option>
                                    {languageList.map(language => <option key={language} value={language}>{language}</option>)}
                                    <option value='French'>French</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label> Currency</Form.Label>
                                <Form.Control
                                    as='select'
                                    onChange={this.handleChange}
                                    value={province.currency}
                                    name='currency'
                                >
                                    {countryCodes.map((country) => (
                                        <option
                                            key={country.code}
                                            value={country.currency}
                                        >
                                            {country.currency.toUpperCase()}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                    </Row>
                    <Row className='mt-2'>
                        <Form.Label>Taxes</Form.Label>
                        {province.taxes.map((tax, index) => {
                            return (
                                <Form.Row key={index}>
                                    <Col style={{ marginBottom: "5px" }}>
                                        <Form.Control
                                            type='text'
                                            placeholder='Tax Name'
                                            name='name'
                                            value={tax.name}
                                            onChange={(e) =>
                                                this.handleChangeTax(e, index)
                                            }
                                            required
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Control
                                            type='text'
                                            placeholder='%'
                                            name='rate'
                                            value={tax.rate}
                                            onChange={(e) =>
                                                this.handleChangeTax(e, index)
                                            }
                                            required
                                        />
                                    </Col>
                                    <Button
                                        variant='link'
                                        className='text-danger'
                                        onClick={() => this.removeTax(index)}
                                    >
                                        <span className='oi oi-minus' /> Remove
                                        tax
                                    </Button>
                                </Form.Row>
                            );
                        })}

                        <Button
                            variant='link'
                            onClick={() => this.addMoreTax()}
                        >
                            <span className='oi oi-plus' /> Add more tax
                        </Button>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            {loading ? (
                                <Loading />
                            ) : (
                                <Button
                                    variant='primary'
                                    className='float-left'
                                    type='submit'
                                >
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        {displayMessage && component === "ProvinceForm" && (
                            <ErrorSuccess error={error} message={message} />
                        )}
                    </Row>
                    <Row></Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.province.error,
        loading: state.province.loading,
        message: state.province.message,
        component: state.province.component,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProvince: (province) => dispatch(updateProvince(province)),
        addProvince: (province) => dispatch(addProvince(province)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvinceForm);
