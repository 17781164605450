import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";

import { countryCodes } from "../../utils/utility";
class CreatePaymentProfileForm extends Component {
    render() {
        const { paymentProfile, creditPaymentOnly } = this.props;
        return (
            <div>
                <Form.Group>
                    <Form.Label>Payment Type</Form.Label>
                    <Form.Control
                        name='paymentType'
                        required
                        value={paymentProfile.paymentType}
                        onChange={this.props.handleChangePayment}
                        as='select'
                    >
                        <option value='Credit Card'>Credit Card</option>
                        {!creditPaymentOnly && (
                            <option value='Void Cheque'>Void Cheque</option>
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Currency</Form.Label>
                    <Form.Control
                        name='currency'
                        required
                        value={paymentProfile.currency}
                        onChange={this.props.handleChangePayment}
                        as='select'
                    >
                        {countryCodes.map((ctr) => (
                            <option key={ctr.code} value={ctr.currency}>
                                {ctr.currency.toUpperCase()}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Commission rate %</Form.Label>
                    <Form.Control
                        name="rate"
                        required
                        value={paymentProfile.rate}
                        onChange={this.props.handleChangePayment}
                        type="number"
                        placeholder="%"
                    />
                </Form.Group> */}

                {/* <Form.Group>
                    <Form.Label>Price per booking</Form.Label>
                    <Form.Control
                        name="pricePerBooking"
                        required
                        value={paymentProfile.pricePerBooking}
                        onChange={this.props.handleChangePayment}
                        type="number"
                        placeholder="$ Price"
                    />
                </Form.Group> */}

                {paymentProfile.services.map((service, index) => {
                    return (
                        <Form.Group key={index}>
                            <div className='mb-5'>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            {index + 1}. Default Service{" "}
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Button
                                            variant='link'
                                            className='text-danger'
                                            style={{ float: "right" }}
                                            onClick={() =>
                                                this.props.removeService(index)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label controlid='name'>
                                            Name
                                        </Form.Label>
                                        <Form.Control
                                            placeholder='Name'
                                            value={service.name}
                                            required={true}
                                            onChange={(e) =>
                                                this.props.handleChangeServiceName(
                                                    e.target.value,
                                                    index,
                                                    "name"
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                            placeholder={"$ Price "}
                                            type='number'
                                            value={service.price}
                                            onChange={(e) =>
                                                this.props.handleChangeServiceName(
                                                    e.target.value,
                                                    index,
                                                    "price"
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col>
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                this.props.handleChangeServiceName(
                                                    e.target.value,
                                                    index,
                                                    "quantity"
                                                )
                                            }
                                            placeholder='Quantity'
                                            type='number'
                                            min={0}
                                            value={service.quantity}
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Group>
                                            <Form.Label controlid='discount'>
                                                Discount ($ or %){" "}
                                            </Form.Label>
                                            <InputGroup className='mb-2'>
                                                <InputGroup.Prepend
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.props.handleChangeServiceName(
                                                            service.discountType !==
                                                                "percentage"
                                                                ? "percentage"
                                                                : "amount",
                                                            index,
                                                            "discountType"
                                                        )
                                                    }
                                                >
                                                    <InputGroup.Text>
                                                        {service.discountType ===
                                                        "percentage"
                                                            ? "%"
                                                            : "$"}
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type='number'
                                                    id='discount'
                                                    onChange={(e) =>
                                                        this.props.handleChangeServiceName(
                                                            e.target.value,
                                                            index,
                                                            "discountValue"
                                                        )
                                                    }
                                                    value={
                                                        service.discountValue
                                                    }
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-2'>
                                    <Col>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                this.props.handleChangeServiceName(
                                                    e.target.value,
                                                    index,
                                                    "description"
                                                )
                                            }
                                            as='textarea'
                                            placeholder='Description'
                                            value={service.description}
                                            rows={2}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form.Group>
                    );
                })}

                <Form.Group>
                    <Button
                        variant='link'
                        onClick={() => this.props.addMoreService()}
                    >
                        <span className='oi oi-plus'></span> Add service
                    </Button>
                </Form.Group>

                <Form.Group className=' border-bottom'>
                    <Button variant='link' onClick={() => this.props.addTax()}>
                        <span className='oi oi-plus'></span> Add Tax
                    </Button>
                </Form.Group>

                {paymentProfile.taxes.map((tax, index) => {
                    return (
                        <Form.Group
                            key={index}
                            className='border-top pt-2 pb-3'
                        >
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label>Tax name:</Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            this.props.handleChangeTax(
                                                index,
                                                "name",
                                                e.target.value
                                            )
                                        }
                                        placeholder='Tax name'
                                        type='string'
                                        value={tax.name}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Tax rate:</Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            this.props.handleChangeTax(
                                                index,
                                                "rate",
                                                e.target.value
                                            )
                                        }
                                        placeholder='Tax rate'
                                        type='number'
                                        value={tax.rate}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        variant='link'
                                        className='text-danger'
                                        style={{ float: "right" }}
                                        onClick={() =>
                                            this.props.removeTax(index)
                                        }
                                    >
                                        Remove
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePaymentProfileForm);
