import { parsePhoneNumber, getAsYouType } from 'awesome-phonenumber'




/**
 * Replace input string with formated phone number
 * @param {*} value
 */
export function formatPhoneNumber(value, country) {

    const ayt = getAsYouType(country);
    return ayt.reset(value.replace(/\D+/g, ''));
}

/**
 * Check if the input string is a valid phone number
 * @param {*} value
 */
export function validatePhoneNumber(value, country) {

    const pn = parsePhoneNumber(value, country)
    return pn.isValid()
}

//get the national format for display
export function displayNationalNumber(phone) {
    const pn = parsePhoneNumber(phone);
    return pn.getNumber('national')
}

//get the unformatted international phone ex: +1204123123
export function getNumberInternational(phone, country) {
    const pn = parsePhoneNumber(phone, country);
    return pn.getNumber();
}