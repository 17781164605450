import {
    GET_GOOGLE_BOOKING_INFO_STARTED,
    GET_GOOGLE_BOOKING_INFO_SUCCESS,
    GET_GOOGLE_BOOKING_INFO_FAILURE,
    CREATE_GOOGLE_BOOKING_INFO_STARTED,
    CREATE_GOOGLE_BOOKING_INFO_SUCCESS,
    CREATE_GOOGLE_BOOKING_INFO_FAILURE,
    UPDATE_GOOGLE_BOOKING_INFO_STARTED,
    UPDATE_GOOGLE_BOOKING_INFO_SUCCESS,
    UPDATE_GOOGLE_BOOKING_INFO_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/googleBookingInfo/googleBookingInfo";

const initialState = {
    merchant: null,
    loading: false,
    message: null,
    error: null,
};

export default function googleBookingInfo(state = initialState, action) {
    switch (action.type) {
        case GET_GOOGLE_BOOKING_INFO_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case GET_GOOGLE_BOOKING_INFO_SUCCESS:
            return {
                ...state,
                merchant: action.payload.merchant,
                loading: false,
                message: null,
                error: null,
            };
        case GET_GOOGLE_BOOKING_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };

        case CREATE_GOOGLE_BOOKING_INFO_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case CREATE_GOOGLE_BOOKING_INFO_SUCCESS:
            return {
                ...state,
                merchant: action.payload.merchant,
                loading: false,
                message: "Google Booking info has been created!",
                error: null,
            };
        case CREATE_GOOGLE_BOOKING_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };

        case UPDATE_GOOGLE_BOOKING_INFO_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case UPDATE_GOOGLE_BOOKING_INFO_SUCCESS:
            return {
                ...state,
                merchant: action.payload.merchant,
                loading: false,
                message: "Google Booking info has been updated!",
                error: null,
            };
        case UPDATE_GOOGLE_BOOKING_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                message: null,
                error: null,
            };
        default:
            return state;
    }
}
