import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { accessPartnerDashboard } from "../../redux/actions/business/business-search";
// import axios from "axios"
import { ErrorMessageToast } from "../../components/ErrorSuccess/ErrorMessageToast"
import API from "../../utils/API/API"
import { decodeHtmlEntity } from "../../utils/utility";
import Loading from "../../layouts/Loading/Loading"
import { displayNationalNumber } from "../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
class BusinessSearchResultTable extends Component {
    // state = {
    //     businesses: [],
    //     error: "",
    //     message: "",
    // }

    // componentDidMount() {
    //     this.initializeState()
    // }

    // initializeState = () => {
    //     if (this.props.businesses.length > 0) {
    //         console.log(this.props.businesses)
    //         // const businesses = this.props.businesses.map(business => {
    //         //     business.city = business.city ? this.props.cities.filter(city => city._id === business.city)[0]?.name : ""
    //         //     business.province = business.province ? this.props.provinces.filter(province => province._id === business.province)[0]?.name : ""
    //         //     business.status = business.status ? this.props.statuses.filter(status => status._id === business.status)[0]?.title : ""
    //         //     business.businessType = business.businessType ? this.props.businessTypes.filter(type => business.businessType.includes(type._id)).map(type => (type.title)) : ""
    //         //     return business
    //         // })
    //         this.setState({
    //             businesses: this.props.businesses
    //         })
    //     }
    // }

    // componentDidUpdate(prevProps) {
    //     if (this.props.businesses.length !== prevProps.businesses.length) {
    //         this.initializeState()
    //     }
    // }

    handleAccessDashboard = (businessId) => {
        let baseURL = "https://manage-backend.partnersdash.com"
        let clientURL = "https://www.partnersdash.com"
        if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
            baseURL = "http://localhost:3000"
            clientURL = "http://localhost:3006"
        }
        API.post(baseURL + "/api/businesses/access-partner-dashboard", { _id: businessId }, { withCredentials: true }).then(() => {
            const win = window.open(clientURL, "_blank");
            win.focus();
        }).catch(error =>
            this.setState({ error: error.response ? error.response.data.message : "There is an error occurred!" })
        )
        // this.props.accessPartnerDashboard(businessId);
    }

    render() {
        const { businesses, error, message, loading } = this.props;
        if (loading) {
            return <Loading />
        }
        return (
            <div>
                {businesses.length > 0 ?
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>Province</th>
                                {/* <th>Status</th> */}
                                <th>Online</th>
                                <th>Type</th>
                                <th>Details</th>
                                <th>Payments</th>
                                {/* <th>Statistics</th> */}
                                <th><div>Partner </div><div>Dash</div></th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {businesses.map(business => {
                                //const status = this.props.statuses.filter(status => status.title === business.status)[0]
                                //const statusColor = status && status.color

                                return <tr key={business._id} >
                                    <td>{business.name}</td>
                                    <td>{displayNationalNumber(business.phone)}</td>
                                    <td>{business.city ? decodeHtmlEntity(business.city.name) : ""}</td>
                                    <td>{business.province?.name}</td>
                                    {/* <td >{business.status} <span className="status-color" style={{ backgroundColor: statusColor }}></span></td> */}
                                    <td ><span className="status-color" style={{ backgroundColor: business.online ? "green" : "red" }}></span></td>
                                    <td>{business.businessType.map(type => (type.title + ", "))}</td>
                                    <td>
                                        <a href={"businesses/" + business._id}>
                                            <span className="oi oi-external-link" />
                                        </a>
                                    </td>
                                    <td>
                                        <a href={"businesses/reports/" + business._id} target="_blank" rel="noreferrer">
                                            <span className="oi oi-external-link" />
                                        </a>
                                    </td>
                                    {/* <td>
                                        <a href={"businesses/statistics/" + business._id}>
                                            <span className="oi oi-external-link" />
                                        </a>
                                    </td> */}
                                    <td>
                                        <button onClick={() => this.handleAccessDashboard(business._id)}>
                                            <span className="oi oi-external-link" />
                                        </button>
                                    </td>
                                    <td>{business.createdBy}</td>
                                </tr>;
                            })}
                        </tbody>
                    </Table> : <p>There is no record!</p>
                }

                <div className="error-message-container">
                    <ErrorMessageToast error={error} message={message} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    businesses: state.searchBusiness.businesses,
    isBusinessTokenGranted: state.searchBusiness.isBusinessTokenGranted,
    loading: state.searchBusiness.loading,
    error: state.searchBusiness.error,
    message: state.searchBusiness.message,
    // component: state.searchBusiness.component,
    cities: state.provinceCity.cities,
    provinces: state.provinceCity.provinces,
    businessTypes: state.businessType.businessTypes,
    statuses: state.businessStatus.businessStatuses
});

const mapDispatchToProps = dispatch => {
    return {
        accessPartnerDashboard: businessId => dispatch(accessPartnerDashboard(businessId)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessSearchResultTable);
