import React from "react";
import { Nav } from "react-bootstrap";

export const BusinessDetailNavigation = (props) => {

    return (
        <Nav variant='pills' className='flex-column' style={{ position: "sticky", top: "20px" }} >
            <Nav.Item>
                <Nav.Link eventKey='general' onClick={() => props.handleChangeTab("general")} ><span className="oi oi-home"></span> General Info</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='accounts' onClick={() => props.handleChangeTab("accounts")}><span className="oi oi-key"></span> Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='phoneSenders' onClick={() => props.handleChangeTab("phoneSenders")}><span className="oi oi-phone"></span> Phone Senders</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='location' onClick={() => props.handleChangeTab("location")}><span className="oi oi-map-marker"></span> Location</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='googleBooking' onClick={() => props.handleChangeTab("googleBooking")}><span className="oi oi-map-marker"></span> Google Booking</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='appointmentSettings' onClick={() => props.handleChangeTab("appointmentSettings")}><span className="oi oi-calendar"></span> Appointment Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='cancellationSettings' onClick={() => props.handleChangeTab("cancellationSettings")}><span className="oi oi-circle-x"></span> Cancellation Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='about' onClick={() => props.handleChangeTab("about")}> <span className="oi oi-comment-square"></span> About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='image' onClick={() => props.handleChangeTab("image")}><span className="oi oi-image"></span> Images</Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
                <Nav.Link eventKey='hours' onClick={() => props.handleChangeTab("hours")}> <span className="oi oi-clock"></span> Hours</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
                <Nav.Link eventKey='taxes' onClick={() => props.handleChangeTab("taxes")}><span className="oi oi-text"></span> Taxes</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
                <Nav.Link eventKey='menu'  onClick={() => props.handleChangeTab("menu")}> <span className="oi oi-book"></span> Menu</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
                <Nav.Link eventKey='staffs'  onClick={() => props.handleChangeTab("staffs")}><span className="oi oi-people"></span> Staffs</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
                <Nav.Link eventKey='publishing' onClick={() => props.handleChangeTab("publishing")}><span className="oi oi-power-standby"></span> Publishing</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
                <Nav.Link eventKey='scores' onClick={() => props.handleChangeTab("scores")}><span className="oi oi-excerpt"></span> Scores</Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
                <Nav.Link eventKey='socialMedia' onClick={() => props.handleChangeTab("socialMedia")}><span className="oi oi-phone"></span> Social Media</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='giftCard' onClick={() => props.handleChangeTab("giftCard")}><span className="oi oi-credit-card"></span> Gift Card</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='website' onClick={() => props.handleChangeTab("website")}><span className="oi oi-globe"></span> Website</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='notes' onClick={() => props.handleChangeTab("notes")}><span className="oi oi-clipboard"></span> Notes</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='notification' onClick={() => props.handleChangeTab("notification")}><span className="oi oi-bell"></span> Notification</Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
                <Nav.Link eventKey='activeFeatures' onClick={() => props.handleChangeTab("activeFeatures")}><span className="oi oi-cog"></span> Active Features</Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
                <Nav.Link eventKey='reviews' onClick={() => props.handleChangeTab("reviews")}><span className="oi oi-chat"></span> Reviews</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey='activate' onClick={() => props.handleChangeTab("activate")}><span className="oi oi-power-standby"></span> Activate</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link style={{ fontWeight: "bold", fontSize: "20px", marginTop: "20px" }} eventKey='payments' href={`/businesses/reports/${props.businessId}`}><span className="oi oi-external-link"></span> Payments</Nav.Link>
            </Nav.Item>
            
        </Nav>
    );
};
