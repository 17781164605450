import {
    ADD_NEW_NOTE_FAILURE,
    ADD_NEW_NOTE_STARTED,
    ADD_NEW_NOTE_SUCCESS,

    UPDATE_NOTE_FAILURE,
    UPDATE_NOTE_STARTED,
    UPDATE_NOTE_SUCCESS,

    GET_ALL_NOTES_FAILURE,
    GET_ALL_NOTES_STARTED,
    GET_ALL_NOTES_SUCCESS,

    DELETE_NOTE_FAILURE,
    DELETE_NOTE_STARTED,
    DELETE_NOTE_SUCCESS,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/note";



const initialState = {
    notes: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function note(state = initialState, action) {
    switch (action.type) {
        case  GET_ALL_NOTES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case  GET_ALL_NOTES_SUCCESS:
            return {
                ...state,
                notes: action.payload.notes,
                loading: false,
                error: null,
                message: null,
                component: "NoteContainer"
            };
        case GET_ALL_NOTES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "NoteContainer"
            };
        case ADD_NEW_NOTE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case ADD_NEW_NOTE_SUCCESS:
            const newNotes = [action.payload.note].concat(state.notes)
            // state.notes.push(action.payload.note);
            
            return {
                ...state,
                notes:newNotes,
                loading: false,
                error: null,
                message: "New Note was created successfully",
                component: "NoteContainer"
            };
        case ADD_NEW_NOTE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "NoteContainer"
            };
       
        case UPDATE_NOTE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case UPDATE_NOTE_SUCCESS:
            const updatedNote = action.payload.note;
            let notes = state.notes
            notes = notes.map(note => note._id === updatedNote._id ? updatedNote : note)
            return {
                ...state,
                notes,
                loading: false,
                error: null,
                message: "Notes were modified successfully",
                component: "NoteContainer"
            };
        case UPDATE_NOTE_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "NoteContainer"
            };
        case DELETE_NOTE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case DELETE_NOTE_SUCCESS:
            const newNotesArray = state.notes.filter(note => note._id !== action.payload.note._id)

            return {
                ...state,
                notes: newNotesArray,
                loading: false,
                error: null,
                message: "Note was deleted successfully",
                component: "NoteContainer"
            };
        case DELETE_NOTE_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "NoteContainer"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return { ...state };
    }
}
