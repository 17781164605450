import React from "react";
import { Table, Button } from "react-bootstrap";
import { displayNationalNumber } from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber";

const ClientTable = (props) => {
    const { clients } = props;
    if (!clients || clients.length === 0) {
        return "No record found!";
    }
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Full Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {clients.map((client) => (
                    <tr key={client._id}>
                        <td>{client.firstName + " " + client.lastName}</td>
                        <td>{displayNationalNumber(client.phone)}</td>
                        <td>{client.email}</td>
                        <td>
                            <Button variant="link" onClick={() => props.selectClient(Object.assign({}, client))}>
                                <span className="oi oi-external-link"></span>
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ClientTable;
