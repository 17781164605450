import React from "react";
import { Col, Alert } from "react-bootstrap";
const ErrorSuccess = props => {
  const { message, error } = props;
  return <div>
    {!error && message && (
      <Col className="animated pulse ">
        <Alert variant="success" className="animated fadeOutLeft delay-3s">{message}</Alert>
      </Col>
    )}
    {error && (
      <Col className="animated rubberBand ">
        <Alert variant="danger" className="animated ">{error}</Alert>
      </Col>
    )}
  </div>;
};

export default ErrorSuccess;
