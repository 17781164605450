import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Loading from "../../../layouts/Loading/Loading";
import API from '../../../utils/API/API';
import { ErrorMessageToast } from "../../ErrorSuccess/ErrorMessageToast";
export const NotificationContainer = (props) => {
    const { business } = props
    const [createdNotification, setCreatedNotification] = useState(null)
    const [notification, setNotification] = useState({
        business: business._id,
        title: '',
        message: '',
        links: [{
            url: ''
        }],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let links = notification.links
        if (name === "link") {
            links = [{
                url: value
            }]
            setNotification((prevNotification) => ({
                ...prevNotification,
                links,
            }));
        } else {
            setNotification((prevNotification) => ({
                ...prevNotification,
                [name]: value,
            }));
        }

    };

    const deleteNotification = (_id) => {
        setMessage(null)
        setError(null)
        setLoading(true)
        API.delete(`/api/notification/delete-notification?_id=${_id}`).then(res => {
            if (res) {
                setLoading(false)
                setMessage("Notification was deleted successfully!")
                setCreatedNotification(null)
            }
        }).catch(error => {
            let errorMessage = "Error when deleting the notification!!"
            if (error.message) {
                errorMessage = error.message
            }
            setLoading(false)
            setError(errorMessage)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(null)
        setError(null)
        setLoading(true)
        API.post("/api/notification/create-notification", notification).then(res => {
            if (res.data) {
                setLoading(false)
                setMessage("The notification was created successfully!")
                setCreatedNotification(res.data.createdNotification)
                setNotification({
                    business: business._id,
                    title: '',
                    message: '',
                    links: [{
                        url: ''
                    }],
                })
            }
        }).catch(error => {
            let errorMessage = "There is an error creating the notification!"
            if (error.message) {
                errorMessage = error.message
            }
            setLoading(false)
            setError(errorMessage)
        })
        // Add your submission logic here
    };
    console.log(error, message)

    return (
        <div>
            <h4>Create Notification</h4>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                placeholder='Title'
                                name='title'
                                value={notification.title}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group>
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                placeholder='Link'
                                name='link'
                                value={notification.links[0].url}
                                onChange={(e) => handleChange(e, 'link')}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder='Message'
                                name='message'
                                value={notification.message}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col className='mt-3'>
                        {!loading ? (
                            <Button
                                variant='primary'
                                className='float-left'
                                type='submit'
                            >
                                <span
                                    className='oi oi-bell'
                                    title='icon name'
                                    aria-hidden='true'
                                /> Send
                            </Button>
                        ) : (
                            <Loading />
                        )}
                    </Col>
                </Row>
            </Form>
            {createdNotification && <div><Row className="mt-3">
                <Col>
                    <h5>{createdNotification.title}</h5>
                </Col>
                <Col>
                    <p>{createdNotification.links[0].url}</p>
                </Col>
            </Row>
                <Row>
                    <Col>
                        <p>{createdNotification.message}</p>
                        <Button variant='danger' onClick={() => deleteNotification(createdNotification._id)}>Unsend</Button>
                    </Col>
                </Row>
            </div>}
            <ErrorMessageToast error={error} message={message} />
        </div>
    );
};
