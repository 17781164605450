import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import queryString from "query-string";
import { resetPassword } from "../../redux/actions/admin/reset-password";
import Loading from "../../layouts/Loading/Loading";
import ErrorSuccess from "../../components/ErrorSuccess/ErrorSuccess";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

class ResetPassword extends Component {
    state = {
        password: {
            newPassword: "",
            repeatPassword: "",
        },
        token: "",
        errors: [],
        displayErrorSucces: false,
    };
    componentDidMount() {
        if (this.props.history.location) {
            const token = queryString.parse(
                this.props.history.location.search
            ).token;
            this.setState({
                token,
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { password, token } = this.state;

        if (
            this.validatePassword(password.newPassword, password.repeatPassword)
        ) {
            this.props.resetPassword(token, password.newPassword);
        }

        this.setState({
            displayErrorSucces: true,
        });
    };
    validatePassword(newPassword, repeatPassword) {
        let errors = [];
        let valid = true;
        if (newPassword !== repeatPassword) {
            valid = false;
            errors.push("Passwords do not match");
        }

        if (newPassword.length < 8) {
            valid = false;
            errors.push("Required minimum length for password is 8");
        }

        this.setState({
            errors,
        });

        return valid;
    }
    handleChange = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { password } = this.state;
        password[field] = value;
        this.setState({
            password,
        });
    };
    render() {
        const { password, errors, displayErrorSucces } = this.state;
        const { loading, component, error, message } = this.props;
        if(message) {
            return <Redirect to="/login"/>
        }
        return (
            <Row >
                <Col></Col>
                <Col md={4} style={{paddingTop: "50px"}}>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/images/dash-blue-sky-1.png"
                        }
                        alt="Dashbooking"
                        style={{ width: "100%" }}
                    />
                  
                    <h3 style={{textAlign: "center", marginTop:"50px"}}>Reset Password</h3>
                    
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                required
                                value={password.newPassword}
                                onChange={this.handleChange}
                                placeholder="Enter new password"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Repeat Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                name="repeatPassword"
                                value={password.repeatPassword}
                                onChange={this.handleChange}
                                placeholder="Repeat new password"
                            />
                        </Form.Group>

                        {loading ? (
                            <Loading />
                        ) : (
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        )}
                        <br/>
                        <div style={{marginTop:"20px"}}>
                            <Link  to="/login">Back to Login</Link>
                        </div>
                        <Row className="mt-3">
                            {errors.length > 0 &&
                                errors.map((error) => {
                                    return (
                                        <Alert key={error} variant="danger">
                                            {error}
                                        </Alert>
                                    );
                                })}
                        </Row>
                        {displayErrorSucces && component === "ResetPassword" && (
                            <Row>
                                <ErrorSuccess error={error} message={message} />
                            </Row>
                        )}
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.resetPassword.loading,
    error: state.resetPassword.error,
    message: state.resetPassword.message,
    component: state.resetPassword.component,
});

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (token, password) =>
            dispatch(resetPassword(token, password)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
