import {
    GET_MENU_STARTED,
    GET_MENU_SUCCESS,
    GET_MENU_FAILURE,
    ADD_NEW_CATEGORIES_STARTED,
    ADD_NEW_CATEGORIES_SUCCESS,
    ADD_NEW_CATEGORIES_FAILURE,
    UPDATE_CATEGORIES_ORDER_FAILURE,
    UPDATE_CATEGORIES_ORDER_SUCCESS,
    UPDATE_CATEGORIES_ORDER_STARTED,
    UPDATE_CATEGORY_INFO_STARTED,
    UPDATE_CATEGORY_INFO_SUCCESS,
    UPDATE_CATEGORY_INFO_FAILURE,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_STARTED,
    DELETE_CATEGORY_SUCCESS,
    ADD_NEW_SERVICE_STARTED,
    ADD_NEW_SERVICE_SUCCESS,
    ADD_NEW_SERVICE_FAILURE,
    UPDATE_SERVICE_INFO_STARTED,
    UPDATE_SERVICE_INFO_SUCCESS,
    UPDATE_SERVICE_INFO_FAILURE,
    UPDATE_SERVICES_ORDER_SUCCESS,
    UPDATE_SERVICES_ORDER_FAILURE,
    DELETE_SERVICE_STARTED,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAILURE,
    UPDATE_SERVICE_STAFF_STARTED,
    UPDATE_SERVICE_STAFF_SUCCESS,
    UPDATE_SERVICE_STAFF_FAILURE,
    UPDATE_STAFF_SERVICES_STARTED,
    UPDATE_STAFF_SERVICES_SUCCESS,
    UPDATE_STAFF_SERVICES_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/business/menu";

const initialState = {
    categories: [],
    services: [],
    loading: false,
    error: null,
    message: null,
    component: null
};

export default function menu(state = initialState, action) {
    switch (action.type) {
        case GET_MENU_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_MENU_SUCCESS:
            return {
                ...state,
                categories: action.payload.menu.categories,
                services: action.payload.menu.services,
                loading: false
            };
        case GET_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "MenuInfoContainer"
            };

        case ADD_NEW_CATEGORIES_STARTED:
            return {
                ...state,
                error: null,
                message: null,
                component: "MenuInfoContainer"
            };

        case ADD_NEW_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: [...state.categories, action.payload.category],
                loading: false,
                error: null,
                message: "New Category was created successfully",
                component: "MenuInfoContainer"
            };
        case ADD_NEW_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "MenuInfoContainer"
            };
        case UPDATE_CATEGORIES_ORDER_STARTED:
            return {
                ...state,
                error: null,
                message: null,
                component: "MenuInfoContainer"
            };
        case UPDATE_CATEGORIES_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "MenuInfoContainer"
            };
        case UPDATE_CATEGORIES_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "MenuInfoContainer"
            };

        case UPDATE_CATEGORY_INFO_STARTED:
            return {
                ...state,

                error: null,
                message: null,
                component: "CategoryForm"
            };
        case UPDATE_CATEGORY_INFO_SUCCESS:
            let newCategories = state.categories.map(category => {
                if (category._id === action.payload.category._id) {
                    return action.payload.category;
                }
                return category;
            });

            return {
                ...state,

                categories: newCategories,
                error: null,
                message: "Category was updated!",
                component: "CategoryForm"
            };
        case UPDATE_CATEGORY_INFO_FAILURE:
            return {
                ...state,

                error: action.payload.error,
                message: null,
                component: "CategoryForm"
            };
        case DELETE_CATEGORY_STARTED:
            return {
                ...state,
                // loading: true,
                error: null,
                message: null,
                component: null
            };
        case DELETE_CATEGORY_SUCCESS:
            const newCategoriesArray = state.categories.filter(
                category => category._id !== action.payload.category._id
            );

            return {
                ...state,
                categories: newCategoriesArray,
                loading: false,
                error: null,
                message: "Category was deleted successfully",
                component: "MenuInfoContainer"
            };
        case DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "MenuInfoContainer"
            };
        case ADD_NEW_SERVICE_STARTED:
            return {
                ...state,
                error: null,
                message: null,
                component: null
            };
        case ADD_NEW_SERVICE_SUCCESS:
            const modifiedCategory = state.categories.map(category => {
                if(category._id === action.payload.service.serviceCategory){
                    category.services.push(action.payload.service._id)
                    return category
                }
                return category
            })
            return {
                ...state,
                services: [...state.services, action.payload.service],
                categories: modifiedCategory,
                error: null,
                message: "Service was created successfully",
                component: "ServiceForm"
            };
        case ADD_NEW_SERVICE_FAILURE:
            return {
                ...state,

                error: action.payload.error,
                message: null,
                component: "ServiceForm"
            };

        case UPDATE_SERVICE_INFO_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: null
            };
        case UPDATE_SERVICE_INFO_SUCCESS:
            const newServicesArray = state.services.map(service => {
                if (service._id === action.payload.service._id) {
                    return action.payload.service;
                }
                return service;
            });

            return {
                ...state,
                services: newServicesArray,
                loading: false,
                error: null,
                message: "Service was updated successfully",
                component: "ServiceForm"
            };
        case UPDATE_SERVICE_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ServiceForm"
            };
        case UPDATE_SERVICES_ORDER_SUCCESS:
           
            return {
                ...state,
                categories: state.categories.map(category => {
                    if(category._id === action.payload.category._id){
                        category.services = action.payload.category.services
                        return category
                    }
                    return category
                }),
                loading: false,
                error: null,
                message: null,
                component: "MenuInfoContainer"
            }
        case UPDATE_SERVICES_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "MenuInfoContainer"
            };
         case DELETE_SERVICE_STARTED:
            return {
                ...state,
                error: null,
                message: null,
                component: "MenuInfoContainer"
            };
        case DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                services: state.services.filter(service => service._id !== action.payload.service._id),
                loading: false,
                error: null,
                message: "Category was deleted successfully",
                component: "Service"
            };
        case DELETE_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "Service"
            };
            case UPDATE_SERVICE_STAFF_STARTED:
                return {
                    ...state,
                    error: null,
                    message: null,
                    component: null
                };
            case UPDATE_SERVICE_STAFF_SUCCESS:
                let updateServicesStaffArray = state.services.map(service => {
                    if (service._id === action.payload.service._id) {
                        return action.payload.service;
                    }
                    return service;
                });

                return {
                    ...state,
                    services: updateServicesStaffArray,
                    loading: false,
                    error: null,
                    message: "Service was updated successfully",
                    component: "ServiceStaff"
                };
            case UPDATE_SERVICE_STAFF_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload.error,
                    message: null,
                    component: "ServiceStaff"
                };
                case UPDATE_STAFF_SERVICES_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "StaffDetailContainer"
            };
        case UPDATE_STAFF_SERVICES_SUCCESS:
                const newServices = action.payload.services
                let services = state.services
                services = services.map(service => {
                    const newService = newServices.find(ser => ser._id === service._id)
                    service = {...service, staffs: newService.staffs}
                    return service
                })
                return {
                    ...state,
                    services,
                    loading: false,
                    error: null,
                    message: "Staff was updated successfully!",
                    component: "StaffDetailContainer"
                };
        case UPDATE_STAFF_SERVICES_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload.error,
                    message: null,
                    component: "StaffDetailContainer"
                };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
