import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab } from "react-bootstrap";
import { BusinessDetailNavigation } from "../../components/business-details/BusinessDetailNavigation";
import BusinessDetailContentContainer from "../../components/business-details/BusinessDetailContentContainer";
import Loading from "../../layouts/Loading/Loading";
import { getBusinessDetail } from "../../redux/actions/business/business";
import { getBusinessType } from "../../redux/actions/admin/business-type";
import { getBusinessStatus } from "../../redux/actions/admin/business-status";
import { getAllProvincesCities } from "../../redux/actions/province-city/province-city";
import { getTaxes } from "../../redux/actions/business/taxes"
class BusinessDetailContainer extends Component {
    state = {
        currentTab: "general"
    };

    handleChangeTab = (tab) => {
        this.setState({
            currentTab: tab
        });
    };

    componentDidMount() {
        const businessId = this.props.match.params.id;
        this.props.getBusinessDetail(businessId);
        this.props.getBusinessType();
        this.props.getBusinessStatus();
        this.props.getAllProvincesCities();
        // this.props.getTaxes(businessId)
        if (this.props.business) {
            this.setState({
                business: this.props.business
            });
        }
    }

    render() {
        const { currentTab } =  this.state;

        const {businessTypeLoading, provinceCityLoading, businessStatusLoading, businessLoading} = this.props 
        if(businessTypeLoading || provinceCityLoading || businessStatusLoading || businessLoading) {
            return <Loading />
        }



        return (
            <div>
                <Row>
                    <Col>
                        <h1>Business Details</h1>
                    </Col>
                </Row>
                
                <Tab.Container
                    defaultActiveKey='general'
                    activeKey={currentTab}
                >
                    <Row>
                        <Col sm={3}>
                            <BusinessDetailNavigation 
                                handleChangeTab={this.handleChangeTab} 
                                businessId={this.props.match.params.id}
                            />
                        </Col>
                        <BusinessDetailContentContainer
                            businessId={this.props.match.params.id}
                            currentTab={currentTab}
                        />
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    businessTypeLoading: state.businessType.loading,
    provinceCityLoading: state.provinceCity.loading,
    businessStatusLoading: state.businessStatus.loading,
    businessLoading: state.business.loading,
});


const mapDispatchToProps = dispatch => {
    return {
        getBusinessDetail: businessId => dispatch(getBusinessDetail(businessId)),
        getBusinessType: () => dispatch(getBusinessType()),
        getBusinessStatus: () => dispatch(getBusinessStatus()),
        getAllProvincesCities: () => dispatch(getAllProvincesCities()),
        getTaxes: (businessId) => dispatch(getTaxes(businessId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessDetailContainer);
