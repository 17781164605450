export const GET_ALL_CITY_STARTED = "GET_ALL_CITY_STARTED"
export const GET_ALL_CITY_SUCCESS = "GET_ALL_CITY_SUCCESS"
export const GET_ALL_CITY_FAILURE = "GET_ALL_CITY_FAILURE"


export const UPDATE_CITY_STARTED = "UPDATE_CITY_STARTED"
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS"
export const UPDATE_CITY_FAILURE = "UPDATE_CITY_FAILURE"

export const ADD_CITY_STARTED = "ADD_CITY_STARTED"
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS"
export const ADD_CITY_FAILURE = "ADD_CITY_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"