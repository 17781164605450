import {
    ADD_STAFF_FAILURE,
    ADD_STAFF_SUCCESS,
    ADD_STAFF_STARTED,
    GET_ALL_STAFFS_STARTED,
    GET_ALL_STAFFS_SUCCESS,
    GET_ALL_STAFFS_FAILURE,
    UPDATE_STAFF_STARTED,
    UPDATE_STAFF_SUCCESS,
    UPDATE_STAFF_FAILURE,
    DELETE_STAFF_STARTED,
    DELETE_STAFF_SUCCESS,
    DELETE_STAFF_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/staff";

const initialState = {
    staffs: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function staff(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_STAFFS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_STAFFS_SUCCESS:
            return {
                ...state,
                staffs: action.payload.staffs,
                loading: false,
                error: null,
                message: null,
                component: "StaffContainer"
            };
        case GET_ALL_STAFFS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "StaffContainer"
            };
        case ADD_STAFF_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case ADD_STAFF_SUCCESS:

            state.staffs.push(action.payload.staff)
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "AddStaffDetailContainer"
            };
        case ADD_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "AddStaffDetailContainer"
            };
        case UPDATE_STAFF_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case UPDATE_STAFF_SUCCESS:
            let editedStaff = action.payload.staff
            let staffs = state.staffs
            staffs = staffs.map(staff => staff._id === editedStaff._id ? editedStaff : staff)
            // staffs.forEach(staff => {
            //     if (staff._id === editedStaff._id) {
            //         staff = editedStaff;
            //     }
            // });
            return {
                ...state,
                staffs,
                loading: false,
                error: null,
                message: "This staff was updated successfully!",
                component: "StaffDetailContainer"
            };
        case UPDATE_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "StaffDetailContainer"
            };
    
        case DELETE_STAFF_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case DELETE_STAFF_SUCCESS:
            const newStaffArray = state.staffs.filter(staff => staff._id !== action.payload.staff._id)
            return {
                ...state,
                staffs: newStaffArray,
                loading: false,
                error: null,
                message: "Staff was deleted successfully!",
                component: "StaffContainer"
            };
        case DELETE_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "StaffContainer"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return { ...state };
    }
}
