import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Container, Button, Badge } from "react-bootstrap";
import { getBusinessPaymentList } from "../../../redux/actions/business-payment/business-payment";
import moment from "moment";
import { getBusinessPaymentDetail } from "../../../redux/actions/business-payment/business-payment";
import PaymentDetailModal from "./PaymentDetailModal";
class PaymentHistory extends Component {
    state = {
        modalShow: false
    };
    showModal = (value) => {
        this.setState({
            modalShow: value,

        });
    };

    displayPaymentType = (payment) => {
        if (payment.paymentType) {
            if (payment.paymentType === "Credit Card") {
                return <>Credit Card <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" /></>
            }
            if (payment.paymentType === "Void Cheque") {
                return <>Void Cheque <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" /></>
            }
        }
        else if (payment.paymentIntentId) {
            return <>Credit Card <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" /></>
        }
        else if (payment.transactionRotessaId) {
            return <>Void Cheque <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" /></>
        }
    }

    displayPaymentStatus = (paymentStatus) => {
        if (paymentStatus === "Pending") {
            return <Badge variant="warning">{paymentStatus}</Badge>
        }
        else if (paymentStatus === "Success") {
            return <Badge variant="success">{paymentStatus}</Badge>
        }
        else if (paymentStatus === "Failed") {
            return <Badge variant="danger">{paymentStatus}</Badge>
        }
        else return <Badge variant="secondary">{paymentStatus}</Badge>
    }

    componentDidMount() {
        if (this.props.business) {
            this.props.getBusinessPaymentList(this.props.business._id);
        }
    }

    getPaymentDetail = (id) => {
        this.props.getBusinessPaymentDetail(id);
        this.showModal(true);
    };

    render() {
        const { payments } = this.props;
        const { modalShow } = this.state;
        return (
            <Container className="mt-3">
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>Created At</th>
                            <th>Total</th>
                            <th>Method</th>
                            <th>Status</th>
                            <th>Submited</th>
                            <th>Detail</th>

                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => {
                            return (
                                <tr key={payment._id}>
                                    <td>
                                        {moment(payment.createdAt).format(
                                            "MMM DD, YYYY hh:mma"
                                        )}
                                    </td>
                                    <td>{Math.round(payment.total * 100) / 100}</td>
                                    <td>
                                        {this.displayPaymentType(payment)}

                                    </td>
                                    <td>{this.displayPaymentStatus(payment.status)}</td>
                                    <td>{
                                        payment.isSubmited
                                            ? <Badge variant="success">Yes</Badge>
                                            : <Badge variant="warning">No</Badge>
                                    }
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() =>
                                                this.getPaymentDetail(
                                                    payment._id
                                                )
                                            }
                                        >
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <PaymentDetailModal
                    showModal={this.showModal}
                    modalShow={modalShow}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.business.business,
    payments: state.businessReport.payments,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessPaymentList: (businessId) =>
            dispatch(getBusinessPaymentList(businessId)),
        getBusinessPaymentDetail: (id) =>
            dispatch(getBusinessPaymentDetail(id)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
