import React, { useEffect, useState } from "react";
import { Container, Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import Loading from "../../../layouts/Loading/Loading";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import { countCompletedAppointment } from "../../../redux/actions/business-payment/business-payment";
import { createBusinessPayment } from "../../../redux/actions/business-payment/business-payment"
const TierPricing = [
    { from: 0, to: 0, price: 0, desciption: "No completed online appointments" },
    {
        from: 1,
        to: 49,
        price: 29,
        desciption: "1 to 49 completed online appointments",
    },
    {
        from: 50,
        to: 149,
        price: 69,
        desciption: "50 to 149 completed online appointments",
    },
    {
        from: 150,
        to: 499,
        price: 99,
        desciption: "150 to 499 completed online appointments",
    },
    {
        from: 500,
        to: 999,
        price: 149,
        desciption: "500 to 999 completed online appointments",
    },
    {
        from: 1000,
        to: 99999,
        price: 199,
        desciption: "1000+ completed online appointments",
    },
];

export const NewMonthlyPayment = (props) => {
    const [from, setFrom] = useState(
        moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
    );
    const [to, setTo] = useState(
        moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
    );

    const dispatch = useDispatch();

    const getBusinessAppointmentData = (business, to, from) => {
        dispatch(countCompletedAppointment(business, to, from));
    };

    const [services, setServices] = useState([]);
    const [description, setDescription] = useState(`Period: ${from} - ${to}`)

    const [disableSubmit, setDisableSubmit] = useState(false)

    const { businessStats, loadingCompleteAppointment, paymentProfile } = useSelector(
        (state) => state.businessReport
    );

    const businessId = useSelector((state) => state.business.business._id);
    const businessServices = useSelector(
        (state) => state.businessReport.paymentProfile.services
    );
    const taxes = useSelector(
        (state) => state.businessReport.paymentProfile.taxes
    );




    useEffect(() => {
        getBusinessAppointmentData(businessId, to, from);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setServices([
            // {
            //     name: "Appointment Processing Fee",
            //     price: 0,
            //     quantity: 0,
            //     discountType: "percentage",
            //     discountValue: 0,
            //     description: "",
            // },
            // {
            //     name: "SMS Text Messages",
            //     price: 0,
            //     quantity: 0,
            //     discountType: "percentage",
            //     discountValue: 0,
            //     description: "",
            // },
            ...businessServices,

        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessServices]);

    useEffect(() => {
        const mapDataToServicePricing = () => {
            const { totalCompletedAppointment, totalNotification } = businessStats;
            let newServices = services
            let servicePriceByAppointment = services.find(ser => ser.name.toLowerCase().includes('appointment'));
            let servicePriceBySMS = services.find(ser => ser.name.toLowerCase().includes('sms'))
            let servicePriceEmail = services.find(ser => ser.name.toLowerCase().includes('email'))
            const calculatePrice = TierPricing.filter(
                (tier) =>
                    tier.from <= totalCompletedAppointment &&
                    tier.to >= totalCompletedAppointment
            )[0];

            //set price for service form
            if (calculatePrice && servicePriceByAppointment) {

                servicePriceByAppointment.price = calculatePrice.price;
                servicePriceByAppointment.description = calculatePrice.desciption;
                servicePriceByAppointment.quantity = 1;
                if (totalNotification && totalNotification.totalTextMessages) {
                    servicePriceBySMS.price = 0.05
                    servicePriceBySMS.quantity = totalNotification.totalTextMessages
                }
                if (totalNotification && totalNotification.totalEmails) {
                    servicePriceEmail.price = 0.00
                    servicePriceEmail.quantity = totalNotification.totalEmails
                }
                newServices = services.map((ser) => {
                    if (ser.name === servicePriceByAppointment.name) {
                        return servicePriceByAppointment
                    }
                    if (ser.name === servicePriceBySMS.name) {
                        return servicePriceBySMS
                    }
                    if (ser.name === servicePriceEmail.name) {
                        return servicePriceEmail
                    }
                    return ser;
                });

                setServices(newServices)
            }


        };

        mapDataToServicePricing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessStats]);





    const chooseWeek = (event, picker) => {
        const newFrom = picker.startDate.format("YYYY-MM-DD");
        const newTo = picker.endDate.format("YYYY-MM-DD");

        setFrom(newFrom);
        setTo(newTo);
        setDescription(`Period: ${newFrom} - ${newTo}`)
        getBusinessAppointmentData(businessId, newTo, newFrom);
    };

    const updateService = (serviceId, fieldName, value) => {
        let updatedService = services.filter((ser) => ser._id === serviceId)[0];
        updatedService[fieldName] = value;
        const newServices = services.map((ser) => {
            if (ser._id === updateService._id) return updateService;
            return ser;
        });
        setServices(newServices);
        calculateTotal();
    };

    const handleSubmitPayment = () => {
        setDisableSubmit(true)
        if (paymentProfile.paymentType === "Credit Card") {
            dispatch(createBusinessPayment(services, taxes, businessId, description))
        } else {
            dispatch(createBusinessPayment(services, taxes, businessId, description))
        }
    };

    const calculateTotal = () => {
        let subtotal = 0;
        let discount = 0;
        let calculatedTaxes = [];
        let totalTax = 0;
        let total = 0;
        services.forEach((ser) => {
            subtotal += Number(ser.price * ser.quantity);
            discount +=
                ser.discountValue && ser.discountType === "percentage"
                    ? Number(
                        (ser.price * ser.quantity * ser.discountValue) / 100
                    )
                    : Number(ser.discountValue);

        });
        calculatedTaxes = taxes.map((tax) => {
            totalTax += (tax.rate * (subtotal - discount)) / 100;
            return {
                rate: tax.rate,
                name: tax.name,
                amount: Math.round((tax.rate * (subtotal - discount)) / 100 * 100) / 100,
            };
        });



        total = subtotal - discount + totalTax;



        return {
            subtotal: Math.round(subtotal * 100) / 100,
            discount: Math.round(discount * 100) / 100,
            calculatedTaxes,
            total: Math.round(total * 100) / 100,
        };
    };

    const { subtotal, discount, calculatedTaxes, total } = calculateTotal();

    if (loadingCompleteAppointment) {
        return <Loading />
    }



    return (
        <Container>

            <Row className='mb-2 pb-2 mt-2 pt-2'>
                <Col className='col-6 col-md-4'>
                    <p>Select Period: </p>
                </Col>
                <Col className='col-6 col-md-8'>
                    <DateRangePicker
                        initialSettings={{
                            startDate: from,
                            endDate: to,
                            maxDate: moment(),
                            opens: "center",
                            showDropdowns: true,
                            autoApply: true,
                            locale: { format: "YYYY-MM-DD" },
                            ranges: {
                                "This month": [
                                    moment().startOf("month"),
                                    moment().endOf("month"),
                                ],
                                "Last month": [
                                    moment()
                                        .subtract(1, "month")
                                        .startOf("month"),
                                    moment()
                                        .subtract(1, "month")
                                        .endOf("month"),
                                ],
                            },
                        }}
                        onHide={chooseWeek}
                    >
                        <Button variant='primary'>
                            <span>
                                From: {moment(from).format("MMM DD, YYYY")}
                            </span>
                            <span className='ml-2'>
                                To: {moment(to).format("MMM DD, YYYY")}
                            </span>
                        </Button>
                    </DateRangePicker>
                </Col>
            </Row>

            <div>
                <h4>Details</h4>
                <Row>
                    <Col>Notes:</Col>
                    <Col className="text-warning">{paymentProfile.notes}</Col>
                </Row>
                <Row>
                    <Col>Total Completed Appointment</Col>
                    <Col>{businessStats.totalCompletedAppointment}</Col>
                </Row>
                {businessStats && businessStats.totalNotification && businessStats.totalNotification.totalTextMessages && <Row>
                    <Col>Total Messages</Col>
                    <Col>{businessStats.totalNotification.totalTextMessages}</Col>
                </Row>}
                {businessStats && businessStats.totalNotification && businessStats.totalNotification.totalEmails && <Row>
                    <Col>Total Emails</Col>
                    <Col>{businessStats.totalNotification.totalEmails}</Col>
                </Row>}
                <Row>
                    <Col>Description: </Col>
                    <Col>
                        <Form.Control
                            placeholder='Period'
                            value={description}
                            onChange={(e) =>
                                setDescription(e.target.value)
                            }
                        />
                    </Col>
                </Row>
            </div>
            <div className='border-top pt-3 mt-2' >


                <h4>Pricing</h4>
                {services.map((service) => {
                    return (
                        <Form className='border-bottom  mt-2 pt-2' key={service._id}>
                            <Row>
                                <Col>
                                    <Form.Label>Service Name</Form.Label>
                                    <Form.Control
                                        placeholder='Service name'
                                        value={service.name}
                                        onChange={(e) =>
                                            updateService(
                                                service._id,
                                                "name",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        value={service.price}
                                        placeholder='Price'
                                        onChange={(e) =>
                                            updateService(
                                                service._id,
                                                "price",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control
                                        placeholder='Quantity'
                                        value={service.quantity}
                                        onChange={(e) =>
                                            updateService(
                                                service._id,
                                                "quantity",
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label controlid='discount'>
                                            Discount ($ or %){" "}
                                        </Form.Label>
                                        <InputGroup className='mb-2'>
                                            <InputGroup.Prepend
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    updateService(
                                                        service._id,
                                                        "discountType",
                                                        service.discountType !==
                                                            "percentage"
                                                            ? "percentage"
                                                            : "amount"
                                                    )
                                                }
                                            >
                                                <InputGroup.Text>
                                                    {service.discountType ===
                                                        "percentage"
                                                        ? "%"
                                                        : "$"}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type='number'
                                                id='discountValue'
                                                onChange={(e) =>
                                                    updateService(
                                                        service._id,
                                                        "discountValue",
                                                        e.target.value
                                                    )
                                                }
                                                value={service.discountValue}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={3}
                                            value={service.description}
                                            onChange={(e) =>
                                                updateService(
                                                    service._id,
                                                    "description",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    );
                })}

                <Row className=' mt-2 pt-2'>
                    <h4>Summary</h4>
                    <Col>
                        <p>Subtotal</p>
                    </Col>
                    <Col>
                        <p>${subtotal}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Discount</p>
                    </Col>
                    <Col>
                        <p>${discount}</p>
                    </Col>
                </Row>
                {calculatedTaxes.map((tax) => {
                    return (
                        <Row key={tax.name}>
                            <Col>
                                <p>
                                    {tax.name} ({tax.rate}%)
                                </p>
                            </Col>
                            <Col>
                                <p>${tax.amount}</p>
                            </Col>
                        </Row>
                    );
                })}

                <Row>
                    <Col>
                        <p>Total</p>
                    </Col>
                    <Col>
                        <p>${total}</p>
                    </Col>
                </Row>
                <Row className='mt-2 mb-4 '>
                    <Col>
                        <Button variant='primary' disabled={disableSubmit || total === 0} onClick={() => handleSubmitPayment()}>Submit Payment</Button>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
