import React from "react";
import { Card, Form, Row, Col, Accordion, Button } from "react-bootstrap";

const Staff = props => {
    const { staffs, staffHours, services } = props;
    const dayInWeeks = [
        "Sunday",
        "Monday",
        "TuesDay",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];

    return (
        <div className='shadow-none p-3 mb-5 bg-light rounded'>
            <h4>Staffs</h4>
            {staffs.map(staff => {
                const staffName  = staff.name.split(' ').slice(0,2).join(' ')
              
                let staffAvatar = staffName
                    .split(/\s/)
                    .reduce(
                        (response, word) => (response += word.slice(0, 1)),
                        ""
                    );
                const staffHour = staffHours.filter(
                    hour => hour.staff === staff._id
                )[0];
                return (
                    <Card key={staff._id} className='mb-3'>
                        <Card.Body> 
                            <Card.Title>
                                <div className="avatar" style={{backgroundColor: staff.color}}>
                                    {staffAvatar}
                                </div>
                                {staff.name}
                                <Form.Check
                                    className='float-right'
                                    type='switch'
                                    label={"Booking Online"}
                                    readOnly
                                    checked={staff.bookingOnline}
                                />
                            </Card.Title>
                            {/* <Card.Subtitle className='mb-2 text-muted'>
                           
                            </Card.Subtitle> */}

                            <Card.Text>
                                {staff.email && (
                                    <span>Email: {staff.email} </span>
                                )}
                                {staff.phone && (
                                    <span className='float-right'>
                                        Phone: {staff.phone}{" "}
                                    </span>
                                )}
                                <br />
                                {staff.note && <span className="text-dark font-weight-light">Note: {staff.note}</span>}
                            </Card.Text>
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant='link'
                                            eventKey='0'
                                        >
                                            Hours
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='0'>
                                        <Card.Body>
                                            <Form.Check
                                                type='switch'
                                                label='The same as business hour'
                                                readOnly
                                                checked={
                                                    staffHour.isBusinessHour
                                                }
                                            />
                                            {dayInWeeks.map((day, dayIndex) => {
                                                return (
                                                    <Row
                                                        key={dayIndex}
                                                        className='shadow-sm p-2 mb-2 bg-white rounded'
                                                    >
                                                        <Col
                                                            md={4}
                                                            className='day-of-weeks'
                                                            style={{
                                                                color: staffHour[
                                                                    dayIndex
                                                                ].isOpen
                                                                    ? "#007BFF"
                                                                    : "#ADB5BD"
                                                            }}
                                                        >
                                                            {day}
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Check
                                                                type='switch'
                                                                id={
                                                                    "custom-switch-" +
                                                                    dayIndex
                                                                }
                                                                label={
                                                                    staffHour[
                                                                        dayIndex
                                                                    ].isOpen
                                                                        ? "Open"
                                                                        : "Closed"
                                                                }
                                                                name='isOpen'
                                                                readOnly
                                                                checked={
                                                                    staffHour[
                                                                        dayIndex
                                                                    ].isOpen
                                                                }
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            {staffHour[dayIndex]
                                                                .isOpen &&
                                                                staffHour[
                                                                    dayIndex
                                                                ].shifts.map(
                                                                    (
                                                                        shift,
                                                                        shiftIndex
                                                                    ) => {
                                                                        return (
                                                                            <Row
                                                                                key={
                                                                                    shiftIndex
                                                                                }
                                                                            >
                                                                                <Col>
                                                                                    <span className='font-weight-bold p-0 m-0'>
                                                                                        Open:
                                                                                    </span>{" "}
                                                                                    {
                                                                                        shift.open
                                                                                    }
                                                                                </Col>
                                                                                <Col>
                                                                                    <span className='font-weight-bold p-0 m-0'>
                                                                                        Close:
                                                                                    </span>{" "}
                                                                                    {
                                                                                        shift.close
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    }
                                                                )}
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant='link'
                                            eventKey='1'
                                        >
                                            Services
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='1'>
                                        <Card.Body>
                                            <Row>
                                                {services.map(service => {
                                                    return (
                                                        <Col
                                                            key={service._id}
                                                            md={4}
                                                        >
                                                            <label className=' checkbox-container'>
                                                                {service.name}
                                                                <input
                                                                    type='checkbox'
                                                                    checked={service.staffs.includes(
                                                                        staff._id
                                                                    )}
                                                                    readOnly
                                                                />
                                                                <span className='checkmark'></span>
                                                            </label>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    );
};

export default Staff;
