import {
    SEARCH_BUSINESS_STARTED,
    SEARCH_BUSINESS_SUCCESS,
    SEARCH_BUSINESS_FAILURE,
    CLEAR_SEARCH_RESULT,
    ACCESS_PARTNER_DASHBOARD_STARTED,
    ACCESS_PARTNER_DASHBOARD_SUCCESS,
    ACCESS_PARTNER_DASHBOARD_FAILURE,
    REMOVE_ERROR_MESSAGE,
}
from "../../constants/business/business-search"
import { LOGOUT } from "../../constants/employee-account/login";
import API from "../../../utils/API/API"

export const searchBusiness = (query) => {
    return dispatch => { 
        dispatch(searchBusinessStarted())
        API.get(`/api/search/businesses${query}`).then(res => {
           
            dispatch(searchBusinessSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, searchBusinessFailure);
           
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const searchBusinessStarted = () => ({
    type: SEARCH_BUSINESS_STARTED
})

const searchBusinessSuccess = (data) => ({
    type: SEARCH_BUSINESS_SUCCESS,
    payload: {
        businesses:data.businesses,
        countbusinesses:data.countbusinesses,
    }
})

const searchBusinessFailure = (error) => ({
    type: SEARCH_BUSINESS_FAILURE,
    payload: {error}
})


export const clearSearchResult = () => {
    return dispatch => {
        dispatch(({
            type: CLEAR_SEARCH_RESULT
        }))
    }
}


export const accessPartnerDashboard = (_id) => {
    return dispatch => { 
        dispatch(accessPartnerDashboardStarted())
        API.post(`/api/businesses/access-partner-dashboard`, {_id}, {withCredentials: true}).then(res => {
            dispatch(accessPartnerDashboardSuccess())
        }).catch(error => {
            catchError(error, dispatch, accessPartnerDashboardFailure);
           
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const accessPartnerDashboardStarted = () => ({
    type: ACCESS_PARTNER_DASHBOARD_STARTED
})

const accessPartnerDashboardSuccess = () => ({
    type: ACCESS_PARTNER_DASHBOARD_SUCCESS
})

const accessPartnerDashboardFailure = (error) => ({
    type: ACCESS_PARTNER_DASHBOARD_FAILURE,
    payload: { error }
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
