import React from "react";
import { Row, Table } from "react-bootstrap";
import { sortArray } from "../../../utils/common/SortArray/SortArray";
const Menu = props => {
    const { categories, services } = props;
    
    return (
        <div>
            <h4>Menu</h4>
            {categories.map(category => {
                return (
                    <Row key={category._id} className="shadow-none p-3 mb-3 bg-light rounded">
                        <h5>{category.name}</h5>
                        <Table striped bordered hover >
                            <thead >
                                <tr >
                                    <th style={{width: '700px'}}>Service</th>
                                    <th>Duration</th>
                                    <th>Price</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {sortArray(
                                    services.filter(
                                        service =>
                                            service.serviceCategory ===
                                            category._id
                                    ),
                                    category.services,
                                    "_id"
                                ).map(service => {
                                 
                                    return (
                                        <tr key={service._id}>
                                            <td ><Row className="pl-3">
                                                {service.name}
                                            </Row>
                                            <Row className="pl-3">
                                                <em className="text-secondary">{service.description} </em>
                                            </Row></td>
                                            <td>{service.duration}</td>
                                            <td><span className="text-dark font-weight-light">{service.priceType}</span> ${service.regularPrice} </td>
                                     
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Row>
                );
            })}
        </div>
    );
};

export default Menu;
