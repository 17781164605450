import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { getAppointments } from "../../redux/actions/appointment/appointment";
import AppointmentTable from '../../components/appointment/AppointmentTable';
import AppointmentDetail from '../../components/appointment/AppointmentDetail';
import Loading from "../../layouts/Loading/Loading";
import Pages from '../../components/common/page/Pages';

class Appointment extends Component {

    state = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        status: "Cancel",
        modalVisible: false,
        appointmentDetail: null,
        page: 1
    }

    componentDidMount() {
        let { startDate, endDate, status, page } = this.state;
        let query = { from: startDate, to: endDate, status, page };
        this.props.getAppointments(query);
    }

    handlePageChange = (page) => {
        let { startDate, endDate, status } = this.state;
        this.setState({ page }, () => {
            let query = { from: startDate, to: endDate, status: status, page };
            this.props.getAppointments(query);
        })
    }

    handleCalendarClose = (event, picker) => {
        let { startDate, endDate, status } = this.state;
        startDate = picker.startDate.format("YYYY-MM-DD");
        endDate = picker.endDate.format("YYYY-MM-DD");

        this.setState({ startDate, endDate, page: 1 }, () => {
            let query = { from: startDate, to: endDate, status: status, page: 1 };
            this.props.getAppointments(query);
        });
    };

    handleDetailView = (appointmentDetail) => {
        this.setState({
            modalVisible: true,
            appointmentDetail
        })
    }

    handleCloseModal = () => {
        this.setState({
            modalVisible: false
        })
    }

    handleChooseStatus = (eventKey) => {
        this.setState({ status: eventKey, page: 1 }, () => {
            let { startDate, endDate, status, page } = this.state;
            let query = { from: startDate, to: endDate, status, page };
            this.props.getAppointments(query);
        })
    }

    render() {
        if (this.props.loading) {
            return <Loading />
        }
        const { startDate, endDate, modalVisible, appointmentDetail, status, page } = this.state
        return (
            <div>
                <Row>
                    <Col>
                        <h1>Appointment</h1>
                    </Col>

                </Row>
                <Row style={{ marginTop: "20px" }} className="justify-content-md-center">
                    <Col>
                        <DateRangePicker
                            initialSettings={{
                                startDate: startDate,
                                endDate: endDate,
                                opens: "left",
                                showDropdowns: true,
                                autoApply: true,
                                locale: { format: "YYYY-MM-DD" },
                                ranges: {
                                    'Today': [moment(), moment()],
                                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                                },
                            }}
                            onHide={this.handleCalendarClose}
                        >
                            <Button variant="outline-primary">{startDate} - {endDate}</Button>
                        </DateRangePicker>
                    </Col>
                    <Col>
                        <Dropdown style={{ float: "right" }} onSelect={eventKey => this.handleChooseStatus(eventKey)}>
                            <Dropdown.Toggle variant="primary" >
                                Status: {status}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ fontSize: "16px" }}>
                                <Dropdown.Item eventKey="Cancel">Cancel</Dropdown.Item>
                                <Dropdown.Item eventKey="Cancel by client">Cancel by client</Dropdown.Item>
                                <Dropdown.Item eventKey="Cancel by business">Cancel by business</Dropdown.Item>
                                <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                                <Dropdown.Item eventKey="Confirmed">Confirmed</Dropdown.Item>
                                <Dropdown.Item eventKey="Checked-in">Checked-in</Dropdown.Item>
                                <Dropdown.Item eventKey="No-show">No-show</Dropdown.Item>
                                <Dropdown.Item eventKey="Completed">Completed</Dropdown.Item>
                                <Dropdown.Item eventKey="All">All</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col>
                        {this.props.countAppointment === 0
                            ? <h5>No Data</h5>
                            : <div>
                                <h3 style={{ marginBottom: "20px" }}>Total: {this.props.countAppointment}</h3>
                                <AppointmentTable handleDetailView={this.handleDetailView} />
                            </div>
                        }
                    </Col>
                </Row>
                <AppointmentDetail
                    handleCloseModal={this.handleCloseModal}
                    modalVisible={modalVisible}
                    appointmentDetail={appointmentDetail}
                />
                <Row style={{ marginTop: "20px" }}>
                    <Col>
                        <Pages
                            page={page}
                            countPages={Math.ceil(this.props.countAppointment / 10)}
                            handlePageChange={this.handlePageChange}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    countAppointment: state.appointment.count,
    loading: state.appointment.loading,
    error: state.appointment.error,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getAppointments: (query) => dispatch(getAppointments(query)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment)
