import {
    CREATE_BUSINESS_STARTED,
    CREATE_BUSINESS_SUCCESS,
    CREATE_BUSINESS_FAILURE,

    GET_BUSINESS_DETAIL_STARTED,
    GET_BUSINESS_DETAIL_SUCCESS,
    GET_BUSINESS_DETAIL_FAILURE,

    UPDATE_BUSINESS_GENERAL_INFO_STARTED,
    UPDATE_BUSINESS_GENERAL_INFO_SUCCESS,
    UPDATE_BUSINESS_GENERAL_INFO_FAILURE,

    UPDATE_BUSINESS_LOCATION_STARTED,
    UPDATE_BUSINESS_LOCATION_SUCCESS,
    UPDATE_BUSINESS_LOCATION_FAILURE,

    UPDATE_BUSINESS_ABOUT_STARTED,
    UPDATE_BUSINESS_ABOUT_SUCCESS,
    UPDATE_BUSINESS_ABOUT_FAILURE,

    UPDATE_BUSINESS_SCORE_STARTED,
    UPDATE_BUSINESS_SCORE_SUCCESS,
    UPDATE_BUSINESS_SCORE_FAILURE,

    UPLOAD_DISPLAY_IMAGE_STARTED,
    UPLOAD_DISPLAY_IMAGE_SUCCESS,
    UPLOAD_DISPLAY_IMAGE_FAILURE,

    REMOVE_DISPLAY_IMAGE_STARTED,
    REMOVE_DISPLAY_IMAGE_SUCCESS,
    REMOVE_DISPLAY_IMAGE_FAILURE,

    UPLOAD_IMAGE_STARTED,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,

    UPDATE_SENDER_PHONES_FAILURE,
    UPDATE_SENDER_PHONES_STARTED,
    UPDATE_SENDER_PHONES_SUCCESS,

    REMOVE_IMAGE_STARTED,
    REMOVE_IMAGE_SUCCESS,
    REMOVE_IMAGE_FAILURE,

    UPDATE_BUSINESS_IMAGES_STARTED,
    UPDATE_BUSINESS_IMAGES_SUCCESS,
    UPDATE_BUSINESS_IMAGES_FAILURE,

    UPDATE_BUSINESS_ACTIVE_FEATURES_STARTED,
    UPDATE_BUSINESS_ACTIVE_FEATURES_SUCCESS,
    UPDATE_BUSINESS_ACTIVE_FEATURES_FAILURE,

    UPDATE_BUSINESS_ONLINE_STATUS_STARTED,
    UPDATE_BUSINESS_ONLINE_STATUS_SUCCESS,
    UPDATE_BUSINESS_ONLINE_STATUS_FAILURE,

    UPDATE_BUSINESS_SOCIAL_MEDIA_STARTED,
    UPDATE_BUSINESS_SOCIAL_MEDIA_SUCCESS,
    UPDATE_BUSINESS_SOCIAL_MEDIA_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/business/business";
import API from "../../../utils/API/API";
import { LOGOUT } from "../../constants/employee-account/login";



export const updateSenderPhones = (businessId, senderPhones) => {
    return dispatch => {
        dispatch(updateSenderPhonesStarted())
        API.patch(`/api/businesses/update-sender-phones`, { businessId, senderPhones })
            .then(res => {
                dispatch(updateSenderPhonesSuccess(res.data))
            })
            .catch(error => {
                catchError(error, dispatch, updateSenderPhonesFailure);

            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }

}

const updateSenderPhonesStarted = () => ({
    type: UPDATE_SENDER_PHONES_STARTED
})

const updateSenderPhonesSuccess = (data) => ({
    type: UPDATE_SENDER_PHONES_SUCCESS,
    payload: { data }

})

const updateSenderPhonesFailure = (error) => ({
    type: UPDATE_SENDER_PHONES_FAILURE,
    payload: { error }

})



/**
 * Upload New Display Image for a business
 * Display image is displayed on the salons list page
 * @param {*} formData 
 */
export const uploadDisplayImage = formData => {
    return dispatch => {
        dispatch(uploadDisplayImageStarted())
        API.post(`/api/businesses/upload-display-image`, formData).then(res => {
            dispatch(uploadDisplayImageSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, uploadDisplayImageFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const uploadDisplayImageStarted = () => ({
    type: UPLOAD_DISPLAY_IMAGE_STARTED
})

const uploadDisplayImageSuccess = (business) => ({
    type: UPLOAD_DISPLAY_IMAGE_SUCCESS,
    payload: { business }
})

const uploadDisplayImageFailure = (error) => ({
    type: UPLOAD_DISPLAY_IMAGE_FAILURE,
    payload: { error }
})


/**
 * Remove Display Image of a business
 * Display image is display on the salons list page
 * @param {*} formData 
 */
export const removeDisplayImage = business => {
    return dispatch => {
        dispatch(removeDisplayImageStarted())
        API.post(`/api/businesses/remove-display-image`, business).then(res => {
            dispatch(removeDisplayImageSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, removeDisplayImageFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const removeDisplayImageStarted = () => ({
    type: REMOVE_DISPLAY_IMAGE_STARTED
})

const removeDisplayImageSuccess = (business) => ({
    type: REMOVE_DISPLAY_IMAGE_SUCCESS,
    payload: { business }
})

const removeDisplayImageFailure = (error) => ({
    type: REMOVE_DISPLAY_IMAGE_FAILURE,
    payload: { error }
})


/**
 * Upload New Image for a business
 * @param {*} formData 
 */
export const uploadImage = formData => {
    return dispatch => {
        dispatch(uploadImageStarted())
        API.post(`/api/businesses/upload-image`, formData).then(res => {
            dispatch(uploadImageSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, uploadImageFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const uploadImageStarted = () => ({
    type: UPLOAD_IMAGE_STARTED
})

const uploadImageSuccess = (business) => ({
    type: UPLOAD_IMAGE_SUCCESS,
    payload: { business }
})

const uploadImageFailure = (error) => ({
    type: UPLOAD_IMAGE_FAILURE,
    payload: { error }
})


/**
 * Remove Image from a business
 * @param {*} business 
 */
export const removeImage = business => {
    return dispatch => {
        dispatch(removeImageStarted())
        API.post(`/api/businesses/remove-image`, business).then(res => {
            dispatch(removeImageSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, removeImageFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const removeImageStarted = () => ({
    type: REMOVE_IMAGE_STARTED
})

const removeImageSuccess = (business) => ({
    type: REMOVE_IMAGE_SUCCESS,
    payload: { business }
})

const removeImageFailure = (error) => ({
    type: REMOVE_IMAGE_FAILURE,
    payload: { error }
})


export const updateBusinessScore = business => {
    return dispatch => {
        dispatch(updateBusinessScoreStarted())
        API.patch(`/api/businesses/update-score`, business).then(res => {

            dispatch(updateBusinessScoreSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, updateBusinessScoreFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const updateBusinessScoreStarted = () => ({
    type: UPDATE_BUSINESS_SCORE_STARTED
})

const updateBusinessScoreSuccess = (business) => ({
    type: UPDATE_BUSINESS_SCORE_SUCCESS,
    payload: { business }
})

const updateBusinessScoreFailure = (error) => ({
    type: UPDATE_BUSINESS_SCORE_FAILURE,
    payload: { error }
})



/**
 * update Business about info
 * @param {*} business 
 */
export const updateBusinessAbout = business => {
    return dispatch => {
        dispatch(updateBusinessAboutStarted())
        API.patch(`/api/businesses/update-about`, business).then(res => {
            dispatch(updateBusinessAboutSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, updateBusinessAboutFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const updateBusinessAboutStarted = () => ({
    type: UPDATE_BUSINESS_ABOUT_STARTED
})

const updateBusinessAboutSuccess = (business) => ({
    type: UPDATE_BUSINESS_ABOUT_SUCCESS,
    payload: { business }
})

const updateBusinessAboutFailure = (error) => ({
    type: UPDATE_BUSINESS_ABOUT_FAILURE,
    payload: { error }
})


export const updateBusinessLocation = business => {
    return dispatch => {
        dispatch(updateBusinessLocationStarted())
        API.patch(`/api/businesses/update-location`, business).then(res => {
            dispatch(updateBusinessLocationSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, updateBusinessLocationFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const updateBusinessLocationStarted = () => ({
    type: UPDATE_BUSINESS_LOCATION_STARTED
})
const updateBusinessLocationSuccess = (business) => ({
    type: UPDATE_BUSINESS_LOCATION_SUCCESS,
    payload: { business }
})
const updateBusinessLocationFailure = (error) => ({
    type: UPDATE_BUSINESS_LOCATION_FAILURE,
    payload: { error }
})
/**
 *
 * @param {*} business
 */
export const updateBusinessGeneralInfo = business => {
    return dispatch => {
        dispatch(updateBusinessGeneralInfoStarted())
        API.patch(`/api/businesses/update-general`, business).then(res => {
            dispatch(updateBusinessGeneralInfoSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, updateBusinessGeneralInfoFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
};
const updateBusinessGeneralInfoStarted = () => ({
    type: UPDATE_BUSINESS_GENERAL_INFO_STARTED
})

const updateBusinessGeneralInfoSuccess = (business) => ({
    type: UPDATE_BUSINESS_GENERAL_INFO_SUCCESS,
    payload: { business }
})
const updateBusinessGeneralInfoFailure = (error) => ({
    type: UPDATE_BUSINESS_GENERAL_INFO_FAILURE,
    payload: { error }
})

/**
 * Create a new business action
 * @param {*} business
 */
export const createBusiness = (business, paymentProfile) => {
    return dispatch => {
        dispatch(createBusinessStarted());
        API.post(`/api/businesses/create`, { business, paymentProfile })
            .then(res => {
                dispatch(createBusinessSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, createBusinessFailure);

            })
            .finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const createBusinessStarted = () => ({
    type: CREATE_BUSINESS_STARTED
});

const createBusinessSuccess = business => ({
    type: CREATE_BUSINESS_SUCCESS,
    payload: { business }
});

const createBusinessFailure = error => ({
    type: CREATE_BUSINESS_FAILURE,
    payload: { error }
});

/**
 * Get a business action
 */

export const getBusinessDetail = businessId => {
    return dispatch => {
        dispatch(getBusinessDetailStarted());
        API.get(`/api/businesses/${businessId}`)
            .then(res => {
                dispatch(getBusinessDetailSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, getBusinessDetailFailure);

            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getBusinessDetailStarted = () => ({
    type: GET_BUSINESS_DETAIL_STARTED
});

const getBusinessDetailSuccess = business => ({
    type: GET_BUSINESS_DETAIL_SUCCESS,
    payload: { business }
});

const getBusinessDetailFailure = error => ({
    type: GET_BUSINESS_DETAIL_FAILURE,
    payload: { error }
});


/**
 * update Business about info
 * @param {*} business 
 */
export const updateBusinessImages = businessImages => {
    return dispatch => {
        dispatch(updateBusinessImagesStarted())
        API.patch(`/api/businesses/update-image-order`, businessImages).then(res => {
            dispatch(updateBusinessImagesSuccess(businessImages))
        }).catch(error => {
            catchError(error, dispatch, updateBusinessImagesFailure);

        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}
const updateBusinessImagesStarted = () => ({
    type: UPDATE_BUSINESS_IMAGES_STARTED
})

const updateBusinessImagesSuccess = (businessImages) => ({
    type: UPDATE_BUSINESS_IMAGES_SUCCESS,
    payload: { businessImages }
})

const updateBusinessImagesFailure = (error) => ({
    type: UPDATE_BUSINESS_IMAGES_FAILURE,
    payload: { error }
})


/**
 * update Business Active Features
 * @param {*} _id 
 * @param {*} feature 
 */
export const updateBusinessActiveFeatures = (_id, feature, formattedFeature) => {
    return dispatch => {
        dispatch(updateBusinessActiveFeaturesStarted())
        API.patch(`/api/businesses/update-active-features`, { _id, feature, formattedFeature })
            .then(res => {
                dispatch(updateBusinessActiveFeaturesSuccess(res.data))
            })
            .catch(error => {
                catchError(error, dispatch, updateBusinessActiveFeaturesFailure);
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}
const updateBusinessActiveFeaturesStarted = () => ({
    type: UPDATE_BUSINESS_ACTIVE_FEATURES_STARTED
})

const updateBusinessActiveFeaturesSuccess = (data) => ({
    type: UPDATE_BUSINESS_ACTIVE_FEATURES_SUCCESS,
    payload: { data }
})

const updateBusinessActiveFeaturesFailure = (error) => ({
    type: UPDATE_BUSINESS_ACTIVE_FEATURES_FAILURE,
    payload: { error }
})


/**
 * update Business Online Status
 * @param {*} _id 
 */
export const updateBusinessOnlineStatus = _id => {
    return dispatch => {
        dispatch(updateBusinessOnlineStatusStarted())
        API.patch(`/api/businesses/update-online-status`, { _id })
            .then(res => {
                dispatch(updateBusinessOnlineStatusSuccess(res.data))
            })
            .catch(error => {
                catchError(error, dispatch, updateBusinessOnlineStatusFailure);

            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}
const updateBusinessOnlineStatusStarted = () => ({
    type: UPDATE_BUSINESS_ONLINE_STATUS_STARTED
})

const updateBusinessOnlineStatusSuccess = (data) => ({
    type: UPDATE_BUSINESS_ONLINE_STATUS_SUCCESS,
    payload: { data }
})

const updateBusinessOnlineStatusFailure = (error) => ({
    type: UPDATE_BUSINESS_ONLINE_STATUS_FAILURE,
    payload: { error }
})

export const updateBusinessSocialMedia = (businessId, socialMedia) => {
    return dispatch => {
        dispatch(updateBusinessSocialMediaStarted())
        API.patch(`/api/businesses/update-social-media`, { businessId, socialMedia })
            .then(res => {
                dispatch(updateBusinessSocialMediaSuccess(res.data))
            })
            .catch(error => {
                catchError(error, dispatch, updateBusinessSocialMediaFailure);

            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}
const updateBusinessSocialMediaStarted = () => ({
    type: UPDATE_BUSINESS_SOCIAL_MEDIA_STARTED
})

const updateBusinessSocialMediaSuccess = (data) => ({
    type: UPDATE_BUSINESS_SOCIAL_MEDIA_SUCCESS,
    payload: { data }
})

const updateBusinessSocialMediaFailure = (error) => ({
    type: UPDATE_BUSINESS_SOCIAL_MEDIA_FAILURE,
    payload: { error }
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
