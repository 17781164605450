import {
    UPDATE_ACCOUNT_FAILURE,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_STARTED,
    SEARCH_ACCOUNT_STARTED,
    SEARCH_ACCOUNT_SUCCESS,
    SEARCH_ACCOUNT_FAILURE,
    SEND_RESET_PASSWORD_STARTED,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAILURE,
    LOGOUT_USER_FROM_ALL_DEVICES_STARTED,
    LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS,
    LOGOUT_USER_FROM_ALL_DEVICES_FAILURE,
    RESET_PIN_STARTED,
    RESET_PIN_SUCCESS,
    RESET_PIN_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/business/account";

const initialState = {
    accounts: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function account(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case UPDATE_ACCOUNT_SUCCESS:
            let accounts = state.accounts;
            const account = action.payload.account;
            accounts.forEach(existAccount => {
                if (existAccount._id === account._id) {
                    existAccount = account;
                }
            });
            return {
                ...state,
                accounts,
                loading: false,
                error: null,
                message: "Account was updated successfully!",
                component: "AccountForm"
            };
        case UPDATE_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "AccountForm"
            };

        case SEARCH_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case SEARCH_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                accounts: action.payload.accounts,
                component: "SearchForm"
            };
        case SEARCH_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "SearchForm"
            };
        case SEND_RESET_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "AccountForm"
            };
        case SEND_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "Reset Password Email has been sent successfully!",
                component: "AccountForm"
            };
        case SEND_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "SearchForm"
            };
        case LOGOUT_USER_FROM_ALL_DEVICES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "AccountForm"
            };
        case LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "User was logout from all devices!",
                component: "AccountForm"
            };
        case LOGOUT_USER_FROM_ALL_DEVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "AccountForm"
            };
        case RESET_PIN_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "AccountForm"
            }
        case RESET_PIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "Admin passcode has been reset successfully!",
                component: "AccountForm"
            }
        case RESET_PIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "AccountForm"
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return state;
    }
}
