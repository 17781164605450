import {
    GET_ALL_EMPLOYEE_STARTED,
    GET_ALL_EMPLOYEE_SUCCESS,
    GET_ALL_EMPLOYEE_FAILURE,
    ADD_EMPLOYEE_STARTED,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_STARTED,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAILURE,
    SEND_RESET_PASSWORD_EMAIL_STARTED,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_FAILURE,
    LOGOUT_USER_FROM_ALL_DEVICES_STARTED,
    LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS,
    LOGOUT_USER_FROM_ALL_DEVICES_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/employee";
import {LOGOUT} from "../../constants/employee-account/login"
import API from "../../../utils/API/API";

/**
 * Logout user from all devices
 * @param {*} email
 */
export const logoutUserFromAllDevices = email => {
    return dispatch => {
        dispatch(logoutUserFromAllDevicesStarted());
        API.post(`/api/admin/employee-accounts/logout-all-devices`, { email })
            .then(res => {
                dispatch(logoutUserFromAllDevicesSuccess());
            })
            .catch(error => {
                catchError(error, dispatch, logoutUserFromAllDevicesFailure);
                // if (error.response) {
                //     //logout the user
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         logoutUserFromAllDevicesFailure(
                //             error.response.data.message
                //         )
                //     );
                // }
                // dispatch(logoutUserFromAllDevicesFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const logoutUserFromAllDevicesStarted = () => ({
    type: LOGOUT_USER_FROM_ALL_DEVICES_STARTED
});

const logoutUserFromAllDevicesSuccess = () => ({
    type: LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS
});

const logoutUserFromAllDevicesFailure = error => ({
    type: LOGOUT_USER_FROM_ALL_DEVICES_FAILURE,
    payload: { error }
});

/**
 * Send Reset Password Email to employee
 * @param {*} employee
 */
export const sendResetPasswordEmail = email => {
    return dispatch => {
        dispatch(sendResetPasswordEmailStarted());
        API.post(`/api/admin/employee-accounts/reset-password`, { email })
            .then(res => {
                dispatch(sendResetPasswordEmailSuccess());
            })
            .catch(error => {
                catchError(error, dispatch, sendResetPasswordEmailFailure);
                // if (error.response) {
                //     //logout the user
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         sendResetPasswordEmailFailure(
                //             error.response.data.message
                //         )
                //     );
                // }
                // dispatch(sendResetPasswordEmailFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const sendResetPasswordEmailStarted = () => ({
    type: SEND_RESET_PASSWORD_EMAIL_STARTED
});

const sendResetPasswordEmailSuccess = () => ({
    type: SEND_RESET_PASSWORD_EMAIL_SUCCESS
});

const sendResetPasswordEmailFailure = error => ({
    type: SEND_RESET_PASSWORD_EMAIL_FAILURE,
    payload: { error }
});

/**
 * Update a city
 *
 */
export const updateEmployee = employee => {
    return dispatch => {
        dispatch(updateEmployeeStarted());

        API.patch(`/api/admin/employee-accounts/update/${employee._id}`, employee)
            .then(res => {
                dispatch(updateEmployeeSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, updateEmployeeFailure);
                // if (error.response) {
                //     //logout the user
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         updateEmployeeFailure(error.response.data.message)
                //     );
                // }
                // dispatch(updateEmployeeFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const updateEmployeeStarted = () => ({
    type: UPDATE_EMPLOYEE_STARTED
});
const updateEmployeeSuccess = employee => ({
    type: UPDATE_EMPLOYEE_SUCCESS,
    payload: { employee }
});
const updateEmployeeFailure = error => ({
    type: UPDATE_EMPLOYEE_FAILURE,
    payload: { error }
});

/**
 * Add new employee account api
 * @param {*} employee
 */
export const addEmployee = employee => {
    return dispatch => {
        dispatch(addEmployeeStarted());
        API.post(`/api/admin/employee-accounts/create`, employee)
            .then(res => {
                dispatch(addEmployeeSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, addEmployeeFailure);
                // if (error.response) {
                //     //logout the user
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         addEmployeeFailure(error.response.data.message)
                //     );
                // }
                // dispatch(addEmployeeFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const addEmployeeStarted = () => ({
    type: ADD_EMPLOYEE_STARTED
});

const addEmployeeSuccess = employeeAccount => ({
    type: ADD_EMPLOYEE_SUCCESS,
    payload: { employeeAccount }
});

const addEmployeeFailure = error => ({
    type: ADD_EMPLOYEE_FAILURE,
    payload: { error }
});

/**
 * Gets all city api call
 */
export const getAllEmployees = query => {
    return dispatch => {
        dispatch(getEmployeeStarted());
        API.get(`/api/admin/employee-accounts${query}`)
            .then(res => {
                dispatch(
                    getEmployeeSuccess(
                        res.data.employeeAccounts,
                        res.data.count
                    )
                );
            })
            .catch(error => {
                catchError(error, dispatch, getEmployeeFailure);
                // if (error.response) {
                //       //logout the user 
                //     if(error.response.status === 401){
                //         localStorage.clear()
                //     }
                //     return dispatch(
                //         getEmployeeFailure(error.response.data.message)
                //     );
                // }
                // dispatch(getEmployeeFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getEmployeeStarted = () => ({
    type: GET_ALL_EMPLOYEE_STARTED
});
const getEmployeeSuccess = (employeeAccounts, count) => ({
    type: GET_ALL_EMPLOYEE_SUCCESS,
    payload: { employeeAccounts, count }
});
const getEmployeeFailure = error => ({
    type: GET_ALL_EMPLOYEE_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
