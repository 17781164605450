import {
    GET_ALL_EMPLOYEE_STARTED,
    GET_ALL_EMPLOYEE_SUCCESS,
    GET_ALL_EMPLOYEE_FAILURE,
    ADD_EMPLOYEE_STARTED,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_STARTED,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAILURE,
    SEND_RESET_PASSWORD_EMAIL_STARTED,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_FAILURE,
    LOGOUT_USER_FROM_ALL_DEVICES_STARTED,
    LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS,
    LOGOUT_USER_FROM_ALL_DEVICES_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/employee";


const initialState = {
    employeeAccounts: [],
    count: 0,
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function employee(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_EMPLOYEE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employeeAccounts: action.payload.employeeAccounts,
                count: action.payload.count,
                loading: false,
                error: null,
                message: null,
                component: "EmployeeContainer"
            };
        case GET_ALL_EMPLOYEE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "EmployeeContainer"
            };
        case ADD_EMPLOYEE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "EmployeeForm"
            };
        case ADD_EMPLOYEE_SUCCESS:
            var employeeList = state.employeeAccounts;
            employeeList.push(action.payload.employeeAccount);
            return {
                ...state,
                employeeAccounts: employeeList,
                loading: false,
                error: null,
                message: "Account was saved successfully!",
                component: "EmployeeForm"
            };
        case ADD_EMPLOYEE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "EmployeeForm"
            };
        case UPDATE_EMPLOYEE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "EmployeeForm"
            };
        case UPDATE_EMPLOYEE_SUCCESS:
            var updatedEmployee = action.payload.employee;
            var newEmployeeList = state.employeeAccounts.map(account =>
                account._id === updatedEmployee._id ? updatedEmployee : account
            );
            return {
                ...state,
                loading: false,
                employeeAccounts: newEmployeeList,
                error: null,
                message: "Account was updated successfully!",
                component: "EmployeeForm"
            };
        case UPDATE_EMPLOYEE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "EmployeeForm"
            };
        case SEND_RESET_PASSWORD_EMAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "EmployeeForm"
            };
        case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "Reset password email has been sent successfully!",
                component: "EmployeeForm"
            };
        case SEND_RESET_PASSWORD_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "EmployeeForm"
            };
        case LOGOUT_USER_FROM_ALL_DEVICES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "EmployeeForm"
            };
        case LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "User was logout from all devices!",
                component: "EmployeeForm"
            };
        case LOGOUT_USER_FROM_ALL_DEVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "EmployeeForm"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return { ...state };
    }
}
