import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import CountryProvinceCityDropdown from "../../common/country-province-city-dropdown/CountryProvinceCityDropdown";
import { updateBusinessLocation } from "../../../redux/actions/business/business";
import { TIME_ZONES} from "../../../utils/utility"
class LocationInfoForm extends Component {
    state = {
        business: {
            _id: "",
            address: "",
            city: "",
            province: "",
            country: "CA",
            timeZone:"",
            geometry: {
                type: "Point",
                coordinates: [0, 0],
            },
            mapLink: "",
           
        },
        disableSaveButton: true,
        errors: [],
    };

    componentDidMount() {
        this.intializeState();
    }


    //get the location data of business from redux store and assign it to state
    intializeState = () => {
        let { business } = this.state;
        let propBusiness = this.props.business;
        if (propBusiness) {
            const locationProperties = Object.keys(business);
            locationProperties.forEach((property) => {
                if (propBusiness[property]) {
                    business[property] = propBusiness[property];
                }
            });
        }
        this.setState({
            business,
        });
    };

    cancelChange = () => {
        this.intializeState();
        this.setState({
            disableSaveButton: true,
            errors: [],
        });
    };

    //except city and province because they are in child component
    handleChange = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { business } = this.state;
        let geometry = Object.assign({}, business.geometry);
        //make a clone of the coordinates array, otherwise the original value in props will be change
        geometry.coordinates = [...business.geometry.coordinates];

        if (field === "latitude") {
            geometry.coordinates[1] = value;
        } else if (field === "longtitude") {
            geometry.coordinates[0] = value;
        } else {
            business[field] = value;
        }
        business.geometry = geometry;
        this.setState({
            business,
            disableSaveButton: false,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { business } = this.state;
        this.props.updateBusinessLocation(business);
    };

    //Handle change city and province separately because they are in child component
    handleChangeCityProvince = (value, field) => {
        let { business } = this.state;
        business[field] = value;
        this.setState({
            business,
            disableSaveButton: false,
        });
    };

    render() {
        const { business, disableSaveButton } = this.state;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                placeholder="Address including city, province, postal code"
                                value={business.address}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>

                    <CountryProvinceCityDropdown
                        handleChangeCityProvince={this.handleChangeCityProvince}
                        country={business.country}
                        city={business.city}
                        province={business.province}
                    />

                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={this.handleChange}
                                value={business.timeZone}
                                name="timeZone"
                            >
                                <option value="">
                            </option>
                            {TIME_ZONES.filter(timeZone => timeZone.country === business.country).map(timeZone => (
                                <option value={timeZone.value} key={timeZone.value}>
                                    {timeZone.label}
                                </option>
                            ))}
                               
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md={6}>
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Latitude"
                                onChange={this.handleChange}
                                value={business.geometry.coordinates[1]}
                                name="latitude"
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Longtitude</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Longtitude"
                                onChange={this.handleChange}
                                value={business.geometry.coordinates[0]}
                                name="longtitude"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Map Link </Form.Label>
                            {business.mapLink && (
                                <a
                                    rel="noopener noreferrer"
                                    href={business.mapLink}
                                    target="_blank"
                                    className="float-right"
                                >
                                    Visit Map Link
                                </a>
                            )}
                            <Form.Control
                                as="textarea"
                                rows="5"
                                placeholder="Google map link"
                                name="mapLink"
                                value={business.mapLink}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            {" "}
                            <Button
                                variant="primary"
                                disabled={disableSaveButton}
                                type="submit"
                            >
                                Save
                            </Button>{" "}
                            <Button
                                variant="secondary"
                                onClick={this.cancelChange}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.business.business,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateBusinessLocation: (business) =>
            dispatch(updateBusinessLocation(business)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfoForm);
