import React from "react";
import { Table, Button, Alert, Row, Col } from "react-bootstrap";


export const CityTable = props => {
    const cities = props.cities;
    const provinces = props.provinces;

    if (cities.length === 0) {
        return (
            <Row>
                <Col>
                    <Alert variant='info'>There is no city found!</Alert>
                </Col>
            </Row>
        );
    }
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Province</th>
                    <th>Number of Businesses</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {cities.map(city => {
                    const province = provinces.filter(
                        province => province._id === city.province
                    )[0];

                    return (
                        <tr key={city._id}>
                            <td>{city.name}</td>
                            <td>{province.name}</td>
                            <td>{city.numberOfBusinesses}</td>
                            <td>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        const selectedCity = Object.assign({}, city)
                                        props.selectCity(selectedCity)
                                    }}
                                >
                                    <span
                                        className='oi oi-pencil'
                                        title='icon name'
                                        aria-hidden='true'
                                    ></span>{" "}
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>


    );
};
