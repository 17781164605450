import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Dropdown, Form, InputGroup, Row, Table } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Loading from '../../../layouts/Loading/Loading'
import API from '../../../utils/API/API'
import Pages from '../../common/page/Pages'
import ErrorSuccess from '../../ErrorSuccess/ErrorSuccess'

const Reviews = (props) => {
    const [rate, setRate] = useState(null)
    const [from, setFrom] = useState(moment())
    const [to, setTo] = useState(moment())
    const [clientName, setClientName] = useState('')

    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)

    const [delayedSearchTerm, setDelayedSearchTerm] = useState(clientName);

    const [reviews, setReviews] = useState([])
    const [countReviews, setCountReviews] = useState(0)

    useEffect(() => {
        const delay = 1000;
        const timeoutId = setTimeout(() => {
            setDelayedSearchTerm(clientName);
            setPage(1)
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [clientName]);

    useEffect(() => {
        const getReviews = async () => {
            try {
                setLoading(true)
                const res = await API.post('/api/review/get-reviews',
                    {
                        business: props.business._id,
                        rate: rate ? [Number(rate)] : [1, 2, 3, 4, 5],
                        createdAt: { $gte: from, $lte: to },
                        clientName: delayedSearchTerm,
                        page: page,
                    }
                );
                setReviews(res.data.reviews)
                setCountReviews(res.data.countReview)
                setLoading(false)
                setMessage(null)
                setError(null)
            } catch (error) {
                setLoading(false)
                setMessage(null)
                setError(error.message)
            }
        }

        getReviews()
    }, [rate, from, to, page, delayedSearchTerm, props.business._id]);

    const handleCalendarClose = (event, picker) => {
        setFrom(picker.startDate)
        setTo(picker.endDate)
        setPage(1)
    };

    const onDelete = async (index) => {
        const confirmed = window.confirm("Are you sure to delete this review?")
        if (confirmed) {
            try {
                setLoading(true)
                await API.post('/api/review/remove-review', reviews[index])
                    .then(async () => {
                        const res = await API.post('/api/review/get-reviews',
                            {
                                business: props.business._id,
                                rate: rate ? [Number(rate)] : [1, 2, 3, 4, 5],
                                createdAt: { $gte: from, $lte: to },
                                clientName,
                                page: page
                            }
                        );
                        setReviews(res.data.reviews)
                        setCountReviews(res.data.countReview)
                    });
                setLoading(false)
                setMessage("Delete review successfully!")
                setError(null)
            } catch (error) {
                setLoading(false)
                setMessage(null)
                setError(error.message)
            }
        }
    }



    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ marginTop: "30px" }}>
            <Row>
                <Col>
                    <ErrorSuccess error={error} message={message} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DateRangePicker
                        initialSettings={{
                            startDate: from,
                            endDate: to,
                            opens: "left",
                            showDropdowns: true,
                            autoApply: true,
                            locale: { format: "YYYY-MM-DD" },
                            ranges: {
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                'This Year': [moment().startOf('year'), moment().endOf('year')],
                            },
                        }}
                        onHide={handleCalendarClose}
                    >
                        <Button variant="outline-primary">{from.format("YYYY-MM-DD")} - {to.format("YYYY-MM-DD")}</Button>
                    </DateRangePicker>
                </Col>
                <Col>
                    <InputGroup className='mb-2'>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Client Name
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type='text'
                            placeholder="Search by Client Name"
                            onChange={e => setClientName(e.target.value)}
                            value={clientName}
                            autoFocus
                        />
                    </InputGroup>
                </Col>
                <Col>
                    <Dropdown
                        style={{ float: "right" }}
                        onSelect={eventKey => {
                            setRate(eventKey)
                            setPage(1)
                        }}
                    >
                        <Dropdown.Toggle variant="primary" >
                            Rate : {rate === null ? "All rating" : rate[0]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ fontSize: "16px" }}>
                            <Dropdown.Item eventKey={null}>All rating</Dropdown.Item>
                            <Dropdown.Item eventKey={1}>1</Dropdown.Item>
                            <Dropdown.Item eventKey={2}>2</Dropdown.Item>
                            <Dropdown.Item eventKey={3}>3</Dropdown.Item>
                            <Dropdown.Item eventKey={4}>4</Dropdown.Item>
                            <Dropdown.Item eventKey={5}>5</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: "30px" }}>
                    {
                        reviews.length > 0
                            ? <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Created At</th>
                                        <th>Client Name</th>
                                        <th>Rate</th>
                                        <th>Comment</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reviews.map((row, index) => (
                                        <tr key={index}>
                                            <td>{moment(row.createdAt).format('YYYY-MM-DD')}</td>
                                            <td>{row.clientName}</td>
                                            <td>{row.rate}</td>
                                            <td>{row.comment}</td>
                                            <td>
                                                <Button variant="danger" onClick={() => onDelete(index)}>
                                                    <span className="oi oi-trash"></span>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            : <h3>No data</h3>
                    }

                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col>
                    <Pages
                        page={page}
                        countPages={Math.ceil(countReviews / 10)}
                        handlePageChange={setPage}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Reviews