export const ADD_ACCOUNT_STARTED = "ADD_ACCOUNT_STARTED"
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS"
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE"
export const FOUND_DUPLICATED_ACCOUNTS = "FOUND_DUPLICATED_ACCOUNTS"
export const REMOVE_DUPLICATED_ACCOUNTS = "REMOVE_DUPLICATED_ACCOUNTS"

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_STARTED = "UPDATE_ACCOUNT_STARTED"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE"


export const SEARCH_ACCOUNT = "SEARCH_ACCOUNT";
export const SEARCH_ACCOUNT_STARTED = "SEARCH_ACCOUNT_STARTED"
export const SEARCH_ACCOUNT_SUCCESS = "SEARCH_ACCOUNT_SUCCESS"
export const SEARCH_ACCOUNT_FAILURE = "SEARCH_ACCOUNT_FAILURE"


export const SEND_RESET_PASSWORD_STARTED = "SEND_RESET_PASSWORD_STARTED"
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS"
export const SEND_RESET_PASSWORD_FAILURE = "SEND_RESET_PASSWORD_FAILURE"

export const RESET_PIN_STARTED = "RESET_PIN_STARTED"
export const RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS"
export const RESET_PIN_FAILURE = "RESET_PIN_FAILURE"

export const LOGOUT_USER_FROM_ALL_DEVICES_STARTED = "LOGOUT_USER_FROM_ALL_DEVICES_STARTED"
export const LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS = "LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS"
export const LOGOUT_USER_FROM_ALL_DEVICES_FAILURE = "LOGOUT_USER_FROM_ALL_DEVICES_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"