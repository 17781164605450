import {
    ADD_BUSINESS_STATUS_STARTED,
    ADD_BUSINESS_STATUS_SUCCESS,
    ADD_BUSINESS_STATUS_FAILURE,
    GET_ALL_BUSINESS_STATUS_STARTED,
    GET_ALL_BUSINESS_STATUS_SUCCESS,
    GET_ALL_BUSINESS_STATUS_FAILURE,
    UPDATE_BUSINESS_STATUS_STARTED,
    UPDATE_BUSINESS_STATUS_SUCCESS,
    UPDATE_BUSINESS_STATUS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/business-status";

const initialState = {
    businessStatuses: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function businessStatus(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BUSINESS_STATUS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_BUSINESS_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                businessStatuses: action.payload.businessStatuses,
                component: "BusinessStatusContainer"
            };
        case GET_ALL_BUSINESS_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessStatusContainer"
            };
        case ADD_BUSINESS_STATUS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessStatusForm"
            };
        case ADD_BUSINESS_STATUS_SUCCESS:
            var businessStatuses = state.businessStatuses;
            businessStatuses.push(action.payload);
            return {
                ...state,
                businessStatuses,
                loading: false,
                error: null,
                message: "Status was save successfully!",
                component: "BusinessStatusForm"
            };
        case ADD_BUSINESS_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessStatusForm"
            };
        case UPDATE_BUSINESS_STATUS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessStatusForm"
            };
        case UPDATE_BUSINESS_STATUS_SUCCESS:
                const updateBusinessStatus = action.payload.businessStatus
                var newBusinessStatuses = state.businessStatuses.map(status => status._id === updateBusinessStatus._id ? updateBusinessStatus:status)
            return {
                ...state,
                businessStatuses: newBusinessStatuses,
                loading: false,
                error: null,
                message: "Status was save successfully!",
                component: "BusinessStatusForm"
            };
        case UPDATE_BUSINESS_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessStatusForm"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
