import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Button,
    Form
} from "react-bootstrap";
import ProvinceModal from "../../../components/admin/province/ProvinceModal";
import ProvinceTable from "../../../components/admin/province/ProvinceTable";
import { getAllProvinces } from "../../../redux/actions/admin/province";
import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../../components/ErrorSuccess/ErrorSuccess";
import { countryCodes } from "../../../utils/utility"


class ProvinceContainer extends Component {
    state = {
        modalShow: false,
        setModalShow: false,
        selectedProvince: null,
        country: "CA"
    };
    componentDidMount() {
        this.props.getAllProvinces();
    }
    openCloseAddModal = e => {
        const { modalShow } = this.state;
        //Reset selectedProvince in state when modal is closing
        if (modalShow) {
            this.setState({
                selectedProvince: null
            });
        }
        this.setState({
            modalShow: !this.state.modalShow
        });
    };

    selectProvince = province => {
        this.setState({
            selectedProvince: province,
            modalShow: true
        });
    };

    handleSelectCountry = e => {
        const value = e.target.value;
        this.setState({
            country: value
        });
    };

    render() {
        const { modalShow, selectedProvince, country } = this.state;
        const { error, message, component, provinces, loading } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <h1>Provinces</h1>
                        <Button variant='info' onClick={this.openCloseAddModal}>
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Form>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as='select'
                                onChange={this.handleSelectCountry}
                                name='Country'
                                value={this.state.country}
                            >
                                {countryCodes.map(ctr => {
                                    return <option key={ctr.country} value={ctr.country}>{ctr.country}</option>
                                })}

                            </Form.Control>
                        </Form>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {loading ? (
                            <Loading />
                        ) : (
                            <ProvinceTable
                                provinces={provinces}
                                selectProvince={this.selectProvince}
                                country={country}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {component === "ProvinceContainer" && (
                            <ErrorSuccess error={error} message={message} />
                        )}
                    </Col>
                </Row>
                <ProvinceModal
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                    selectedProvince={selectedProvince}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        provinces: state.province.provinces,
        loading: state.province.loading,
        error: state.province.error,
        message: state.province.message,
        component: state.province.component
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllProvinces: () => dispatch(getAllProvinces())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvinceContainer);
