import {
    GET_WEBSITE_STARTED,
    GET_WEBSITE_SUCCESS,
    GET_WEBSITE_FAILURE,
    UPDATE_WEBSITE_STARTED,
    UPDATE_WEBSITE_SUCCESS,
    UPDATE_WEBSITE_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/business/website";
import API from "../../../utils/API/API"

export const getWebsite = (bid) => {
    return dispatch => {
        dispatch(getWebsiteStarted())
        API.get(`/api/website/get?bid=${bid}`).then(res => {
            dispatch(getWebsiteSuccess(res.data))
        }).catch(error => {
            if (error.response) {
                //Logout user if not authenticated
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return dispatch(
                    getWebsiteFailure(error.response.data.message)
                );
            }
            dispatch(getWebsiteFailure(error.message))
        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const getWebsiteStarted = () => ({
    type: GET_WEBSITE_STARTED
})

const getWebsiteSuccess = (website) => ({
    type: GET_WEBSITE_SUCCESS,
    payload: { website }
})

const getWebsiteFailure = (error) => ({
    type: GET_WEBSITE_FAILURE,
    payload: { error }
})

export const updateWebsite = (website) => {
    return dispatch => {
        dispatch(updateWebsiteStarted())
        API.patch(`/api/website/update`, website).then(res => {
            dispatch(updateWebsiteSuccess(res.data))
        }).catch(error => {
            if (error.response) {
                //Logout user if not authenticated
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return dispatch(
                    updateWebsiteFailure(error.response.data.message)
                );
            }
            dispatch(updateWebsiteFailure(error.message))
        }).finally(() => setTimeout(function () {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const updateWebsiteStarted = () => ({
    type: UPDATE_WEBSITE_STARTED
})

const updateWebsiteSuccess = (website) => ({
    type: UPDATE_WEBSITE_SUCCESS,
    payload: { website }
})

const updateWebsiteFailure = (error) => ({
    type: UPDATE_WEBSITE_FAILURE,
    payload: { error }
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})