import React from "react";
import { Modal, Button } from "react-bootstrap";
import ApprovalDetail from "./ApprovalDetail";

const ApprovalModal = (props) => {
  const { approval, ...rest } = props;
  return (
    <Modal
      {...rest}
      style={{ width: "120% !important" }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Approval Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ApprovalDetail approval={approval} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovalModal;
