export const GET_BUSINESS_ACCOUNT_STARTED = "GET_BUSINESS_ACCOUNT_STARTED"
export const GET_BUSINESS_ACCOUNT_SUCCESS = "GET_BUSINESS_ACCOUNT_SUCCESS"
export const GET_BUSINESS_ACCOUNT_FAILURE = "GET_BUSINESS_ACCOUNT_FAILURE"

export const ADD_BUSINESS_ACCOUNT_STARTED = "ADD_BUSINESS_ACCOUNT_STARTED"
export const ADD_BUSINESS_ACCOUNT_SUCCESS = "ADD_BUSINESS_ACCOUNT_SUCCESS"
export const ADD_BUSINESS_ACCOUNT_FAILURE = "ADD_BUSINESS_ACCOUNT_FAILURE"


export const FOUND_DUPLICATED_ACCOUNTS = "FOUND_DUPLICATED_ACCOUNTS"
export const REMOVE_DUPLICATED_ACCOUNTS = "REMOVE_DUPLICATED_ACCOUNTS"

// export const UPDATE_BUSINESS_ACCOUNT_STARTED = "UPDATE_BUSINESS_ACCOUNT_STARTED"
// export const UPDATE_BUSINESS_ACCOUNT_SUCCESS = "UPDATE_BUSINESS_ACCOUNT_SUCCESS"
// export const UPDATE_BUSINESS_ACCOUNT_FAILURE = "UPDATE_BUSINESS_ACCOUNT_FAILURE"

export const REMOVE_BUSINESS_ACCOUNT_STARTED = "REMOVE_BUSINESS_ACCOUNT_STARTED"
export const REMOVE_BUSINESS_ACCOUNT_SUCCESS = "REMOVE_BUSINESS_ACCOUNT_SUCCESS"
export const REMOVE_BUSINESS_ACCOUNT_FAILURE = "REMOVE_BUSINESS_ACCOUNT_FAILURE"

export const SEND_WELCOME_EMAIL_STARTED = "SEND_WELCOME_EMAIL_STARTED"
export const SEND_WELCOME_EMAIL_SUCCESS = "SEND_WELCOME_EMAIL_SUCCESS"
export const SEND_WELCOME_EMAIL_FAILURE = "SEND_WELCOME_EMAIL_FAILURE"

export const ADD_DUPLICATED_ACCOUNT_STARTED = "ADD_DUPLICATED_ACCOUNT_STARTED"
export const ADD_DUPLICATED_ACCOUNT_SUCCESS = "ADD_DUPLICATED_ACCOUNT_SUCCESS"
export const ADD_DUPLICATED_ACCOUNT_FAILURE = "ADD_DUPLICATED_ACCOUNT_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"

