import {
    GET_STAFF_HOUR_SUCCESS,
    GET_STAFF_HOUR_STARTED,
    GET_STAFF_HOUR_FAILURE,

    UPDATE_STAFF_HOUR_STARTED,
    UPDATE_STAFF_HOUR_SUCCESS,
    UPDATE_STAFF_HOUR_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/staff-hour";


const initialState = {
    staffHour: {},
    loading: false,
    error: null,
    message: null,
    component: null
};

export default function staffHour (state = initialState, action){
    switch (action.type) {
        case GET_STAFF_HOUR_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "StaffHour"
            }
        case GET_STAFF_HOUR_SUCCESS: 
            return {
                ...state,
                staffHour: action.payload.staffHour,
                loading: false,
                error: null,
                message: null,
                component: "StaffHour"
            }
        case GET_STAFF_HOUR_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "StaffHour"
            }
        case UPDATE_STAFF_HOUR_STARTED: 
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "StaffHour"
            }
        case UPDATE_STAFF_HOUR_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: null,
                message: "Update staff hour successfully!",
                staffHour: action.payload.staffHour,
                component: "StaffHour"
            }
        case UPDATE_STAFF_HOUR_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "StaffHour"
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        default:
            return {
                ...state
            };
    }
};
