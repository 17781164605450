import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { updateBusinessSocialMedia } from "../../../redux/actions/business/business"
class SocialMedia extends Component {
    state = {
        socialMedia: [],
        disabledSaveButton: true
    }
    componentDidMount() {
        let { socialMedia } = this.state;
        let propBusiness = this.props.business;
        if (propBusiness) {
            socialMedia = this.props.business.socialMedia ? this.props.business.socialMedia : []
        }
        this.setState({
            socialMedia
        });
    }

    handleChange = (e) => {
        const key = e.target.id;
        const index = e.target.name
        const value = e.target.value;
        let { socialMedia } = this.state

        socialMedia[index][key] = value;
        this.setState({
            socialMedia,
            disabledSaveButton: false
        });
    }

    handleDeleteMedia = (index) => {
        const confirmed = window.confirm(
            "Are your sure to delete this social media?"
        );
        if (confirmed) {
            let { socialMedia } = this.state;
            socialMedia.splice(index, 1);
            this.setState({
                socialMedia,
                disabledSaveButton: false
            });
        }
    }

    handleAddMedia = () => {
        let { socialMedia } = this.state
        socialMedia = [
            ...socialMedia,
            {
                type: "",
                url: ""
            }
        ]
        this.setState({ socialMedia })
    }

    handleCancel = () => {
        let { socialMedia } = this.state;
        let propBusiness = this.props.business;
        if (propBusiness) {
            socialMedia = this.props.business.socialMedia ? this.props.business.socialMedia : []
        }
        this.setState({
            socialMedia,
            disabledSaveButton: true
        });
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { socialMedia } = this.state
        this.props.updateBusinessSocialMedia(this.props.business._id, socialMedia)
        this.setState({ disabledSaveButton: true })
    }

    render() {
        const { socialMedia, disabledSaveButton } = this.state
        return (
            <Row>
                <Form onSubmit={this.handleSubmit}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Url</th>
                                <th>Social Media</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {socialMedia.map((media, index) => {
                                return <tr key={index}>
                                    <td>
                                        <Form.Control
                                            id="url"
                                            name={index}
                                            required
                                            value={media.url}
                                            onChange={this.handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            id="type"
                                            name={index}
                                            required
                                            value={media.type}
                                            as="select"
                                            onChange={this.handleChange}
                                        >
                                            <option value="">Please selct</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Google">Google</option>
                                            <option value="Instagram">Instagram</option>
                                            <option value="Tiktok">TikTok</option>
                                            <option value="Yelp">Yelp</option>
                                            <option value="Twitter">Twitter</option>
                                            <option value="LinkedIn">LinkedIn</option>
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <Button
                                            variant='link'
                                            className='text-danger'
                                            onClick={() =>
                                                this.handleDeleteMedia(index)
                                            }
                                        >
                                            <span
                                                className='oi oi-trash'
                                            ></span>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </Table>
                    <Row className='mt-3'>
                        <Col>
                            <Button
                                variant='info'
                                onClick={this.handleAddMedia}
                            >
                                {" "}
                                <span
                                    className='oi oi-plus'
                                    title='icon name'
                                    aria-hidden='true'
                                ></span>{" "}
                                Add More Social Media
                            </Button>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <Button variant='primary' type='submit' disabled={disabledSaveButton}>
                                Save
                            </Button>{" "}
                            <Button
                                variant='secondary'
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => {
    return {
        updateBusinessSocialMedia: (businessId, socialMedia) => dispatch(updateBusinessSocialMedia(businessId, socialMedia)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia)
