import axios from "axios";

let authToken = "";

if (localStorage.getItem("user")) {
   const user = JSON.parse(localStorage.getItem("user"));
   authToken = user.token
}

let baseURL = "https://manage-backend.partnersdash.com"

if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
   baseURL = "http://localhost:3015"
}

export default axios.create({
   baseURL,
   // withCredentials: true,
   headers: { common: { Authorization: `Bearer ${authToken}` } }
});
