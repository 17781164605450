import React from "react";
import { Form, Col, Row, Button, InputGroup } from "react-bootstrap";
import { countryCodes } from "../../../utils/utility";

const ClientGeneralForm = (props) => {
    const { client,
        deleteClientProfile,
        formOnChange,
        updateClientProfile,
        selectedClientCountry
    } = props;

    return (
        <div className="mt-2">
            <Row className="mt-2">
                <Col style={{ width: "100%", display: "inline" }}>
                    <h5>
                        General Information
                        <Button style={{ float: "right" }} variant="danger" onClick={() => {
                            if (window.confirm("Are you sure you want to delete this user?")) {
                                deleteClientProfile()
                            }
                        }}
                        >
                            Delete <span className='oi oi-trash' title='icon name' aria-hidden='true' />
                        </Button>
                    </h5>
                </Col>
            </Row>
            <div className="m-2">
                <Row className="mt-2">
                    <Col>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={client.firstName}
                            onChange={(e) => formOnChange(e)}
                            required
                        />
                    </Col>
                    <Col>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={client.lastName}
                            onChange={(e) => formOnChange(e)}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Form.Label>Phone</Form.Label>
                        <InputGroup>
                            <Form.Control
                                as="select"
                                value={selectedClientCountry}
                                onChange={props.handleChangeFormClientCountry}
                                name="clientCountry"
                                style={{ maxWidth: '100px', borderRight: "solid 1px gray" }}
                            >
                                {countryCodes.map(country =>
                                    <option value={country.code} key={country.code}>{country.label}</option>
                                )}
                            </Form.Control>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                value={client.phone}
                                onChange={(e) => formOnChange(e)}
                                required
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={client.email}
                            onChange={(e) => formOnChange(e)}
                            required
                        />
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                            as="select"
                            name="gender"
                            value={client.gender}
                            onChange={(e) => formOnChange(e)}
                        >
                            <option value=""></option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                            <option value="Prefer not to say">Prefer not to say</option>
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Label>Birthday</Form.Label>
                        <Form.Control
                            type="text"
                            name="birthday"
                            placeholder="Example: Jan 02"
                            value={client.birthday}
                            onChange={(e) => formOnChange(e)}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={12}>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={client.address}
                            onChange={(e) => formOnChange(e)}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Check
                            type="checkbox"
                            name="notifyByPhone"
                            label="Notify By Phone"
                            defaultChecked={client.notifyByPhone}
                            onClick={(e) => formOnChange(e)}
                        />
                    </Col>
                    <Col md={6}>
                        <Form.Check
                            type="checkbox"
                            name="notifyByEmail"
                            label="Notify By Email"
                            defaultChecked={client.notifyByEmail}
                            onClick={(e) => formOnChange(e)}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center" style={{ marginTop: "20px" }}>
                    <Col md="auto">
                        <Button style={{ float: "right" }} onClick={() => updateClientProfile()} variant="primary">
                            Update
                        </Button>
                    </Col>
                </Row>
            </div>


        </div>
    );
};

export default ClientGeneralForm;
