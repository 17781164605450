import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Col, Row } from "react-bootstrap";
import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../ErrorSuccess/ErrorSuccess";
import {
    addEmployee,
    updateEmployee,
    sendResetPasswordEmail,
    logoutUserFromAllDevices
} from "../../../redux/actions/admin/employee";

import { formatPhoneNumber } from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
class EmployeeForm extends Component {
    state = {
        employee: {
            id: "",
            name: "",
            title: "",
            phone: "",
            email: "",
            allowedApps: [],
            role: "dashbooking_employee",
            active: true
        },
        displayMessageError: false
    };
    componentDidMount() {
        if (this.props.employee) {
            this.setState({
                employee: this.props.employee
            });
        }
    }

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;

        let employee = this.state.employee;
        if (field === "phone") {
            value = formatPhoneNumber(value, "CA");
            employee.phone = value
        } else {
            employee[field] = value
        }


        this.setState({
            employee
        });
    };

    handleChangeAllowedApps = (e) => {
        const value = e.target.value;

        let { allowedApps } = this.state.employee;
        if (allowedApps.includes(value)) {

            allowedApps = allowedApps.filter(option => option !== value);
        } else {
            allowedApps = [...allowedApps, value];
        }


        this.setState({
            employee: {
                ...this.state.employee, allowedApps
            }
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        const { employee } = this.state;
        if (employee._id) {
            this.props.updateEmployee(employee);
        } else {
            this.props.addEmployee(employee);
        }
        this.setState({
            displayMessageError: true
        });
    };

    handleResetPassword = email => () => {
        const confirmed = window.confirm("A reset password email will be sent to the user. Confirm?");
        if (confirmed) {
            this.props.sendResetPasswordEmail(email);
            this.setState({
                displayMessageError: true
            });
        }

    };

    handleLogoutUser = (email) => () => {
        const confirmed = window.confirm("Logout this user from all devices. Confirm?");
        if (confirmed) {
            this.props.logoutUserFromAllDevices(email);
            this.setState({
                displayMessageError: true
            });
        }
    }

    render() {
        const { employee, displayMessageError } = this.state;
        const { error, message, component, loading } = this.props;
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Row className='mt-2'>
                            <Col md={6}>
                                <Form.Label> Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={employee.name}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Label> Title</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Title'
                                    name='title'
                                    value={employee.title}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={6}>
                                <Form.Label> Email</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Email'
                                    name='email'
                                    value={employee.email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Label> Phone</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Phone'
                                    name='phone'
                                    value={employee.phone}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={6}>
                                <Form.Label>Allow Apps:</Form.Label>
                                <Form.Control as="select" multiple name='allowedApps' value={employee.allowedApps} onChange={this.handleChangeAllowedApps}>
                                    <option value="management">Management</option>
                                    <option value="merchant">Merchant</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={6}>
                                <Form.Label> Active</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='active'
                                    custom='true'
                                    value={employee.active}
                                    onChange={this.handleChange}
                                >
                                    <option value='true'>Active</option>
                                    <option value='false'>Inactive</option>
                                </Form.Control>
                            </Col>
                            <Col md={6}>
                                <Form.Label> Role</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='role'
                                    custom='true'
                                    value={employee.role}
                                    onChange={this.handleChange}
                                >
                                    <option value='dashbooking_employee'>
                                        Employee
                                    </option>
                                    <option value='dashbooking_accountant'>
                                        Accountant
                                    </option>
                                    <option value='dashbooking_developer'>
                                        Developer
                                    </option>
                                    <option value='dashbooking_admin'>
                                        Admin
                                    </option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <Button
                                        variant='primary'
                                        className='float-left'
                                        type='submit'
                                    >
                                        Save
                                    </Button>
                                )}
                            </Col>
                            <Col>
                                {employee._id && (
                                    <Button
                                        variant='link'
                                        onClick={this.handleResetPassword(
                                            employee.email
                                        )}
                                    >
                                        Reset Password
                                    </Button>
                                )}
                            </Col>
                            <Col>
                                {employee._id && (
                                    <Button
                                        variant='link'
                                        onClick={this.handleLogoutUser(
                                            employee.email
                                        )}
                                    >
                                        Logout User
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            {displayMessageError &&
                                component === "EmployeeForm" && (
                                    <ErrorSuccess
                                        error={error}
                                        message={message}
                                    />
                                )}
                        </Row>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.employee.error,
        loading: state.employee.loading,
        message: state.employee.message,
        component: state.employee.component
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addEmployee: employeeAccount => dispatch(addEmployee(employeeAccount)),
        updateEmployee: employeeAccount => dispatch(updateEmployee(employeeAccount)),
        sendResetPasswordEmail: email => dispatch(sendResetPasswordEmail(email)),
        logoutUserFromAllDevices: email => dispatch(logoutUserFromAllDevices(email))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeForm);
