export const SEARCH_BUSINESS_STARTED = "SEARCH_BUSINESS_STARTED"
export const SEARCH_BUSINESS_SUCCESS = "SEARCH_BUSINESS_SUCCESS"
export const SEARCH_BUSINESS_FAILURE = "SEARCH_BUSINESS_FAILURE"

export const CLEAR_SEARCH_RESULT = "CLEAR_SEARCH_RESULT"

export const ACCESS_PARTNER_DASHBOARD_STARTED = "ACCESS_PARTNER_DASHBOARD_STARTED"
export const ACCESS_PARTNER_DASHBOARD_SUCCESS = "ACCESS_PARTNER_DASHBOARD_SUCCESS"
export const ACCESS_PARTNER_DASHBOARD_FAILURE = "ACCESS_PARTNER_DASHBOARD_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"