import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Row, Col, Button} from 'react-bootstrap'
import  CityModal  from '../../../components/admin/city/CityModal';
import {getAllCitiesByProvince} from '../../../redux/actions/admin/city'
import { CityTable } from '../../../components/admin/city/CityTable';
import Loading from '../../../layouts/Loading/Loading';
import ErrorSuccess from '../../../components/ErrorSuccess/ErrorSuccess'

class CityContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            modalShow: false,
            setModalShow: false,
            selectedCity: null
            
        };
        // this.selectCity = this.selectCity.bind(this)
    }
  

    componentDidMount() {
        const query = this.props.location.search
        this.props.getAllCitiesByProvince(query)
    }
    
    openCloseAddModal = e => {
        const { modalShow } = this.state;
        //Reset selectedCity in state when modal is closing
      
        if (modalShow) {
            this.setState({
                selectedCity: null
            });
        }
        this.setState({
            modalShow: !this.state.modalShow
        });
    };

    selectCity = (city) => {
        this.setState({
            selectedCity: city,
            modalShow: true
        });
    };




    render() {
        const { modalShow, selectedCity  } = this.state;
        const {provinces,  cities, loading, component, error, message, province} = this.props

        const provinceName = province ? `Cities in ${province.name}` :  "Cities"

        return (
            <div>
                <Row>
                    <a href="/admin/provinces"> <span className="oi oi-chevron-left"></span> Go back to Provinces</a>
                </Row>
                 <Row>
                    <Col>
                        <h1>{provinceName}  </h1>
                        <Button variant='info' onClick={this.openCloseAddModal}>
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    {loading? <Loading/>:<CityTable cities = {cities} provinces = {provinces} province={province} selectCity={this.selectCity}/>}
                </Row>
                <Row>
                    
                        {component === "CityContainer" && (
                            <ErrorSuccess error={error} message={message} />
                        )}
                    
                </Row>
                <CityModal
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                    selectedCity={selectedCity}
                    province={this.props.province}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        provinces: state.city.provinces,
        province: state.city.province,
        cities: state.city.cities,
        loading: state.city.loading,
        error: state.city.error,
        message: state.city.message,
        component: state.city.component
    }
}
    


const mapDispatchToProps = (dispatch) => {
    return  {
      getAllCitiesByProvince: (query) => dispatch(getAllCitiesByProvince(query))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityContainer)
