import {
    GET_GIFT_CARD_SETTINGS_STARTED,
    GET_GIFT_CARD_SETTINGS_SUCCESS,
    GET_GIFT_CARD_SETTINGS_FAILURE,
    UPDATE_GIFT_CARD_SETTINGS_STARTED,
    UPDATE_GIFT_CARD_SETTINGS_SUCCESS,
    UPDATE_GIFT_CARD_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/gift-card-settings/giftCardSettings";

import API from "../../../utils/API/API";

export const getGiftCardSettings = (id) => {
    return dispatch => {
        dispatch(getGiftCardSettingsStarted())
        API.get(`/api/gift-card-settings/get/${id}`)
            .then(res => {
                dispatch(getGiftCardSettingsSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, getGiftCardSettingsFailure);
            })
    }
}

const getGiftCardSettingsStarted = () => ({
    type: GET_GIFT_CARD_SETTINGS_STARTED
})

const getGiftCardSettingsSuccess = (data) => ({
    type: GET_GIFT_CARD_SETTINGS_SUCCESS,
    payload: {
        settings: data
    }
})

const getGiftCardSettingsFailure = (error) => ({
    type: GET_GIFT_CARD_SETTINGS_FAILURE,
    payload: { error }
})

export const updateGiftCardSettings = (settings) => {
    return dispatch => {
        dispatch(updateGiftCardSettingsStarted())
        API.post(`/api/gift-card-settings/update`, settings)
            .then(res => {
                dispatch(updateGiftCardSettingsSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, updateGiftCardSettingsFailure);
            })
    }
}

const updateGiftCardSettingsStarted = () => ({
    type: UPDATE_GIFT_CARD_SETTINGS_STARTED
})

const updateGiftCardSettingsSuccess = (data) => ({
    type: UPDATE_GIFT_CARD_SETTINGS_SUCCESS,
    payload: {
        settings: data
    }
})

const updateGiftCardSettingsFailure = (error) => ({
    type: UPDATE_GIFT_CARD_SETTINGS_FAILURE,
    payload: { error }
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
    }

    dispatch(nextFunction(message));
    setTimeout(function () {
        dispatch(removeErrorMessage());
    }, 2000);
}