import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Dropdown } from "react-bootstrap";
import BusinessReportNavigation from "../../components/business-payment/BusinessReportNavigation";
import BusinessReportContentContainer from "../../components/business-payment/BusinessReportContentContainer";
import { getBusinessDetail } from "../../redux/actions/business/business";
import {
    // getBusinessReportGeneral, 
    getBusinessPaymentProfile,
    // getBusinessReportMonths 
} from "../../redux/actions/business-payment/business-payment";
import { getAllProvinces } from "../../redux/actions/admin/province";

import moment from "moment";
import "./daterangepicker.css";
import Loading from "../../layouts/Loading/Loading";
import { ErrorMessageToast } from "../../components/ErrorSuccess/ErrorMessageToast"

class BusinessReportContainer extends Component {
    state = {
        currentTab: "paymentProfile",
        // selectedDate: "",
    };

    componentDidMount() {
        const businessId = this.props.match.params.id;

        // this.props.getBusinessReportMonths(businessId);
        this.props.getAllProvinces();
        this.props.getBusinessDetail(businessId);
        this.props.getBusinessPaymentProfile(businessId);
    };

    handleChangeTab = (tab) => {
        this.setState({
            currentTab: tab
        });
    };


    mapDropwdownItems = (months) => {
        let mapDropwdownItems = null;

        if (months) {
            mapDropwdownItems = months.map(month => {
                return <Dropdown.Item
                    key={month._id}
                    eventKey={month._id}
                    active={this.state.selectedDate === moment(month.month, "YYYY-MM").format("MMM YYYY")}
                >
                    {moment(month.month, "YYYY-MM").format("MMM YYYY")}
                </Dropdown.Item>
            })
        };

        mapDropwdownItems.sort((a, b) => (a.start > b.start ? 1 : -1));

        return mapDropwdownItems;
    };


    render() {
        const {
            // selectedDate, 
            currentTab
        } = this.state;
        const { business, loadingBusiness, loadingGeneral, loadingProvince, error, message } = this.props;


        if (loadingBusiness || loadingGeneral || loadingProvince) {
            return <Loading />
        }
        return (
            <div>
                <Row>
                    <Col>
                        <h2>{business && business.name}</h2>
                    </Col>
                    {/* {currentTab === "general" && <Col md="auto" >
                        <Dropdown
                            as={ButtonGroup}
                            onSelect={(monthId, event) => this.handleDropdownSelect(monthId, event.target.outerText)}
                        >
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                {selectedDate || "No data found"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {mapDropwdownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    } */}
                </Row>

                <Tab.Container
                    defaultActiveKey="paymentProfile"
                >
                    <Row>
                        <Col sm={3}>
                            <BusinessReportNavigation
                                handleChangeTab={this.handleChangeTab}
                                businessId={this.props.match.params.id}
                            />
                        </Col>
                        <Col>
                            <BusinessReportContentContainer
                                currentTab={currentTab}
                            />
                        </Col>
                    </Row>
                </Tab.Container>
                <div className="error-message-container">
                    <ErrorMessageToast error={error} message={message} />
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => ({
    business: state.business.business,
    loadingProvince: state.province.loading,
    loadingBusiness: state.business.loading,
    loadingGeneral: state.businessReport.loading,
    error: state.businessReport.error,
    message: state.businessReport.message,
    months: state.businessReport.months,

});

const mapDispatchToProps = dispatch => {
    return {
        getAllProvinces: () => dispatch(getAllProvinces()),
        getBusinessDetail: businessId => dispatch(getBusinessDetail(businessId)),
        getBusinessPaymentProfile: businessId => dispatch(getBusinessPaymentProfile(businessId)),
        // getBusinessReportGeneral: query => dispatch(getBusinessReportGeneral(query)),
        // getBusinessReportMonths: business => dispatch(getBusinessReportMonths(business)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessReportContainer);