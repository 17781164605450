import {
    GET_APPOINTMENTS_STARTED,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/appointment/appointment";

const initialState = {
    appointments: [],
    count: 0,
    loading: false,
    error: null,
};

export default function appointment(state = initialState, action) {
    switch (action.type) {
        case GET_APPOINTMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload.appointments,
                count: action.payload.countAppointments,
                loading: false,
                error: null,
            };
        case GET_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}
