import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
export const PreviewImageModal = props => {

    const { imageUrl, handleDeleteImage,  ...rest } = props;

    return (
        <div>
            <Modal
                {...rest}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='modal-90w'
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Preview Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <img src={imageUrl} style={{ width: "100%" }} alt="Business Description"/>
                        </Col>
                    </Row>

                  
                    <Row className="mt-3">
                        <Col>
                          
                            <Button className="text-danger" variant="link" onClick={() => handleDeleteImage()}> <span className='oi oi-trash'></span> Delete</Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
