import {
    GET_ALL_PROVINCES_CITIES_STARTED,
    GET_ALL_PROVINCES_CITIES_SUCCESS,
    GET_ALL_PROVINCES_CITIES_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/province-city/province-city"
import API from "../../../utils/API/API"


export const getAllProvincesCities = () => {
    return dispatch => {
        dispatch(getAllProvincesCitiesStarted())
        API.get(`/api/get-all-cities-and-provinces`).then(res => {
            dispatch(getAllProvincesCitiesSuccess(res.data))
        }).catch(error => {
            if (error.response) {
                //Logout user if not authenticated
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return dispatch(
                    getAllProvincesCitiesFailure(error.response.data.message)
                );
            }
            dispatch(getAllProvincesCitiesFailure(error.message))
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const getAllProvincesCitiesStarted = () => ({
    type: GET_ALL_PROVINCES_CITIES_STARTED
})

const getAllProvincesCitiesSuccess = (data) => ({
    type: GET_ALL_PROVINCES_CITIES_SUCCESS,
    payload: data
})

const getAllProvincesCitiesFailure =(error) => ({
    type: GET_ALL_PROVINCES_CITIES_FAILURE,
    payload: {error}
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})