import {
    SEARCH_CLIENT_STARTED,
    SEARCH_CLIENT_SUCCESS,
    SEARCH_CLIENT_FAILURE,
    BLOCK_CLIENT_STARTED,
    BLOCK_CLIENT_SUCCESS,
    BLOCK_CLIENT_FAILURE,
    UPDATE_CLIENT_PROFILE_STARTED,
    UPDATE_CLIENT_PROFILE_SUCCESS,
    UPDATE_CLIENT_PROFILE_FAILURE,
    DELETE_CLIENT_STARTED,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILURE,
    UPDATE_CLIENT_ACTIVE_STARTED,
    UPDATE_CLIENT_ACTIVE_SUCCESS,
    UPDATE_CLIENT_ACTIVE_FAILURE,
    GET_CLIENT_STATISTICS_STARTED,
    GET_CLIENT_STATISTICS_SUCCESS,
    GET_CLIENT_STATISTICS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/client/client";

const initialState = {
    clients: [],
    client: null,
    newClientsCount: 0,
    loading: false,
    error: null,
    message: null,
    component: null, //use this to define in which component  message or error should be displaed
};

export default function client(state = initialState, action) {
    switch (action.type) {
        case SEARCH_CLIENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ClientContainer",
            };
        case SEARCH_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.payload.clients,
                loading: false,
                message: null,
                error: null,
            };
        case SEARCH_CLIENT_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            }
        case BLOCK_CLIENT_STARTED:
            return {
                ...state,
                message: null,
                loading: true,
                error: null,
                component: "ClientAuthModal"
            };
        case BLOCK_CLIENT_SUCCESS:
            var blockMessage = "";
            if (action.payload.data.block) {
                blockMessage = "The client has been blocked!"
            } else {
                blockMessage = "The client has been unblocked!"
            }
            var clients = state.clients.map(client => {
                if (client.account._id === action.payload.data._id) {
                    client.account.block = action.payload.data.block
                    client.account.blockReason = action.payload.data.blockReason
                }
                return client
            })
            return {
                ...state,
                clients,
                message: blockMessage,
                loading: false,
                error:null,
            }
        case BLOCK_CLIENT_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_CLIENT_PROFILE_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
                component: "ClientProfileModal"
            };
        case UPDATE_CLIENT_PROFILE_SUCCESS:
            var updatedProfile = action.payload.data

            var newClientArray = state.clients.map(client => {
                if (client._id === updatedProfile._id) {
                    // res appointments returns an array of appointmentId
                    // replace that with the populated appointment data which is already on the client 
                    updatedProfile.appointments = [...client.appointments]
                    return updatedProfile
                }
                return client
            })

            return {
                ...state,
                loading: false,
                clients: newClientArray,
                message: "The client profile was updated successfully!",
                error: null,
            }
        case UPDATE_CLIENT_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };
        case DELETE_CLIENT_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
                component: "ClientProfileModal",
            };
        case DELETE_CLIENT_SUCCESS:
            const deletedClientId = action.payload.data;
            var updatedClientArray = state.clients.filter(client =>
                client._id !== deletedClientId
            )

            return {
                ...state,
                loading: false,
                clients: updatedClientArray,
                message: "The client profile was deleted successfully!",
                error: null,
            }
        case DELETE_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };
        case UPDATE_CLIENT_ACTIVE_STARTED:
            return {
                ...state,
                message: null,
                loading: true,
                error: null,
                component: "ClientAuthModal"
            };
        case UPDATE_CLIENT_ACTIVE_SUCCESS:
            var updateActiveMessage = "";
            if (action.payload.data.active) {
                updateActiveMessage = "The client is active"
            } else {
                updateActiveMessage = "The client is now inactive"
            }

            var clientActiveArray = state.clients.map(client => {
                if (client.account._id === action.payload.data._id) {
                    client.account.active = action.payload.data.active
                }
                return client
            })

            return {
                ...state,
                clients: clientActiveArray,
                message: updateActiveMessage,
                loading: false,
                error:null,
            }
        case UPDATE_CLIENT_ACTIVE_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            };
        case GET_CLIENT_STATISTICS_STARTED:
            return {
                ...state,
                message: null,
                loading: true,
                error: null,
                component: "ClientStatistics"
            };
        case GET_CLIENT_STATISTICS_SUCCESS:
            return {
                ...state,
                newClientsCount: action.payload.data.newClientsCount,
                message: "Client statistics successful",
                loading: false,
                error:null,
            }
        case GET_CLIENT_STATISTICS_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}
