import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import ClientStatistics from "../../../components/client/client-statistics/ClientStatistics";
import moment from "moment";
import { getClientStatistics } from "../../../redux/actions/client/client";
import Loading from "../../../layouts/Loading/Loading";

class ClientStatisticsContainer extends Component {
    state = {
        startDate: moment().startOf('month').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
    }
    
    componentDidMount() {
        const { startDate, endDate } = this.state;
        let query = { from: startDate, to: endDate };
        this.props.getClientStatistics(query);
    };

    handleCalendarClose = (event, picker) => {
        let { startDate, endDate } = this.state;
        startDate = picker.startDate.format("YYYY-MM-DD");
        endDate = picker.endDate.format("YYYY-MM-DD");
        
        this.setState({ startDate, endDate }, () => {
            let query = { from: startDate, to: endDate };
            this.props.getClientStatistics(query);
        });
    };

    render() {
        const { startDate, endDate } = this.state;
        const { newClientsCount, loading } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <h1>Client Statistics</h1>
                    </Col>
                </Row>
                {loading && <Loading />}
                <Row className="m-3">
                    <Col>
                        <ClientStatistics 
                            startDate={startDate}
                            endDate={endDate}
                            handleCalendarClose={this.handleCalendarClose}
                            newClientsCount={newClientsCount}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    newClientsCount: state.client.newClientsCount,
    loading: state.client.loading,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getClientStatistics: (query) => dispatch(getClientStatistics(query)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientStatisticsContainer);
