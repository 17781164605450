import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import queryString from "query-string";
import { formatPhoneNumber } from "../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
import {
    searchBusiness,
    clearSearchResult,
} from "../../redux/actions/business/business-search";
import { countryCodes } from "../../utils/utility";
class BusinessSearchForm extends Component {
    state = {
        searchForm: {
            name: "",
            phone: "",
            email: "",
            address: "",
            businessType: "",
            status: "",
            province: "",
            city: "",
            online: "",
            country: "",
        },
        cities: [],
        provinces: [],
    };

    isSearchButtonDisable = () => {
        const { searchForm } = this.state;
        let isDisabled = true;
        const requiredFields = [
            "name",
            "phone",
            "email",
            "address",
            "status",
            "province",
            "city",
            "online",
            "country",
        ];
        requiredFields.forEach((field) => {
            if (searchForm[field]) {
                isDisabled = false;
            }
        });
        return isDisabled;
    };

    componentDidMount() {
        if (this.props.provinces) {
            this.setState({
                provinces: this.props.provinces,
            });
        }
        if (this.props.cities) {
            this.setState({
                cities: this.props.cities,
            });
        }

        //based on the url, get all parameters for the search form
        const queryObject = queryString.parse(this.props.location.search);

        if (Object.keys(queryObject).length > 0) {
            let { searchForm } = this.state;
            Object.keys(queryObject).forEach((key) => {
                if (queryObject[key]) {
                    searchForm[key] = queryObject[key];
                    if (key === "phone") {
                        searchForm[key] = formatPhoneNumber(
                            queryObject[key],
                            searchForm.country
                        );
                    }
                }
            });
            this.setState({
                searchForm,
            });
            this.props.searchBusiness(this.props.location.search + `&page=1`);
            this.props.displaySearchResult();
        }
    }

    handleChangeSearchForm = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { searchForm } = this.state;
        //reset city field when province has been changed
        if (field === "province") {
            searchForm.city = "";
        }

        if (field === "phone") {
            value = formatPhoneNumber(value, searchForm.country);
        }

        if (field === "country") {
            searchForm.phone = formatPhoneNumber(searchForm.phone, value);
            searchForm.city = "";
            searchForm.province = "";
        }

        searchForm[field] = value;
        this.setState({
            searchForm,
        });

        // let providedFields = 0;
        // Object.values(searchForm).forEach(value => {
        //     if (value) {
        //         providedFields++;
        //     }
        // });
        // if (providedFields >= 3 || searchForm.name.length > 6 || searchForm.phone || searchForm.email) {
        //     this.setState({
        //         disableSeachButton: false
        //     });
        // } else {
        //     this.setState({
        //         disableSeachButton: true
        //     });
        // }
    };

    clearForm = () => {
        this.setState({
            searchForm: {
                name: "",
                phone: "",
                email: "",
                address: "",
                businessType: "",
                status: "",
                country:"ca",
                province: "",
                city: "",
                online: "",
            },
        });
        this.props.history.push("/businesses");
        this.props.hideSearchResult();
        this.props.clearSearchResult();
    };

    render() {
        const {
            businessTypes,
            //statuses
        } = this.props;
        let { cities, provinces, searchForm } = this.state;
        //only display province of the selected country

        console.log(searchForm.country)
        if (searchForm.country) {
            provinces = this.props.provinces.filter(
                (province) =>
                    province.country === searchForm.country
            
            );
        }
        //only display cities of the selected province
        if (searchForm.province) {
            cities = this.props.cities.filter(
                (city) => city.province === searchForm.province
            );
        }

        const disableSeachButton = this.isSearchButtonDisable();

        return (
            <Form md={12} className='m-3'>
                <h3>Search</h3>
                <Form.Group>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Label> Business Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Name'
                                name='name'
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.name}
                            />
                        </Col>
                        
                        <Col md={6}>
                            <Form.Label>Country - Phone</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    as='select'
                                    value={searchForm.country}
                                    name='country'
                                    onChange={this.handleChangeSearchForm}
                                    style={{
                                        maxWidth: "150px",
                                        borderRight: "1px solid gray",
                                    }}
                                >
                                    <option value=''>Select Country</option>
                                    {countryCodes.map((country) => (
                                        <option
                                            value={country.code}
                                            key={country.code}
                                        >
                                            {country.label}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control
                                    type='text'
                                    placeholder='Phone'
                                    name='phone'
                                    onChange={this.handleChangeSearchForm}
                                    value={searchForm.phone}
                                    disabled={searchForm.country === ""}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Label> Email</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='Email'
                                name='email'
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.email}
                            />
                        </Col>

                        <Col md={6}>
                            <Form.Label> Address</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Address'
                                name='address'
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.address}
                            />
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col>
                            <Form.Label>Province</Form.Label>
                            <Form.Control
                                as='select'
                                name='province'
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.province}
                            >
                                <option value=''>Select Province</option>
                                {provinces.map((province) => {
                                    return (
                                        <option
                                            key={province._id}
                                            value={province._id}
                                        >
                                            {province.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                as='select'
                                name='city'
                                disabled={!searchForm.province}
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.city}
                            >
                                <option value=''>Select City</option>
                                {cities.map((city) => {
                                    return (
                                        <option key={city._id} value={city._id}>
                                            {city.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Form.Label>Business Type</Form.Label>
                            <Form.Control
                                as='select'
                                name='businessType'
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.businessType}
                            >
                                <option value=''>Select Business Type</option>
                                {businessTypes.map((type) => {
                                    return (
                                        <option key={type._id} value={type._id}>
                                            {type.title}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Online</Form.Label>
                            <Form.Control
                                as='select'
                                name='online'
                                onChange={this.handleChangeSearchForm}
                                value={searchForm.online}
                            >
                                <option value=''>Select Online Status</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={6} className='mt-4'>
                            <Button
                                variant='primary'
                                type='submit'
                                className='mr-3'
                                disabled={disableSeachButton}
                            >
                                {" "}
                                <span
                                    className='oi oi-magnifying-glass'
                                    title='icon name'
                                    aria-hidden='true'
                                ></span>{" "}
                                Search
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={this.clearForm}
                            >
                                <span
                                    className='oi oi-x'
                                    title='icon name'
                                    aria-hidden='true'
                                ></span>{" "}
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    businesses: state.searchBusiness.businesses,
    loading: state.searchBusiness.loading,
    error: state.searchBusiness.error,
    message: state.searchBusiness.message,
    component: state.searchBusiness.component,
});

const mapDispatchToProps = (dispatch) => {
    return {
        searchBusiness: (query) => dispatch(searchBusiness(query)),
        clearSearchResult: () => dispatch(clearSearchResult()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSearchForm);
