import {
    GET_ALL_PROVINCE_STARTED,
    GET_ALL_PROVINCE_SUCCESS,
    GET_ALL_PROVINCE_FAILURE,
    UPDATE_PROVINCE_STARTED,
    UPDATE_PROVINCE_SUCCESS,
    UPDATE_PROVINCE_FAILURE,
    ADD_PROVINCE_STARTED,
    ADD_PROVINCE_SUCCESS,
    ADD_PROVINCE_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/province";

const initialState = {
    provinces: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function province(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PROVINCE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_PROVINCE_SUCCESS:
            return {
                ...state,
                provinces: action.payload.provinces,
                loading: false,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_PROVINCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ProvinceContainer"
            };
        case UPDATE_PROVINCE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case UPDATE_PROVINCE_SUCCESS:
            const updatedProvince = action.payload.province;
            var provinces = state.provinces.map(province =>
                province._id === updatedProvince._id ? updatedProvince : province
            );
            
            return {
                ...state,
                provinces,
                loading: false,
                error: null,
                message: "Province was saved successfully!",
                component: "ProvinceForm"
            };
        case UPDATE_PROVINCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ProvinceForm"
            };
        case ADD_PROVINCE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case ADD_PROVINCE_SUCCESS:
            var newProvinceList = state.provinces;
            newProvinceList.push(action.payload.province);
            return {
                ...state,
                provinces: newProvinceList,
                loading: false,
                error: null,
                message: "Province was saved successfully!",
                component: "ProvinceForm"
            };
        case ADD_PROVINCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ProvinceForm"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
