import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import NotFoundPage from "../layouts/NotFound/NotFoundPage";
import BusinessContainer from "../pages/business/BusinessContainer";
import BusinessDetailContainer from "../pages/business-details/BusinessDetailContainer";
import TestContainer from "../pages/test/TestContainer";
import ClientSearchContainer from "../pages/client/client-search/ClientSearchContainer";
import ClientStatisticsContainer from "../pages/client/client-statistics/ClientStatisticsContainer";
import BusinessReportContainer from "../pages/business-payment/BusinessReportContainer";
import CreateBusinessPage from "../pages/business/CreateBusinessPage"
import NavigationContainer from "../layouts/menu/NavigationContainer";
import Appointment from "../pages/appointment/Appointment";
// import BusinessStatisticsContainer from "../pages/business-statistics/BusinessStatisticsContainer";
import { Container } from "react-bootstrap";
import ProvinceContainer from "../pages/admin/province/ProvinceContainer";
import CityContainer from "../pages/admin/city/CityContainer";
import EmployeeContainer from "../pages/admin/employee/EmployeeContainer";
import BusinessTypeContainer from "../pages/admin/business-type/BusinessTypeContainer";
import BusinessStatusContainer from "../pages/admin/business-status/BusinessStatusContainer";
import Notification from '../pages/notification/Notification';
import Payments from '../pages/payments/Payments';

export const AdminRouters = () => {
    return (
        <Router>
            <NavigationContainer />
            <Container>
                <div className="App" style={{ marginTop: "20px" }}>
                    <Switch>
                        <Route path="/test" component={TestContainer} />
                        <Route path="/login" component={BusinessContainer} />
                        <Route path="/" exact component={BusinessContainer} />

                        <Route
                            path="/businesses"
                            exact
                            component={BusinessContainer}
                        />

                        <Route
                            path="/create-business"
                            exact
                            component={CreateBusinessPage}
                        />

                        <Route
                            path="/businesses/:id"
                            exact
                            component={BusinessDetailContainer}
                        />
                        <Route
                            path="/businesses/reports/:id"
                            component={BusinessReportContainer}
                        />
                        {/* <Route
                            path="/businesses/statistics/:id"
                            component={BusinessStatisticsContainer}
                        /> */}

                        <Route
                            path="/clients/search"
                            component={ClientSearchContainer}
                        />
                        <Route
                            path="/clients/statistics"
                            component={ClientStatisticsContainer}
                        />
                        <Route
                            path="/admin/business-status"
                            component={BusinessStatusContainer}
                        />
                        <Route
                            path="/admin/business-type"
                            component={BusinessTypeContainer}
                        />
                        <Route
                            path="/admin/provinces"
                            component={ProvinceContainer}
                        />
                        <Route path="/admin/cities" component={CityContainer} />
                        <Route
                            path="/admin/employees"
                            component={EmployeeContainer}
                        />
                        <Route
                            path="/appointment"
                            exact
                            component={Appointment}
                        />
                        {/* <Route
                                    path="/admin/employees"
                                    component={
                                        EmployeeContainer
                                    }
                                /> */}

                        <Route
                            path="/notification"
                            component={
                                Notification
                            }
                        />
                        <Route
                            path="/payments"
                            component={
                                Payments
                            }
                        />
                        <Route path="/*" component={NotFoundPage} />

                    </Switch>
                    {/* </Container> */}
                </div>
            </Container>
        </Router>
    );
};
