export const SEARCH_CLIENT_STARTED = "SEARCH_CLIENT_STARTED"
export const SEARCH_CLIENT_SUCCESS = "SEARCH_CLIENT_SUCCESS"
export const SEARCH_CLIENT_FAILURE = "SEARCH_CLIENT_FAILURE"

export const BLOCK_CLIENT_STARTED = "BLOCK_CLIENT_STARTED"
export const BLOCK_CLIENT_SUCCESS = "BLOCK_CLIENT_SUCCESS"
export const BLOCK_CLIENT_FAILURE = "BLOCK_CLIENT_FAILURE"

export const UPDATE_CLIENT_PROFILE_STARTED = "UPDATE_CLIENT_PROFILE_STARTED"
export const UPDATE_CLIENT_PROFILE_SUCCESS = "UPDATE_CLIENT_PROFILE_SUCCESS"
export const UPDATE_CLIENT_PROFILE_FAILURE = "UPDATE_CLIENT_PROFILE_FAILURE"

export const DELETE_CLIENT_STARTED = "DELETE_CLIENT_STARTED"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE"

export const UPDATE_CLIENT_ACTIVE_STARTED = "UPDATE_CLIENT_ACTIVE_STARTED"
export const UPDATE_CLIENT_ACTIVE_SUCCESS = "UPDATE_CLIENT_ACTIVE_SUCCESS"
export const UPDATE_CLIENT_ACTIVE_FAILURE = "UPDATE_CLIENT_ACTIVE_FAILURE"

export const GET_CLIENT_STATISTICS_STARTED = "GET_CLIENT_STATISTICS_STARTED"
export const GET_CLIENT_STATISTICS_SUCCESS = "GET_CLIENT_STATISTICS_SUCCESS"
export const GET_CLIENT_STATISTICS_FAILURE = "GET_CLIENT_STATISTICS_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"