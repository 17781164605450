import {
    CREATE_PREVIEW_BUSINESS_STARTED,
    CREATE_PREVIEW_BUSINESS_SUCCESS,
    CREATE_PREVIEW_BUSINESS_FAILURE,
    GET_PREVIEW_BUSINESS_STARTED,
    GET_PREVIEW_BUSINESS_SUCCESS,
    GET_PREVIEW_BUSINESS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/preview-business";

import API from "../../../utils/API/API";

/**
 * Gets all preview of a business
 */
export const getAllPreviewBusiness = businessId => {
    return dispatch => {
        dispatch(getAllPreviewBusinessStarted());
        API.get(`/api/preview-business/${businessId}`)
            .then(res => {
                dispatch(
                    getAllPreviewBusinessSuccess(
                        res.data,
                    )
                );
            })
            .catch(error => {
                if (error.response) {
                    //logout the user
                    if (error.response.status === 401) {
                        localStorage.clear()
                    }
                    return dispatch(
                        getAllPreviewBusinessFailure(error.response.data.message)
                    );
                }
                dispatch(getAllPreviewBusinessFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getAllPreviewBusinessStarted = () => ({
    type: GET_PREVIEW_BUSINESS_STARTED
});
const getAllPreviewBusinessSuccess = (previews) => ({
    type: GET_PREVIEW_BUSINESS_SUCCESS,
    payload: { previews }
});
const getAllPreviewBusinessFailure = error => ({
    type: GET_PREVIEW_BUSINESS_FAILURE,
    payload: { error }
});


/**
 * Gets all notes
 */
export const createPreviewBusiness = preview => {
    return dispatch => {
        dispatch(createPreviewBusinessStarted());
        API.post(`/api/preview-business/create`, preview)
            .then(res => {
                dispatch(
                    createPreviewBusinessSuccess(
                        res.data,
                    )
                );
            })
            .catch(error => {
                if (error.response) {
                    //logout the user
                    if (error.response.status === 401) {
                        localStorage.clear()
                    }
                    return dispatch(
                        createPreviewBusinessFailure(error.response.data.message)
                    );
                }
                dispatch(createPreviewBusinessFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const createPreviewBusinessStarted = () => ({
    type: CREATE_PREVIEW_BUSINESS_STARTED
});
const createPreviewBusinessSuccess = (preview) => ({
    type: CREATE_PREVIEW_BUSINESS_SUCCESS,
    payload: { preview }
});
const createPreviewBusinessFailure = error => ({
    type: CREATE_PREVIEW_BUSINESS_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});