import React, { useState } from "react";
import { Table, Badge, Button, Alert, Modal } from "react-bootstrap";
import { decodeHtmlEntity } from "../../../utils/utility";

const BusinessAccountTable = props => {
  const [show, setShow] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleClose = () => setShow(false);
  // Modify handleShow to take an account as a parameter
  const handleShow = (account) => {
    setSelectedAccount(account);
    setShow(true)
    // Your existing code to show the modal
  };


  if (!props.accounts[0]) {
    return <Alert variant="secondary">There is no record found!</Alert>;
  }
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Status</th>
          <th>Role</th>
          <th>Edit</th>
          <th>Send Welcome Email</th>
          <th>Remove Account</th>
        </tr>
      </thead>
      <tbody>
        {props.accounts.map(account => {
          // const business = {
          //   _id: props.business,
          //   account: account._id
          // }
          return (
            <tr key={account.email}>
              <td>{decodeHtmlEntity(account.name)}</td>
              <td>{account.email}</td>
              <td>{account.phone}</td>
              <td>
                {account.active.toString() === "true" ? (
                  <Badge variant="success">Active</Badge>
                ) : (
                  <Badge variant="secondary">Inactive</Badge>
                )}
              </td>
              <td>
                {account.role === "salon_owner" ? (
                  <Badge variant="danger">Owner</Badge>
                ) : (
                  <Badge variant="primary">Employee</Badge>
                )}
              </td>
              <td>
                <Button variant="link text-info" onClick={() => props.selecteAccount(account)} >
                  <span className="oi oi-pencil"></span>
                </Button>
              </td>
              <td>
                <Button variant="info" size="sm" onClick={() => props.sendWelcomeEmail(account)} >
                  Send
                </Button>
              </td>
              <td>
                <Button variant="link text-danger" onClick={() => handleShow(account)} >
                  <span className="oi oi-x"></span> Remove
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Remove Account</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure to remove this account <span style={{ fontWeight: "bold" }}>{selectedAccount?.email}</span>?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="danger" onClick={() => props.removeBusinessAccount(selectedAccount?._id, props.business)}>
                      Remove
                    </Button>
                  </Modal.Footer>
                </Modal>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table >
  );
};

export default BusinessAccountTable;
