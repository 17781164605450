import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import BusinessStatusModal from "../../../components/admin/business-status/BusinessStatusModal";
import { getBusinessStatus, addBusinessStatus, updateBusinessStatus } from "../../../redux/actions/admin/business-status";
import BusinessStatusTable from "../../../components/admin/business-status/BusinessStatusTable";
import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../../components/ErrorSuccess/ErrorSuccess";
function mapStateToProps(state) {
    return {
        businessStatuses: state.businessStatus.businessStatuses,
        loading: state.businessStatus.loading,
        error: state.businessStatus.error,
        message: state.businessStatus.message,
        component: state.businessStatus.component
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBusinessStatus: () => dispatch(getBusinessStatus()),
        addBusinessStatus: (businessStatus) => dispatch(addBusinessStatus(businessStatus)),
        updateBusinessStatus: (businessStatus) => dispatch(updateBusinessStatus(businessStatus))
    };
}

class BusinessStatusContainer extends Component {
    state = {
        modalShow: false,
        setModalShow: false,
        selectedStatus: null
    };

    openCloseAddModal = e => {
        const {modalShow} = this.state
        //Reset selectedStatus in state when modal is closing
        if(modalShow){
          this.setState({
            selectedStatus: null
          })
        }
        this.setState({
            modalShow: !this.state.modalShow
        });
        
    };
    componentDidMount() {
        this.props.getBusinessStatus();
    }

    selectBusinessStatus = businessStatus => {
      this.setState({
        selectedStatus : businessStatus,
        modalShow: true,
      })
    }

    render() {
        const { modalShow , selectedStatus} = this.state;
        const { loading, error, message, component } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <h1>Business Status</h1>

                        <Button variant='info' onClick={this.openCloseAddModal}>
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {loading ? (
                            <Loading />
                        ) : (
                            <BusinessStatusTable
                                businessStatuses={this.props.businessStatuses}
                                selectBusinessStatus = {this.selectBusinessStatus}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {component === "BusinessStatusContainer" && (
                            <ErrorSuccess error={error} message={message} />
                        )}
                    </Col>
                </Row>
                <BusinessStatusModal
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                    selectedStatus={selectedStatus}      
                   
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessStatusContainer);
