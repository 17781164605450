import React, { Component } from "react";
import { connect } from "react-redux";
// import API from "../../../utils/API/API";
import { Button, Form, Col, Row } from "react-bootstrap";
import { ProvinceDropDown } from "./ProvinceDropDown";
import { addCity, updateCity } from "../../../redux/actions/admin/city";
import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../ErrorSuccess/ErrorSuccess";
class CityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: {
                name: "",
                province: "",
                cityUrl: "",
                isActiveForFetching: false,
            },
            // numberOfBusinesses: 0,
            displayMessage: false
        };
    }
    componentDidMount() {
        var { selectedCity, province } = this.props;
        // if (selectedCity) {
        //     API.get(`/api/cities/count-business/${selectedCity._id}`).then(res => {
        //         if (res.data?.numberOfBusinesses) {

        //             this.setState({
        //                 numberOfBusinesses: res.data.numberOfBusinesses,
        //             });
        //         }
        //     }).catch(err => {
        //         console.log("Cannot count businesses", err)
        //     })
        // }
        if (selectedCity && province) {
            if (selectedCity.name && !selectedCity.cityUrl) {
                selectedCity.cityUrl = selectedCity.name.replace(/ /g, "-").toLowerCase();
            }
            this.setState({
                city: { ...selectedCity, isActiveForFetching: selectedCity.isActiveForFetching || false },
            });
        } else if (province) {
            this.setState({
                city: {
                    name: "",
                    cityUrl: "",
                    isActiveForFetching: false,
                    province: province._id,
                }
            });
        }
    }

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }

        let city = this.state.city;
        city[field] = value;
        this.setState({
            city
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const city = this.state.city;
        if (city._id) {
            this.props.updateCity(city);
        } else {
            this.props.addCity(city);
        }
        this.setState({
            displayMessage: true
        });
    };
    render() {
        const { city, displayMessage } = this.state;

        const {
            provinces,
            province,
            loading,
            error,
            message,
            component
        } = this.props;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Row>
                            <Col md={12}>
                                <Form.Label> Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={city.name}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </Row>
                        {/* <Row className="mt-3">
                            <Col md={6}>
                                <p className="text-warning">Number of businesses: {numberOfBusinesses}</p>
                            </Col>
                        </Row> */}
                        <Row className="mt-3">
                            <Col md={6}>
                                <Form.Label htmlFor="cityUrl"> City Url</Form.Label>
                                <Form.Control
                                    type='text'
                                    id="cityUrl"
                                    placeholder='City Unique Url'
                                    name='cityUrl'
                                    value={city.cityUrl}
                                    onChange={this.handleChange}
                                // required
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Label htmlFor="isActiveForFetching"> Is Enable For Fetching</Form.Label>
                                <Form.Check
                                    id="isActiveForFetching"
                                    type='checkbox'
                                    name='isActiveForFetching'
                                    checked={city.isActiveForFetching}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={12}>
                                <Form.Label> Province</Form.Label>
                                <ProvinceDropDown
                                    provinces={provinces}
                                    province={province._id}
                                    city={city}
                                    handleChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3'>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <Button
                                        variant='primary'
                                        className='float-left'
                                        type='submit'
                                    >
                                        Save
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            {displayMessage && component === "CityForm" && (
                                <ErrorSuccess error={error} message={message} />
                            )}
                        </Row>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        provinces: state.city.provinces,
        province: state.city.province,
        loading: state.city.loading,
        error: state.city.error,
        message: state.city.message,
        component: state.city.component
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCity: city => dispatch(addCity(city)),
        updateCity: city => dispatch(updateCity(city))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CityForm);
