import React from "react";
import { Table, Button } from "react-bootstrap";

const BusinessTypeTable = props => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {props.businessTypes.map(type => {
                    return (
                        <tr key={type._id}>
                            <td>{type.title}</td>
                            <td>{type.image ? <img src={type.image} width='100' height='50' alt="business-type-img" /> : "No image"}</td>
                            <td>
                                <Button variant='primary' onClick={() => {
                                    const selectBusinessType = Object.assign({}, type)
                                    props.selectBusinessType(selectBusinessType)
                                }}>
                                    <span
                                        className='oi oi-pencil'
                                        title='icon name'
                                        aria-hidden='true'
                                    ></span>{" "}
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default BusinessTypeTable;
