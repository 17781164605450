import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import moment from 'moment';
import Loading from '../../../layouts/Loading/Loading';
import { getWebsite, updateWebsite } from '../../../redux/actions/business/website'
class Website extends Component {
    state = {
        website: {
            hasWebsite: false,
            hasWebsiteWithDashbooking: false,
            previousUrl: "",
            dashbookingWebsiteUrl: '',
            expireDate: '',
            domainHostingProvider: '',
            googleBusinessAccountEmail: "",
            password: "",
            notes: ""
        },
        disableButton: true,
        errors: []
    }

    componentDidMount() {
        this.props.getWebsite(this.props.business._id)
    }

    componentDidUpdate(prevProps) {
        const { websiteInfo } = this.props
        if (websiteInfo && prevProps.websiteInfo !== websiteInfo) {
            let website = Object.assign({}, this.props.websiteInfo)
            if (website.expireDate) {
                website.expireDate = moment(website.expireDate).format('YYYY-MM-DD')
            } else {
                website.expireDate = ''
            }
            this.setState({ website, disableButton: true })
        }
    }

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;
        let { website, disableButton } = this.state;

        if (field === "previousUrl") {
            website.hasWebsite = value ? true : false
            website[field] = value;
        }
        else if (field === "dashbookingWebsiteUrl") {
            website.hasWebsiteWithDashbooking = value ? true : false
            website[field] = value;
        }
        else {
            website[field] = value;
        }
        disableButton = false;
        if (JSON.stringify(website) === JSON.stringify(this.props.websiteInfo)) {
            disableButton = true;
        }

        this.setState({
            website,
            disableButton,
            errors: []
        });
    };

    cancelChange = () => {
        let website = null
        if (this.props.websiteInfo) {
            website = Object.assign({}, this.props.websiteInfo)
            if (website.expireDate) {
                website.expireDate = moment(website.expireDate).format('YYYY-MM-DD')
            } else {
                website.expireDate = ''
            }
        }
        else {
            website = {
                hasWebsite: false,
                hasWebsiteWithDashbooking: false,
                previousUrl: "",
                dashbookingWebsiteUrl: '',
                expireDate: '',
                domainHostingProvider: '',
                googleBusinessAccountEmail: "",
                password: "",
                notes: ""
            }
        }

        this.setState({
            website,
            disableButton: true,
            errors: []
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let { website } = this.state
        website.business = this.props.business._id
        this.props.updateWebsite(website)
    }

    render() {
        const { website, errors, disableButton } = this.state
        const { loading } = this.props
        if (loading) {
            return <Loading />
        }
        return <div>
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col>
                        <Form.Label>Previous Website {website.previousUrl && <a target="_blank"  rel="noreferrer" href={"http://"+website.previousUrl}><span className="oi oi-link-intact"></span></a>}</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Previous Website'
                            name='previousUrl'
                            value={website.previousUrl}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Dashbooking Website {website.dashbookingWebsiteUrl && <a target="_blank"  rel="noreferrer" href={"http://"+website.dashbookingWebsiteUrl}><span className="oi oi-link-intact"></span></a>}</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Dashbooking Website'
                            value={website.dashbookingWebsiteUrl}
                            name='dashbookingWebsiteUrl'
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Form.Label>Domain Hosting Provider</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Domain Hosting Provider'
                            name='domainHostingProvider'
                            value={website.domainHostingProvider}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Expired Day </Form.Label>
                        <Form.Control
                            type='date'
                            value={website.expireDate}
                            name='expireDate'
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Form.Label>Google Business Account Email</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Google Business Account Email'
                            name='googleBusinessAccountEmail'
                            value={website.googleBusinessAccountEmail}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Password </Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Google Business Account Password'
                            value={website.password}
                            name='password'
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Form.Label>Notes </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder='Notes'
                            value={website.notes}
                            name='notes'
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {" "}
                        <Button
                            variant='primary'
                            disabled={disableButton}
                            type='submit'
                        >
                            Save
                        </Button>{" "}
                        <Button
                            variant='secondary'
                            onClick={() => this.cancelChange()}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                {errors.length > 0 && (
                    <Row className='mt-3'>
                        <Col>
                            {errors.map(error => {
                                return (
                                    <Alert key={error} variant='danger'>
                                        {error}
                                    </Alert>
                                );
                            })}
                        </Col>
                    </Row>
                )}
            </Form>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    websiteInfo: state.website.websiteInfo,
    loading: state.website.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getWebsite: bid => dispatch(getWebsite(bid)),
        updateWebsite: website => dispatch(updateWebsite(website)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Website);
