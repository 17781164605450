import React from "react";
import { Table, Form, Button } from "react-bootstrap";
const ApprovalHistoryTable = (props) => {
    const {previewBusinesses} = props
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    
                    <th>Approver's Name</th>
                    <th>Email</th>
                    <th>Title</th>
                    <th>Approved</th>
                    
                    <th>Approval's Date</th>
                    <th>Sumitted Date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {previewBusinesses.map((preview) => (
                    <tr key={preview._id}>
                        <td>{preview.approverName}</td>
                        <td>{preview.email}</td>
                        <td>{preview.title}</td>
                        <td>
                            <Form.Check
                                inline={true}
                                type="switch"
                                id={"isApproved"}
                                label={
                                  preview.isApproved
                                        ? "Approved"
                                        : "No"
                                }
                                name="isApproved"
                                checked={preview.isApproved}
                                readOnly
                            />
                        </td>
                        <td>{preview.approvedAt}</td>
                        <td>{preview.submittedAt}</td>
                        <td>
                            <Button
                                variant="link"
                                onClick={() =>
                                    props.selectApproval(preview)
                                }
                            >
                                Details
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ApprovalHistoryTable;
