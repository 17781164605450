import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Form, Col, Row, Button, Table} from "react-bootstrap"
import {PreviewImageModal} from "./PreviewImageModal"
import {uploadDisplayImage, removeDisplayImage, uploadImage, removeImage} from "../../../redux/actions/business/business"

import ImageTable from "./ImageTable"
class ImageContainer extends Component {
    state = {
        business: {
            _id: "",
            images: []
        },
        displayImageFile: null,
        imageFile: null,
        selectedImage: "",
        modalShow: false,
        setModalShow: false,
        disabledUploadButton: true,
        disabledSubmitButton: true,
    };


    componentDidMount() {
        this.initializeState();
    }

    initializeState = () => {
        let { business } = this.state;
        let propBusiness = this.props.business;
        if (propBusiness) {
            const imageProperties = Object.keys(business);
            imageProperties.forEach(property => {
                if (propBusiness[property]) {
                    business[property] = propBusiness[property];
                }
            });
            business.displayImage = propBusiness.displayImage
        }
        this.setState({
            business
        });
    };


    openCloseAddModal = (image) => {
        const { modalShow } = this.state;
        //Reset selectedStatus in state when modal is closing
        if (modalShow) {
            this.setState({
                selectedImage: null,
            });
        }
        this.setState({
            modalShow: !this.state.modalShow,
            selectedImage: image
        });
    };

    handleDeleteImage = () => {
        const confirmed = window.confirm("Are you sure to delete this image?")
        if(confirmed) {
            let business = {}
            business._id = this.state.business._id
            business.image = this.state.selectedImage
            if (this.state.business.displayImage === this.state.selectedImage) {
                this.props.removeDisplayImage(business)
            } else {
                this.props.removeImage(business)
            }
            this.setState({
                modalShow: false,
                selectedImage: null
            }) 
        }
    }

    handleDisplayImageChange = (e) => {
        this.setState({
            displayImageFile: e.target.files[0],
            disabledUploadButton: false,
        })
    }

    handleImageChange = (e) => {
        this.setState({
            imageFile: e.target.files[0],
            disabledSubmitButton: false
        })
    }

    handleUploadDisplayImage = (e) => {
        e.preventDefault()
        let displayImageFormData = new FormData()
        const { displayImageFile, business } = this.state
        if (displayImageFile) {
            displayImageFormData.append("file", displayImageFile)
            displayImageFormData.append("_id", business._id)
            this.props.uploadDisplayImage(displayImageFormData)
        }
    }

    handleUploadImage= (e) => {
        e.preventDefault()
        let imagesFormData = new FormData() 
        const { imageFile, business } = this.state
        
        if (imageFile) {
            imagesFormData.append("file", imageFile)
            imagesFormData.append("_id", business._id)
            this.props.uploadImage(imagesFormData)
        }
    }

    handleCancelDisplayImage = () => {
        this.setState({
            selectedImage: null,
            disabledUploadButton: true,
            displayImageFile: null,
        })
    }

    handleCancel = () => {
        this.setState({
            selectedImage: null,
            disabledSubmitButton: true,
            imageFile: null,
        })
    }

    render() {
        const { business, selectedImage, modalShow, disabledUploadButton, disabledSubmitButton } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <Form onSubmit={this.handleUploadDisplayImage} encType="multipart/form-data">
                    <Row>
                        <Col>
                            {(business.displayImage === "" || business.displayImage === undefined) ? <h5>There is no display image for this business</h5> : 
                            <Table borderless responsive="lg">
                                <thead style={{backgroundColor: 'rgb(0,0,0,0.15'}}>
                                    <tr>
                                        <th></th>
                                        <th>Url</th>
                                        <th>Images</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>{business.displayImage}</td>
                                        <td>
                                            <img src={business.displayImage} width='100' height='50' alt="business-display-img" />
                                        </td>
                                        <td>
                                            <Button
                                                className='text-info'
                                                variant='link'
                                                onClick={() => this.openCloseAddModal(business.displayImage)}
                                            >
                                                <span className="oi oi-external-link" />
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>}
                        </Col> 
                    </Row>
                    <Row>
                        <Col>
                            <h6><span className="oi oi-data-transfer-upload"></span> Upload Display Image (Small image on Salons page)</h6>
                            <input md={12} type="file" encType="multipart/form-data" name="file" disabled={business.displayImage} onChange={this.handleDisplayImageChange} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Button variant='primary' disabled={disabledUploadButton} type='submit'>
                                Upload
                            </Button>{" "}
                            {/* <Button variant='secondary' onClick={this.handleCancelDisplayImage}>Cancel</Button> */}
                        </Col>
                    </Row>
                </Form>
                <hr />
                <Form onSubmit={this.handleUploadImage} encType="multipart/form-data">
                    <Row>
                        <Col>
                            {business.images.length === 0 ? <h5>There is no image for this business</h5>: <ImageTable images={business.images} openCloseAddModal={this.openCloseAddModal}/> }
                        </Col> 
                    </Row>
                    <Row>
                        <Col>
                            <h6><span className="oi oi-data-transfer-upload"></span> Upload New Image (If there are 5 images or less)</h6>
                             <input md={12} type="file" encType="multipart/form-data" name="file" disabled={business.images.length>=5} onChange={this.handleImageChange} />
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <Button variant='primary' disabled={disabledSubmitButton} type='submit'>
                                Upload
                            </Button>{" "}
                            {/* <Button variant='secondary' onClick={this.handleCancel}>Cancel</Button> */}
                        </Col>
                    </Row>
                </Form>
                <PreviewImageModal
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                    imageUrl={selectedImage}
                    handleDeleteImage = {this.handleDeleteImage}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        uploadDisplayImage: (formData) => dispatch(uploadDisplayImage(formData)),
        removeDisplayImage: (business) => dispatch(removeDisplayImage(business)),
        uploadImage: (formData) => dispatch(uploadImage(formData)),
        removeImage: (business) => dispatch(removeImage(business)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageContainer)
