import React from "react";
import { Badge, Button, Table } from "react-bootstrap";

const EmployeeTable = (props) => {
    const displayRole = (employee) => {
        switch (employee.role) {
            case "dashbooking_accountant":
                return <Badge variant="warning"> Accountant </Badge>;
            case "dashbooking_developer":
                return <Badge variant="info"> Developer </Badge>;
            case "dashbooking_admin":
                return <Badge variant="primary"> Admin </Badge>;
            default: 
                return <Badge variant="secondary"> Employee </Badge>;
        }
    };
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Acitve</th>
                    <th>Role</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {props.employeeAccounts.map((employee) => {
                    return (
                        <tr key={employee._id}>
                            <td>{employee.name}</td>
                            <td>{employee.title}</td>
                            <td>{employee.email}</td>
                            <td>{employee.phone}</td>
                            <td>
                                <span
                                    className={
                                        employee.active
                                            ? "dot active-user"
                                            : "dot"
                                    }
                                >
                                    {" "}
                                </span>
                            </td>
                            <td>
                               {displayRole(employee)}
                            </td>
                            <td>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        const selectedEmployee = Object.assign(
                                            {},
                                            employee
                                        );
                                        props.selectEmployee(selectedEmployee);
                                    }}
                                >
                                    <span
                                        className="oi oi-pencil"
                                        title="icon name"
                                        aria-hidden="true"
                                    ></span>{" "}
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default EmployeeTable;
