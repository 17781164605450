export const GET_ALL_EMPLOYEE_STARTED = "GET_ALL_EMPLOYEE_STARTED"
export const GET_ALL_EMPLOYEE_SUCCESS = "GET_ALL_EMPLOYEE_SUCCESS"
export const GET_ALL_EMPLOYEE_FAILURE = "GET_ALL_EMPLOYEE_FAILURE"


export const UPDATE_EMPLOYEE_STARTED = "UPDATE_EMPLOYEE_STARTED"
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS"
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_CITUPDATE_EMPLOYEE_FAILUREY_FAILURE"

export const ADD_EMPLOYEE_STARTED = "ADD_EMPLOYEE_STARTED"
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS"
export const ADD_EMPLOYEE_FAILURE = "ADD_EMPLOYEE_FAILURE"


export const SEND_RESET_PASSWORD_EMAIL_STARTED = "SEND_RESET_PASSWORD_EMAIL_STARTED"
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = "SEND_RESET_PASSWORD_EMAIL_SUCCESS"
export const SEND_RESET_PASSWORD_EMAIL_FAILURE = "SEND_RESET_PASSWORD_EMAIL_FAILURE"



export const LOGOUT_USER_FROM_ALL_DEVICES_STARTED = "LOGOUT_USER_FROM_ALL_DEVICES_STARTED"
export const LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS = "LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS"
export const LOGOUT_USER_FROM_ALL_DEVICES_FAILURE = "LOGOUT_USER_FROM_ALL_DEVICES_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"