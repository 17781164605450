import {
    GET_TAXES_STARTED,
    GET_TAXES_SUCCESS,
    GET_TAXES_FAILURE,
    CREATE_TAXES_STARTED,
    CREATE_TAXES_SUCCESS,
    CREATE_TAXES_FAILURE,
    UPDATE_TAXES_STARTED,
    UPDATE_TAXES_SUCCESS,
    UPDATE_TAXES_FAILURE,
    DELETE_TAXES_STARTED, 
    DELETE_TAXES_SUCCESS,
    DELETE_TAXES_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/business/taxes";

const initialState = {
    taxes: [],
    loading: false,
    error: null,
    message: null,
    component: null,
};

export default function businessTax(state = initialState, action) {
    switch (action.type) {
        case GET_TAXES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "TaxContainer",
            };
        case GET_TAXES_SUCCESS:
            return {
                ...state,
                taxes: action.payload.taxes,
                loading: false,
                error: null,
                message: null,
                component: "TaxContainer",
            };
        case GET_TAXES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "TaxContainer",
            };

        case CREATE_TAXES_STARTED: 
            return {
                ...state,
                loading: false, 
                error: null,
                message: null,
                component: "TaxForm"
            };
        case CREATE_TAXES_SUCCESS:  
            return {
                ...state,
                taxes: [...state.taxes,action.payload.tax],
                loading: false,
                error: null,
                message: "The new tax was saved successfully!",
                component: "TaxForm"
            }
        case CREATE_TAXES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "TaxForm"
            }
        case UPDATE_TAXES_STARTED: 
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "TaxForm"
            }
        case UPDATE_TAXES_SUCCESS: 
            const updatedTax = action.payload.tax
            let taxes = state.taxes.map(tax =>(tax._id === updatedTax._id ? updatedTax : tax))
            return {
                ...state, 
                taxes,
                loading: false, 
                error: null,
                message: "The tax was updated successfully!",
                component: "TaxForm"
            }
        case UPDATE_TAXES_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "TaxForm"
            }
        case DELETE_TAXES_STARTED: 
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "TaxContainer"
            }
        case DELETE_TAXES_SUCCESS:
            const deletedTax = action.payload.tax 
            return {
                ...state,
                taxes: state.taxes.filter(tax => tax._id !== deletedTax._id),
                loading: false,
                error: null,
                message: "The tax was deleted successfully!",
                component: "TaxContainer"

            }
        case DELETE_TAXES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message:null,
                component: "TaxContainer"
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        default:
            return {
                ...state,
            };
    }
}
