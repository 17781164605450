import {
    GET_BUSINESS_SUMMARY_STARTED,
    GET_BUSINESS_SUMMARY_SUCCESS,
    GET_BUSINESS_SUMMARY_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/business-summary"
import { LOGOUT } from "../../constants/employee-account/login";
import API from "../../../utils/API/API";


export const getBusinessSummary = businessUrl => {
    return dispatch => {
        dispatch(getBusinessSummaryStart())
        API.get(`/api/business-preview/${businessUrl}`).then( res => {
            dispatch(getBusinessSummarySuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, getBusinessSummaryFailure);
           
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}


const getBusinessSummaryStart = () => ({
    type: GET_BUSINESS_SUMMARY_STARTED
})

const getBusinessSummarySuccess = (businessData) => ({
    type: GET_BUSINESS_SUMMARY_SUCCESS,
    payload: {businessData}
})

const getBusinessSummaryFailure = (error) => ({
    type: GET_BUSINESS_SUMMARY_FAILURE,
    payload: {error}
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
