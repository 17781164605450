import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Col, Row, Button, Alert, InputGroup, } from "react-bootstrap";
import { updateBusinessGeneralInfo } from "../../../redux/actions/business/business";
import {
    displayNationalNumber,
    formatPhoneNumber,
    getNumberInternational,
    validatePhoneNumber,
} from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
import BusinessStatusDropdown from "../../common/business-status-dropdown/BusinessStatusDropdown";
import { countryCodes, LANGUAGE_LIST } from "../../../utils/utility";
const languageList = LANGUAGE_LIST();

class GeneralInfoForm extends Component {
    state = {
        business: {
            _id: "",
            name: "",
            urlName: "",
            businessType: [],
            notificationEmails: [],
            minimumRequiredForBooking: 0,
            autoSendReviewLink: true,
            isIncludePhoneInReminder: false,
            isDirectGoogleReview: false,
            isHideClientPhoneNumber: false,
            isScanReview: false,
            status: "",
            email: "",
            phone: "",
            country: "CA",
            language: "",
            isFirstLogin: false,
            policy: ""
        },
        disableButton: true,
        smsSentFromError: null,
        errors: [],
    };

    componentDidMount() {
        const { business } = this.props;
        if (business) {
            this.setState({
                business: Object.assign(
                    {},
                    {
                        ...business,
                        phone: displayNationalNumber(business.phone),
                        country: business.country || "CA",
                    }
                ),
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { business, errors } = this.state;
        let valid = true;
        //validate phone number
        if (!validatePhoneNumber(business.phone, business.country)) {
            let error = "Phone Number is invalid!";
            if (!errors.includes(error)) {
                errors.push(error);
            }
            valid = false;
        }
        if (business.businessType.length === 0) {
            let error = "Business type is not specified!";
            errors.push(error);
            valid = false;
        }
        this.setState({
            errors,
        });

        if (valid) {
            this.setState({
                errors: [],
            });
            business.phone = getNumberInternational(
                business.phone,
                business.country
            );
            this.props.updateBusinessGeneralInfo(business);
        }
    };

    handleChange = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { business, disableButton } = this.state;

        if (field === "phone") {
            value = formatPhoneNumber(value, business.country);
        }
        if (field === "country") {
            business.phone = formatPhoneNumber(business.phone, value);
            business.country = value;
        }
        if (field === "smsSentFrom") {
            const internationalNumberRegex = /^\+\d{1,4}\d{1,14}$/;
            if (!internationalNumberRegex.test(value)) {
                this.setState({
                    smsSentFromError: "Incorrect format phone number!"
                })
            } else {
                this.setState({
                    smsSentFromError: null
                })
            }
        }

        if (field === "businessType") {
            if (!business.businessType.includes(value)) {
                business.businessType.push(value);
            }
            // console.log(business.businessType)
        }
        else if (field === "isDirectGoogleReview" && value === "true") {
            business.isScanReview = 'false';
            business.isDirectGoogleReview = 'true';
        }
        else {
            business[field] = value;
        }

        disableButton = false;
        if (JSON.stringify(business) === JSON.stringify(this.props.business)) {
            disableButton = true;
        }
        this.setState({
            business,
            disableButton,
            errors: [],
        });
    };

    cancelChange = () => {
        const { business } = this.props;
        const businessObj = Object.assign(
            {},
            { ...business, phone: displayNationalNumber(business.phone) }
        );
        if (!businessObj.country) {
            businessObj.country = "CA";
        }
        this.setState({
            business: businessObj,
            disableButton: true,
            errors: [],
        });
    };

    handleChangeNotificationEmail = (e) => {
        let { business } = this.state;
        business.notificationEmails = e.target.value
            .replace(/\s/g, "")
            .split(",");
        this.setState({
            business,
            disableButton: false,
        });
    };

    handleRemoveBusinessType = (businessTypeId) => {
        let { business } = this.state;
        business.businessType = business.businessType.filter(
            (type) => type !== businessTypeId
        );

        this.setState({
            business,
            disableButton: false,
        });
    };

    render() {
        const { business, disableButton, smsSentFromError, errors } = this.state;
        const { businessTypes, businessStatuses } = this.props;
        const businessTypeArray = businessTypes.businessTypes;
        const businessStatusArray = businessStatuses.businessStatuses;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={business.name}
                                required
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col>
                            <Form.Label>
                                Url Name{" "}
                                {business.online && (
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={
                                            "https://www.dashbooking.com/salon/" +
                                            business.urlName
                                        }
                                    >
                                        <span className='oi oi-link-intact'></span>
                                    </a>
                                )}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Example: queen-nail-winnipeg-portage'
                                value={business.urlName}
                                name='urlName'
                                onChange={this.handleChange}
                                disabled={business.online}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Label>Business Email</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='email@example.com'
                                value={business.email}
                                name='email'
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Phone</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    as='select'
                                    value={business.country}
                                    onChange={this.handleChange}
                                    name='country'
                                    style={{
                                        maxWidth: "100px",
                                        borderRight: "solid 1px gray",
                                    }}
                                >
                                    {countryCodes.map((country) => (
                                        <option
                                            value={country.code}
                                            key={country.code}
                                        >
                                            {country.label}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control
                                    type='text'
                                    value={business.phone}
                                    placeholder='Business Phone'
                                    name='phone'
                                    onChange={this.handleChange}
                                    required
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Label>Custom Sender Email</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='email@example.com'
                                value={business.senderEmail}
                                name='senderEmail'
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Custom SMS sender phone</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='+12043456789'
                                value={business.smsSentFrom}
                                name='smsSentFrom'
                                onChange={this.handleChange}
                            />
                            {business.smsSentFrom && <p className="text-danger">{smsSentFromError}</p>}
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>
                                Minimum $ required for online bookings
                            </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='$50'
                                value={business.minimumRequiredForBooking || 0}
                                name='minimumRequiredForBooking'
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Language</Form.Label>
                            <Form.Control
                                as='select'
                                onChange={this.handleChange}
                                value={business.language}
                                name='language'
                            >

                                {languageList.map((language) => {
                                    return <option value={language} key={language}>{language}</option>
                                })}
                                <option value='French'>French</option>
                            </Form.Control>
                        </Col>

                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <Form.Label>Activation Required</Form.Label>
                            <Form.Control
                                as='select'
                                name='isFirstLogin'
                                onChange={this.handleChange}
                                value={business.isFirstLogin}
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Is Call To Book</Form.Label>
                            <Form.Control
                                as='select'
                                onChange={this.handleChange}
                                value={business.isCallToBook}
                                name='isCallToBook'
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Allow Overlap in Appointment Page</Form.Label>
                            <Form.Control
                                as='select'
                                name='allowOverlappedAppointment'
                                onChange={this.handleChange}
                                value={business.allowOverlappedAppointment}
                            >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                        </Col>


                    </Row>
                    <Row className='mt-3'>

                        <Col>
                            <Form.Label>Auto Send Review Link</Form.Label>
                            <Form.Control
                                as='select'
                                onChange={this.handleChange}
                                value={business.autoSendReviewLink}
                                name='autoSendReviewLink'
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Is Direct Google Review</Form.Label>
                            <Form.Control
                                as='select'
                                name='isDirectGoogleReview'
                                onChange={this.handleChange}
                                value={business.isDirectGoogleReview}
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>

                        <Col>
                            <Form.Label>Enable Scan Review</Form.Label>
                            <Form.Control
                                as='select'
                                name='isScanReview'
                                disabled={business.isDirectGoogleReview === "true" || business.isDirectGoogleReview === true}
                                onChange={this.handleChange}
                                value={business.isScanReview}
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>

                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Label>Include Phone In SMS Reminder</Form.Label>
                            <Form.Control
                                as='select'
                                onChange={this.handleChange}
                                value={business.isIncludePhoneInReminder}
                                name='isIncludePhoneInReminder'
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Is Split Tip By Ratio</Form.Label>
                            <Form.Control
                                as='select'
                                name='isSplitTipByRatio'

                                onChange={this.handleChange}
                                value={business.isSplitTipByRatio}
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Is Hide Client Phone Number</Form.Label>
                            <Form.Control
                                as='select'
                                name='isHideClientPhoneNumber'

                                onChange={this.handleChange}
                                value={business.isHideClientPhoneNumber}
                            >
                                <option value='true'>Yes</option>
                                <option value='false'>No</option>
                            </Form.Control>
                        </Col>

                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Label>Business Type</Form.Label>

                            <Form.Control
                                as='select'
                                name='businessType'
                                // required
                                onChange={this.handleChange}
                            >
                                <option value=''>Select Business Type</option>
                                {businessTypeArray.map((type) => {
                                    return (
                                        <option key={type._id} value={type._id}>
                                            {type.title}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                            <span className='business-type-tags-container'>
                                {business.businessType && Array.isArray(business.businessType) &&
                                    business.businessType.map((type) => {
                                        return (
                                            <div
                                                key={type}
                                                className='business-type-tag bg-dark'
                                            >
                                                {
                                                    businessTypeArray.find(
                                                        (typeObject) =>
                                                            typeObject._id ===
                                                            type
                                                    ).title
                                                }
                                                <button
                                                    onClick={() =>
                                                        this.handleRemoveBusinessType(
                                                            type
                                                        )
                                                    }
                                                    type='button'
                                                    className='close'
                                                    aria-label='Close'
                                                >
                                                    <span
                                                        aria-hidden='true'
                                                        className='text-white'
                                                    >
                                                        &times;
                                                    </span>
                                                </button>
                                            </div>
                                        );
                                    })}
                            </span>
                        </Col>
                        <Col>
                            <BusinessStatusDropdown
                                businessStatusArray={businessStatusArray}
                                handleChange={this.handleChange}
                                businessStatusId={business.status}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Label>Salon Additional Policy</Form.Label>
                            <Form.Control
                                as='textarea'
                                name='policy'
                                rows={5}
                                onChange={this.handleChange}
                                value={business.policy}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            {" "}
                            <Button
                                variant='primary'
                                disabled={disableButton}
                                type='submit'
                            >
                                Save
                            </Button>{" "}
                            <Button
                                variant='secondary'
                                onClick={() => this.cancelChange()}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                    {errors.length > 0 && (
                        <Row className='mt-3'>
                            <Col>
                                {errors.map((error) => {
                                    return (
                                        <Alert key={error} variant='danger'>
                                            {error}
                                        </Alert>
                                    );
                                })}
                            </Col>
                        </Row>
                    )}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    businessTypes: {
        businessTypes: state.businessType.businessTypes,
        error: state.businessType.error,
        message: state.businessType.message,
        component: state.businessType.component,
    },
    businessStatuses: {
        businessStatuses: state.businessStatus.businessStatuses,
        error: state.businessStatus.error,
        message: state.businessStatus.message,
        component: state.businessStatus.component,
    },
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateBusinessGeneralInfo: (business) =>
            dispatch(updateBusinessGeneralInfo(business)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoForm);
