import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";

const ClientStatistics = (props) => {
    const { startDate, endDate, newClientsCount } = props;

    return (
        <div>
            <Row className="justify-content-md-center">
                <Col>
                    <DateRangePicker
                        initialSettings={{
                            startDate: startDate,
                            endDate: endDate,
                            maxDate: moment(),
                            opens: "left",
                            showDropdowns: true,
                            autoApply: true,
                            locale: { format: "YYYY-MM-DD" },
                            ranges: {
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                'This Year': [moment().startOf('year'), moment().endOf('year')],
                            },
                        }}
                        onHide={props.handleCalendarClose}
                    >
                        <Button variant="outline-primary">{startDate} - {endDate}</Button>
                    </DateRangePicker>
                </Col>
            </Row>
            <Row className="m-3 justify-content-md-center">
                <div key="newClientCount" className="col-xl-6 col-md-6 col-sm-12 mb-4">
                    <div className="card border-primary shadow-sm h-100">
                        <div className="card-body">
                            <h5 className="mb-4">New Clients</h5>
                            <div className="d-flex justify-content-between">
                                <span className="text-primary">
                                    <h1 className="ml-1">{newClientsCount}</h1>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default ClientStatistics;