export const GET_MENU_STARTED = "GET_MENU_STARTED"
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS"
export const GET_MENU_FAILURE = "GET_MENU_FAILURE"

export const GET_ALL_CATEGORIES_STARTED = "GET_ALL_CATEGORIES_STARTED"
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS"
export const GET_ALL_CATEGORIES_FAILURE = "GET_ALL_CATEGORIES_FAILURE"

export const ADD_NEW_CATEGORIES_STARTED = "ADD_NEW_CATEGORIES_STARTED"
export const ADD_NEW_CATEGORIES_SUCCESS = "ADD_NEW_CATEGORIES_SUCCESS"
export const ADD_NEW_CATEGORIES_FAILURE = "ADD_NEW_CATEGORIES_FAILURE"

export const UPDATE_CATEGORIES_ORDER_STARTED = "UPDATE_CATEGORIES_ORDER_STARTED"
export const UPDATE_CATEGORIES_ORDER_SUCCESS = "UPDATE_CATEGORIES_ORDER_SUCCESS"
export const UPDATE_CATEGORIES_ORDER_FAILURE = "UPDATE_CATEGORIES_ORDER_FAILURE"

export const UPDATE_CATEGORY_INFO_STARTED = "UPDATE_CATEGORY_INFO_STARTED"
export const UPDATE_CATEGORY_INFO_SUCCESS = "UPDATE_CATEGORY_INFO_SUCCESS"
export const UPDATE_CATEGORY_INFO_FAILURE = "UPDATE_CATEGORY_INFO_FAILURE"

export const DELETE_CATEGORY_STARTED = "DELETE_CATEGORY_STARTED"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE"



export const ADD_NEW_SERVICE_STARTED = 'ADD_NEW_SERVICE_STARTED'
export const ADD_NEW_SERVICE_SUCCESS = 'ADD_NEW_SERVICE_SUCCESS'
export const ADD_NEW_SERVICE_FAILURE = 'ADD_NEW_SERVICE_FAILURE'

export const UPDATE_SERVICE_INFO_STARTED = 'UPDATE_SERVICE_INFO_STARTED'
export const UPDATE_SERVICE_INFO_SUCCESS = 'UPDATE_SERVICE_INFO_SUCCESS'
export const UPDATE_SERVICE_INFO_FAILURE = 'UPDATE_SERVICE_INFO_FAILURE'

export const DELETE_SERVICE_STARTED = 'DELETE_SERVICE_STARTED'
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS'
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE'

export const UPDATE_SERVICES_ORDER_STARTED = "UPDATE_SERVICES_ORDER_STARTED"
export const UPDATE_SERVICES_ORDER_SUCCESS = "UPDATE_SERVICES_ORDER_SUCCESS"
export const UPDATE_SERVICES_ORDER_FAILURE = "UPDATE_SERVICES_ORDER_FAILURE"

export const UPDATE_SERVICE_STAFF_STARTED = 'UPDATE_SERVICES_STAFF_STARTED'
export const UPDATE_SERVICE_STAFF_SUCCESS = 'UPDATE_SERVICES_STAFF_SUCCESS'
export const UPDATE_SERVICE_STAFF_FAILURE = 'UPDATE_SERVICES_STAFF_FAILURE'


export const UPDATE_STAFF_SERVICES_STARTED ="UPDATE_STAFF_SERVICES_STARTED"
export const UPDATE_STAFF_SERVICES_SUCCESS ="UPDATE_STAFF_SERVICES_SUCCESS"
export const UPDATE_STAFF_SERVICES_FAILURE = "UPDATE_STAFF_SERVICES_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"