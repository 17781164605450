import React from "react";
import { Modal, Button } from "react-bootstrap";
import BusinessAccountForm from "./BusinessAccountForm";
const AddAccountModal = props => {
  const {account, business, ...rest} = props
  return (

    <Modal
      {...rest}
      style={{width: "120% !important"}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BusinessAccountForm account={account} business={business} closeForm={props.onHide} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAccountModal;
