import {
    GET_ALL_BUSINESS_TYPE_STARTED,
    GET_ALL_BUSINESS_TYPE_SUCCESS,
    GET_ALL_BUSINESS_TYPE_FAILURE,
    UPDATE_BUSINESS_TYPE_STARTED,
    UPDATE_BUSINESS_TYPE_SUCCESS,
    UPDATE_BUSINESS_TYPE_FAILURE, 
    ADD_BUSINESS_TYPE_STARTED, 
    ADD_BUSINESS_TYPE_SUCCESS,
    ADD_BUSINESS_TYPE_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/business-type";
import API from "../../../utils/API/API";
import {LOGOUT} from "../../constants/employee-account/login"
/**
 * Gets all business types api call
 */
export const getBusinessType = () => {
    return dispatch => {
        dispatch(getBusinessTypeStarted());
        API.get(`/api/business-types`)
            .then(res => {
                dispatch(getBusinessTypeSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, getBusinessTypeFailure);
                // if (error.response) {
                //     if(error.response.status === 401){
                //         localStorage.clear()
                //     }
                //     return dispatch(
                //         getBusinessTypeFailure(error.response.data.message)
                //     );
                // } 
                //     dispatch(getBusinessTypeFailure(error.message));
                
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getBusinessTypeStarted = () => ({
    type: GET_ALL_BUSINESS_TYPE_STARTED
});
const getBusinessTypeSuccess = businessTypes => ({
    type: GET_ALL_BUSINESS_TYPE_SUCCESS,
    payload: { businessTypes }
});
const getBusinessTypeFailure = error => ({
    type: GET_ALL_BUSINESS_TYPE_FAILURE,
    payload: { error }
});

/**
 * Update a business type
 * @param {*} businessType
 */
export const updateBusinessType = businessType => {
    return dispatch => {
        dispatch(updateBusinessTypeStarted());
        API.post(`/api/business-types/update`, businessType)
            .then(res => {
                dispatch(updateBusinessTypeSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, updateBusinessTypeFailure);
                // if (error.response) {
                //     if(error.response.status === 401){
                //         localStorage.clear()
                //     }
                //     return dispatch(
                //         updateBusinessTypeFailure(error.response.data.message)
                //     );
                // }
                // dispatch(updateBusinessTypeFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};
const updateBusinessTypeStarted = () => ({
    type: UPDATE_BUSINESS_TYPE_STARTED
});
const updateBusinessTypeSuccess = businessType => ({
    type: UPDATE_BUSINESS_TYPE_SUCCESS,
    payload: { businessType }
});
const updateBusinessTypeFailure = error => ({
    type: UPDATE_BUSINESS_TYPE_FAILURE,
    payload: { error }
});

/**
 * Add new business type action
 */
 export const addBusinessType = (businessType) => {
     return dispatch => {
         dispatch(addBusinessTypeStarted());
         API.post(`/api/business-types/create`, businessType).then(res => {
             dispatch(addBusinessTypeSuccess(res.data))
         }).catch(error => {
            catchError(error, dispatch, addBusinessTypeFailure);
            //  if(error.response){
            //     //Logout user if not authenticated
            //     if(error.response.status === 401){
            //         localStorage.clear()
            //     }
            //      return dispatch(addBusinessTypeFailure(error.response.data.message))
            //  }
            //  dispatch(addBusinessTypeFailure(error.message))
         }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
     }
 }

 const addBusinessTypeStarted = () => ({
     type: ADD_BUSINESS_TYPE_STARTED
 })

 const addBusinessTypeSuccess = (businessType) => ({
    type: ADD_BUSINESS_TYPE_SUCCESS,
    payload: { businessType }
})

const addBusinessTypeFailure = (error) => ({
    type: ADD_BUSINESS_TYPE_FAILURE,
    payload: { error }
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
