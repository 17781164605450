import React from "react";
import { Table, Button } from "react-bootstrap";



const ProvinceTable = props => {
    let { provinces, country } = props;
    provinces = provinces.filter(province => province.country === country)
    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Edit</th>
                    <th>Cities</th>
                </tr>
            </thead>
            <tbody>
                {provinces.map(province => {
                    const provinceName = province.name
                        .replace(/ /g, "-")
                        .toLowerCase();
                    const href = `/admin/cities?province=${provinceName}`;
                    return (
                        <tr key={province._id}>
                            <td>
                                <a href={href}>{province.name}</a>
                            </td>
                            <td>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        const selectedProvince = Object.assign(
                                            {},
                                            province
                                        );
                                        props.selectProvince(selectedProvince);
                                    }}
                                >
                                    <span
                                        className='oi oi-pencil'
                                        title='icon name'
                                        aria-hidden='true'
                                    ></span>{" "}
                                    Edit
                                </Button>
                            </td>
                            <td>
                                <a href={href}>
                                    <Button variant='warning'>
                                        Manage Cities
                                    </Button>
                                </a>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default ProvinceTable;
