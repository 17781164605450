import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
export const AdminNavigation = () => {
    return (
        <Nav className="mr-auto">
            <Nav.Link href="/businesses">Businesses</Nav.Link>
            <Nav.Link href="/appointment">Appointment</Nav.Link>
            <NavDropdown title="Clients" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/clients/search">
                    Search
                </NavDropdown.Item>
                <NavDropdown.Item href="/clients/statistics">
                    Statistics
                </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Admin Area" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/admin/employees?page=1">
                    Employees
                </NavDropdown.Item>

                <NavDropdown.Item href="/admin/provinces">
                    Provinces / City
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/business-status">
                    Business Status
                </NavDropdown.Item>
                <NavDropdown.Item href="/admin/business-type">
                    Business Type
                </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/notification">Notification</Nav.Link>
            <Nav.Link href="/payments">Payments</Nav.Link>
        </Nav>
    );
};
