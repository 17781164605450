import {
    GET_WEBSITE_STARTED,
    GET_WEBSITE_SUCCESS,
    GET_WEBSITE_FAILURE,
    UPDATE_WEBSITE_STARTED,
    UPDATE_WEBSITE_SUCCESS,
    UPDATE_WEBSITE_FAILURE,

    REMOVE_ERROR_MESSAGE
} from "../../constants/business/website";

const initialState = {
    websiteInfo: null,
    loading: false,
    error: null,
    message: null,
};

export default function website(state = initialState, action) {
    switch (action.type) {
        case GET_WEBSITE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_WEBSITE_SUCCESS:
            return {
                ...state,
                websiteInfo: action.payload.website,
                loading: false,
                error: null,
                message: null,
            };
        case GET_WEBSITE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };

        case UPDATE_WEBSITE_STARTED: 
            return {
                ...state,
                loading: true, 
                error: null,
                message: null,
            };
        case UPDATE_WEBSITE_SUCCESS:  
            return {
                ...state,
                websiteInfo: action.payload.website,
                loading: false,
                error: null,
                message: "The website has been updated successfully!",
            }
        case UPDATE_WEBSITE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            }
        
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        default:
            return {
                ...state,
            };
    }
}