import {
    SEARCH_CLIENT_STARTED,
    SEARCH_CLIENT_SUCCESS,
    SEARCH_CLIENT_FAILURE,
    BLOCK_CLIENT_STARTED,
    BLOCK_CLIENT_SUCCESS,
    BLOCK_CLIENT_FAILURE,
    UPDATE_CLIENT_PROFILE_STARTED,
    UPDATE_CLIENT_PROFILE_SUCCESS,
    UPDATE_CLIENT_PROFILE_FAILURE,
    DELETE_CLIENT_STARTED,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILURE,
    UPDATE_CLIENT_ACTIVE_STARTED,
    UPDATE_CLIENT_ACTIVE_SUCCESS,
    UPDATE_CLIENT_ACTIVE_FAILURE,
    GET_CLIENT_STATISTICS_STARTED,
    GET_CLIENT_STATISTICS_SUCCESS,
    GET_CLIENT_STATISTICS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/client/client"
import { LOGOUT } from "../../constants/employee-account/login";
import API from "../../../utils/API/API";


export const searchClient = (query) => {
    return dispatch => {
        dispatch(searchClientStarted())
        API.get(`/api/clients/search${query}`)
        .then(res => {
            dispatch(searchClientSuccess(res.data))
        })
        .catch((err) => {
            catchError(err, dispatch, searchClientFailure);
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const searchClientStarted = () => ({
    type: SEARCH_CLIENT_STARTED
})

const searchClientSuccess = (clients) => ({
    type: SEARCH_CLIENT_SUCCESS,
    payload: { clients }
})

const searchClientFailure = (error) => ({
    type: SEARCH_CLIENT_FAILURE,
    payload: { error }
})


export const blockClient = (clientAccountId, blockReason) => {
    return dispatch => {
        dispatch(blockClientStarted())
        API.patch(`/api/clients/block`, {clientAccountId, blockReason})
        .then(res => {
            dispatch(blockClientSuccess(res.data))
        })
        .catch((err) => {
            catchError(err, dispatch, blockClientFailure);
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const blockClientStarted = () => ({
    type: BLOCK_CLIENT_STARTED
})

const blockClientSuccess = (data) => ({
    type: BLOCK_CLIENT_SUCCESS,
    payload: { data }
})

const blockClientFailure = (error) => ({
    type: BLOCK_CLIENT_FAILURE,
    payload: { error }
})


export const updateClientProfile = (client) => {
    return dispatch => {
        dispatch(updateClientProfileStarted())
        API.patch(`/api/clients/update`, client)
        .then((res) => {
            dispatch(updateClientProfileSuccess(res.data));
        })
        .catch((err) => {
            catchError(err, dispatch, updateClientProfileFailure);
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const updateClientProfileStarted = () => ({
    type: UPDATE_CLIENT_PROFILE_STARTED
})

const updateClientProfileSuccess = (data) => ({
    type: UPDATE_CLIENT_PROFILE_SUCCESS,
    payload: { data },
})

const updateClientProfileFailure = (error) => ({
    type: UPDATE_CLIENT_PROFILE_FAILURE,
    payload: { error }
})


export const deleteClient = (clientId) => {
    return dispatch => {
        dispatch(deleteClientStarted())
        API.patch(`/api/clients/delete`, {clientId})
        .then((res) => {
            dispatch(deleteClientSuccess(res.data));
        })
        .catch((err) => {
            catchError(err, dispatch, deleteClientFailure);
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const deleteClientStarted = () => ({
    type: DELETE_CLIENT_STARTED
})

const deleteClientSuccess = (data) => ({
    type: DELETE_CLIENT_SUCCESS,
    payload: { data },
})

const deleteClientFailure = (error) => ({
    type: DELETE_CLIENT_FAILURE,
    payload: { error }
})


export const updateClientActive = (clientAccountId) => {
    return dispatch => {
        dispatch(updateClientActiveStarted())
        API.patch(`/api/clients/update-active`, {clientAccountId})
        .then((res) => {
            dispatch(updateClientActiveSuccess(res.data));
        })
        .catch((err) => {
            catchError(err, dispatch, updateClientActiveFailure);
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const updateClientActiveStarted = () => ({
    type: UPDATE_CLIENT_ACTIVE_STARTED
})

const updateClientActiveSuccess = (data) => ({
    type: UPDATE_CLIENT_ACTIVE_SUCCESS,
    payload: { data },
})

const updateClientActiveFailure = (error) => ({
    type: UPDATE_CLIENT_ACTIVE_FAILURE,
    payload: { error }
})


export const getClientStatistics = (query) => {
    return dispatch => {
        dispatch(getClientStatisticsStarted())
        API.get(`/api/clients/get-statistics?from=${query.from}&to=${query.to}&status=${query.status}`)
        .then((res) => {
            dispatch(getClientStatisticsSuccess(res.data));
        })
        .catch((err) => {
            catchError(err, dispatch, getClientStatisticsFailure);
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const getClientStatisticsStarted = () => ({
    type: GET_CLIENT_STATISTICS_STARTED
})

const getClientStatisticsSuccess = (data) => ({
    type: GET_CLIENT_STATISTICS_SUCCESS,
    payload: { data },
})

const getClientStatisticsFailure = (error) => ({
    type: GET_CLIENT_STATISTICS_FAILURE,
    payload: { error }
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
