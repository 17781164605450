import {
    GET_PAYMENTS_STARTED,
    GET_PAYMENTS_SUCCESS,
    GET_PAYMENTS_FAILURE,
    GET_PAYMENT_DETAILS_STARTED,
    GET_PAYMENT_DETAILS_SUCCESS,
    GET_PAYMENT_DETAILS_FAILURE,
    REMOVE_PAYMENT_STARTED,
    REMOVE_PAYMENT_SUCCESS,
    REMOVE_PAYMENT_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/payment/payment";
import { LOGOUT } from "../../constants/employee-account/login"
import API from "../../../utils/API/API";


export const removePayment = (paymentId) => {
    return (dispatch) => {
        dispatch(removePaymentStarted())
        API.delete(`/api/businesses/payments/delete?paymentId=${paymentId}`)
            .then((res) => {
                dispatch(removePaymentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, removePaymentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const removePaymentStarted = () => ({
    type: REMOVE_PAYMENT_STARTED,
});

const removePaymentSuccess = (data) => ({
    type: REMOVE_PAYMENT_SUCCESS,
    payload: data.payment
});

const removePaymentFailure = (error) => ({
    type: REMOVE_PAYMENT_FAILURE,
    payload: { error },
});

export const getPayments = (query) => {
    return dispatch => {
        dispatch(getPaymentsStarted());
        API.get(`/api/payments/get?from=${query.from}&to=${query.to}&status=${query.status}&page=${query.page}`)
            .then(res => {
                dispatch(getPaymentsSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, getPaymentsFailure);
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getPaymentsStarted = () => ({
    type: GET_PAYMENTS_STARTED,
});

const getPaymentsSuccess = (data) => ({
    type: GET_PAYMENTS_SUCCESS,
    payload: { data }
});

const getPaymentsFailure = error => ({
    type: GET_PAYMENTS_FAILURE,
    payload: { error }
});


export const getPaymentDetails = (paymentId) => {
    return dispatch => {
        dispatch(getPaymentDetailsStarted());
        API.get(`/api/payments/details?paymentId=${paymentId}`)
            .then(res => {
                dispatch(getPaymentDetailsSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, getPaymentDetailsFailure);
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getPaymentDetailsStarted = () => ({
    type: GET_PAYMENT_DETAILS_STARTED,
});

const getPaymentDetailsSuccess = (data) => ({
    type: GET_PAYMENT_DETAILS_SUCCESS,
    payload: { data }
});

const getPaymentDetailsFailure = error => ({
    type: GET_PAYMENT_DETAILS_FAILURE,
    payload: { error }
});



const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});


const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
