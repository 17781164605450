import {
    GET_ALL_CITY_STARTED,
    GET_ALL_CITY_SUCCESS,
    GET_ALL_CITY_FAILURE,
    ADD_CITY_STARTED,
    ADD_CITY_SUCCESS,
    ADD_CITY_FAILURE,
    UPDATE_CITY_STARTED,
    UPDATE_CITY_SUCCESS, 
    UPDATE_CITY_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/city";
import {LOGOUT} from "../../constants/employee-account/login"
import API from "../../../utils/API/API";
import axios from "axios";
/**
 * Gets all city api call
 */
export const getAllCitiesByProvince = query => {
    return dispatch => {
        dispatch(getCityStarted());
        const getAllProvinces = API.get(`/api/provinces`);
        const getAllCities = API.get(`/api/cities${query}`);
        axios
            .all([getAllProvinces, getAllCities])
            .then(
                axios.spread((...responses) => {
                    const provinces = responses[0].data;
                    const cities = responses[1].data.cities;
                    const province = responses[1].data.province;
                   
                    dispatch(getCitySuccess(provinces, cities, province));
                })
            )
            .catch(error => {
                catchError(error, dispatch, getCityFailure);
                // if (error.response) {
                //     //Logout user if not authenticated
                // if(error.response.status === 401){
                //     localStorage.clear()
                // }
                //     return dispatch(
                //         getCityFailure(error.response.data.message)
                //     );
                // }
                // dispatch(getCityFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
        }, 3000))
    };
};

const getCityStarted = () => ({
    type: GET_ALL_CITY_STARTED
});
const getCitySuccess = (provinces, cities, province) => ({
    type: GET_ALL_CITY_SUCCESS,
    payload: { provinces, cities, province }
});
const getCityFailure = error => ({
    type: GET_ALL_CITY_FAILURE,
    payload: { error }
});


/**
 * Add a city
 * 
 */

export const addCity = (city) => {
    return dispatch => {
        dispatch(addCityStarted());
        API.post(`/api/cities/create`, city)
            .then(res => {
                dispatch(addCitySuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, addCityFailure);
                // if (error.response) {
                //     //Logout user if not authenticated
                // if(error.response.status === 401){
                //     localStorage.clear()
                // }
                //     return dispatch(
                //         addCityFailure(error.response.data.message)
                //     );
                // } 
                //     dispatch(addCityFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const addCityStarted = () => ({
    type: ADD_CITY_STARTED
});
const addCitySuccess = city=> ({
    type: ADD_CITY_SUCCESS,
    payload: { city }
});
const addCityFailure = error => ({
    type: ADD_CITY_FAILURE,
    payload: { error }
});



/**
 * Update a city
 * 
 */

export const updateCity = (city) => {
    return dispatch => {
        dispatch(updateCityStarted());
        API.patch(`/api/cities/update/${city._id}`, city)
            .then(res => {
                dispatch(updateCitySuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, updateCityFailure);
                // if (error.response) {
                //     if(error.response.status === 401){
                //         localStorage.clear()
                //     }
                //     return dispatch(
                //         updateCityFailure(error.response.data.message)
                //     );
                // } 
                //     dispatch(updateCityFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const updateCityStarted = () => ({
    type:  UPDATE_CITY_STARTED
});
const updateCitySuccess = city=> ({
    type: UPDATE_CITY_SUCCESS,
    payload: { city }
});
const updateCityFailure = error => ({
    type: UPDATE_CITY_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
