import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Badge, Modal, Accordion, Card, Form } from "react-bootstrap";
import Loading from "../../layouts/Loading/Loading";
import { PaymentDetails } from "./PaymentDetails";
import { removePayment } from "../../redux/actions/payment/payment";
class PaymentDetailsModal extends Component {

    deletePayment = (paymentId) => {
        const confirmed = window.confirm("Confirm delete this payment?")
        if (confirmed) {
            this.props.removePayment(paymentId)
            this.props.handleShowModal(false)
        }
    }

    displayPaymentMethodIcon = (paymentMethod) => {
        if (paymentMethod === "Credit Card") {
            return <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" />
        } else {
            return <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" />
        }
    }

    displayPaymentStatus = (paymentStatus) => {
        if (paymentStatus === "Pending") {
            return <Badge variant="warning">{paymentStatus}</Badge>
        }
        else if (paymentStatus === "Success") {
            return <Badge variant="success">{paymentStatus}</Badge>
        }
        else if (paymentStatus === "Failed") {
            return <Badge variant="danger">{paymentStatus}</Badge>
        }
        else return <Badge variant="secondary">{paymentStatus}</Badge>
    }



    render() {
        const { userRole, loadingDetails, paymentDetails } = this.props;
        return (
            <Modal
                show={this.props.isModalShowing}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => this.props.handleShowModal(false)}
            // centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Payment Detail
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "scroll", height: "600px" }}>
                    {loadingDetails && <Loading />}
                    {!loadingDetails &&
                        paymentDetails &&
                        paymentDetails.businessPayment && (
                            <PaymentDetails
                                businessPayment={paymentDetails.businessPayment}
                                handlehandleShowModal={this.props.handlehandleShowModal}
                            />
                        )}

                    {!loadingDetails && paymentDetails &&
                        paymentDetails.businessPayment &&
                        (paymentDetails.businessPayment.status === "Failed" || paymentDetails.businessPayment.status === "Chargeback")
                        && (
                            <Button onClick={() => this.handleSendDeclineReminder()}>Send a payment declined reminder</Button>
                        )}
                    {!loadingDetails && paymentDetails && ["admin", "accountant"].includes(userRole) && !paymentDetails.businessPayment?.isSubmited
                        &&
                        <Button
                            variant="danger"
                            type="submit"
                            className="m-2 p-2"
                            onClick={() => this.deletePayment(paymentDetails.businessPayment._id)}
                        >
                            Delete Payment
                        </Button>
                    }


                    {!loadingDetails && (
                        <Accordion>
                            {["admin", "accountant"].includes(
                                userRole
                            ) &&
                                paymentDetails &&
                                paymentDetails.businessPayment &&
                                paymentDetails.paymentIntent &&
                                ["succeeded", "Success"].includes(
                                    paymentDetails.businessPayment.status
                                ) && (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                className="text-warning"
                                                eventKey="0"
                                            >
                                                Refund this payment
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Form
                                                    className="p-3"
                                                    onSubmit={(e) =>
                                                        this.handleRefundSubmit(
                                                            e
                                                        )
                                                    }
                                                >
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Cancel reason
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            required={true}
                                                            placeholder="Enter reason"
                                                            onChange={(e) =>
                                                                this.updateRefundReason(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Button
                                                        variant="danger"
                                                        type="submit"
                                                    >
                                                        Refund payment
                                                    </Button>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey="1"
                                    >
                                        JSON Data
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <pre
                                            style={{
                                                fontSize: ".65rem",
                                                padding: ".5rem",
                                            }}
                                        >
                                            {paymentDetails &&
                                                paymentDetails.paymentIntent &&
                                                JSON.stringify(
                                                    paymentDetails.paymentIntent,
                                                    null,
                                                    2
                                                )}
                                            {paymentDetails &&
                                                paymentDetails.transactionRotessa &&
                                                JSON.stringify(
                                                    paymentDetails.transactionRotessa,
                                                    null,
                                                    2
                                                )}
                                        </pre>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userRole: state.authenticate.user.role,
    loadingDetails: state.payment.loadingDetails,
    paymentDetails: state.payment.paymentDetails,
});

const mapDispatchToProps = (dispatch) => {
    return {
        removePayment: (paymentId) => dispatch(removePayment(paymentId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsModal);