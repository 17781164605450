export const GET_PAYMENTS_STARTED = "GET_PAYMENTS_STARTED"
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"
export const GET_PAYMENTS_FAILURE = "GET_PAYMENTS_FAILURE"

export const GET_PAYMENT_DETAILS_STARTED = "GET_PAYMENT_DETAILS_STARTED"
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS"
export const GET_PAYMENT_DETAILS_FAILURE = "GET_PAYMENT_DETAILS_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"

export const REMOVE_PAYMENT_STARTED = "REMOVE_PAYMENT_STARTED"
export const REMOVE_PAYMENT_SUCCESS = "REMOVE_PAYMENT_SUCCESS"
export const REMOVE_PAYMENT_FAILURE = "REMOVE_PAYMENT_FAILURE"