import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import NotFoundPage from "../layouts/NotFound/NotFoundPage";
import BusinessContainer from "../pages/business/BusinessContainer";
import BusinessDetailContainer from "../pages/business-details/BusinessDetailContainer";
import BusinessReportContainer from "../pages/business-payment/BusinessReportContainer";
import CreateBusinessPage from "../pages/business/CreateBusinessPage";
import NavigationContainer from "../layouts/menu/NavigationContainer";
import ClientSearchContainer from "../pages/client/client-search/ClientSearchContainer";
import ClientStatisticsContainer from "../pages/client/client-statistics/ClientStatisticsContainer";
// import Appointment from "../pages/appointment/Appointment";
// import BusinessStatisticsContainer from "../pages/business-statistics/BusinessStatisticsContainer";
import Payments from "../pages/payments/Payments";
import { Container } from "react-bootstrap";

export const AccountantRouters = () => {
    return (
        <Router>
            <NavigationContainer />
            <Container>
                <div className="App" style={{ marginTop: "20px" }}>
                    <Switch>
                        <Route path="/" exact component={BusinessContainer} />
                        <Route
                            path="/businesses"
                            exact
                            component={BusinessContainer}
                        />
                        <Route
                            path="/create-business"
                            exact
                            component={CreateBusinessPage}
                        />
                        <Route
                            path="/businesses/:id"
                            exact
                            component={BusinessDetailContainer}
                        />
                        <Route
                            path="/businesses/reports/:id"
                            component={BusinessReportContainer}
                        />
                        {/* <Route
                            path="/businesses/statistics/:id"
                            component={BusinessStatisticsContainer}
                        /> */}

                        <Route
                            path="/clients/search"
                            component={ClientSearchContainer}
                        />
                        <Route
                            path="/clients/statistics"
                            component={ClientStatisticsContainer}
                        />
                        <Route
                            path="/payments"
                            component={Payments}
                        />
                        <Route path="/*" component={NotFoundPage} />
                    </Switch>
                </div>
            </Container>
        </Router>
    );
};
