export const GET_GOOGLE_BOOKING_INFO_STARTED = "GET_GOOGLE_BOOKING_INFO_STARTED"
export const GET_GOOGLE_BOOKING_INFO_SUCCESS = "GET_GOOGLE_BOOKING_INFO_SUCCESS"
export const GET_GOOGLE_BOOKING_INFO_FAILURE = "GET_GOOGLE_BOOKING_INFO_FAILURE"

export const CREATE_GOOGLE_BOOKING_INFO_STARTED = "CREATE_GOOGLE_BOOKING_INFO_STARTED"
export const CREATE_GOOGLE_BOOKING_INFO_SUCCESS = "CREATE_GOOGLE_BOOKING_INFO_SUCCESS"
export const CREATE_GOOGLE_BOOKING_INFO_FAILURE = "CREATE_GOOGLE_BOOKING_INFO_FAILURE"

export const UPDATE_GOOGLE_BOOKING_INFO_STARTED = "UPDATE_GOOGLE_BOOKING_INFO_STARTED"
export const UPDATE_GOOGLE_BOOKING_INFO_SUCCESS = "UPDATE_GOOGLE_BOOKING_INFO_SUCCESS"
export const UPDATE_GOOGLE_BOOKING_INFO_FAILURE = "UPDATE_GOOGLE_BOOKING_INFO_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"