/**
 * Turn a encoded string to html
 * @param {*} stringValue
 */
//  function(stringValue) {
//   const strToDecode = stringValue;
//   const parser = new DOMParser();
//   const decodedValue = parser.parseFromString(
//     `<!doctype html><body>${strToDecode}`,
//     "text/html"
//   ).body.textContent;
//   return decodedValue;
// }

export default function decodeHtmlEntities(str) {
  return  String(str).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#x27;/g, "'");
}


// const allReplace = (str, chars) => {
//   var retStr = String(str);
//   for (var x in chars) {
//       retStr = retStr.replace(new RegExp(x, 'g'), chars[x]);
//   }
//   return retStr;
// };


// export const decodeHtmlEntity = (str) => {
//   var chars = { '&amp;': '&', '&lt;': '<', '&gt;': '>', '&quot;': '"', '&#x27;': "'", '&#x2F;': "/", " &#x5C;": "\\" };
//   return allReplace(str, chars)
//   // return String(str).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#x27;/g, "'").replace(/&#x2F;/g, "/");
// }

// export const capitalizeFirstLetter = (string) => {
//   const arr = string.split(" ");
//   for (var i = 0; i < arr.length; i++) {
//       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
//   }
//   return arr.join(" ");
// }