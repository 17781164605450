import {
    CREATE_PREVIEW_BUSINESS_STARTED,
    CREATE_PREVIEW_BUSINESS_SUCCESS,
    CREATE_PREVIEW_BUSINESS_FAILURE,
    GET_PREVIEW_BUSINESS_STARTED,
    GET_PREVIEW_BUSINESS_SUCCESS,
    GET_PREVIEW_BUSINESS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/preview-business";

const initialState = {
    previewBusinesses: [],
    loading: false,
    error: null,
    message: null,
    component: null,
};

export default function previewBusinesses(state = initialState, action) {
    switch (action.type) {
        case GET_PREVIEW_BUSINESS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "PublishingContainer",
            };
        case GET_PREVIEW_BUSINESS_SUCCESS:
            return {
                ...state,
                previewBusinesses: action.payload.previews,
                loading: false,
                error: null,
                message: null,
                component: "PublishingContainer",
            };
        case GET_PREVIEW_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "PublishingContainer",
            };
        case CREATE_PREVIEW_BUSINESS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "PublishingApprovalForm",
            };
        case CREATE_PREVIEW_BUSINESS_SUCCESS:
            return {
                ...state,
                previewBusinesses: [...state.previewBusinesses, action.payload.preview],
                loading: false,
                error: null,
                message: "Preview Link was sent to the approver successfully!",
                component: "PublishingApprovalForm",
            };
        case CREATE_PREVIEW_BUSINESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "PublishingApprovalForm",
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return { ...state };
    }
}
