import React from "react";
import { Carousel } from "react-bootstrap";
const Images = props => {
    const { business } = props;
    return (
        <div>
            <h4>Images</h4>
          
            <Carousel  prevIcon={<span style={{fontSize: "2em"}} className="oi oi-arrow-circle-left text-white"></span>} nextIcon ={<span style={{fontSize: "2em"}} className="oi oi-arrow-circle-right text-white"></span>}>
                {business.images.map(image => (
                    <Carousel.Item key={image}>
                        <img
                            className='d-block w-100'
                            src={image}
                            key={image}
                            alt='First slide'
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default Images;
