import {
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/employee-account/reset-password";

const initialState = {
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function resetPassword(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "ResetPassword"
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "Password has been reset successfully!",
                component: "ResetPassword"
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "ResetPassword"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
