import React from "react";
import { Table } from "react-bootstrap";

const ClientHistory = (props) => {
    const { client } = props;
    return (
        <div className="mt-2">
            <h5>History</h5>
            <div className="m-2">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Business Name</th>
                            <th>Status</th>
                            <th>Services</th>
                        </tr>
                    </thead>
                    <tbody>
                        {client.appointments.map(appointment => {
                        return (
                            <tr key={appointment._id}>
                                <td>{appointment.date}</td>
                                <td>{appointment.business.name}</td>
                                <td>{appointment.status}</td>
                                <td>
                                    {appointment.services.map((service, i) => {
                                        if (i === appointment.services.length - 1) {
                                            return service.name
                                        }
                                        return service.name + ", "
                                    })}
                                </td>
                            </tr>
                        );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ClientHistory;
