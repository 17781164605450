import React, { Component } from "react";
import { connect } from "react-redux";
import {updateBusinessAbout} from "../../../redux/actions/business/business";
import {
    Form,
    Button,
    Row,
    Col
} from "react-bootstrap";

import {decodeHtmlEntity} from "../../../utils/utility";

class AboutInfoForm extends Component {
    state = {
        business: {
            _id: "",
            about: [],
        },
        disabledSaveButton: true
    };

    componentDidMount() {
        this.initializeState();
    }

    //Fetch about array from redux store into business state 
    initializeState = () => {
        let { business } = this.state;
        let propBusiness = this.props.business;
        if (propBusiness) {
            const _id = propBusiness._id
            const about = [...propBusiness.about]
            business.about = about
            business._id = _id
        }
        this.setState({
            business
        });
    };

    handleAddMoreParagraph = () => {
        let { business } = this.state;
        if (!business.about.includes("")) {
            business.about.push("");
            this.setState({
                business
            });
        }
    };

    handleChangePragraph = e => {
        const index = e.target.name;
        let value = e.target.value;
        let { business } = this.state;

        business.about[index] = value;
        this.setState({
            business,
            disabledSaveButton: false
        });
    };

    handleDeleteParagraph = index => {
        const confirmed = window.confirm(
            "Are your sure to delete this paragraph?"
        );
        if (confirmed) {
            let { business } = this.state;
            business.about.splice(index, 1);
            this.setState({
                business,
                disabledSaveButton: false
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault()
        const {business} = this.state
        this.props.updateBusinessAbout(business)
    }
    
    handleCancel = () => {
        this.initializeState()
        this.setState({
            disabledSaveButton: true
        })
    }

    render() {
        const { business, disabledSaveButton } = this.state;
        
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    {business.about.length === 0 && (
                        <p>There is no description for the business</p>
                    )}
                    {business.about.map((paragraph, index) => {
                        return (
                            <Row key={index} className='mt-3'>
                                <Col>
                                    <Form.Label>
                                        Paragraph {index + 1}:{" "}
                                    </Form.Label>
                                    <Button
                                        variant='link'
                                        className='float-right text-danger'
                                        onClick={() =>
                                            this.handleDeleteParagraph(index)
                                        }
                                    >
                                        {" "}
                                        <span
                                            className='oi oi-trash'
                                            title='icon name'
                                            aria-hidden='true'
                                        ></span>{" "}
                                        Delete
                                    </Button>{" "}
                                    <Form.Control
                                        as='textarea'
                                        name={index}
                                        value={decodeHtmlEntity(paragraph)}
                                        onChange={this.handleChangePragraph}
                                        rows={parseInt(paragraph.length/70)}
                                    />
                                </Col>
                            </Row>
                        );
                    })}

                    <Row className='mt-3'>
                        <Col>
                            <Button
                                variant='info'
                                onClick={this.handleAddMoreParagraph}
                                disabled={business.about.length >= 3}
                            >
                                {" "}
                                <span
                                    className='oi oi-plus'
                                    title='icon name'
                                    aria-hidden='true'
                                ></span>{" "}
                                Add More Paragraph
                            </Button>
                        </Col>
                    </Row>
                   
                    <Row className='mt-3'>
                        <Col>
                            <Button variant='primary' type='submit' disabled={disabledSaveButton}>
                                Save
                            </Button>{" "}
                            <Button variant='secondary' onClick={this.handleCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => {
    return {
        updateBusinessAbout: (business) => dispatch(updateBusinessAbout(business))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutInfoForm);
