import React from "react";
import { Modal, Button, Row, Form } from "react-bootstrap";
const NoteModal = props => {
    const { selectedNote, handleChange, updateSelectedNote, ...rest } = props;
    return (
        <Modal
            {...rest}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Note Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ width: "100%" }}>
                    <Form.Group
                        className='ml-3'
                        style={{ width: "100%" }}
                        controlId='exampleForm.ControlTextarea1'
                    >
                        <Form.Control
                            as='textarea'
                            rows='3'
                            name='note'
                            value={selectedNote ? selectedNote.note : ""}
                            onChange={props.handleChange}
                        />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.onHide}>
                    Close
                </Button>
                <Button variant='primary' onClick={props.updateSelectedNote}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NoteModal;
