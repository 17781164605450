export const GET_TAXES_STARTED = "GET_TAXES_STARTED"
export const GET_TAXES_SUCCESS = "GET_TAXES_SUCCESS"
export const GET_TAXES_FAILURE = "GET_TAXES_FAILURE"

export const CREATE_TAXES_STARTED = "CREATE_TAXES_STARTED"
export const CREATE_TAXES_SUCCESS = "CREATE_TAXES_SUCCESS"
export const CREATE_TAXES_FAILURE = "CREATE_TAXES_FAILURE"

export const UPDATE_TAXES_STARTED = "UPDATE_TAXES_STARTED"
export const UPDATE_TAXES_SUCCESS = "UPDATE_TAXES_SUCCESS"
export const UPDATE_TAXES_FAILURE = "UPDATE_TAXES_FAILURE"

export const DELETE_TAXES_STARTED = "DELETE_TAXES_STARTED"
export const DELETE_TAXES_SUCCESS = "DELETE_TAXES_SUCCESS"
export const DELETE_TAXES_FAILURE = "DELETE_TAXES_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"