import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab } from "react-bootstrap";
// import GeneralReport from "./general-report/GeneralReport";
// import MonthlyPaymentForm from "./month-payment-form/MonthlyPaymentForm";
import PaymentHistory from "./payment-history/PaymentHistory"
import PaymentProfile from "./payment-profile/PaymentProfile";
import WeeklyPaymentForm from "./weekly-payment-form/WeeklyPaymentForm";
import VoidPaymentProfile from "./void-payment-profile/VoidPaymentProfile";
// import VoidWeeklyPayment from "./void-weekly-payment/VoidWeeklyPayment";
import { NewMonthlyPayment } from "./new-monthly-payment/NewMonthlyPayment";

class BusinessReportContentContainer extends Component {
    render() {
        const { currentTab } = this.props
        return (
            <Tab.Content>
                {/* {currentTab === "general" && <Tab.Pane eventKey="general">
                    <GeneralReport />
                </Tab.Pane>} */}
                {currentTab === "newMonthlyPayment" && <Tab.Pane eventKey="newMonthlyPayment">
                    <NewMonthlyPayment />
                </Tab.Pane>}
                {/* {currentTab === "monthlyPayment" && <Tab.Pane eventKey="monthlyPayment">
                    <MonthlyPaymentForm />
                </Tab.Pane>} */}
                {currentTab === "weeklyPayment" && <Tab.Pane eventKey="weeklyPayment">
                    <WeeklyPaymentForm />
                </Tab.Pane>}
                {currentTab === "paymentHistory" && <Tab.Pane eventKey="paymentHistory">
                    <PaymentHistory />
                </Tab.Pane>}
                {currentTab === "paymentProfile" && <Tab.Pane eventKey="paymentProfile">
                    <PaymentProfile />
                </Tab.Pane>}
                {currentTab === "paymentProfileVoid" && <Tab.Pane eventKey="paymentProfileVoid">
                    <VoidPaymentProfile />
                </Tab.Pane>}
                {/* {currentTab === "weeklyPaymentVoid" && <Tab.Pane eventKey="weeklyPaymentVoid">
                    <VoidWeeklyPayment />
                </Tab.Pane>} */}
            </Tab.Content>
        );
    };
};

const mapStateToProps = state => ({
    error: state.business.error,
    message: state.business.message,
    component: state.business.component,
    months: state.businessReport.months,

});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessReportContentContainer);