export const CREATE_BUSINESS_STARTED = "CREATE_BUSINESS_STARTED"
export const CREATE_BUSINESS_SUCCESS = "CREATE_BUSINESS_SUCCESS"
export const CREATE_BUSINESS_FAILURE = "CREATE_BUSINESS_FAILURE"


export const GET_BUSINESS_DETAIL_STARTED = "GET_BUSINESS_DETAIL_STARTED"
export const GET_BUSINESS_DETAIL_SUCCESS = "GET_BUSINESS_DETAIL_SUCCESS"
export const GET_BUSINESS_DETAIL_FAILURE = "GET_BUSINESS_DETAIL_FAILURE"


export const UPDATE_BUSINESS_GENERAL_INFO_STARTED = "UPDATE_BUSINESS_GENERAL_INFO_STARTED"
export const UPDATE_BUSINESS_GENERAL_INFO_SUCCESS = "UPDATE_BUSINESS_GENERAL_INFO_SUCCESS"
export const UPDATE_BUSINESS_GENERAL_INFO_FAILURE = "UPDATE_BUSINESS_GENERAL_INFO_FAILURE"


export const UPDATE_BUSINESS_LOCATION_STARTED = "UPDATE_BUSINESS_LOCATION_STARTED"
export const UPDATE_BUSINESS_LOCATION_SUCCESS = "UPDATE_BUSINESS_LOCATION_SUCCESS"
export const UPDATE_BUSINESS_LOCATION_FAILURE = "UPDATE_BUSINESS_LOCATION_FAILURE"

export const UPDATE_BUSINESS_ABOUT_STARTED = "UPDATE_BUSINESS_ABOUT_STARTED"
export const UPDATE_BUSINESS_ABOUT_SUCCESS = "UPDATE_BUSINESS_ABOUT_SUCCESS"
export const UPDATE_BUSINESS_ABOUT_FAILURE = "UPDATE_BUSINESS_ABOUT_FAILURE"


export const UPDATE_BUSINESS_SCORE_STARTED = "UPDATE_BUSINESS_SCORE_STARTED"
export const UPDATE_BUSINESS_SCORE_SUCCESS = "UPDATE_BUSINESS_SCORE_SUCCESS"
export const UPDATE_BUSINESS_SCORE_FAILURE = "UPDATE_BUSINESS_SCORE_FAILURE"

export const UPLOAD_DISPLAY_IMAGE_STARTED = "UPLOAD_DISPLAY_IMAGE_STARTED"
export const UPLOAD_DISPLAY_IMAGE_SUCCESS = "UPLOAD_DISPLAY_IMAGE_SUCCESS"
export const UPLOAD_DISPLAY_IMAGE_FAILURE = "UPLOAD_DISPLAY_IMAGE_FAILURE"

export const REMOVE_DISPLAY_IMAGE_STARTED = "REMOVE_DISPLAY_IMAGE_STARTED"
export const REMOVE_DISPLAY_IMAGE_SUCCESS = "REMOVE_DISPLAY_IMAGE_SUCCESS"
export const REMOVE_DISPLAY_IMAGE_FAILURE = "REMOVE_DISPLAY_IMAGE_FAILURE"

export const UPLOAD_IMAGE_STARTED = "UPLOAD_IMAGE_STARTED"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE"

export const REMOVE_IMAGE_STARTED = "REMOVE_IMAGE_STARTED"
export const REMOVE_IMAGE_SUCCESS = "REMOVE_IMAGE_SUCCESS"
export const REMOVE_IMAGE_FAILURE = "REMOVE_IMAGE_FAILURE"


export const UPDATE_SENDER_PHONES_STARTED = "UPDATE_SENDER_PHONES_STARTED"
export const UPDATE_SENDER_PHONES_SUCCESS = "UPDATE_SENDER_PHONES_SUCCESS"
export const UPDATE_SENDER_PHONES_FAILURE = "UPDATE_SENDER_PHONES_FAILURE"


// export const REMOVE_BUSINESS_ACCOUNT_STARTED = "REMOVE_BUSINESS_ACCOUNT_STARTED"
// export const REMOVE_BUSINESS_ACCOUNT_SUCCESS = "REMOVE_BUSINESS_ACCOUNT_SUCCESS"
// export const REMOVE_BUSINESS_ACCOUNT_FAILURE = "REMOVE_BUSINESS_ACCOUNT_FAILURE"

export const UPDATE_BUSINESS_ACCOUNT_STARTED = "UPDATE_BUSINESS_ACCOUNT_STARTED"
export const UPDATE_BUSINESS_ACCOUNT_SUCCESS = "UPDATE_BUSINESS_ACCOUNT_SUCCESS"
export const UPDATE_BUSINESS_ACCOUNT_FAILURE = "UPDATE_BUSINESS_ACCOUNT_FAILURE"


export const UPDATE_BUSINESS_IMAGES_STARTED = "UPDATE_BUSINESS_IMAGES_STARTED"
export const UPDATE_BUSINESS_IMAGES_SUCCESS = "UPDATE_BUSINESS_IMAGES_SUCCESS"
export const UPDATE_BUSINESS_IMAGES_FAILURE = "UPDATE_BUSINESS_IMAGES_FAILURE"

export const UPDATE_BUSINESS_ACTIVE_FEATURES_STARTED = "UPDATE_BUSINESS_ACTIVE_FEATURES_STARTED"
export const UPDATE_BUSINESS_ACTIVE_FEATURES_SUCCESS = "UPDATE_BUSINESS_ACTIVE_FEATURES_SUCCESS"
export const UPDATE_BUSINESS_ACTIVE_FEATURES_FAILURE = "UPDATE_BUSINESS_ACTIVE_FEATURES_FAILURE"

export const UPDATE_BUSINESS_ONLINE_STATUS_STARTED = "UPDATE_BUSINESS_ONLINE_STATUS_STARTED"
export const UPDATE_BUSINESS_ONLINE_STATUS_SUCCESS = "UPDATE_BUSINESS_ONLINE_STATUS_SUCCESS"
export const UPDATE_BUSINESS_ONLINE_STATUS_FAILURE = "UPDATE_BUSINESS_ONLINE_STATUS_FAILURE"

export const UPDATE_BUSINESS_SOCIAL_MEDIA_STARTED = "UPDATE_BUSINESS_SOCIAL_MEDIA_STARTED"
export const UPDATE_BUSINESS_SOCIAL_MEDIA_SUCCESS = "UPDATE_BUSINESS_SOCIAL_MEDIA_SUCCESS"
export const UPDATE_BUSINESS_SOCIAL_MEDIA_FAILURE = "UPDATE_BUSINESS_SOCIAL_MEDIA_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"

