import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { countryCodes } from "../../../utils/utility";
class CountryProvinceCityDropdown extends Component {
    state = {
        country: "",
        province: "",
        city: "",
        provinces: [],
        cities: [],
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.province !== prevProps.province ||
            this.props.city !== prevProps.city
        ) {
            //get the country of the province if province is available from props
            if (this.props.provinces) {
                let provinceObject = this.props.provinces.filter(
                    (province) => province._id === this.props.province
                )[0];
                if (provinceObject) {
                    this.setState({
                        country: provinceObject.country,
                    });
                }
            }
            //end get the country
            this.setState({
                city: this.props.city,
                province: this.props.province,
            });
        }
    }

    handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        //if change country then clear province and city value in both state and props
        if (field === "country" && value !== this.state.country) {
            this.setState({
                city: "",
                province: "",
            });
            this.props.handleChangeCityProvince(value, "country");
            this.props.handleChangeCityProvince("", "province");
            this.props.handleChangeCityProvince("", "city");
        }
        //if change province then clear city value
        else if (field === "province" && value !== this.state.province) {
            this.setState({
                city: "",
            });
            this.props.handleChangeCityProvince("", "city");
        }
        //save changes in state
        this.setState({
            [field]: value,
        });
        //save changes in props except country field
        if (field !== "country") {
            this.props.handleChangeCityProvince(value, field);
        }
    };

    //based on the selected country display appropriate provinces
    //base on the selected province displaying cities
    filterProvinceAndCity = () => {
        let { cities, provinces } = this.props;

        let { country, province } = this.state;
        if (province) {
            cities = cities.filter((city) => city.province === province);
        }

        if (country) {
            provinces = provinces.filter(
                (province) => province.country === country
            );
        }

        return { cities, provinces };
    };

    render() {
        const { country, province, city } = this.state;
        const { cities, provinces } = this.filterProvinceAndCity();

        return (
            <div>
                <Row className='mt-3'>
                    <Col>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as='select'
                            value={country}
                            name='country'
                            onChange={this.handleChange}
                        >
                            {countryCodes.map((ctr) => (
                                <option value={ctr.country} key={ctr.country}>
                                    {ctr.country}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as='select'
                            value={province}
                            name='province'
                            onChange={this.handleChange}
                            required
                        >
                            <option value=''>Select Province</option>
                            {provinces.map((province) => {
                                return (
                                    <option
                                        value={province._id}
                                        key={province._id}
                                    >
                                        {province.name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            as='select'
                            name='city'
                            value={city}
                            onChange={this.handleChange}
                            required
                        >
                            <option value=''>Select City</option>
                            {cities.map((city) => {
                                return (
                                    <option value={city._id} key={city._id}>
                                        {city.name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cities: state.provinceCity.cities,
    provinces: state.provinceCity.provinces,
});

export default connect(mapStateToProps)(CountryProvinceCityDropdown);
