import {
    GET_PAYMENTS_STARTED,
    GET_PAYMENTS_SUCCESS,
    GET_PAYMENTS_FAILURE,
    GET_PAYMENT_DETAILS_STARTED,
    GET_PAYMENT_DETAILS_SUCCESS,
    GET_PAYMENT_DETAILS_FAILURE,
    REMOVE_PAYMENT_STARTED,
    REMOVE_PAYMENT_SUCCESS,
    REMOVE_PAYMENT_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/payment/payment";

const initialState = {
    payments: [],
    summary: [],
    paymentsCount: 0,
    paymentDetails: null,
    loading: false,
    loadingDetails: false,
    error: null,
};

export default function payment(state = initialState, action) {
    switch (action.type) {
        case REMOVE_PAYMENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case REMOVE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: state.payments.filter(
                    (payment) => payment._id !== action.payload._id
                ),
            };
        case REMOVE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_PAYMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload.data.payments,
                paymentsCount: action.payload.data.paymentsCount,
                summary: action.payload.data.summary
            };
        case GET_PAYMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                summary: []
            };

        case GET_PAYMENT_DETAILS_STARTED:
            return {
                ...state,
                loadingDetails: true,
                error: null,
            };
        case GET_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                loadingDetails: false,
                paymentDetails: action.payload.data,
            };
        case GET_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                loadingDetails: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}
