import React from "react";
import { Modal, Button, Tabs, Tab, Row } from "react-bootstrap";
import ClientGeneralForm from "./ClientGeneralForm";
import ClientAuthenticationForm from "./ClientAuthenticationForm";
import ErrorSuccess from "../../ErrorSuccess/ErrorSuccess";
import ClientHistory from "./ClientHistory";

const ClientModal = (props) => {
    const {
        selectedClient,
        blockReasonOnChange,
        blockClientAccount,
        updateClientActive,
        error,
        component,
        message,
        deleteClientProfile,
        formOnChange,
        updateClientProfile,
        selectedClientCountry,
        handleChangeFormClientCountry,
        ...rest
    } = props;

    return (
        <Modal
            {...rest}
            style={{ width: "120% !important" }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {selectedClient ? selectedClient.firstName + ' ' + selectedClient.lastName : "Client"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
                    <Tab eventKey="general" title="General">
                        <ClientGeneralForm
                            client={selectedClient}
                            deleteClientProfile={deleteClientProfile}
                            formOnChange={formOnChange}
                            updateClientProfile={updateClientProfile}
                            selectedClientCountry={selectedClientCountry}
                            handleChangeFormClientCountry={handleChangeFormClientCountry}
                        />
                    </Tab>
                    <Tab eventKey="authentication" title="Authentication">
                        <ClientAuthenticationForm
                            client={selectedClient}
                            blockReasonOnChange={blockReasonOnChange}
                            blockClientAccount={blockClientAccount}
                            updateClientActive={updateClientActive}
                        />
                    </Tab>
                    <Tab eventKey="history" title="History">
                        <ClientHistory
                            client={selectedClient}
                        />
                    </Tab>
                </Tabs>
                {(component === "ClientModal" || component === "ClientProfileModal" || component === "ClientAuthModal") && (
                    <Row className="mt-2">
                        <ErrorSuccess error={error} message={message} />
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ClientModal;
