import React from 'react'
import { Row, Col, Form } from "react-bootstrap";

const AboutInformation = (props) => {
    const {business} = props
    return (
        <div className='shadow-none p-3 mb-5 bg-light rounded'>
             <h4>Business Description</h4>
             <Row>
                <Col>
                    {business.about.map(paragraph => (
                        <Form.Control
                        as="textarea" rows="4"
                        className="mb-3"
                        name='address'
                        key={paragraph}
                        value={paragraph}
                        readOnly
                    />
                    ))}
                </Col>
            </Row>
        </div>
    )
}

export default AboutInformation