import API from "../../../utils/API/API";
import {
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_STARTED,
  LOGOUT
} from "../../constants/employee-account/login";


const initialState = {
  user: {
    authenticated: false
  },
  loading: false,
  error: null
};

/**
 * Handle Login and Logout
 * User object with token will be stored in localStorage
 * @param {*} state
 * @param {*} action
 */
export default function authenticate(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_USER_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      };
    //User Login
    case AUTHENTICATE_USER_SUCCESS:

      //Make API to server and set the new USer as an example
      const newUser = {
        authenticated: true,
        email: action.payload.user.account.email,
        name: action.payload.user.account.name,
        role: action.payload.user.account.role,
        token: action.payload.user.token

      };

      localStorage.setItem("user", JSON.stringify(newUser));
      const token = newUser.token;

      //set API header for server side request authentication
      API.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return { ...state, user: newUser };

    case AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Incorrect Email or Password!"
      };
    //User logout
    case LOGOUT:
      localStorage.clear();
      API.defaults.headers.common["Authorization"] = `Bearer `;
      const user = {
        authenticated: false
      };
      return { ...state, user, loading: false, error: null };
    default:
      return state;
  }
}
