export const GET_ALL_PROVINCE_STARTED = "GET_ALL_PROVINCE_STARTED"
export const GET_ALL_PROVINCE_SUCCESS = "GET_ALL_PROVINCE_SUCCESS"
export const GET_ALL_PROVINCE_FAILURE = "GET_ALL_PROVINCE_FAILURE"

export const UPDATE_PROVINCE_STARTED = "UPDATE_PROVINCE_STARTED"
export const UPDATE_PROVINCE_SUCCESS = "UPDATE_PROVINCE_SUCCESS"
export const UPDATE_PROVINCE_FAILURE = "UPDATE_PROVINCE_FAILURE"

export const ADD_PROVINCE_STARTED = "ADD_PROVINCE_STARTED"
export const ADD_PROVINCE_SUCCESS = "ADD_PROVINCE_SUCCESS"
export const ADD_PROVINCE_FAILURE = "ADD_PROVINCE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"