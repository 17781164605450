import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Form, Row, Col, Button} from "react-bootstrap";
import {updateBusinessScore} from "../../../redux/actions/business/business";

class ScoreInfoForm extends Component {
    state = {
        business: {
            _id: "",
            staffs: [],
            dashbookingScore: {
                currentPoint: 3,
                defaultPointPerStaff: 5,
                numberOfStaffs: 1
            },
            customerScore: {
                totalReviews: 0,
                average: 0
            }
        }
    }

    componentDidMount() {
        this.initializeState()
    } 

    handleChangeDashbookingScore = (e) => {
        const field = e.target.name 
        let value = e.target.value  

        if(field === "currentPoint"){
            if(value > 5)  value = 5
            if(value <0) value = 0
           
        }

        if(field === "numberOfStaffs"){
            if(value <=0) value = 1
        }
    
        let {business} = this.state 
        business.dashbookingScore[field] = value 
    
        this.setState({
            business
        })
     
    }

    handleChangeCustomerScore = (e) => {
        const field = e.target.name 
        let value = e.target.value  

        if(field === "average"){
            if(value > 5)  value = 5
            if(value <0) value = 0
           
        }
    
        let {business} = this.state 
        business.customerScore[field] = value 
    
        this.setState({
            business
        })
     
    }


    initializeState = () => {
        let { business } = this.state;
        let propBusiness = this.props.business;
        if (propBusiness) {
            const scoreProperties = Object.keys(business)
          
            scoreProperties.forEach(property => {
             if(propBusiness[property]) {
               
                 business[property] = propBusiness[property]
             } 
            })
        }
        this.setState({
            business
        });
    };

    handleSubmit = (e) => {
        e.preventDefault() 
        const {business} = this.state
        if(this.props.role === "admin") {
            this.props.updateBusinessScore(business)
        }
        
    }

    render() {

        const { business } = this.state
        const { role } = this.props
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    
                    <Row className="mt-3">
                        <h4>Dash Booking Score</h4>
                        <Col>
                            <Form.Label>Current Point</Form.Label>
                            <Form.Control
                                type='number'
                                name='currentPoint'
                                disabled={role !== "admin"}
                                value={business.dashbookingScore.currentPoint}
                                onChange = {this.handleChangeDashbookingScore}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Default Point Per Staff</Form.Label>
                            <Form.Control
                                type='number'
                                name='defaultPointPerStaff'
                                disabled={role !== "admin"}
                                value={business.dashbookingScore.defaultPointPerStaff}
                                onChange = {this.handleChangeDashbookingScore}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Number of Staff <strong>({business.staffs.length} staffs)</strong></Form.Label>
                            <Form.Control
                                type='number'
                                name='numberOfStaffs'
                                disabled={role !== "admin"}
                                value={business.dashbookingScore.numberOfStaffs}
                                onChange = {this.handleChangeDashbookingScore}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <h4>Customer Score</h4>
                        <Col>
                            <Form.Label>Total review</Form.Label>
                            <Form.Control
                                type='number'
                                name='totalReviews'
                                disabled={role !== "admin"}
                                value={business.customerScore.totalReviews}
                                onChange = {this.handleChangeCustomerScore}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Average</Form.Label>
                            <Form.Control
                                type='number'
                                max={5}
                                name='average'
                                disabled={role !== "admin"}
                                value={business.customerScore.average}
                                onChange = {this.handleChangeCustomerScore}
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Button variant='primary' disabled={role !== "admin"} type='submit' >
                                Save
                            </Button>{" "}
                            <Button variant='secondary'>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    role: state.authenticate.user.role
})

const mapDispatchToProps = dispatch => {
    return {
        updateBusinessScore: (business) => dispatch(updateBusinessScore(business))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreInfoForm)
