import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Col, Row, Table } from "react-bootstrap";
import Loading from '../../../layouts/Loading/Loading'
import { updateBusinessType, addBusinessType } from '../../../redux/actions/admin/business-type'
import ErrorSuccess from '../../ErrorSuccess/ErrorSuccess'

class BusinessTypeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            businessType: {
                _id: "",
                title: "",
                typeUrl: "",
                isActiveForFetching: false,
            },
            imageFile: null,
            displayMessage: false,
            loading: false
        };
    };

    componentDidMount() {
        if (this.props.selectedType) {
            this.setState({
                businessType: {
                    ...this.props.selectedType,
                    isActiveForFetching: this.props.selectedType.isActiveForFetching ? this.props.selectedType.isActiveForFetching : false,
                    typeUrl: this.props.selectedType.typeUrl ? this.props.selectedType.typeUrl : ""
                }
            })
        } else {
            this.setState({
                businessType: {
                    _id: "",
                    title: "",
                    typeUrl: "",
                    isActiveForFetching: false,
                }
            })
        }
    };

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }

        let businessType = this.state.businessType;

        businessType[field] = value;

        this.setState({
            businessType
        });
    };

    handleImageChange = (e) => {
        this.setState({
            imageFile: e.target.files[0],
        })
    };

    handleSubmit = e => {
        e.preventDefault()
        const { businessType, imageFile } = this.state

        let businessTypeFormData = new FormData()
        if (imageFile) {
            if (businessType._id) {
                businessTypeFormData.append("_id", businessType._id)
                businessTypeFormData.append("title", businessType.title)
                businessTypeFormData.append("typeUrl", businessType.typeUrl)
                businessTypeFormData.append("isActiveForFetching", businessType.isActiveForFetching)
                businessTypeFormData.append("file", imageFile)

                this.props.updateBusinessType(businessTypeFormData)
            } else {
                businessTypeFormData.append("title", businessType.title)
                businessTypeFormData.append("file", imageFile)
                businessTypeFormData.append("typeUrl", businessType.typeUrl)
                businessTypeFormData.append("isActiveForFetching", businessType.isActiveForFetching)

                this.props.addBusinessType(businessTypeFormData)
            }
        } else {
            if (businessType._id) {
                businessTypeFormData.append("_id", businessType._id)
                businessTypeFormData.append("title", businessType.title)
                businessTypeFormData.append("typeUrl", businessType.typeUrl)
                businessTypeFormData.append("isActiveForFetching", businessType.isActiveForFetching)

                this.props.updateBusinessType(businessTypeFormData)
            } else {
                businessTypeFormData.append("title", businessType.title)
                businessTypeFormData.append("typeUrl", businessType.typeUrl)
                businessTypeFormData.append("isActiveForFetching", businessType.isActiveForFetching)

                this.props.addBusinessType(businessTypeFormData)
            }
        }
        this.setState({
            displayMessage: true
        })
    };

    render() {
        const { businessType, displayMessage, loading } = this.state
        const { component, error, message } = this.props
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col md={12}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Title'
                                name='title'
                                value={businessType.title}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Label>Url</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Url'
                                name='typeUrl'
                                value={businessType.typeUrl}
                                onChange={this.handleChange}

                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label htmlFor='isActiveForFetching'>Is Active For Fetching</Form.Label>
                            <Form.Check
                                type='checkbox'
                                id="isActiveForFetching"
                                name='isActiveForFetching'
                                checked={businessType.isActiveForFetching}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            {(businessType.image === "" || businessType.image === undefined) ? <h5>There is currently no image saved</h5> :
                                <Table borderless responsive="lg">
                                    <thead style={{ backgroundColor: 'rgb(0,0,0,0.15' }}>
                                        <tr>
                                            <th></th>
                                            <th>Image Url</th>
                                            <th>Image</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>{businessType.image}</td>
                                            <td>
                                                <img src={businessType.image} width='100' height='50' alt="business-type-img" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6><span className="oi oi-data-transfer-upload"></span>Upload New Image</h6>
                            <input md={12} type="file" encType="multipart/form-data" name="file" onChange={this.handleImageChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            {!loading ? (
                                <Button
                                    variant='primary'
                                    className='float-left'
                                    type='submit'
                                >
                                    Save
                                </Button>
                            ) : (
                                <Loading />
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        {displayMessage && component === "BusinessTypeForm" && <ErrorSuccess error={error} message={message} />}
                    </Row>
                </Form.Group>
                <Row>

                </Row>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.businessType.error,
        loading: state.businessType.loading,
        message: state.businessType.message,
        component: state.businessType.component
    };
}



const mapDispatchToProps = (dispatch) => {
    return {
        updateBusinessType: (businessType) => dispatch(updateBusinessType(businessType)),
        addBusinessType: (businessType) => dispatch(addBusinessType(businessType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeForm)
