import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { authenticateUser } from "../../redux/actions/employee-account/login";
import Loading from "../../layouts/Loading/Loading";
import { DashBookingHorizontalLogo } from "../../layouts/menu/DashBookingLogo";
const mapStateToProps = (state) => {
    return {
        user: state.authenticate.user,
        loading: state.authenticate.loading,
        error: state.authenticate.error,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        authenticateUser: (user) => dispatch(authenticateUser(user)),
    };
}
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
            errorMessage: "",
        };
    }
    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        const { email, password } = this.state;
        this.setState({
            loading: true,
        });
        e.preventDefault();
        this.props.authenticateUser({ email, password });
        this.setState({
            loading: false,
        });
    };
    render() {
        const { loading, error } = this.props;
        return (
            <Row className="mt-5" >
                <Col></Col>
                <Col md={4}>
                    <div align="center">
                        <DashBookingHorizontalLogo />
                    </div>
                  
                        <h3 style={{textAlign: "center", marginTop:"50px"}}>Login</h3>
                  
                    <Form method="POST" onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                required
                                onChange={this.handleChange}
                                placeholder="Enter email"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                onChange={this.handleChange}
                                name="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                        {!loading ? (
                            <Button variant="primary" type="submit">
                                Login
                            </Button>
                        ) : (
                            <Loading />
                        )}
                    </Form>
                    <br />
                    {error && <Alert variant="danger">{error}</Alert>}
                </Col>
                <Col></Col>
            </Row>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
