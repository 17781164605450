import {
    GET_TAXES_STARTED,
    GET_TAXES_SUCCESS,
    GET_TAXES_FAILURE,
    CREATE_TAXES_STARTED,
    CREATE_TAXES_SUCCESS,
    CREATE_TAXES_FAILURE,
    UPDATE_TAXES_STARTED,
    UPDATE_TAXES_SUCCESS,
    UPDATE_TAXES_FAILURE,
    DELETE_TAXES_STARTED, 
    DELETE_TAXES_SUCCESS,
    DELETE_TAXES_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/business/taxes";

import API from "../../../utils/API/API";

/**
 * Gets all taxes
 */
export const getTaxes = (businessId) => {
    return (dispatch) => {
        dispatch(getTaxesStarted());
        API.get(`/api/business-taxes?bid=${businessId}`)
            .then((res) => {
                dispatch(getTaxesSuccess(res.data));
            })
            .catch((error) => {
                if (error.response) {
                    //logout the user
                    if (error.response.status === 401) {
                        localStorage.clear();
                    }
                    return dispatch(
                        getTaxesFailure(error.response.data.message)
                    );
                }
                dispatch(getTaxesFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getTaxesStarted = () => ({
    type: GET_TAXES_STARTED,
});
const getTaxesSuccess = (taxes) => ({
    type: GET_TAXES_SUCCESS,
    payload: { taxes },
});
const getTaxesFailure = (error) => ({
    type: GET_TAXES_FAILURE,
    payload: { error },
});

/**
 * Create tax for a business
 */

export const createTax = (tax) => {
    return (dispatch) => {
        dispatch(createTaxesStarted());
        API.post(`/api/business-taxes/create`, tax)
            .then((res) => {
                dispatch(createTaxesSuccess(res.data));
            })
            .catch((error) => {
                if (error.response) {
                    //logout the user
                    if (error.response.status === 401) {
                        localStorage.clear();
                    }
                    return dispatch(
                        createTaxesFailure(error.response.data.message)
                    );
                }
                dispatch(createTaxesFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const createTaxesStarted = () => ({
    type: CREATE_TAXES_STARTED,
});

const createTaxesSuccess = (tax) => ({
    type: CREATE_TAXES_SUCCESS,
    payload: { tax },
});

const createTaxesFailure = (error) => ({
    type: CREATE_TAXES_FAILURE,
    payload: { error },
});

/**
 * Update Tax
 */
export const updateTax = (tax) => {
    return (dispatch) => {
        dispatch(updateTaxesStarted());
        API.patch("/api/business-taxes/update", tax).then((res) => {
            dispatch(updateTaxesSuccess(res.data));
        }).catch((error) => {
            if (error.response) {
                //logout the user
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return dispatch(
                    updateTaxesFailure(error.response.data.message)
                );
            }
            dispatch(updateTaxesFailure(error.message));
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    };
};


const updateTaxesStarted = () => ({
    type: UPDATE_TAXES_STARTED,
})
const updateTaxesSuccess = (tax) => ({
    type: UPDATE_TAXES_SUCCESS,
    payload: { tax },
})

const updateTaxesFailure = (error) => ({
    type: UPDATE_TAXES_FAILURE,
    payload: { error },
})


/**
 * Delete a tax
 */

export const deleteTax = (taxId) => {
    return (dispatch) => {
        dispatch(deleteTaxesStarted());
        API.delete(`/api/business-taxes/delete/${taxId}`).then((res) => {
            dispatch(deleteTaxesSuccess(res.data));
        }).catch((error) => {
            if (error.response) {
                //logout the user
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return dispatch(
                    deleteTaxesFailure(error.response.data.message)
                );
            }
            dispatch(deleteTaxesFailure(error.message));
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    };
};


const deleteTaxesStarted = () => ({
    type: DELETE_TAXES_STARTED,
})


const deleteTaxesSuccess = (tax) => ({
    type: DELETE_TAXES_SUCCESS,
    payload: { tax },
})

const deleteTaxesFailure = (error) => ({
    type: DELETE_TAXES_FAILURE,
    payload: { error },
})

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})