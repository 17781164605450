import React from "react";
import { Table, Button } from "react-bootstrap";

const BusinessStatusTable = props => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    
                    <th>Title</th>
                    <th>Description</th>
                    <th>Color</th>
                    <th>Edit</th>
                   
                </tr>
            </thead>
            <tbody>
                {props.businessStatuses.map(status => {
                    return (
                        <tr key={status._id}>
                             
                            <td>{status.title}</td>
                            <td>{status.description}</td>
                            <td><span className="status-color" style={{backgroundColor: status.color}}></span></td>
                            <td>
                                <Button variant='primary' onClick={() => {
                                    const selectedStatus = Object.assign({}, status)
                                    props.selectBusinessStatus(selectedStatus)
                                }}>
                                    <span
                                        className='oi oi-pencil'
                                        title='icon name'
                                        aria-hidden='true'
                                    ></span>{" "}
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default BusinessStatusTable;
