import React, {useState} from 'react'
import {Toast} from "react-bootstrap"


export const ErrorMessageToast = (props) => {
    const {error, message} = props
    const [show, setShow] = useState(true);
    if(error) {
      console.log("%c" + error, "color:red; font-size: 20px")
    }
   
    return (
        <div style={{marginTop: "25px"}}>
        {message && <Toast  onClose={() => setShow(false)} show={show}>
          <Toast.Header className="bg-success">
            <strong className="mr-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast> }
        {error && <Toast  onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header className="bg-danger">
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{error}</Toast.Body>
        </Toast>}
        </div>
    )
}
