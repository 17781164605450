import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import ApprovalHistoryTable from "./ApprovalHistoryTable";
import ApprovalModal from "./ApprovalModal";
import {
    getAllPreviewBusiness,
    createPreviewBusiness,
} from "../../../../redux/actions/business/preview-business";
import ErrorSuccess from "../../../ErrorSuccess/ErrorSuccess";
import Loading from "../../../../layouts/Loading/Loading";
import { formatPhoneNumber } from "../../../../utils/common/FormatPhoneNumber/FormatPhoneNumber"
class PublishingApprovalForm extends Component {
    state = {
        previewBusiness: {
            email: "",
            approverName: "",
            title: "",
            phone: "",
            isApproved: false,
            approvedAt: "",
            submitedAt: "",
            viewedAt: "",
            submittedBy: "",
            isPreviewLinkValid: true,
            changes: "",
            business: "",
        },
        modalShow: false,
        selectedApproval: null,
    };

    componentDidMount() {
        if (this.props.business) {
            this.props.getAllPreviewBusiness(this.props.business._id);
            let { previewBusiness } = this.state
            previewBusiness.business = this.props.business._id
            this.setState({ previewBusiness })
        }
    }

    selectApproval = (approval) => {
        this.setState({
            selectedApproval: approval,
            modalShow: true,
        });
    };

    openCloseModal = (e) => {
        this.setState({
            modalShow: !this.state.modalShow,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault()
        const { previewBusiness } = this.state

        this.props.createPreviewBusiness(previewBusiness)
    }

    handleChange = (e) => {
        let { previewBusiness } = this.state;
        const field = e.target.name;
        let value = e.target.value;

        previewBusiness[field] = value;
        if (field === "phone") {
            previewBusiness[field] = formatPhoneNumber(value, "CA")
        }
        this.setState({
            previewBusiness,
        });
    };
    render() {
        const { previewBusiness, selectedApproval, modalShow } = this.state;
        const {
            previewBusinesses,
            loading,
            message,
            error,
            component,
        } = this.props;
        if (loading) {
            return <Loading />;
        }

        return (
            <div>
                <h4>Approvals</h4>
                <Form onSubmit={this.handleSubmit}>
                    <Row className="mt-2">
                        <Col>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Approver's email"
                                value={previewBusiness.email}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="approverName"
                                placeholder="Approver's full name"
                                value={previewBusiness.approverName}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Approver's phone"
                                value={previewBusiness.phone}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="Approver's title"
                                value={previewBusiness.title}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Button type="submit" className="m-3">
                            Submit
                        </Button>
                    </Row>
                </Form>
                {component === "PublishingApprovalForm" && (
                    <Row className="mt-2">
                        <ErrorSuccess error={error} message={message} />
                    </Row>
                )}
                <div>
                    <ApprovalHistoryTable
                        selectApproval={this.selectApproval}
                        previewBusinesses={previewBusinesses}
                    />
                </div>
                <ApprovalModal
                    show={modalShow}
                    approval={selectedApproval}
                    onHide={this.openCloseModal}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    previewBusinesses: state.previewBusiness.previewBusinesses,
    loading: state.previewBusiness.loading,
    message: state.previewBusiness.message,
    error: state.previewBusiness.error,
    component: state.previewBusiness.component,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getAllPreviewBusiness: (businessId) =>
            dispatch(getAllPreviewBusiness(businessId)),
        createPreviewBusiness: (preview) =>
            dispatch(createPreviewBusiness(preview)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishingApprovalForm);
