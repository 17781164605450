
export const ADD_BUSINESS_STATUS_STARTED = "ADD_BUSINESS_STATUS_STARTED"
export const ADD_BUSINESS_STATUS_SUCCESS = "ADD_BUSINESS_STATUS_SUCCESS"
export const ADD_BUSINESS_STATUS_FAILURE = "ADD_BUSINESS_STATUS_FAILURE"


export const UPDATE_BUSINESS_STATUS_STARTED = "UPDATE_BUSINESS_STATUS_STARTED"
export const UPDATE_BUSINESS_STATUS_SUCCESS = "UPDATE_BUSINESS_STATUS_SUCCESS"
export const UPDATE_BUSINESS_STATUS_FAILURE = "UPDATE_BUSINESS_STATUS_FAILURE"

export const GET_ALL_BUSINESS_STATUS_STARTED = "GET_ALL_BUSINESS_STATUS_STARTED"
export const GET_ALL_BUSINESS_STATUS_SUCCESS = "GET_ALL_BUSINESS_STATUS_SUCCESS"
export const GET_ALL_BUSINESS_STATUS_FAILURE = "GET_ALL_BUSINESS_STATUS_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"