import {
    SEND_SYSTEM_NOTIFICATION_STARTED,
    SEND_SYSTEM_NOTIFICATION_SUCCESS,
    SEND_SYSTEM_NOTIFICATION_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/notification/notification"

const initialState = {
    loading: false,
    error: null,
    message: null,
};

export default function notification(state = initialState, action) {
    switch (action.type) {
        case SEND_SYSTEM_NOTIFICATION_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case SEND_SYSTEM_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Notification has been sent!",
                error: null,
            };
        case SEND_SYSTEM_NOTIFICATION_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state,
            };
    }
}