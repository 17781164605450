import React from "react";
import { Modal, Button } from "react-bootstrap";
import ProvinceForm from "./ProvinceForm";


const ProvinceModal = props => {
    const {
        selectedProvince,
        ...rest
    } = props;
    return (
        <Modal
            {...rest}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Province
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <ProvinceForm selectedProvince={selectedProvince}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProvinceModal;
