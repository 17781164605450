import React from "react";
import { Modal, Button } from "react-bootstrap";
import BusinessTypeForm from "./BusinessTypeForm";

const BusinessTypeModal = props => {
    const {
        selectedType,
        ...rest
    } = props;
    return (
        <Modal
            {...rest}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Business Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BusinessTypeForm selectedType={selectedType}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BusinessTypeModal;
