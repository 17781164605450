import React from 'react'
import { Modal, Button } from "react-bootstrap";
import EmployeeForm from './EmployeeForm';
const EmployeeModal = (props) => {
    const {
        selectedEmployee,
        ...rest
    } = props;
    return (
        <div>
             <Modal
            {...rest}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Employee
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <EmployeeForm employee={selectedEmployee}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
    )
}

export default EmployeeModal