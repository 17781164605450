import { parsePhoneNumber } from 'awesome-phonenumber';
import React, { useState } from 'react';
import { Form, FormGroup, Button, Row, Col, } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { updateSenderPhones } from '../../../redux/actions/business/business';
import Loading from "../../../layouts/Loading/Loading";
import { useSelector } from "react-redux";
const { Label } = Form;
function SenderPhonesForm(props) {
    const { business } = props

    // Initialize state with business.senderPhones values
    const [isUpdated, setIsUpdated] = useState(false);
    const [error, setError] = useState(null);
    const [senderPhones, setSenderPhones] = useState({
        notificationSenderNumber: business.senderPhones?.notificationSenderNumber || '',
        marketingSenderNumber: business.senderPhones?.marketingSenderNumber || '',
    });

    const { loading } = useSelector(
        (state) => state.business
    );

    const dispatch = useDispatch();

    // Handle changes to the input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setIsUpdated(true);
        const pn = parsePhoneNumber(value);
        if (value === '') {
            setError(null)
        }
        else if (pn.isValid()) {
            setError(null)
        }
        else {
            setError(`${name} is invalid`)
        }

        if (value !== "" && name === 'notificationSenderNumber' && value === senderPhones.marketingSenderNumber) {
            setError('Notification Sender Number and Marketing Sender Number cannot be the same')
        }

        if (value !== "" && name === 'marketingSenderNumber' && value === senderPhones.notificationSenderNumber) {
            setError('Notification Sender Number and Marketing Sender Number cannot be the same')
        }




        setSenderPhones(prevState => ({
            ...prevState,
            [name]: value,
        }));

    };





    // Handle form submission (example)
    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send the updated senderPhones to your backend
        dispatch(updateSenderPhones(business._id, senderPhones))

    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label> Notification Sender Number: (+12043214567)</Label>
                            <Form.Control
                                name='notificationSenderNumber'
                                type='Text'
                                value={senderPhones.notificationSenderNumber}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>  Marketing Sender Number:: (+12043214567)</Label>
                            <Form.Control
                                name='marketingSenderNumber'
                                type='Text'
                                value={senderPhones.marketingSenderNumber}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                {error && <div className="text-warning">{error}</div>}
                <Button className="mt-2" disabled={!!error || !isUpdated} color='primary' type='submit'>
                    Update Sender Phones
                </Button>



            </Form>

        </div>
    );
}

export default SenderPhonesForm;