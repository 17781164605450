import {
    GET_CACELLATION_SETTINGS_STARTED,
    GET_CACELLATION_SETTINGS_SUCCESS,
    GET_CACELLATION_SETTINGS_FAILURE,
    UPDATE_CACELLATION_SETTINGS_STARTED,
    UPDATE_CACELLATION_SETTINGS_SUCCESS,
    UPDATE_CACELLATION_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from '../../constants/cancellation-settings/cancellationSettings'

const initialState = {
    cancellationSettings: null,
    loading: false,
    error: null,
    message: null,
}

export default function cancellationSettings(state = initialState, action) {
    switch (action.type) {
        case GET_CACELLATION_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case GET_CACELLATION_SETTINGS_SUCCESS:
            return {
                ...state,
                cancellationSettings: action.payload.cancellationSettings,
                loading: false,
                message: null,
                error: null,
            }
        case GET_CACELLATION_SETTINGS_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            }
        case UPDATE_CACELLATION_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case UPDATE_CACELLATION_SETTINGS_SUCCESS:
            return {
                ...state,
                cancellationSettings: action.payload.cancellationSettings,
                loading: false,
                message: action.payload.message,
                error: null,
            }
        case UPDATE_CACELLATION_SETTINGS_FAILURE:
            return {
                ...state,
                message: null,
                loading: false,
                error: action.payload.error,
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                message: null,
                error: null,
            }
        default:
            return state
    }
}
