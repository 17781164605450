import {
    GET_ALL_CITY_STARTED,
    GET_ALL_CITY_SUCCESS,
    GET_ALL_CITY_FAILURE,
    ADD_CITY_STARTED,
    ADD_CITY_SUCCESS,
    ADD_CITY_FAILURE,
    UPDATE_CITY_STARTED,
    UPDATE_CITY_SUCCESS,
    UPDATE_CITY_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/city";

const initialState = {
    provinces: [],
    cities: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};

export default function city(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CITY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_CITY_SUCCESS:
            return {
                ...state,
                provinces: action.payload.provinces,
                cities: action.payload.cities,
                province: action.payload.province,
                loading: false,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "CityContainer"
            };
        case ADD_CITY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case ADD_CITY_SUCCESS:
            var cities = state.cities;
            cities.push(action.payload.city);
            return {
                ...state,
                cities,
                loading: false,
                error: null,
                message: "City was saved successfully!",
                component: "CityForm"
            };
        case ADD_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "CityForm"
            };

        case UPDATE_CITY_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case UPDATE_CITY_SUCCESS:
            var newCityList = state.cities;
            var updatedCity = action.payload.city;
            if (state.province._id === action.payload.city.province) {
                newCityList = newCityList.map(city => city._id === updatedCity._id? updatedCity: city )
                // newCityList.push(action.payload.city);
            } else {
                newCityList = newCityList.filter(city => city._id !== action.payload.city._id)
            }
            return {
                ...state,
                cities: newCityList,
                loading: false,
                error: null,
                message: "City was saved successfully!",
                component: "CityForm"
            };
        case UPDATE_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "CityForm"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
