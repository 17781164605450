import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Pagination } from "react-bootstrap";
import EmployeeModal from "../../../components/admin/employee/EmployeeModal";
import EmployeeTable from "../../../components/admin/employee/EmployeeTable";
import queryString from "query-string";
import { getAllEmployees } from "../../../redux/actions/admin/employee";
import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../../components/ErrorSuccess/ErrorSuccess";
import SearchForm from "../../../components/admin/employee/SearchForm";

class EmployeeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            setModalShow: false,
            selectedEmployee: null,
            page: 1,
            query: "?page=1"
        };
    }

    componentDidMount() {
        const query = this.props.location.search;
       
        this.props.getAllEmployees(query);
        const page = queryString.parse(this.props.location.search).page;

        this.setState({ page, query });
    }

    openCloseAddModal = e => {
        const { modalShow } = this.state;
        if (modalShow) {
            this.setState({
                selectedEmployee: null
            });
        }
        this.setState({
            modalShow: !this.state.modalShow
        });
    };

    selectEmployee = employee => {
        this.setState({
            selectedEmployee: employee,
            modalShow: true
        });
    };

    render() {
        const { modalShow, selectedEmployee, page } = this.state;
        const {
            employeeAccounts,
            count,
            loading,
            component,
            error,
            message
        } = this.props;

        //Maximum number of items per page
        const maxNumber = parseInt(count / 10) + 1;
        //active page
        let active = parseInt(page);

        let items = [];
        for (let number = 1; number <= maxNumber; number++) {
            const href = `/admin/employees?page=${number}`;
            items.push(
                <Pagination.Item
                    key={number}
                    href={href}
                    active={number === active}
                >
                    {number}
                </Pagination.Item>
            );
        }

        const paginationBasic = <Pagination>{items}</Pagination>;
        return (
            <div>
                <Row>
                    <Col>
                        <h1>Employee Accounts </h1>
                        <Button variant='info' onClick={this.openCloseAddModal}>
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New
                        </Button>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <SearchForm location={this.props.location} history={this.props.history}/>
                    </Col>
                </Row>
                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <Row className='mx-auto' style={{ width: "250px" }}>
                            <Col>{paginationBasic}</Col>
                        </Row>

                        <Row>
                            <Col>
                                <EmployeeTable
                                    employeeAccounts={employeeAccounts}
                                    selectEmployee={this.selectEmployee}
                                />
                            </Col>
                        </Row>
                        <Row className='mx-auto' style={{ width: "250px" }}>
                            <Col>{paginationBasic}</Col>
                        </Row>
                    </div>
                )}
                <Row>
                    <Col>
                        {component === "EmployeeContainer" && (
                            <ErrorSuccess error={error} message={message} />
                        )}
                    </Col>
                </Row>

                <EmployeeModal
                    selectedEmployee={selectedEmployee}
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        employeeAccounts: state.employee.employeeAccounts,
        count: state.employee.count,
        loading: state.employee.loading,
        error: state.employee.error,
        message: state.employee.message,
        component: state.employee.component
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllEmployees: query => dispatch(getAllEmployees(query))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContainer);
