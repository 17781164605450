import {
    GET_ALL_BUSINESS_TYPE_STARTED,
    GET_ALL_BUSINESS_TYPE_SUCCESS,
    GET_ALL_BUSINESS_TYPE_FAILURE,
    UPDATE_BUSINESS_TYPE_STARTED,
    UPDATE_BUSINESS_TYPE_SUCCESS,
    UPDATE_BUSINESS_TYPE_FAILURE,
    ADD_BUSINESS_TYPE_STARTED,
    ADD_BUSINESS_TYPE_SUCCESS,
    ADD_BUSINESS_TYPE_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/business-type";
const initialState = {
    businessTypes: [],
    loading: false,
    error: null,
    message: null,
    component: null //use this to define in which component  message or error should be displaed
};
export default function businessType(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BUSINESS_TYPE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: null
            };
        case GET_ALL_BUSINESS_TYPE_SUCCESS:
            return {
                ...state,
                businessTypes: action.payload.businessTypes,
                loading: false,
                error: null,
                message: null,
                component: "BusinessTypeContainer"
            };
        case GET_ALL_BUSINESS_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                message: null,
                component: "BusinessTypeContainer"
            };
        case UPDATE_BUSINESS_TYPE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessTypeForm"
            };
        case UPDATE_BUSINESS_TYPE_SUCCESS:
            const updatedType = action.payload.businessType
            var businessTypes = state.businessTypes.map(type => type._id === updatedType._id ? updatedType:type)
            return {
                ...state,
                businessTypes,
                loading: false,
                error: null,
                message: "Business type was saved successfully!",
                component: "BusinessTypeForm"
            };
        case UPDATE_BUSINESS_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessTypeForm"
            };
        case ADD_BUSINESS_TYPE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessTypeForm"
            };
        case ADD_BUSINESS_TYPE_SUCCESS:
            var newBusinessTypes = state.businessTypes;
            newBusinessTypes.push(action.payload.businessType);
            return {
                ...state,
                businessTypes : newBusinessTypes,
                loading: false,
                error: null,
                message: "Business type was saved successfully!",
                component: "BusinessTypeForm"
            };
        case ADD_BUSINESS_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessTypeForm"
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
