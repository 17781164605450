import React from "react";
import { Row, Col, Form } from "react-bootstrap";
const LocationInformation = props => {
    const {business, province, city} = props
    return (
        <div className='shadow-none p-3 mb-5 bg-light rounded'>
            <h4>Location Information</h4>
            <Row>
                <Col>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        type='text'
                        name='address'
                        placeholder='Address including city, province, postal code'
                        value={business.address}
                        readOnly
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Province'
                        readOnly
                        value={province && province.name}
                    />
                </Col>
                <Col>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='City'
                        readOnly
                        value={city && city.name}
                    />
                </Col>
                <Col>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='City'
                        readOnly
                        value={province && province.country}
                    />
                </Col>
            </Row>

            {/* <CountryProvinceCityDropdown handleChangeCityProvince = { this.handleChangeCityProvince } city={business.city} province ={business.province}/> */}

            <Row className='mt-3'>
                <Col md={6}>
                    <Form.Label>Longtitude</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Longtitude'
                        readOnly
                        value={business.geometry.coordinates[0]}
                        name='longtitude'
                    />
                </Col>
                <Col md={6}>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Latitude'
                        readOnly
                        value={business.geometry.coordinates[1]}
                        name='latitude'
                    />
                </Col>
            </Row>
            
            <Row className='mt-3'>
                <Col>
                    <Form.Label>Map Link </Form.Label>
                    {business.mapLink && (
                        <a
                            rel='noopener noreferrer'
                            href={business.mapLink}
                            target='_blank'
                            className='float-right'
                        >
                            Visit Map Link
                        </a>
                    )}
                    <Form.Control
                        as='textarea'
                        rows='5'
                        placeholder='Google map link'
                        name='mapLink'
                        value={business.mapLink}
                        readOnly
                    />
                </Col>
            </Row>
        </div>
    );
};

export default LocationInformation;
