import React, { Component } from 'react'
import {Table,Row, Button} from "react-bootstrap"
import { connect } from "react-redux";
import { updateBusinessImages } from "../../../redux/actions/business/business";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle,
    // background: isDragging ? "linear-gradient(90deg, rgba(48,238,253,0.50773812943146) 27%, rgba(233,148,233,0.5945728633250176) 91%)" : "",
    borderRadius: isDragging ? "15px": "",
    borderBottom: '1px solid rgb(0,0,0,.15)'
});

class ImageTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: []
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        const { images } = this.props

        const imagesArray = images.map((image,index) => {
            const newImage ={ image:image, id: index}
            return newImage
        })

        this.setState({
            images: imagesArray
        })
    }

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const images = reorder(
            this.state.images,
            result.source.index,
            result.destination.index
        );
        
        const {business} = this.props
        let businessImages = {_id:'', images: []}
        businessImages.images = images.map((item) => {
            return item.image
        })
        businessImages._id = business._id
        this.props.updateBusinessImages(businessImages)
        this.setState({
            images
        });
    };

    render() {
        const { openCloseAddModal } = this.props;
        const { images } = this.state;
        
        return (
            <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                 <Table borderless responsive="lg">
                    <thead style={{backgroundColor: 'rgb(0,0,0,0.15'}}>
                        <tr>
                            <th>
                            </th>
                            <th>Url</th>
                            <th>Images</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <Droppable droppableId='droppable'>
                                            {provided => (
                                               <tbody 
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                >
                                                   
                                                {images.map((image, index) => {
                                                    return (
                                                <Draggable
                                                    key={image.id}
                                                    draggableId={JSON.stringify({
                                                        nodeId: image.id,
                                                        type: "DragItem"})}
                                                    index={index}
                                                    isDragDisabled={images.length <= 1}
                                                >
                                                {(provided, snapshot) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                    )}
                                                >
                                                    <td>{index + 1 }</td>
                                                    <td>{image.image}</td>
                                                    <td>
                                                    <img src={image.image} width='100' height='50' alt={image.index} />
                                                    </td>
                                                    <td>                   
                                                            <Button
                                                                className='text-info'
                                                                variant='link'
                                                                onClick={() =>
                                                                    openCloseAddModal(
                                                                        image.image
                                                                    )
                                                                }
                                                            >
                                                               <span className="oi oi-external-link"></span>
                                                            </Button>
                                                     
                                                    </td>
        
                                        </tr>
                                    )}
                                </Draggable>
                                                  
                                            );
                                        }
                                    )}
                                    {provided.placeholder}
                                </tbody>
   
                                    
                                    )}
                                    </Droppable>
                                    </Table>
                                    </DragDropContext>
                                    <Row className='mt-3'>
                </Row>

            </div>
            
        )
    }
}

const mapStateToProps = state => ({
    business: state.business.business,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateBusinessImages: businessImages => dispatch(updateBusinessImages(businessImages))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ImageTable);
