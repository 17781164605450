import {
    GET_BUSINESS_HOUR_STARTED,
    GET_BUSINESS_HOUR_SUCCESS,
    GET_BUSINESS_HOUR_FAILURE,
    UPDATE_BUSINESS_HOUR_STARTED,
    UPDATE_BUSINESS_HOUR_SUCCESS,
    UPDATE_BUSINESS_HOUR_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/business/business-hour"


const initialState = {
    businessHour: {},
    loading: false,
    error: null,
    message: null,
    component: null
}

export default function businessHour (state = initialState, action) {
    switch(action.type) {
        case GET_BUSINESS_HOUR_STARTED: 
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "HourContainer"
            }
        case GET_BUSINESS_HOUR_SUCCESS:
            return {
                ...state,
                loading: false,
                businessHour: action.payload.businessHour,
                error: null,
                message: null,
                component: "HourContainer"
            }
        case GET_BUSINESS_HOUR_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "HourContainer"
            }
        case UPDATE_BUSINESS_HOUR_STARTED: 
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component:"HourContainer"
            }
        case UPDATE_BUSINESS_HOUR_SUCCESS:
            return {
                ...state,
                loading: false,
                businessHour: action.payload.businessHour,
                error: null,
                message: "Update business hour successfully!",
                component:"HourContainer",

            }
        case UPDATE_BUSINESS_HOUR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component:"HourContainer",
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            }
        default:
            return {
                ...state
            };
    }
}