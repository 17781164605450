import {
    GET_ALL_PROVINCES_CITIES_STARTED,
    GET_ALL_PROVINCES_CITIES_SUCCESS,
    GET_ALL_PROVINCES_CITIES_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/province-city/province-city";

const initialState = {
    cities: [],
    provinces: [],
    loading: false,
    error: null,
    message: null,
    component: null
};

export default function provinceCity(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PROVINCES_CITIES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "CountryProvinceCityDropdown"
            };
        case GET_ALL_PROVINCES_CITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                cities: action.payload.cities,
                provinces: action.payload.provinces,
                component: "CountryProvinceCityDropdown"
            }
       
    
        case GET_ALL_PROVINCES_CITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "CountryProvinceCityDropdown"
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default: 
            return {
                ...state
            }
    }
}
