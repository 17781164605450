import React, { Component } from "react";
import "./App.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Login from "./pages/login/Login";
import { connect } from "react-redux";
import ResetPassword from "./pages/reset-password/ResetPassword";
import { createBrowserHistory } from "history";
import {EmployeeRouters} from "./routers/EmployeeRouters"
import { AdminRouters } from "./routers/AdminRouters";
import { DeveloperRouters } from "./routers/DeveloperRouters";
import { AccountantRouters } from "./routers/AccountantRouters";
const mapStateToProps = (state) => {
    return { user: state.authenticate.user };
};

const history = createBrowserHistory();
class App extends Component {
    render() {
        const loggedIn = this.props.user.authenticated;
        const userRole = this.props.user.role

        if (!loggedIn) {
            return (
                <Router>
                    <Switch>
                        <Route path="/reset-password" exact>
                            <ResetPassword history={history} />
                        </Route>
                        <Route path="/*" component={Login} />
                    </Switch>
                </Router>
            );
        }

        else if( userRole === "developer") {
          return <DeveloperRouters />
        }


        else if ( userRole === "admin") {
            return (
               <AdminRouters />
            );
        }

        else if ( ["accountant"].includes(userRole)) {
            return (
               <AccountantRouters />
            );
        }

        else if ( ["employee"].includes(userRole)) {
            return <EmployeeRouters />
        }

        else {
            return <Login/>
        }



    }
}

export default connect(mapStateToProps)(App);
