import {
    ADD_NEW_NOTE_FAILURE,
    ADD_NEW_NOTE_STARTED,
    ADD_NEW_NOTE_SUCCESS,

    UPDATE_NOTE_FAILURE,
    UPDATE_NOTE_STARTED,
    UPDATE_NOTE_SUCCESS,

    GET_ALL_NOTES_FAILURE,
    GET_ALL_NOTES_STARTED,
    GET_ALL_NOTES_SUCCESS,

    DELETE_NOTE_FAILURE,
    DELETE_NOTE_STARTED,
    DELETE_NOTE_SUCCESS,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/note";
import { LOGOUT } from "../../constants/employee-account/login";
import API from "../../../utils/API/API";

/**
 * Gets all notes
 */
export const getAllNotes = businessId => {
    return dispatch => {
        dispatch(getAllNotesStarted());
        API.get(`/api/businesses/notes/${businessId}`)
            .then(res => {
                dispatch(
                    getAllNotesSuccess(
                        res.data,
                    )
                );
            })
            .catch(error => {
                catchError(error, dispatch, getAllNotesFailure);
              
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getAllNotesStarted = () => ({
    type: GET_ALL_NOTES_STARTED
});
const getAllNotesSuccess = (notes) => ({
    type: GET_ALL_NOTES_SUCCESS,
    payload: { notes }
});
const getAllNotesFailure = error => ({
    type: GET_ALL_NOTES_FAILURE,
    payload: { error }
});

/**
 * Add new Note
 * @param {*} note
 */
export const addNewNote = note => {

    return dispatch => {
        dispatch(addNewNoteStarted());
        API.post(`/api/businesses/notes/create`, note)
            .then(res => {

                dispatch(addNewNoteSuccess(res.data));
            })
            .catch(error => {

                catchError(error, dispatch, addNewNoteFailure);
              
            }).finally(() =>  setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const addNewNoteStarted = () => ({
    type: ADD_NEW_NOTE_STARTED
});
const addNewNoteSuccess = (note) => ({
    type: ADD_NEW_NOTE_SUCCESS,
    payload: { note }
});
const addNewNoteFailure = error => ({
    type: ADD_NEW_NOTE_FAILURE,
    payload: { error }
});

/**
 * Update note
 * @param {*} note
 */
export const updateNote = note => {
    return dispatch => {
        dispatch(updateNoteStarted());
        API.patch(`/api/businesses/notes/update`, note)
            .then(res => {
                dispatch(updateNoteSuccess(res.data));

            })
            .catch(error => {
                catchError(error, dispatch, updateNoteFailure);
               
            }).finally(() =>  setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const updateNoteStarted = () => ({
    type: UPDATE_NOTE_STARTED
});
const updateNoteSuccess = (note) => ({
    type: UPDATE_NOTE_SUCCESS,
    payload: { note }
});
const updateNoteFailure = error => ({
    type: UPDATE_NOTE_FAILURE,
    payload: { error }
});

/**
 * Delete Selected Note
 * @param {*} note
 */
export const deleteSelectedNote = noteId => {
    return dispatch => {
        dispatch(deleteNoteStarted());
        API.delete(`/api/businesses/notes/delete/${noteId}`)
            .then(res => {
                dispatch(deleteNoteSuccess(res.data));
            })
            .catch(error => {

                catchError(error, dispatch, deleteNoteFailure);
              
            }).finally(() =>  setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const deleteNoteStarted = () => ({
    type: DELETE_NOTE_STARTED
});
const deleteNoteSuccess = (note) => ({
    type: DELETE_NOTE_SUCCESS,
    payload: { note }
});
const deleteNoteFailure = error => ({
    type: DELETE_NOTE_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
