import {
    GET_BUSINESS_ACCOUNT_STARTED,
    GET_BUSINESS_ACCOUNT_SUCCESS,
    GET_BUSINESS_ACCOUNT_FAILURE,
    ADD_BUSINESS_ACCOUNT_STARTED,
    ADD_BUSINESS_ACCOUNT_SUCCESS,
    ADD_BUSINESS_ACCOUNT_FAILURE,
    FOUND_DUPLICATED_ACCOUNTS,
    REMOVE_DUPLICATED_ACCOUNTS,
    REMOVE_BUSINESS_ACCOUNT_STARTED,
    REMOVE_BUSINESS_ACCOUNT_SUCCESS,
    REMOVE_BUSINESS_ACCOUNT_FAILURE,
    // UPDATE_BUSINESS_ACCOUNT_STARTED,
    // UPDATE_BUSINESS_ACCOUNT_SUCCESS,
    // UPDATE_BUSINESS_ACCOUNT_FAILURE,
    SEND_WELCOME_EMAIL_STARTED,
    SEND_WELCOME_EMAIL_SUCCESS,
    SEND_WELCOME_EMAIL_FAILURE,
    ADD_DUPLICATED_ACCOUNT_STARTED,
    ADD_DUPLICATED_ACCOUNT_SUCCESS,
    ADD_DUPLICATED_ACCOUNT_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/business-account";

const initialState = {
    businessAccounts: [],
    duplicatedAccounts: [],
    loading: false,
    error: null,
    message: null,
    component: "BusinessAccountContainer" //use this to define in which component  message or error should be displaed
};

export default function businessAccount(state = initialState, action) {
    switch (action.type) {
        case GET_BUSINESS_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessAccountContainer"
            };

        case GET_BUSINESS_ACCOUNT_SUCCESS:
            return {
                ...state,
                businessAccounts: action.payload.accounts,
                loading: false,
                error: null,
                message: null,
                component: "BusinessAccountContainer"
            };
        case GET_BUSINESS_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessAccountContainer"
            };
        case ADD_BUSINESS_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessAccountContainer"
            };
        case ADD_BUSINESS_ACCOUNT_SUCCESS:
            state.businessAccounts.push(action.payload.account);
            return {
                ...state,
                loading: false,
                error: null,
                message: "Account was saved!",
                component: "BusinessAccountContainer"
            };
        case ADD_BUSINESS_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessAccountContainer"
            };
        case REMOVE_BUSINESS_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
                component: "BusinessAccountContainer"
            };
        case REMOVE_BUSINESS_ACCOUNT_SUCCESS:
            return {
                ...state,
                businessAccounts: state.businessAccounts.filter(account =>
                    action.payload.accountID !== account._id
                ),
                loading: false,
                error: null,
                message: "Account was removed from the business",
                component: "BusinessAccountContainer"
            };
        case REMOVE_BUSINESS_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
                component: "BusinessAccountContainer"
            };

        case FOUND_DUPLICATED_ACCOUNTS:
            return {
                ...state,
                loading: false,
                error: "Found duplicated accounts with the email or phone",
                message: null,
                component: "BusinessAccountContainer",
                duplicatedAccounts: action.payload.duplicatedAccounts
            };
        case REMOVE_DUPLICATED_ACCOUNTS:
            return {
                ...state,
                error: null,
                message: null,
                component: "BusinessAccountContainer",
                duplicatedAccounts: []
            };

        // case UPDATE_BUSINESS_ACCOUNT_STARTED:
        //     return {
        //         ...state,
        //         loading: true,
        //         error: null,
        //         message: null,
        //         component: "BusinessAccountContainer"
        //     };
        // case UPDATE_BUSINESS_ACCOUNT_SUCCESS:
        //     state.businessAccounts.push(action.payload.account)
        //     return {
        //         ...state,
        //         loading: false,
        //         error: null,
        //         message: "Account was saved!",
        //         component: "BusinessAccountContainer",
        //         duplicatedAccounts: []
        //     };
        // case UPDATE_BUSINESS_ACCOUNT_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload.error,
        //         message: null,
        //         component: "BusinessAccountContainer",
        //     };
        case SEND_WELCOME_EMAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case SEND_WELCOME_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: "Welcome email has been sent successfully!",
            };
        case SEND_WELCOME_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case ADD_DUPLICATED_ACCOUNT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            }
        case ADD_DUPLICATED_ACCOUNT_SUCCESS:
            let addedAccount = action.payload.account
            return {
                ...state,
                businessAccounts: [...state.businessAccounts, addedAccount],
                duplicatedAccounts: state.duplicatedAccounts.filter(account => account._id !== addedAccount._id),
                loading: false,
                error: null,
                message: "Account was saved!",
            }
        case ADD_DUPLICATED_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: null,
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null
            };
        default:
            return {
                ...state
            };
    }
}
