import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import AddAccountModal from "./AddAccountModal";
// import {removeDuplicatedAccounts} from "../../../redux/actions/account"
//import {removeBusinessAccount} from "../../../redux/actions/business"
import BusinessAccountTable from "./BusinessAccountTable";
import {
    getBusinessAccount,
    removeBusinessAccount,
    //updateBusinessAccount,
    removeDuplicatedAccounts,
    addDuplicatedAccount,
    sendWelcomeEmail
} from "../../../redux/actions/business/business-account";
import Loading from "../../../layouts/Loading/Loading";
import DuplicatedAccountTable from "./DuplicatedAccountTable";

class BusinessAccountContainer extends Component {
    state = {
        modalShow: false,
        selectedAccount: null
    };

    sendWelcomeEmail = account => {
        this.props.sendWelcomeEmail(account.email, this.props.business._id)
    }

    selecteAccount = account => {
        this.setState({
            selectedAccount: account,
            modalShow: true
        });
    };

    componentDidMount() {
        if (this.props.business) {
            this.props.getBusinessAccount(this.props.business._id);
        }
    }

    removeAccount = business => {
        const confirmed = window.confirm(
            "Are you sure to remove this account from the business?"
        );
        if (confirmed) {
            this.props.removeBusinessAccount(business);
        }
    };

    // updateBusinessAccount = business => {
    //     const confirm = window.confirm(
    //         "Are your sure to use this account for the business?"
    //     );
    //     if (confirm) {
    //         this.props.updateBusinessAccount(business);
    //     }
    // };

    selectAccount = account => () => {
        this.setState({
            selectedAccount: account
            //   modalShow: true,
        });
    };

    openCloseAddModal = e => {
        if (this.state.modalShow) {
            this.setState({
                selectedAccount: null
            });
        }
        this.setState({
            modalShow: !this.state.modalShow
        });

        // this.props.removeDuplicatedAccounts()
    };

    render() {
        const { modalShow, selectedAccount } = this.state;
        const {
            business,
            businessAccounts,
            duplicatedAccounts,
            loading,
        } = this.props;
        if (loading) {
            return <Loading />;
        }

        return (
            <div>
                <Row>
                    <Col>
                        <Button
                            className=' mb-3'
                            variant='info'
                            onClick={this.openCloseAddModal}
                        //disabled={businessAccounts.length > 0}
                        >
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New Account
                        </Button>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col>
                        <BusinessAccountTable
                            accounts={businessAccounts}
                            selecteAccount={this.selecteAccount}
                            sendWelcomeEmail={this.sendWelcomeEmail}
                            business={business._id}
                            removeBusinessAccount={this.props.removeBusinessAccount}
                        />
                    </Col>
                </Row>

                {duplicatedAccounts && duplicatedAccounts.length > 0 && (
                    <Row className='mt-3'>
                        <Col>
                            <DuplicatedAccountTable
                                accounts={duplicatedAccounts}
                                // updateBusinessAccount={
                                //     this.updateBusinessAccount
                                // }
                                business={business._id}
                                selectAccount={this.selectAccount}
                                removeDuplicatedAccounts={this.props.removeDuplicatedAccounts}
                                addDuplicatedAccount={this.props.addDuplicatedAccount}
                            />
                        </Col>
                    </Row>
                )}
                <AddAccountModal
                    show={modalShow}
                    business={business._id}
                    account={selectedAccount}
                    onHide={this.openCloseAddModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    business: state.business.business,
    businessAccounts: state.businessAccount.businessAccounts,
    duplicatedAccounts: state.businessAccount.duplicatedAccounts,
    loading: state.businessAccount.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getBusinessAccount: businessId => dispatch(getBusinessAccount(businessId)),
        //updateBusinessAccount: business => dispatch(updateBusinessAccount(business)),
        removeBusinessAccount: (accountId, businessId) => dispatch(removeBusinessAccount(accountId, businessId)),
        removeDuplicatedAccounts: () => dispatch(removeDuplicatedAccounts()),
        sendWelcomeEmail: (email, businessId) => dispatch(sendWelcomeEmail(email, businessId)),
        addDuplicatedAccount: (accountId, businessId) => dispatch(addDuplicatedAccount(accountId, businessId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessAccountContainer);
