import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import BusinessTypeModal from '../../../components/admin/business-type/BusinessTypeModal';
import ErrorSuccess from '../../../components/ErrorSuccess/ErrorSuccess'
import BusinessTypeTable from '../../../components/admin/business-type/BusinessTypeTable'
import { getBusinessType } from '../../../redux/actions/admin/business-type'
import Loading from '../../../layouts/Loading/Loading';

class BusinessTypeContainer extends Component {
    state = {
        modalShow: false,
        setModalShow: false,
        selectedType: null
    };

    componentDidMount() {
        this.props.getBusinessType()
    }

    // Supposed to update the selectedType but the modal has already rendered the values
    // componentDidUpdate(prevProps) {
    //     if (this.props.businessTypes !== prevProps.businessTypes && this.state.selectedType !== null) {
    //         const { selectedType } = this.state;
    //         let newSelectedType = this.props.businessTypes.filter(newBusinessTypes => {
    //             return newBusinessTypes._id === selectedType._id
    //         })[0]

    //         this.setState({
    //             selectedType: newSelectedType
    //         });
    //     }
    // }


    openCloseAddModal = e => {
        const { modalShow } = this.state
        //Reset selectedType in state when modal is closing
        if (modalShow) {
            this.setState({
                selectedType: null
            })
        }
        this.setState({
            modalShow: !this.state.modalShow
        });

    };

    selectBusinessType = businessType => {
        this.setState({
            selectedType: businessType,
            modalShow: true,
        })
    }

    render() {
        const { modalShow, selectedType } = this.state;
        const { error, message, component, businessTypes, loading } = this.props

        return (
            <div>
                <Row>
                    <Col>
                        <h1>Business Type</h1>
                        <Button variant='info' onClick={this.openCloseAddModal}>
                            {" "}
                            <span
                                className='oi oi-plus'
                                title='icon name'
                                aria-hidden='true'
                            ></span>{" "}
                            Add New
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    {loading ? <Loading /> : <BusinessTypeTable businessTypes={businessTypes} selectBusinessType={this.selectBusinessType} />}
                </Row>
                <Row>
                    <Col>
                        {component === "BusinessStatusContainer" && (
                            <ErrorSuccess error={error} message={message} />
                        )}
                    </Col>
                </Row>
                <BusinessTypeModal
                    show={modalShow}
                    onHide={this.openCloseAddModal}
                    selectedType={selectedType}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        businessTypes: state.businessType.businessTypes,
        loading: state.businessType.loading,
        error: state.businessType.error,
        message: state.businessType.message,
        component: state.businessType.component
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessType: () => dispatch(getBusinessType()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeContainer)
