import React from "react";
import {Spinner} from "react-bootstrap"
const Loading = () => {
  return (
    <div style={{width: "100%", marginTop:"80px", textAlign:"center"}}>
          <Spinner animation="border" variant="warning" />
        </div>
  );
};

export default Loading;
