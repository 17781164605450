import {
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/employee-account/reset-password";
import {LOGOUT} from "../../constants/employee-account/login"
import API from "../../../utils/API/API";

export const resetPassword = (token, password) => {
    return dispatch => {
        
        dispatch(resetPasswordStarted());
     
        API.post(
            `/api/employee-accounts/reset-password`,
            { token, password }
        )
            .then(res => {
                dispatch(resetPasswordSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, resetPasswordFailure);
                // if (error.response) {
                //     return dispatch(
                //         resetPasswordFailure(error.response.data.message)
                //     );
                // }
                // dispatch(resetPasswordFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const resetPasswordStarted = () => ({
    type: RESET_PASSWORD_STARTED
});

const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS
});

const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});


const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
