import React from "react";
import { Table, Badge, Button, Alert } from "react-bootstrap";

const DuplicatedAccountTable = props => {
  if (!props.accounts) {
    return <Alert variant="secondary">There is no record found!</Alert>;
  }
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Business</th>
          <th>Status</th>
          <th>Role</th>

        </tr>
      </thead>
      <tbody>
        {props.accounts.map(account => {
          // const business = account.businesses[0]
          return (
            <tr key={account.email}>
              <td>{account.name}</td>
              <td>{account.email}</td>
              <td>{account.phone}</td>
              <td>
                <ul>
                  {account.businesses.map(business =>
                    <li key={business.name}><a href={"/businesses/" + business._id} target="_blank" rel="noopener noreferrer">{business.name}</a></li>
                  )}
                </ul>
                {/* <a href={"/businesses/" + business._id} target="_blank" rel="noopener noreferrer">{business.name}</a> */}
              </td>
              <td>
                {account.active.toString() === "true" ? (
                  <Badge variant="success">Active</Badge>
                ) : (
                  <Badge variant="secondary">Inactive</Badge>
                )}
              </td>
              <td>
                {account.role === "salon_owner" ? (
                  <Badge variant="danger">Owner</Badge>
                ) : (
                  <Badge variant="primary">Employee</Badge>
                )}
              </td>


              <td>
                <Button variant="link text-secondary" onClick={() => props.removeDuplicatedAccounts()}>
                  Cancel
                </Button>
              </td>
              <td>
                <Button variant="link text-primary" onClick={() => props.addDuplicatedAccount(account._id, props.business)}>
                  Confirm
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DuplicatedAccountTable;
