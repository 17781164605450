import {
    GET_ALL_PROVINCE_STARTED,
    GET_ALL_PROVINCE_SUCCESS,
    GET_ALL_PROVINCE_FAILURE,
    UPDATE_PROVINCE_STARTED,
    UPDATE_PROVINCE_SUCCESS,
    UPDATE_PROVINCE_FAILURE,
    ADD_PROVINCE_STARTED,
    ADD_PROVINCE_SUCCESS,
    ADD_PROVINCE_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/province";
import {LOGOUT} from "../../constants/employee-account/login"
import API from "../../../utils/API/API";

/**
 * Gets all province api call
 */
export const getAllProvinces = () => {
    return dispatch => {
        dispatch(getProvinceStarted());
        API.get(`/api/provinces`)
            .then(res => {
                dispatch(getProvinceSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, getProvinceFailure);
                // if (error.response) {
                //     //Logout user if not authenticated
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         getProvinceFailure(error.response.data.message)
                //     );
                // }
                // dispatch(getProvinceFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getProvinceStarted = () => ({
    type: GET_ALL_PROVINCE_STARTED
});
const getProvinceSuccess = provinces => ({
    type: GET_ALL_PROVINCE_SUCCESS,
    payload: { provinces }
});
const getProvinceFailure = error => ({
    type: GET_ALL_PROVINCE_FAILURE,
    payload: { error }
});

/**
 * Update a province api call
 */
export const updateProvince = province => {
    return dispatch => {
        dispatch(updateProvinceStarted());
        API.patch(`/api/provinces/update/${province._id}`, province)
            .then(res => {
                dispatch(updateProvinceSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, updateProvinceFailure);
                // if (error.response) {
                //     //Logout user if not authenticated
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         updateProvinceFailure(error.response.data.message)
                //     );
                // }
                // dispatch(updateProvinceFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const updateProvinceStarted = () => ({
    type: UPDATE_PROVINCE_STARTED
});
const updateProvinceSuccess = province => ({
    type: UPDATE_PROVINCE_SUCCESS,
    payload: { province }
});
const updateProvinceFailure = error => ({
    type: UPDATE_PROVINCE_FAILURE,
    payload: { error }
});

/**
 * Add a province api call
 */
export const addProvince = province => {
    return dispatch => {
        dispatch(addProvinceStarted());
        API.post(`/api/provinces/create`, province)
            .then(res => {
                dispatch(addProvinceSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, addProvinceFailure);
                // if (error.response) {
                //     if (error.response.status === 401) {
                //         localStorage.clear();
                //     }
                //     return dispatch(
                //         addProvinceFailure(error.response.data.message)
                //     );
                // }
                // dispatch(addProvinceFailure(error.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const addProvinceStarted = () => ({
    type: ADD_PROVINCE_STARTED
});
const addProvinceSuccess = province => ({
    type: ADD_PROVINCE_SUCCESS,
    payload: { province }
});
const addProvinceFailure = error => ({
    type: ADD_PROVINCE_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
