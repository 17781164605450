import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Badge } from 'react-bootstrap'
import {decodeHtmlEntity} from "../../utils/utility";

const statusColor = {
    "Pending": "#faad14",
    "Confirmed": "#108ee9",
    "Checked-in": "#722ed1",
    "Cancel": "#8c8c8c",
    "No-show": "#fa541c",
    "Completed": "#87d068",
}

class AppointmentTable extends Component {
    render() {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Business</th>
                        <th>Status</th>
                        <th>Cancel By</th>
                        <th>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.appointments.map(appointment => {
                        return <tr key={appointment._id} >
                            <td>{appointment.number}</td>
                            <td>{appointment.date}</td>
                            <td>{decodeHtmlEntity(appointment.business.name)}</td>
                            <td><Badge style={{ backgroundColor: statusColor[appointment.status] }}>{appointment.status}</Badge></td>
                            <td>{appointment.canceledBy}</td>
                            <td>
                                <button onClick={() => this.props.handleDetailView(appointment)}>
                                    <span className="oi oi-external-link" />
                                </button>
                            </td>
                        </tr>
                    })}
                </tbody>

            </Table>
        )
    }
}

const mapStateToProps = (state) => ({
    appointments: state.appointment.appointments,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTable)
