import {
    GET_APPOINTMENTS_STARTED,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/appointment/appointment";
import {LOGOUT} from "../../constants/employee-account/login"
import API from "../../../utils/API/API";

export const getAppointments = (query) => {
    return dispatch => {
        dispatch(getAppointmentsStarted());
        API.get(`/api/appointments/getAll?from=${query.from}&to=${query.to}&status=${query.status}&page=${query.page}`)
            .then(res => {
                dispatch(getAppointmentsSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, getAppointmentsFailure);
             
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const getAppointmentsStarted = () => ({
    type: GET_APPOINTMENTS_STARTED
});

const getAppointmentsSuccess = (data) => ({
    type: GET_APPOINTMENTS_SUCCESS,
    payload: {
        appointments: data.appointments,
        countAppointments: data.countAppointments
    }
});

const getAppointmentsFailure = error => ({
    type: GET_APPOINTMENTS_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
