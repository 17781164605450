import React from 'react'
import { Form, Row, Col } from "react-bootstrap";
 const ApprovalDetail = (props) => {
     const previewBusiness = props.approval
     
    return (
        <div>
          <h4 className="mt-2">Approver</h4>
          <Row className="mt-2">
            <Col>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Approver's email"
                value={previewBusiness.email}
                // onChange={this.handleChange}
                readOnly
              />
            </Col>
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Approver's full name"
                value={previewBusiness.approverName}
                // onChange={this.handleChange}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Approver's phone"
                value={previewBusiness.phone}
                // onChange={this.handleChange}
                readOnly
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Approver's title"
                value={previewBusiness.title}
                // onChange={this.handleChange}
                readOnly
              />
            </Col>
          </Row>

          <h5 className="mt-2">Approval Details</h5>
          <Row className="mt-2">
            <Col>
              <Form.Check
                inline={true}
                type="switch"
                id="isApproved"
                label={
                  previewBusiness.isApproved ? "Approved" : "Not approved yet"
                }
                name="isApproved"
                checked={previewBusiness.isApproved}
                readOnly
              />
            </Col>
            <Col>
              <Form.Label>Approval's Date</Form.Label>
              <Form.Control
                type="text"
                name="approvedAt"
                placeholder="Approval's date"
                value={previewBusiness.approvedAt}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Form.Label>View Date</Form.Label>
              <Form.Control
                type="text"
                name="viewAt"
                placeholder="View date"
                value={previewBusiness.lastViewedAt}
                readOnly
              />
            </Col>
            <Col>
              <Form.Label>Submission Date</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Submission date"
                value={previewBusiness.submittedAt}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Col col={12} className="m-0 p-0 mt-2">
                {/* <Form.Label>Preview Link is Valid</Form.Label> */}
                <Form.Check
                  inline={true}
                  type="switch"
                  id="isPreviewLinkValid"
                  label={
                    previewBusiness.isPreviewLinkValid
                      ? "Preview link is available"
                      : "Preview link is not available"
                  }
                  name="isPreviewLinkValid"
                  checked={previewBusiness.isPreviewLinkValid}
                  readOnly
                />
              </Col>
              <Col col={12} className="m-0 p-0 mt-2">
                <Form.Label>Submitted By</Form.Label>
                <Form.Control
                  type="text"
                  name="submittedBy"
                  placeholder="Submitted by"
                  value={previewBusiness.submittedBy}
                  readOnly
                />
              </Col>
            </Col>
            <Col>
              <Form.Label>Changes</Form.Label>
              <Form.Control
                as="textarea"
                rows="5"
                name="changes"
                value={previewBusiness.changes}
                readOnly
              />
            </Col>
          </Row>
        </div>
    )
}


export default ApprovalDetail