import {
    ADD_BUSINESS_STATUS_STARTED,
    ADD_BUSINESS_STATUS_SUCCESS,
    ADD_BUSINESS_STATUS_FAILURE,
    GET_ALL_BUSINESS_STATUS_STARTED,
    GET_ALL_BUSINESS_STATUS_SUCCESS,
    GET_ALL_BUSINESS_STATUS_FAILURE,
    UPDATE_BUSINESS_STATUS_STARTED,
    UPDATE_BUSINESS_STATUS_SUCCESS,
    UPDATE_BUSINESS_STATUS_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/admin/business-status";


import {LOGOUT} from "../../constants/employee-account/login"

import API from "../../../utils/API/API";

export const getBusinessStatus = () => {
    return dispatch => {
        dispatch(getBusinessStatusStarted());
        API.get(`/api/business-status`).then(res => {
            dispatch(getBusinessStatusSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, getBusinessStatusFailure);
            // if(error.response){
            //     return dispatch(getBusinessStatusFailure(error.response.data.message))
            // }
            // dispatch(getBusinessStatusFailure(error.message))
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const getBusinessStatusStarted= () => ({
    type: GET_ALL_BUSINESS_STATUS_STARTED
})
const getBusinessStatusSuccess = (businessStatuses) => ({
    type: GET_ALL_BUSINESS_STATUS_SUCCESS,
    payload: {businessStatuses}
})
const getBusinessStatusFailure = error => ({
    type: GET_ALL_BUSINESS_STATUS_FAILURE,
    payload: {error}
})

/**
 * Add new business status action
 * @param {*} businessStatus
 */
export const addBusinessStatus = businessStatus => {
    return dispatch => {
        dispatch(addBusinessStatusStarted());
        API.post(`/api/business-status/create`, businessStatus)
            .then(res => {
                dispatch(addBusinessStatusSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, addBusinessStatusFailure);
                 //logout the user if not authorized
                //  if(error.response.status === 401){
                //     localStorage.clear()
                // }
                // if(error.response){
                //     return dispatch(addBusinessStatusFailure(error.response.data.message))
                // }
                // dispatch(addBusinessStatusFailure(error.response.data.message));
            }).finally(() => setTimeout(function() {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const addBusinessStatusStarted = () => ({
    type: ADD_BUSINESS_STATUS_STARTED
});

const addBusinessStatusSuccess = (newStatus) => ({
    type: ADD_BUSINESS_STATUS_SUCCESS,
    payload: newStatus
});

const addBusinessStatusFailure = error => ({
    type: ADD_BUSINESS_STATUS_FAILURE,
    payload: { error }
});

/**
 * Update Business Status
 */
export const updateBusinessStatus = businessStatus => {
    return dispatch => {
        dispatch(updateBusinessStatusStarted())
        API.patch(`/api/business-status/update/${businessStatus._id}`, businessStatus).then(res => {
            dispatch(updateBusinessStatusSuccess(res.data))
        }).catch(error => {
            catchError(error, dispatch, updateBusinessStatusFailure);
            // if(error.response) {
            //     //logout the user if not authorized
            //     if(error.response.status === 401){
            //         localStorage.clear()
            //     }
            //     return dispatch(updateBusinessStatusFailure(error.response.data.message))
            // }
            // dispatch(updateBusinessStatusFailure(error.message))
        }).finally(() => setTimeout(function() {
            dispatch(removeErrorMessage());
        }, 3000))
    }
}

const updateBusinessStatusStarted = () => ({
    type: UPDATE_BUSINESS_STATUS_STARTED
});

const updateBusinessStatusSuccess = (businessStatus) => ({
    type: UPDATE_BUSINESS_STATUS_SUCCESS,
    payload: {businessStatus}
});

const updateBusinessStatusFailure = error => ({
    type: UPDATE_BUSINESS_STATUS_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});



const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
