import React from 'react'
import { Form } from "react-bootstrap";

const BusinessStatusDropdown = (props) => {
    const { businessStatusArray, businessStatusId } = props;
    const businessStatus = businessStatusArray.filter(status => status._id===businessStatusId)[0]
    return (
        <div>
        <Form.Label >Business Status <span className="status-color" style={{backgroundColor: businessStatus && businessStatus.color }}></span> </Form.Label>
        <Form.Control
            as='select'
            name='status'
            value={businessStatusId || ""}
            required
            onChange={e => props.handleChange(e)}
        >
            <option value=''>Select Business Type</option>
            {businessStatusArray.map(status => {
                return (
                    <option key={status._id} value={status._id}>
                        {status.title}
                    </option>
                );
            })}
        </Form.Control>

    </div>
    )
}
export default BusinessStatusDropdown

