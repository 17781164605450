import {
    AUTHENTICATE_USER_STARTED,
    AUTHENTICATE_USER_SUCCESS,
    AUTHENTICATE_USER_FAILURE,
    LOGOUT
  } from "../../constants/employee-account/login";
  import API from "../../../utils/API/API";

  export const authenticateUser = (user) => {
      return dispatch => {
          dispatch(authenticateUserStarted());
          const email = user.email
          const password = user.password
          API.post(`/api/employee-accounts/login`, {
              email,
              password
          }).then(res => {
              dispatch(authenticateUserSuccess(res.data))
          }).catch(err => {
              console.log(err.message)
              dispatch(authenticateUserFailure(err.message))
          })
      }
  //   return { type: AUTHENTICATE_USER, payload };
  }

  const authenticateUserStarted = () => ({
      type: AUTHENTICATE_USER_STARTED
  })

  const authenticateUserSuccess = user => ({
      type: AUTHENTICATE_USER_SUCCESS,
      payload: {
          user
      }
  })

  const authenticateUserFailure = error => ({
      type: AUTHENTICATE_USER_FAILURE,
      payload: {error}
  })

  export function logout() {
    return { type: LOGOUT, payload: null };
  }

