import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Loading from "../../layouts/Loading/Loading"
import { sendSystemNotification } from "../../redux/actions/notification/notification"
import { ErrorMessageToast } from "../../components/ErrorSuccess/ErrorMessageToast";

class Notification extends Component {
    state = {
        notification: {
            title: '',
            message: '',
            links: [{
                url: ''
            }],
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        const { notification } = this.state
        this.props.sendSystemNotification(notification)
    }

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;

        let notification = this.state.notification;
        if (field === "link") {
            notification.links[0].url = value
        } else {
            notification[field] = value;
        }

        this.setState({
            notification,
        });
    };

    render() {
        const { notification } = this.state
        const { loading, error, message } = this.props
        return (
            <div>
                <h1>Notification</h1>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group >
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    placeholder='Title'
                                    name='title'
                                    value={notification.title}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group >
                                <Form.Label>Link</Form.Label>
                                <Form.Control
                                    placeholder='Link'
                                    name='link'
                                    value={notification.links[0].url}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group >
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="3"
                                    placeholder='Message'
                                    name='message'
                                    value={notification.message}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='mt-3'>
                            {!loading ? (
                                <Button
                                    variant='primary'
                                    className='float-left'
                                    type='submit'
                                >
                                    <span
                                        className='oi oi-bell'
                                        title='icon name'
                                        aria-hidden='true'
                                    /> Send
                                </Button>
                            ) : (
                                <Loading />
                            )}
                        </Col>
                    </Row>
                </Form>
                <ErrorMessageToast error={error} message={message} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.notification.loading,
    error: state.notification.error,
    message: state.notification.message,
})

const mapDispatchToProps = (dispatch) => {
    return {
        sendSystemNotification: (notification) => dispatch(sendSystemNotification(notification)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
