import {
    GET_GOOGLE_BOOKING_INFO_STARTED,
    GET_GOOGLE_BOOKING_INFO_SUCCESS,
    GET_GOOGLE_BOOKING_INFO_FAILURE,
    CREATE_GOOGLE_BOOKING_INFO_STARTED,
    CREATE_GOOGLE_BOOKING_INFO_SUCCESS,
    CREATE_GOOGLE_BOOKING_INFO_FAILURE,
    UPDATE_GOOGLE_BOOKING_INFO_STARTED,
    UPDATE_GOOGLE_BOOKING_INFO_SUCCESS,
    UPDATE_GOOGLE_BOOKING_INFO_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/googleBookingInfo/googleBookingInfo";

import API from "../../../utils/API/API";

export const getGoogleBookingInfo = (id) => {
    return dispatch => {
        dispatch(getGoogleBookingInfoStarted())
        API.get(`/api/googleBookingInfo/${id}`)
            .then(res => {
                dispatch(getGoogleBookingInfoSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, getGoogleBookingInfoFailure);
            })
    }
}

const getGoogleBookingInfoStarted = () => ({
    type: GET_GOOGLE_BOOKING_INFO_STARTED
})

const getGoogleBookingInfoSuccess = (data) => ({
    type: GET_GOOGLE_BOOKING_INFO_SUCCESS,
    payload: {
        merchant: data
    }
})

const getGoogleBookingInfoFailure = (error) => ({
    type: GET_GOOGLE_BOOKING_INFO_FAILURE,
    payload: { error }
})

export const createGoogleBookingInfo = (merchant) => {
    return dispatch => {
        dispatch(createGoogleBookingInfoStarted())
        API.post(`/api/googleBookingInfo/create`, merchant)
            .then(res => {
                dispatch(createGoogleBookingInfoSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, createGoogleBookingInfoFailure);
            })
    }
}

const createGoogleBookingInfoStarted = () => ({
    type: CREATE_GOOGLE_BOOKING_INFO_STARTED
})

const createGoogleBookingInfoSuccess = (data) => ({
    type: CREATE_GOOGLE_BOOKING_INFO_SUCCESS,
    payload: {
        merchant: data
    }
})

const createGoogleBookingInfoFailure = (error) => ({
    type: CREATE_GOOGLE_BOOKING_INFO_FAILURE,
    payload: { error }
})

export const updateGoogleBookingInfo = (merchant) => {
    return dispatch => {
        dispatch(updateGoogleBookingInfoStarted())
        API.post(`/api/googleBookingInfo/update`, merchant)
            .then(res => {
                dispatch(updateGoogleBookingInfoSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, updateGoogleBookingInfoFailure);
            })
    }
}

const updateGoogleBookingInfoStarted = () => ({
    type: UPDATE_GOOGLE_BOOKING_INFO_STARTED
})

const updateGoogleBookingInfoSuccess = (data) => ({
    type: UPDATE_GOOGLE_BOOKING_INFO_SUCCESS,
    payload: {
        merchant: data
    }
})

const updateGoogleBookingInfoFailure = (error) => ({
    type: UPDATE_GOOGLE_BOOKING_INFO_FAILURE,
    payload: { error }
})



const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
    }

    dispatch(nextFunction(message));
    setTimeout(function () {
        dispatch(removeErrorMessage());
    }, 2000);
}