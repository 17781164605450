import React from "react";
import { Row, Col, Form } from "react-bootstrap";
const GeneralInformation = props => {
    const {businessTypes} = props
 
    return (
        <div className="shadow-none p-3 mb-5 bg-light rounded">
            <h4>General Information</h4>
            <Row>
                <Col>
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Name'
                        name='name'
                        value={props.business.name}
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>Url Name</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Example: queen-nail-winnipeg-portage'
                        value={props.business.urlName}
                        name='urlName'
                        readOnly
                    />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Form.Label>Business Email</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='email@example.com'
                        value={props.business.email}
                        name='email'
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                        type='text'
                        value={props.business.phone}
                        placeholder='Business Phone'
                        name='phone'
                        readOnly
                    />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Form.Label>Business Type</Form.Label>
                    {businessTypes  && businessTypes.map(type => (
                        <span
                        key={type._id}
                        className='btn btn-secondary mr-2 ml-1'
                    >
                        {
                           type.title
                        }
                    </span>
                    ))}
                    {/* <div>
                        {props.business.businessType &&
                            props.business.businessType.map(type => {
                                return (
                                    <span
                                        key={type}
                                        className='btn btn-secondary mr-2'
                                    >
                                        {
                                            props.businessTypes.filter(
                                                typeObject =>
                                                    typeObject._id === type
                                            )[0].title
                                        }
                                    </span>
                                );
                            })}
                    </div> */}
                </Col>
            </Row>
        </div>
    );
};

export default GeneralInformation;
