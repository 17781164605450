import React from "react";
import { Row, Col, Badge, Button } from "react-bootstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deletePayment } from "../../../redux/actions/business-payment/business-payment";
export const PaymentDetail = (props) => {

    const dispatch = useDispatch()

    const deleteFailedPayment = (paymentId) => {
        const confirmed = window.confirm("Confirm delete this payment?")
        if (confirmed) {
            dispatch(deletePayment(paymentId))
            props.closeModal(false)
        }
    }

    const { businessPayment } = props;
    let discount = 0.0;
    let subtotal = businessPayment.subtotal;
    const displayDiscount = () => {
        if (businessPayment.discount && businessPayment.discount.value > 0) {
            if (businessPayment.discount.type === "amount") {
                discount = businessPayment.discount.value;
                return (
                    <Row>
                        <Col>
                            <strong>Discount: </strong>
                        </Col>
                        <Col>
                            <span>-{discount}</span>
                        </Col>
                    </Row>
                );
            } else {
                discount = (businessPayment.discount.value * subtotal) / 100;
                return (
                    <Row>
                        <Col>
                            <strong>
                                Discount ({businessPayment.discount.value}%):{" "}
                            </strong>
                        </Col>
                        <Col>
                            <span>-{discount}</span>
                        </Col>
                    </Row>
                );
            }
        }
    };
    const displayTaxes = () => {
        if (businessPayment.taxes.length > 0) {
            return businessPayment.taxes.map((tax) => {
                const taxValue = ((subtotal - discount) * tax.rate) / 100;
                return (
                    <Row key={tax._id}>
                        <Col>
                            <strong>
                                {tax.name} ({tax.rate}%):
                            </strong>{" "}
                        </Col>
                        <Col>
                            <span>{taxValue}</span>
                        </Col>
                    </Row>
                );
            });
        }
    };

    const displayErrors = () => {
        if (businessPayment.error && businessPayment.error.length > 0) {
            const errorList = businessPayment.error.map((err) => {
                return <p key={err}>{err}</p>;
            });
            return (
                <Row>
                    <Col>
                        <strong>Errors:</strong>
                        {errorList}
                    </Col>
                </Row>
            );
        }
    };

    const displayCommission = (commission) => {
        if (commission) {
            return < div >
                <h5>Network access constribution</h5>
                <Row >
                    <Col md={6}>
                        Base on range:
                    </Col>
                    <Col>{commission.from}</Col>
                    <Col>{commission.to}</Col>
                </Row>
                {/* <Row >
                    <Col md={6}>
                        Rate: {commission.rate}% on ${commission.salesFromDashbooking}
                    </Col>
                    <Col>${(commission.rate * commission.salesFromDashbooking / 100).toFixed(2)}</Col>
                    <Col>x1</Col>
                </Row> */}

                <Row >
                    <Col md={6}>
                        Price per booking: ${commission.pricePerBooking} on {commission.dashbookingCompletedAppointments} appointments
                    </Col>
                    <Col>${(commission.pricePerBooking * commission.dashbookingCompletedAppointments).toFixed(2)}</Col>
                    <Col>x1</Col>
                </Row>

            </div >
        }
    }

    const displayStatus = (status) => {
        if (["Failed", "Canceled"].includes(businessPayment.status)) {
            return <Badge variant="danger"> {businessPayment.status} </Badge>
        }
        else if (["succeeded", "Success"].includes(businessPayment.status)) {
            return <Badge variant="success"> {businessPayment.status} </Badge>
        } else {
            return <Badge variant="warning"> {businessPayment.status} </Badge>
        }
    }

    const displayPaymentType = (payment) => {
        if (payment.paymentType) {
            if (payment.paymentType === "Credit Card") {
                return <>Credit Card <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" /></>
            }
            if (payment.paymentType === "Void Cheque") {
                return <>Credit Card <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" /></>
            }
        }
        else if (payment.paymentIntentId) {
            return <>Credit Card <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" /></>
        }
        else if (payment.transactionRotessaId) {
            return <>Void Cheque <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" /></>
        }
    }
    return (
        <div className="p-2">
            {displayCommission(businessPayment.commission)}
            {businessPayment.services.length > 0 && <h5>Services</h5>}
            {businessPayment.services.map((service, index) => {
                return (
                    <Row key={service._id}>
                        <Col md={6}>
                            {index + 1}. {service.name}
                        </Col>
                        <Col>${service.price}</Col>
                        <Col>x{service.quantity}</Col>
                    </Row>
                );
            })}
            <Row className="mt-3">
                <Col md={6}></Col>
                <Col>
                    <Row>
                        <Col>
                            <strong>Subtotal:</strong>
                        </Col>
                        <Col>
                            <span>{businessPayment.subtotal.toFixed(2)}</span>
                        </Col>
                    </Row>

                    {businessPayment.discount &&
                        businessPayment.discount.value > 0 &&
                        displayDiscount()}
                    {displayTaxes()}
                    <Row>
                        <Col>
                            <strong>Total:</strong>
                        </Col>
                        <Col>
                            <span>{businessPayment.total.toFixed(2)}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <strong>Method: </strong>
                    {displayPaymentType(businessPayment)}

                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <strong>Status: </strong>
                    {displayStatus(businessPayment.status)}
                </Col>
            </Row>
            {businessPayment.description && <Row className="mt-2">
                <Col>
                    <strong>Description: </strong>
                    {businessPayment.description}
                </Col>
            </Row>}

            {businessPayment.canceled && <Row>
                <Col>
                    <strong>Cancel reason: </strong>
                    <p>{businessPayment.canceled.reason}</p>
                </Col>
                <Col>
                    <strong>Date: </strong>
                    <p>{businessPayment.canceled.date}</p>
                </Col>
                <Col>
                    <strong>By: </strong>
                    <p>{businessPayment.canceled.by}</p>
                </Col>
            </Row>}

            <Row className="mt-2">
                <Col>
                    <strong>Note: </strong>
                    <p>{businessPayment.note}</p>
                </Col>
            </Row>

            {displayErrors()}


            <div>
                <strong>Date created: </strong>
                <p>
                    {moment(businessPayment.createdAt).format(
                        "dddd, DD MMM YYYY, HH:mm"
                    )}
                </p>
                <strong>Created by: </strong>
                <p>{businessPayment.createdBy}</p>
            </div>

            {(businessPayment.status === "Failed" || !businessPayment.isSubmited)
                && <div>
                    <Button variant="danger" onClick={() => {
                        deleteFailedPayment(businessPayment._id)
                    }}>Delete This Payment</Button>
                </div>}


        </div>
    );
};
