/**
 * Redux for employee account 
 * handles search, update, send reset password
 */
import {
    SEARCH_ACCOUNT_STARTED,
    SEARCH_ACCOUNT_SUCCESS,
    SEARCH_ACCOUNT_FAILURE,
    UPDATE_ACCOUNT_STARTED,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILURE,
    SEND_RESET_PASSWORD_STARTED,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAILURE,
    LOGOUT_USER_FROM_ALL_DEVICES_STARTED,
    LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS,
    LOGOUT_USER_FROM_ALL_DEVICES_FAILURE,
    RESET_PIN_STARTED,
    RESET_PIN_SUCCESS,
    RESET_PIN_FAILURE,

    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/account";
import {LOGOUT} from "../../constants/employee-account/login"
import API from "../../../utils/API/API";


/**
 * Logout user from all devices
 * @param {*} email
 */
export const logoutUserFromAllDevices = email => {
    return dispatch => {
        dispatch(logoutUserFromAllDevicesStarted());
        API.post(`/api/accounts/logout-all-devices`, { email })
            .then(res => {
                dispatch(logoutUserFromAllDevicesSuccess());
            })
            .catch(error => {
                catchError(error, dispatch, logoutUserFromAllDevicesFailure);
               
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const logoutUserFromAllDevicesStarted = () => ({
    type: LOGOUT_USER_FROM_ALL_DEVICES_STARTED
});

const logoutUserFromAllDevicesSuccess = () => ({
    type: LOGOUT_USER_FROM_ALL_DEVICES_SUCCESS
});

const logoutUserFromAllDevicesFailure = error => ({
    type: LOGOUT_USER_FROM_ALL_DEVICES_FAILURE,
    payload: { error }
});

/**
 * Send reset password action
 */
export const sendResetPassword = email => {
    return dispatch => {
        dispatch(sendResetPasswordStarted());
        API.post(`/api/accounts/reset-password`, { email })
            .then(res => {
                dispatch(sendResetPasswordSuccess());
            })
            .catch(error => {
                catchError(error, dispatch, sendResetPasswordFailure);

               
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const sendResetPasswordStarted = () => ({
    type: SEND_RESET_PASSWORD_STARTED
});

const sendResetPasswordSuccess = () => ({
    type: SEND_RESET_PASSWORD_SUCCESS
});

const sendResetPasswordFailure = error => ({
    type: SEND_RESET_PASSWORD_FAILURE,
    payload: { error }
});



/**
 * Update account
 * @param {*} account
 */
export const updateAccount = account => {
    return dispatch => {
        dispatch(updateAccountStart());
        API.patch(`/api/accounts/update-salon-account`, account)
            .then(res => {
                dispatch(updateAcountSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, updateAccountFailure);
               
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const updateAccountStart = () => ({
    type: UPDATE_ACCOUNT_STARTED
});

const updateAcountSuccess = account => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: { account }
});

const updateAccountFailure = error => ({
    type: UPDATE_ACCOUNT_FAILURE,
    payload: { error }
});

/**
 * Search Account action
 */

export const searchAccount = searchBy => {
    return dispatch => {
        dispatch(searchAccountStart());
        var url = "/api/accounts/search?";
        if (searchBy.email) {
            url = url + "&email=" + searchBy.email;
        }
        if (searchBy.phone) {
            url = url + "&phone=" + searchBy.phone;
        }
        if (searchBy.name) {
            url = url + "&name=" + searchBy.name;
        }
        if (searchBy.active) {
            url = url + "&active=" + searchBy.active;
        }

        API.get(url)
            .then(res => {
                dispatch(searchAcountSuccess(res.data));
            })
            .catch(error => {
                catchError(error, dispatch, searchAccountFailure);
              
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const searchAccountStart = () => ({
    type: SEARCH_ACCOUNT_STARTED
});

const searchAcountSuccess = accounts => ({
    type: SEARCH_ACCOUNT_SUCCESS,
    payload: { accounts }
});

const searchAccountFailure = error => ({
    type: SEARCH_ACCOUNT_FAILURE,
    payload: { error }
});

export const resetPin = accountId => {
    return dispatch => {
        dispatch(resetPinStart());
        API.post(`/api/accounts/reset-pin`, { accountId })
            .then(res => {
                dispatch(resetPinSuccess());
            })
            .catch(error => {
                catchError(error, dispatch, resetPinFailure);
               
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    };
};

const resetPinStart = () => ({
    type: RESET_PIN_STARTED
});

const resetPinSuccess = account => ({
    type: RESET_PIN_SUCCESS,
});

const resetPinFailure = error => ({
    type: RESET_PIN_FAILURE,
    payload: { error }
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
