import {
    // GET_BUSINESS_REPORT_GENERAL_STARTED,
    // GET_BUSINESS_REPORT_GENERAL_SUCCESS,
    // GET_BUSINESS_REPORT_GENERAL_FAILURE,
    // GET_BUSINESS_REPORT_MONTHS_STARTED,
    // GET_BUSINESS_REPORT_MONTHS_SUCCESS,
    // GET_BUSINESS_REPORT_MONTHS_FAILURE,
    GET_BUSINESS_PAYMENT_LIST_STARTED,
    GET_BUSINESS_PAYMENT_LIST_SUCCESS,
    GET_BUSINESS_PAYMENT_LIST_FAILURE,
    CREATE_BUSINESS_PAYMENT_INTENT_STARTED,
    CREATE_BUSINESS_PAYMENT_INTENT_SUCCESS,
    CREATE_BUSINESS_PAYMENT_INTENT_FAILURE,
    CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_STARTED,
    CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_SUCCESS,
    CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_FAILURE,
    GET_BUSINESS_PAYMENT_DETAIL_STARTED,
    GET_BUSINESS_PAYMENT_DETAIL_SUCCESS,
    GET_BUSINESS_PAYMENT_DETAIL_FAILURE,
    GET_BUSINESS_PAYMENT_PROFILE_STARTED,
    GET_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    GET_BUSINESS_PAYMENT_PROFILE_FAILURE,
    CREATE_BUSINESS_PAYMENT_PROFILE_STARTED,
    CREATE_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    CREATE_BUSINESS_PAYMENT_PROFILE_FAILURE,
    UPDATE_BUSINESS_PAYMENT_PROFILE_STARTED,
    UPDATE_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    UPDATE_BUSINESS_PAYMENT_PROFILE_FAILURE,
    DELETE_PAYMENT_STARTED,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAILURE,
    REFUND_PAYMENT_STARTED,
    REFUND_PAYMENT_SUCCESS,
    REFUND_PAYMENT_FAILURE,
    SEND_PAYMENT_DECLINE_REMINDER_STARTED,
    SEND_PAYMENT_DECLINE_REMINDER_SUCCESS,
    SEND_PAYMENT_DECLINE_REMINDER_FAILURE,
    GET_COMPLETED_APPOINTMENTS_STARTED,
    GET_COMPLETED_APPOINTMENTS_SUCCESS,
    GET_COMPLETED_APPOINTMENTS_FAILURE,
    GET_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
    GET_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    GET_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    CREATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
    CREATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    CREATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
    UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    GET_BUSINESS_APPOINTMENT_COUNT_STARTED,
    GET_BUSINESS_APPOINTMENT_COUNT_SUCCESS,
    GET_BUSINESS_APPOINTMENT_COUNT_FAILURE,
    CREATE_BUSINESS_PAYMENT_STARTED,
    CREATE_BUSINESS_PAYMENT_SUCCESS,
    CREATE_BUSINESS_PAYMENT_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business-payment/business-payment";

const initialState = {
    months: [],
    businessStats: {},
    general: {},
    payments: [],
    paymentProfile: {},
    paymentMethod: null,
    creditCardProfile: null,
    paymentDetail: null,
    loadingPaymentDetail: false,
    loading: false,
    error: null,
    message: null,
    component: "",
    loadingCompleteAppointment: false,
    completedAppointments: [],
    voidChequeProfile: null,
    loadingPaymentProfile: false,
};

export default function businessReport(state = initialState, action) {
    switch (action.type) {
        case CREATE_BUSINESS_PAYMENT_STARTED:
            return {
                ...state,
                loadingCompleteAppointment: true,
            };
        case CREATE_BUSINESS_PAYMENT_SUCCESS:
            return {
                ...state,
                loadingCompleteAppointment: false,
                message: "Payment was submitted successfully!",
            };
        case CREATE_BUSINESS_PAYMENT_FAILURE:
            return {
                ...state,
                loadingCompleteAppointment: false,
                error: action.payload.error,
            };
        case GET_BUSINESS_APPOINTMENT_COUNT_STARTED:
            return {
                ...state,
                loadingCompleteAppointment: true,
            };
        case GET_BUSINESS_APPOINTMENT_COUNT_SUCCESS:
            return {
                ...state,
                loadingCompleteAppointment: false,
                businessStats: action.payload,
            };
        case GET_BUSINESS_APPOINTMENT_COUNT_FAILURE:
            return {
                ...state,
                loadingCompleteAppointment: false,
                error: action.payload.error,
            };
        case DELETE_PAYMENT_STARTED:
            return {
                ...state,
                loadingPaymentDetail: true,
            };
        case DELETE_PAYMENT_SUCCESS:
            return {
                ...state,
                loadingPaymentDetail: false,
                payments: state.payments.filter(
                    (payment) => payment._id !== action.payload._id
                ),
                message: "Payment was deleted successfully!",
            };
        case DELETE_PAYMENT_FAILURE:
            return {
                ...state,
                loadingPaymentDetail: false,
                error: action.payload.error,
            };
        // case GET_BUSINESS_REPORT_MONTHS_STARTED:
        //     return {
        //         ...state,
        //         loading: true,
        //         error: null,
        //         message: null,
        //         component: "BusinessStatisticsContainer",
        //     };
        // case GET_BUSINESS_REPORT_MONTHS_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         months: action.payload.data,
        //     };
        // case GET_BUSINESS_REPORT_MONTHS_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload.error,
        //     };
        case SEND_PAYMENT_DECLINE_REMINDER_STARTED:
            return {
                ...state,
            };
        case SEND_PAYMENT_DECLINE_REMINDER_SUCCESS:
            return {
                ...state,
                message: "Reminders has been sent successfully!",
            };
        case SEND_PAYMENT_DECLINE_REMINDER_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case REFUND_PAYMENT_STARTED:
            return {
                ...state,
                loadingPaymentDetail: true,
                error: null,
                message: null,
            };

        case REFUND_PAYMENT_SUCCESS:
            let newPayment = action.payload.data;
            let newPaymentArray = state.payments.map((payment) => {
                if (payment._id === newPayment._id) {
                    return newPayment;
                }
                return payment;
            });
            return {
                ...state,
                payments: newPaymentArray,
                loadingPaymentDetail: false,
                paymentDetail: {
                    ...state.paymentDetail,
                    businessPayment: action.payload.data,
                },
                message: "The payment has been refunded successfully!",
            };
        case REFUND_PAYMENT_FAILURE:
            return {
                ...state,
                loadingPaymentDetail: false,
                error: action.payload.error,
            };
        case UPDATE_BUSINESS_PAYMENT_PROFILE_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "PaymentProfileContainer",
            };
        case UPDATE_BUSINESS_PAYMENT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentProfile: action.payload.data,
                message: "Profile has been updated successfully!",
            };
        case UPDATE_BUSINESS_PAYMENT_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CREATE_BUSINESS_PAYMENT_PROFILE_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                component: "PaymentProfileContainer",
            };
        case CREATE_BUSINESS_PAYMENT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentProfile: action.payload.data,
                message: "Profile has been created successfully!",
            };
        case CREATE_BUSINESS_PAYMENT_PROFILE_FAILURE:
            return {
                ...state,
                loadingPaymentProfile: false,
                error: action.payload.error,
            };
        case GET_BUSINESS_PAYMENT_PROFILE_STARTED:
            return {
                ...state,
                loadingPaymentProfile: true,
                error: null,
                message: null,
                component: "PaymentProfileContainer",
            };
        case GET_BUSINESS_PAYMENT_PROFILE_SUCCESS:
            return {
                ...state,
                loadingPaymentProfile: false,
                paymentProfile: action.payload.data.paymentProfile,
                paymentMethod: action.payload.data.paymentMethod,
                creditCardProfile: action.payload.data.creditCardProfile,
                voidChequeProfile: action.payload.data.voidChequeProfile,
            };
        case GET_BUSINESS_PAYMENT_PROFILE_FAILURE:
            return {
                ...state,
                loadingPaymentProfile: false,
                error: action.payload.error,
            };
        case GET_BUSINESS_PAYMENT_DETAIL_STARTED:
            return {
                ...state,
                loadingPaymentDetail: true,
                error: null,
                message: null,
            };
        case GET_BUSINESS_PAYMENT_DETAIL_SUCCESS:
            return {
                ...state,
                loadingPaymentDetail: false,
                paymentDetail: action.payload.data,
            };
        case GET_BUSINESS_PAYMENT_DETAIL_FAILURE:
            return {
                ...state,
                loadingPaymentDetail: false,
                error: action.payload.error,
            };
        case CREATE_BUSINESS_PAYMENT_INTENT_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case CREATE_BUSINESS_PAYMENT_INTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "Payment has been created. Check payment history for more detail.",
            };
        case CREATE_BUSINESS_PAYMENT_INTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: "There was an error with the payment. Check payment history for more detail.",
            };
        case CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "Payment has been created. Check payment history for more detail.",
            };
        case CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_FAILURE:
            return {
                ...state,
                loading: false,
                error: "There was an error with the payment. Check payment history for more detail.",
            };
        case GET_BUSINESS_PAYMENT_LIST_STARTED:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        case GET_BUSINESS_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload.data,
            };
        case GET_BUSINESS_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        // case GET_BUSINESS_REPORT_GENERAL_STARTED:
        //     return {
        //         ...state,
        //         loading: true,
        //         error: null,
        //         message: null,
        //         component: "GeneralContainer",
        //     };
        // case GET_BUSINESS_REPORT_GENERAL_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         general: action.payload.data,
        //     };
        // case GET_BUSINESS_REPORT_GENERAL_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload.error,
        //     };

        case GET_COMPLETED_APPOINTMENTS_STARTED:
            return {
                ...state,
                loadingCompleteAppointment: true,
                error: null,
                message: null,
            };
        case GET_COMPLETED_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                loadingCompleteAppointment: false,
                completedAppointments: action.payload.data,
            };
        case GET_COMPLETED_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loadingCompleteAppointment: false,
                error: action.payload.error,
            };
        case GET_BUSINESS_VOID_PAYMENT_PROFILE_STARTED:
            return {
                ...state,
                loadingPaymentProfile: true,
                error: null,
                message: null,
            };
        case GET_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS:
            return {
                ...state,
                loadingPaymentProfile: false,
                voidChequeProfile: action.payload.data,
            };
        case GET_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE:
            return {
                ...state,
                loadingPaymentProfile: false,
                error: action.payload.error,
            };
        case CREATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED:
            return {
                ...state,
                loadingPaymentProfile: true,
                error: null,
                message: null,
            };
        case CREATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS:
            return {
                ...state,
                loadingPaymentProfile: false,
                message: "Customer Profile was created successfully!",
                voidChequeProfile: action.payload.customerProfile,
            };
        case CREATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE:
            return {
                ...state,
                loadingPaymentProfile: false,
                error: action.payload.error,
            };
        case UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED:
            return {
                ...state,
                loadingPaymentProfile: true,
                error: null,
                message: null,
            };
        case UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS:
            return {
                ...state,
                loadingPaymentProfile: false,
                voidChequeProfile: action.payload,
                message: "Bank profile has been update successfully!",
            };
        case UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE:
            return {
                ...state,
                loadingPaymentProfile: false,
                error: action.payload.error,
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                loadingCompleteAppointment: false,
                error: null,
                message: null,
            };

        default:
            return {
                ...state,
            };
    }
}
