import React from 'react'
import { Modal, Button } from "react-bootstrap";
import CityForm from './CityForm';

const CityModal = (props) => {
    const {
        selectedCity,
        ...rest
    } = props;
   var provinceName = (!selectedCity && props.province) ? `Add City in ${props.province.name}`: "City"
  
    return (
        <Modal
            {...rest}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                {provinceName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                   <CityForm selectedCity={selectedCity} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default  CityModal
