import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import {
    createBusinessPaymentProfile,
    updateBusinessPaymentProfile,
} from "../../../redux/actions/business-payment/business-payment";
import { getBusinessPaymentProfile } from "../../../redux/actions/business-payment/business-payment";
import { countryCodes } from "../../../utils/utility";
class PaymentProfile extends Component {
    state = {
        startBillingOn: '',
        credit: 0.0,
        paymentType: "Credit Card",
        billingInfo: null,
        notes: null,
        currency: "cad",
        taxes: [],
        rate: 10,
        // pricePerBooking: 0,
        services: [
            {
                name: "",
                price: 0.0,
                quantity: 1,
                description: "",
                discountType: "percentage",
                discountValue: 0,
            },
        ],
        customerId: "",
        paymentMethodId: "",
        defaultServicesInvalid: false,
    };

    componentDidMount() {
        const { paymentProfile } = this.props;

        if (Object.keys(paymentProfile).length !== 0) {
            let services = [];

            services = paymentProfile.services.map((service) => {
                return Object.assign({}, service);
            });

            this.setState({
                startBillingOn: paymentProfile.startBillingOn || "",
                credit: paymentProfile.credit ?? 0.0,
                paymentType: paymentProfile.paymentType ?? "Credit Card",
                notes: paymentProfile.notes ?? "",
                currency: paymentProfile.currency ?? "cad",
                rate: paymentProfile.rate,
                taxes: paymentProfile.taxes,
                billingInfo: paymentProfile.billingInfo || null,
                // pricePerBooking: paymentProfile.pricePerBooking,
                services,
                customerId: paymentProfile.customerId,
                paymentMethodId: paymentProfile.paymentMethodId || "",
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.paymentProfile !== this.props.paymentProfile) {
            const { paymentProfile } = this.props;
            if (Object.keys(paymentProfile).length !== 0) {
                let services = [];

                services = paymentProfile.services.map((service) => {
                    return Object.assign({}, service);
                });

                this.setState({
                    startBillingOn: paymentProfile.startBillingOn || "",
                    credit: paymentProfile.credit ?? 0.0,
                    paymentType: paymentProfile.paymentType ?? "Credit Card",
                    currency: paymentProfile.currency ?? "cad",
                    notes: paymentProfile.notes,
                    rate: paymentProfile.rate,
                    taxes: paymentProfile.taxes,
                    billingInfo: paymentProfile.billingInfo || null,
                    // pricePerBooking: paymentProfile.pricePerBooking,
                    services,
                    customerId: paymentProfile.customerId,
                    paymentMethodId: paymentProfile.paymentMethodId || "",
                });
            }
        }
    }

    handleChangeCurrency = (value) => {
        this.setState({
            currency: value,
        });
    };

    handleChangeRate = (value) => {
        this.setState({
            rate: value,
        });
    };

    handleChangeNotes = (value) => {
        this.setState({
            notes: value,
        });
    };

    handleChangePaymentType = (value) => {
        this.setState({
            paymentType: value,
        });
    };

    handleChangeTax = (index, field, value) => {
        let { taxes } = this.state;
        let newTaxes = taxes;
        newTaxes[index][field] = value;
        this.setState({
            taxes: newTaxes,
        });
    };

    handleChangeBillingInfo = (e) => {
        let billingInfo = this.state.billingInfo ? this.state.billingInfo : { legalName: "", address: "", phone: "", email: "" };
        billingInfo[e.target.name] = e.target.value
        this.setState({
            billingInfo
        });
    };

    validateDefaultServices = () => {
        const { services } = this.state;
        let defaultServicesInvalid = false;

        services.forEach((service) => {
            if (!service.name) {
                defaultServicesInvalid = true;
            }
        });

        this.setState({ defaultServicesInvalid });
    };

    addMoreService = () => {
        let { services } = this.state;
        const newService = {
            name: "",
            price: 0.0,
            quantity: 1,
            discountType: "percentage",
            discountValue: 0,
            description: "",
        };
        services = [...services, newService];
        this.setState(
            {
                services,
            },
            () => {
                this.validateDefaultServices();
            }
        );
    };

    addTax = () => {
        let { taxes } = this.state;
        const newTax = { name: "", rate: 0 };
        taxes = [...taxes, newTax];
        this.setState({
            taxes,
        });
    };

    removeTax = (index) => {
        let { taxes } = this.state;
        taxes.splice(index, 1);
        this.setState({
            taxes,
        });
    };

    removeService = (index) => {
        let { services } = this.state;
        services.splice(index, 1);
        this.setState(
            {
                services,
            },
            () => {
                this.validateDefaultServices();
            }
        );
    };

    handleChangeServiceName = (value, index, fieldName) => {
        let { services } = this.state;
        let updatedService = services[index];
        updatedService[fieldName] = value;
        this.setState(
            {
                services,
            },
            () => {
                this.validateDefaultServices();
            }
        );
    };

    handleChangeCustomerId = (value) => {
        this.setState({ customerId: value });
    };

    handleChangePaymentMethodId = (value) => {
        this.setState({ paymentMethodId: value });
    };

    handleChangeStartBillingOn = (value) => {
        this.setState({ startBillingOn: value });
    };

    handleChangeCredit = (value) => {
        this.setState({ credit: value });
    };

    // handleChangePricePerBooking = (value) => {
    //     this.setState({ pricePerBooking: value });
    // };

    displayAccountNumber = (value) => {
        if (value) {
            const newString = "***" + value.substring(value.length - 3);
            return newString;
        }
        return null;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { business, paymentProfile } = this.props;
        const {
            startBillingOn,
            credit,
            paymentType,
            notes,
            rate,
            currency,
            taxes,
            billingInfo,
            // pricePerBooking,
            services,
            customerId,
            paymentMethodId,
        } = this.state;

        const profileObject = {
            startBillingOn,
            credit,
            paymentType,
            notes,
            currency,
            rate,
            taxes,
            billingInfo,
            // pricePerBooking,
            services,
            customerId,
            paymentMethodId,
            business: business._id,
        };

        // Check if paymentProfile object is empty
        if (Object.keys(paymentProfile).length === 0) {
            this.props.createBusinessPaymentProfile(profileObject);
        } else {
            this.props.updateBusinessPaymentProfile(profileObject);
        }

        window.scrollTo(0, 0);
    };

    render() {
        const {
            startBillingOn,
            credit,
            paymentType,
            notes,
            currency,
            taxes,
            billingInfo,
            // pricePerBooking,
            services,
            customerId,
            paymentMethodId,
            defaultServicesInvalid,
        } = this.state;
        const { paymentMethod, creditCardProfile, voidChequeProfile } =
            this.props;
        const userRole = this.props.user.role;
        let isEmployee = ["employee"].includes(userRole) ? true : false;

        return (
            <Container>
                <Row className='justify-content-md-center'>
                    <Col />
                    <Col md='auto'>
                        <h4>Profile</h4>
                    </Col>
                    <Col />
                </Row>
                <Form>
                    <Form.Group>
                        <Form.Label>
                            Payment Type{" "}
                            {paymentType === "Credit Card" ? (
                                <img
                                    width='30px'
                                    src='/assets/images/credit-card.svg'
                                    alt='Credit Card'
                                />
                            ) : (
                                <img
                                    width='30px'
                                    src='/assets/images/cheque.svg'
                                    alt='Void Cheque'
                                />
                            )}
                        </Form.Label>
                        <Form.Control
                            as='select'
                            value={paymentType}
                            onChange={(e) =>
                                this.handleChangePaymentType(e.target.value)
                            }
                        >
                            <option value='Credit Card'>Credit Card</option>
                            <option value='Void Cheque'>Void Cheque</option>
                        </Form.Control>
                    </Form.Group>

                    <Row className='mt-2'>
                        <Col>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                onChange={(e) =>
                                    this.handleChangeNotes(e.target.value)
                                }
                                as='textarea'
                                placeholder='Notes'
                                value={notes || ""}
                                rows={2}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Currency</Form.Label>
                                <Form.Control
                                    value={currency}
                                    onChange={(e) =>
                                        this.handleChangeCurrency(e.target.value)
                                    }
                                    name='currency'
                                    required
                                    as='select'
                                >
                                    {countryCodes.map((ctr) => (
                                        <option key={ctr.code} value={ctr.currency}>
                                            {ctr.currency.toUpperCase()}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Credit</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="credit"
                                    value={credit}
                                    onChange={(e) =>
                                        this.handleChangeCredit(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Start Billing On (if leave empty, payment will be made at the start of next month)</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="startBillingOn"
                                    value={startBillingOn}
                                    onChange={(e) =>
                                        this.handleChangeStartBillingOn(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    {services.map((service, index) => {
                        return (
                            <Form.Group key={index}>
                                <div className='mb-5'>
                                    <Row>
                                        <Col>
                                            <Form.Label>
                                                {index + 1}. Default Service{" "}
                                            </Form.Label>
                                        </Col>
                                        <Col>
                                            {!isEmployee && (
                                                <Button
                                                    variant='link'
                                                    className='text-danger'
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                        this.removeService(
                                                            index
                                                        )
                                                    }
                                                >
                                                    Remove
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>
                                                Service Name
                                            </Form.Label>
                                            <Form.Control
                                                placeholder='Name'
                                                value={service.name}
                                                required={true}
                                                onChange={(e) =>
                                                    this.handleChangeServiceName(
                                                        e.target.value,
                                                        index,
                                                        "name"
                                                    )
                                                }
                                                disabled={isEmployee}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                placeholder={"$ Price "}
                                                type='number'
                                                value={service.price}
                                                onChange={(e) =>
                                                    this.handleChangeServiceName(
                                                        e.target.value,
                                                        index,
                                                        "price"
                                                    )
                                                }
                                                disabled={isEmployee}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label controlid='discount'>
                                                    Discount ($ or %){" "}
                                                </Form.Label>
                                                <InputGroup className='mb-2'>
                                                    <InputGroup.Prepend
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            this.handleChangeServiceName(
                                                                service.discountType !==
                                                                    "percentage"
                                                                    ? "percentage"
                                                                    : "amount",
                                                                index,
                                                                "discountType"
                                                            )
                                                        }
                                                    >
                                                        <InputGroup.Text>
                                                            {service.discountType ===
                                                                "percentage"
                                                                ? "%"
                                                                : "$"}
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        type='number'
                                                        id='discount'
                                                        onChange={(e) =>
                                                            this.handleChangeServiceName(
                                                                e.target.value,
                                                                index,
                                                                "discountValue"
                                                            )
                                                        }
                                                        value={
                                                            service.discountValue
                                                        }
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                onChange={(e) =>
                                                    this.handleChangeServiceName(
                                                        e.target.value,
                                                        index,
                                                        "quantity"
                                                    )
                                                }
                                                placeholder='Quantity'
                                                type='number'
                                                min={1}
                                                value={service.quantity}
                                                disabled={isEmployee}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                onChange={(e) =>
                                                    this.handleChangeServiceName(
                                                        e.target.value,
                                                        index,
                                                        "description"
                                                    )
                                                }
                                                as='textarea'
                                                placeholder='Description'
                                                value={service.description}
                                                rows={2}
                                                disabled={isEmployee}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Form.Group>
                        );
                    })}
                    <Form.Group>
                        {!isEmployee && (
                            <Button
                                variant='link'
                                onClick={() => this.addMoreService()}
                            >
                                <span className='oi oi-plus'></span> Add service
                            </Button>
                        )}
                    </Form.Group>

                    {taxes.map((tax, index) => {
                        return (
                            <Form.Group
                                key={index}
                                className='border-top pt-2 pb-3'
                            >
                                <Row className='mt-2'>
                                    <Col>
                                        <Form.Label>Tax name:</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                this.handleChangeTax(
                                                    index,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                            placeholder='Tax name'
                                            type='string'
                                            value={tax.name}
                                            disabled={isEmployee}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Tax rate:</Form.Label>
                                        <Form.Control
                                            onChange={(e) =>
                                                this.handleChangeTax(
                                                    index,
                                                    "rate",
                                                    e.target.value
                                                )
                                            }
                                            placeholder='Tax rate'
                                            type='number'
                                            value={tax.rate}
                                            disabled={isEmployee}
                                        />
                                    </Col>
                                    <Col>
                                        {!isEmployee && (
                                            <Button
                                                variant='link'
                                                className='text-danger'
                                                style={{ float: "right" }}
                                                onClick={() =>
                                                    this.removeTax(index)
                                                }
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        );
                    })}

                    <Form.Group className=' border-bottom'>
                        {!isEmployee && (
                            <Button
                                variant='link'
                                onClick={() => this.addTax()}
                            >
                                <span className='oi oi-plus'></span> Add Tax
                            </Button>
                        )}
                    </Form.Group>

                    {paymentType === "Void Cheque" && voidChequeProfile && (
                        <div>
                            <Form.Group>
                                <Form.Label>Customer ID</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Customer ID'
                                    value={voidChequeProfile.id ?? null}
                                    readOnly
                                    disabled={true}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Customer ID'
                                    value={this.displayAccountNumber(
                                        voidChequeProfile.account_number ?? ""
                                    )}
                                    readOnly
                                    disabled={true}
                                />
                            </Form.Group>
                        </div>
                    )}

                    {paymentType === "Credit Card" && (
                        <div>
                            <Form.Group>
                                <Form.Label>Customer ID</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Customer ID'
                                    value={customerId}
                                    onChange={(e) =>
                                        this.handleChangeCustomerId(
                                            e.target.value
                                        )
                                    }
                                    disabled={isEmployee}
                                />
                                {creditCardProfile && (
                                    <Row>
                                        <Col md={6}>
                                            <p style={{ margin: "0px" }}>
                                                Name: {creditCardProfile.name}
                                            </p>
                                        </Col>
                                        <Col md={6}>
                                            <p style={{ margin: "0px" }}>
                                                Phone: {creditCardProfile.phone}
                                            </p>
                                        </Col>
                                        <Col md={6}>
                                            <p style={{ margin: "0px" }}>
                                                Email: {creditCardProfile.email}
                                            </p>
                                        </Col>
                                        <Col md={6}>
                                            <p style={{ margin: "0px" }}>
                                                Description:{" "}
                                                {creditCardProfile.description}
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Payment Method ID</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Payment Method ID'
                                    value={paymentMethodId}
                                    onChange={(e) =>
                                        this.handleChangePaymentMethodId(
                                            e.target.value
                                        )
                                    }
                                    disabled={isEmployee}
                                />
                                {paymentMethod && (
                                    <Row>
                                        <Col>
                                            <p style={{ margin: "0px" }}>
                                                Type: {paymentMethod.card.brand}
                                            </p>
                                        </Col>
                                        <Col>
                                            <p style={{ margin: "0px" }}>
                                                Ending:{" "}
                                                {paymentMethod.card.last4}
                                            </p>
                                        </Col>
                                        <Col>
                                            <p style={{ margin: "0px" }}>
                                                Exp:{" "}
                                                {paymentMethod.card.exp_month}/
                                                {paymentMethod.card.exp_year}
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Group>
                        </div>
                    )}


                    <h4 className="mt-3">Billing Information</h4>
                    <Row>
                        <Col><Form.Group>
                            <Form.Label>Legal Name</Form.Label>
                            <Form.Control
                                type='text'
                                name="legalName"
                                placeholder='Legal Name'
                                value={billingInfo?.legalName || ""}
                                onChange={(e) => this.handleChangeBillingInfo(e)}

                            />
                        </Form.Group>
                        </Col>
                        <Col> <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type='text'
                                name="address"
                                placeholder='Address'
                                value={billingInfo?.address || ""}
                                onChange={(e) => this.handleChangeBillingInfo(e)}

                            />
                        </Form.Group></Col>
                    </Row>
                    <Row>
                        <Col>  <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type='text'
                                name="phone"
                                placeholder='phone'
                                value={billingInfo?.phone || ""}
                                onChange={(e) => this.handleChangeBillingInfo(e)}

                            />
                        </Form.Group></Col>
                        <Col>  <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='email'
                                name="email"
                                value={billingInfo?.email || ""}
                                onChange={(e) => this.handleChangeBillingInfo(e)}

                            />
                        </Form.Group>
                        </Col>
                    </Row>






                    <Form.Group>
                        {!isEmployee && (
                            <Button
                                className='mt-3'
                                disabled={defaultServicesInvalid}
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                Save
                            </Button>
                        )}
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authenticate.user,
    paymentProfile: state.businessReport.paymentProfile,
    paymentMethod: state.businessReport.paymentMethod,
    creditCardProfile: state.businessReport.creditCardProfile,
    voidChequeProfile: state.businessReport.voidChequeProfile,
    loading: state.businessReport.loadingPaymentProfile,
    business: state.business.business,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessPaymentProfile: (customerId) =>
            dispatch(getBusinessPaymentProfile(customerId)),
        createBusinessPaymentProfile: (profile) =>
            dispatch(createBusinessPaymentProfile(profile)),
        updateBusinessPaymentProfile: (profile) =>
            dispatch(updateBusinessPaymentProfile(profile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProfile);
