export const ADD_NEW_NOTE_STARTED = 'ADD_NEW_NOTE_STARTED'
export const ADD_NEW_NOTE_SUCCESS = 'ADD_NEW_NOTE_SUCCESS'
export const ADD_NEW_NOTE_FAILURE = 'ADD_NEW_NOTE_FAILURE'

export const UPDATE_NOTE_STARTED = 'UPDATE_NOTE_STARTED'
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE'

export const GET_ALL_NOTES_STARTED = 'GET_ALL_NOTES_STARTED'
export const GET_ALL_NOTES_SUCCESS = 'GET_ALL_NOTES_SUCCESS'
export const GET_ALL_NOTES_FAILURE = 'GET_ALL_NOTES_FAILURE'

export const DELETE_NOTE_STARTED = 'DELETE_NOTE_STARTED'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE'

export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE'