import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import Loading from "../../../layouts/Loading/Loading"
import ErrorSuccess from "../../ErrorSuccess/ErrorSuccess";
import {addBusinessStatus, updateBusinessStatus} from '../../../redux/actions/admin/business-status'

class BusinessStatusForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businessStatus: {
                id: "",
                title: "",
                description: "",
                color:""
            },
            displayMessage: false,
        };
        
    }
    componentDidMount() {
        if (this.props.selectedStatus) {
            this.setState({
                businessStatus: this.props.selectedStatus
            });
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        const {businessStatus} = this.state
        if(businessStatus._id){
            this.props.updateBusinessStatus(businessStatus)
        } else {
            this.props.addBusinessStatus(businessStatus)
        }
        this.setState({
            displayMessage: true
        })
    }

    handleChange = e => {
        const field = e.target.name;
        let value = e.target.value;

        let businessStatus = this.state.businessStatus;

        businessStatus[field] = value;
        this.setState({
            businessStatus
        });
    };

    render() {
        const { businessStatus, displayMessage } = this.state;
        const {message, error, component, loading} = this.props
      
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Title'
                                name='title'
                                value={businessStatus.title}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                       
                        <Col md={6}>
                            <Form.Label>Color<span className="status-color" style={{backgroundColor: businessStatus.color}}></span></Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Color'
                                name='color'
                                value={businessStatus.color}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                        <Col md={12}> 
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea" rows="3"
                                placeholder='Description'
                                name='description'
                                value={businessStatus.description}
                                onChange={this.handleChange}
                               
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            {!loading ? (
                                <Button
                                    variant='primary'
                                    className='float-left'
                                    type='submit'
                                >
                                    Save
                                </Button>
                            ) : (
                                <Loading />
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        {displayMessage && component === "BusinessStatusForm" && <ErrorSuccess error={error} message={message}/>}
                    </Row>
                </Form.Group>
                <Row>

                </Row>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.businessStatus.loading,
    error: state.businessStatus.error,
    message: state.businessStatus.message,
    component: state.businessStatus.component
});

const mapDispatchToProps = (dispatch) => {
    return {
        addBusinessStatus: (businessStatus) => dispatch(addBusinessStatus(businessStatus)),
        updateBusinessStatus: (businessStatus) => dispatch(updateBusinessStatus(businessStatus))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessStatusForm);
