import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import ClientSearchForm from "../../../components/client/client-search/ClientSearchForm";
import ClientTable from "../../../components/client/client-search/ClientTable";
import { displayNationalNumber, formatPhoneNumber, getNumberInternational } from "../../../utils/common/FormatPhoneNumber/FormatPhoneNumber";
import queryString from "query-string";
import { searchClient, blockClient, updateClientProfile, deleteClient, updateClientActive } from "../../../redux/actions/client/client";
import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../../components/ErrorSuccess/ErrorSuccess";
import ClientModal from "../../../components/client/client-search/ClientModal";
import { parsePhoneNumber } from "awesome-phonenumber";

class ClientSearchContainer extends Component {
    state = {
        searchForm: {
            phone: "",
            email: "",
        },
        selectedClient: {
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            gender: "",
            birthday: "",
            notifyByPhone: true,
            notifyByEmail: true,
        },
        searchCountry: "CA",
        selectedClientCountry: "CA",
        disableSeachButton: true,
        fetchData: false,
        clientModalShow: false,
    };

    componentDidMount() {
        //based on the url, get all parameters for the search form
        const queryObject = queryString.parse(this.props.location.search);

        if (Object.keys(queryObject).length > 0) {
            let { searchForm, fetchData, searchCountry } = this.state;

            Object.keys(queryObject).forEach((key) => {
                if (queryObject[key]) {
                    searchForm[key] = queryObject[key];
                    if (key === "phone") {
                        searchForm[key] = formatPhoneNumber(queryObject[key], queryObject.searchCountry);
                    }
                    if (key === "searchCountry") {
                        searchCountry = queryObject[key]
                    }
                    fetchData = true;
                }
            });
            this.setState({
                searchCountry,
                searchForm,
                disableSeachButton: true,
                fetchData,
            });
            queryObject.phone = getNumberInternational(queryObject.phone, queryObject.searchCountry)
            const querry = queryString.stringify({
                phone: queryObject.phone,
                email: queryObject.email
            })
            if (fetchData) {
                this.props.searchClient(`?${querry}`);
            }
        }
    };

    handleChangeSearchForm = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { searchForm, searchCountry } = this.state;
        //reset city field when province has been changed

        if (field === "phone") {
            value = formatPhoneNumber(value, searchCountry);
        }

        searchForm[field] = value;
        this.setState({
            searchForm,
        });

        this.setState({
            disableSeachButton: false,
        });
    };

    handleChangeCountrySearchForm = (e) => {
        const { searchForm } = this.state
        searchForm.phone = formatPhoneNumber(searchForm.phone, e.target.value)
        this.setState({
            searchForm,
            searchCountry: e.target.value,
        });
    }

    clearSearchForm = () => {
        this.setState({
            searchForm: {
                phone: "",
                email: "",
            },
            searchCountry: "CA",
            selectedClientCountry: "CA",
            selectedClient: null,
            disableSeachButton: true,
            fetchData: false,
        });
    };

    selectClient = (selectedClient) => {
        const pn = parsePhoneNumber(selectedClient.phone);
        selectedClient.phone = pn.getNumber('national')
        this.setState({
            selectedClient,
            selectedClientCountry: pn.getRegionCode(),
            clientModalShow: true,
        });
    };

    openCloseClientModal = e => {
        this.setState({
            clientModalShow: !this.state.clientModalShow,

        });
    };

    deleteClientProfile = () => {
        const { selectedClient } = this.state;

        this.props.deleteClient(selectedClient._id)
    };

    formOnChange = (e) => {
        let { selectedClient, selectedClientCountry } = this.state;

        if (e.target.name === "phone") {
            selectedClient[e.target.name] = formatPhoneNumber(e.target.value, selectedClientCountry);
        } else if (e.target.name === "notifyByPhone" || e.target.name === "notifyByEmail") {
            selectedClient[e.target.name] = e.target.checked;
        } else {
            selectedClient[e.target.name] = e.target.value;
        }
        this.setState({ selectedClient })
    };

    handleChangeFormClientCountry = (e) => {
        const { selectedClient } = this.state
        selectedClient.phone = formatPhoneNumber(selectedClient.phone, e.target.value)
        this.setState({
            selectedClient,
            selectedClientCountry: e.target.value,
        });
    }

    updateClientProfile = () => {
        const { selectedClient, selectedClientCountry } = this.state;
        selectedClient.phone = getNumberInternational(selectedClient.phone, selectedClientCountry)
        this.props.updateClientProfile(selectedClient)
        selectedClient.phone = displayNationalNumber(selectedClient.phone)
    };

    blockReasonOnChange = (blockReason) => {
        let { selectedClient } = this.state;
        selectedClient.account.blockReason = blockReason;

        this.setState({ selectedClient })
    };

    blockClientAccount = (clientAccountId) => {
        const { selectedClient } = this.state;
        this.props.blockClient(clientAccountId, selectedClient.account.blockReason)
    };

    updateClientActive = (clientAccountId) => {
        this.props.updateClientActive(clientAccountId)
    };

    render() {
        const { searchForm, disableSeachButton, fetchData, clientModalShow, selectedClient, searchCountry, selectedClientCountry } = this.state;
        const { loading, message, error, component, clients } = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        <h1>Client Search</h1>
                    </Col>
                </Row>
                <Row className="m-3">
                    <Col>
                        <ClientSearchForm
                            searchForm={searchForm}
                            handleChangeSearchForm={this.handleChangeSearchForm}
                            disableSeachButton={disableSeachButton}
                            clearSearchForm={this.clearSearchForm}
                            searchCountry={searchCountry}
                            handleChangeCountrySearchForm={this.handleChangeCountrySearchForm}
                        />
                    </Col>
                </Row>
                {loading && <Loading />}
                {fetchData && !loading && (
                    <Row className="m-3">
                        <Col>
                            <ClientTable
                                clients={clients}
                                selectClient={this.selectClient}
                            />
                        </Col>
                    </Row>
                )}

                {component === "ClientContainer" && (
                    <Row>
                        <ErrorSuccess error={error} message={message} />
                    </Row>
                )}

                <ClientModal
                    show={clientModalShow}
                    onHide={this.openCloseClientModal}
                    selectedClient={selectedClient}
                    deleteClientProfile={this.deleteClientProfile}
                    formOnChange={this.formOnChange}
                    updateClientProfile={this.updateClientProfile}
                    blockReasonOnChange={this.blockReasonOnChange}
                    blockClientAccount={this.blockClientAccount}
                    updateClientActive={this.updateClientActive}
                    error={error}
                    message={message}
                    component={component}
                    selectedClientCountry={selectedClientCountry}
                    handleChangeFormClientCountry={this.handleChangeFormClientCountry}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    clients: state.client.clients,
    loading: state.client.loading,
    error: state.client.error,
    message: state.client.message,
    component: state.client.component,
});

const mapDispatchToProps = (dispatch) => {
    return {
        searchClient: (query) => dispatch(searchClient(query)),
        blockClient: (clientAccountId, blockReason) => dispatch(blockClient(clientAccountId, blockReason)),
        updateClientProfile: (client) => dispatch(updateClientProfile(client)),
        deleteClient: (clientId) => dispatch(deleteClient(clientId)),
        updateClientActive: (clientAccountId) => dispatch(updateClientActive(clientAccountId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSearchContainer);
