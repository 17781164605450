import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { createGoogleBookingInfo, getGoogleBookingInfo, updateGoogleBookingInfo } from '../../../redux/actions/googleBookingInfo/googleBookingInfo';
import Loading from '../../../layouts/Loading/Loading';


export class GoogleBookingForm extends Component {

    state = {
        merchant: {
            merchant_id: "",
            name: "",
            telephone: "",
            url: "",
            category: "beauty_salon",
            geo: {
                latitude: 0,
                longitude: 0,
                address: {
                    street_address: "",
                    locality: "",
                    region: "",
                    country: "",
                    postal_code: ""
                }
            },
        },
        disableSaveButton: true,
        errors: [],
    };

    handleChange = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        let { merchant } = this.state;

        if (field === "latitude" || field === "longitude") {
            merchant.geo[field] = value;
        } else if (['street_address', 'locality', 'region', 'country', 'postal_code'].includes(field)) {
            merchant.geo.address[field] = value;
        }
        else {
            merchant[field] = value;
        }

        this.setState({
            merchant,
            disableSaveButton: false,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { merchant } = this.state;
        if (this.props.merchant) {
            this.props.updateGoogleBookingInfo(merchant)
        } else {
            this.props.createGoogleBookingInfo(merchant)
        }
        // this.setState({
        //     disableSaveButton: false,
        // })
    };

    cancelChange = () => {
        this.intializeState();
        this.setState({
            disableSaveButton: true,
            errors: [],
        });
    }

    intializeState = () => {
        let { merchant, business, cities, provinces } = this.props;
        if (merchant) {
            let copiedObjectMerchant = JSON.parse(JSON.stringify(this.props.merchant));
            this.setState({
                merchant: copiedObjectMerchant
            })
        } else {
            this.setState({
                merchant: {
                    merchant_id: business._id,
                    name: business.name,
                    telephone: business.phone,
                    url: "https://www.dashbooking.com/salon/" + business.urlName,
                    category: "beauty_salon",
                    geo: {
                        latitude: business.geometry.coordinates[1],
                        longitude: business.geometry.coordinates[0],
                        address: {
                            street_address: "",
                            locality: cities.filter(city => city._id === business.city)[0]?.name,
                            region: provinces.filter(province => province._id === business.province)[0]?.name,
                            country: business.country,
                            postal_code: ""
                        }
                    },
                },
            })
        }


    };

    componentDidMount() {
        this.props.getGoogleBookingInfo(this.props.business._id)
        this.intializeState()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.merchant !== this.props.merchant) {
            this.intializeState()
        }
    }

    render() {
        const { merchant, disableSaveButton } = this.state
        const { loading } = this.props
        if (loading) {
            return <Loading />
        }
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Row className="mt-3">
                        <Col>
                            <Alert variant={this.props.merchant ? "success" : "danger"}>
                                {
                                    this.props.merchant
                                        ? "This business already has a Google Booking info! You can update it!"
                                        : "This business does not have a Google Booking info! You can create one!"
                                }
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Google business name"
                                value={merchant.name}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Telephone</Form.Label>
                            <Form.Control
                                type="text"
                                name="telephone"
                                placeholder="Google business phone"
                                value={merchant.telephone}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type="text"
                                name="url"
                                placeholder="Business website"
                                value={merchant.url}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md={6}>
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Latitude"
                                onChange={this.handleChange}
                                value={merchant.geo.latitude}
                                name="latitude"
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Longitude"
                                onChange={this.handleChange}
                                value={merchant.geo.longitude}
                                name="longitude"
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Street address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Google business address"
                                onChange={this.handleChange}
                                value={merchant.geo.address.street_address}
                                name="street_address"
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Google business postal code"
                                onChange={this.handleChange}
                                value={merchant.geo.address.postal_code}
                                name="postal_code"
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Country <span className="text-warning">(CA)</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Google business country"
                                onChange={this.handleChange}
                                value={merchant.geo.address.country}
                                name="country"
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Province <span className="text-warning">(Manitoba)</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Google business province"
                                onChange={this.handleChange}
                                value={merchant.geo.address.region}
                                name="region"
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>City <span className="text-warning">(Winnipeg)</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Google business city"
                                onChange={this.handleChange}
                                value={merchant.geo.address.locality}
                                name="locality"
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            {" "}
                            <Button
                                variant="primary"
                                disabled={disableSaveButton}
                                type="submit"
                            >
                                Save
                            </Button>{" "}
                            <Button
                                variant="secondary"
                                onClick={this.cancelChange}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    business: state.business.business,
    cities: state.provinceCity.cities,
    provinces: state.provinceCity.provinces,

    merchant: state.googleBookingInfo.merchant,
    loading: state.googleBookingInfo.loading,
});
const mapDispatchToProps = (dispatch) => {
    return {
        getGoogleBookingInfo: (id) => dispatch(getGoogleBookingInfo(id)),
        createGoogleBookingInfo: (merchant) => dispatch(createGoogleBookingInfo(merchant)),
        updateGoogleBookingInfo: (merchant) => dispatch(updateGoogleBookingInfo(merchant)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GoogleBookingForm)