import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col  } from "react-bootstrap";
import {
    createBusinessVoidProfile,
    updateBusinessVoidProfile,
} from "../../../redux/actions/business-payment/business-payment";

class VoidPaymentProfile extends Component {
    state = {
        voidChequeProfile: {
            name: "",
            address: {
                address_1: "",
                city: "",
                province_code: "",
                postal_code: "",
            },
            custom_identifier: "",
            transit_number: "",
            institution_number: "",
            account_number: "",
            authorization_type:""
        },
    };

    componentDidMount() {
        let { voidChequeProfile } = this.state;
        const { business } = this.props;
        if (this.props.voidChequeProfile) {
            voidChequeProfile = this.props.voidChequeProfile;
        } else {
            voidChequeProfile.name = business.name;
            voidChequeProfile.address.address_1 = business.address;
            voidChequeProfile.custom_identifier = business._id;
        }

        this.setState({
            voidChequeProfile,
        });
    }

    handleChangeAddress = (e) => {
        let { address } = this.state.voidChequeProfile;
        const field = e.target.name;
        let value = e.target.value;

        if (field === "province_code") {
            value = value.toUpperCase();
        }

        address[field] = value;
        this.setState({
            voidChequeProfile: { ...this.state.voidChequeProfile, address },
        });
    };

    handleChange = (e) => {
        let { voidChequeProfile } = this.state;
        const field = e.target.name;
        var value = e.target.value;

        voidChequeProfile = {
            ...voidChequeProfile,
            [field]: value,
        };

        this.setState({
            voidChequeProfile,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { voidChequeProfile } = this.state;
        const { business } = this.props;
        voidChequeProfile.custom_identifier = business._id;
        voidChequeProfile.id
            ? this.props.updateBusinessVoidProfile(voidChequeProfile)
            : this.props.createBusinessVoidProfile(voidChequeProfile);
    };

    render() {
        const { voidChequeProfile } = this.state;
        const { loadingSubmit } = this.props;
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <h4>Void Check Profile</h4>
                    </Col>
                </Row>
                <Form
                    onSubmit={this.handleSubmit}
                    id="void-check-bank-account-form"
                >
                    <div>
                        <h5 style={{ padding: "10px", textAlign: "center" }}>
                            Business information
                        </h5>
                        {voidChequeProfile.id && (
                            <Form.Group>
                                <Form.Label>Rotessa customer id</Form.Label>
                                <Form.Control
                                    placeholder="12345"
                                    value={voidChequeProfile.id}
                                    name="id"
                                    readOnly
                                />
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Form.Label>Business name</Form.Label>
                            <Form.Control
                                placeholder="Business name"
                                value={voidChequeProfile.name}
                                name="name"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Street address</Form.Label>
                            <Form.Control
                                placeholder="123 Main St"
                                value={voidChequeProfile.address.address_1}
                                name="address_1"
                                onChange={this.handleChangeAddress}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                placeholder="City"
                                value={voidChequeProfile.address.city ?? ""}
                                name="city"
                                onChange={this.handleChangeAddress}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Province Code</Form.Label>
                            <Form.Control
                                placeholder="AB"
                                maxLength={2}
                                minLength={2}
                                value={
                                    voidChequeProfile.address.province_code ?? ""
                                }
                                name="province_code"
                                onChange={this.handleChangeAddress}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                placeholder="R2V 3R5"
                                name="postal_code"
                                value={
                                    voidChequeProfile.address.postal_code ?? ""
                                }
                                onChange={this.handleChangeAddress}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Identifier</Form.Label>
                            <Form.Control
                                placeholder="businessId"
                                value={voidChequeProfile.custom_identifier}
                                name="custom_identifier"
                                readOnly
                            />
                        </Form.Group>

                        <h5 style={{ padding: "10px", textAlign: "center" }}>
                            Bank account
                        </h5>
                        <Form.Group>
                            <Form.Label>Transit Number</Form.Label>
                            <Form.Control
                                placeholder="5 digits"
                                maxLength={5}
                                minLength={5}
                                value={voidChequeProfile.transit_number??""}
                                name="transit_number"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Institution Number</Form.Label>
                            <Form.Control
                                placeholder="3 digits"
                                maxLength={3}
                                minLength={3}
                                value={voidChequeProfile.institution_number??""}
                                name="institution_number"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control
                                placeholder="5 to 12 digits"
                                maxLength={12}
                                minLength={5}
                                value={voidChequeProfile.account_number??""}
                                name="account_number"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>Authorization Type</Form.Label>
                        <Form.Control
                            name='authorization_type'
                            required
                            value={voidChequeProfile.authorization_type}
                            onChange={this.handleChange}
                            as='select'
                        >
                            <option></option>
                            <option value='In Person'>In Person</option>
                            <option value='Online'>Online</option>
                        </Form.Control>
                    </Form.Group>
                    </div>

                    <Button
                        disabled={loadingSubmit}
                        style={{marginBottom:"30px"}}
                        variant="primary"
                        type="submit"
                        form="void-check-bank-account-form"
                        value="Submit"
                    >
                        {loadingSubmit ? "Loading..." : "Save"}
                    </Button>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.business.business,
    voidChequeProfile: state.businessReport.voidChequeProfile,
    loadingSubmit: state.businessReport.loadingPaymentProfile,
});

const mapDispatchToProps = (dispatch) => {
    return {
        createBusinessVoidProfile: (voidChequeProfile) =>
            dispatch(createBusinessVoidProfile(voidChequeProfile)),
        updateBusinessVoidProfile: (voidChequeProfile) =>
            dispatch(updateBusinessVoidProfile(voidChequeProfile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidPaymentProfile);
