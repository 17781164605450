import React from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { countryCodes } from "../../../utils/utility";

const ClientSearchForm = (props) => {
    const { searchForm, disableSeachButton, searchCountry } = props;

    return (
        <Form md={12}>
            <Form.Group>

                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Label> Phone</Form.Label>
                        <InputGroup>
                            <Form.Control
                                as="select"
                                value={searchCountry}
                                onChange={props.handleChangeCountrySearchForm}
                                name="searchCountry"
                                style={{ maxWidth: '100px', borderRight: "solid 1px gray" }}
                            >
                                {countryCodes.map(country =>
                                    <option value={country.code} key={country.code}>{country.label}</option>
                                )}
                            </Form.Control>
                            <Form.Control
                                type="text"
                                placeholder="Phone"
                                name="phone"
                                onChange={props.handleChangeSearchForm}
                                value={searchForm.phone}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <Form.Label> Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={props.handleChangeSearchForm}
                            value={searchForm.email}
                        />
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>
                        <Button type="submit" disabled={disableSeachButton}>Submit</Button>
                        <Button type="reset" variant="secondary" className="ml-3" onClick={() => props.clearSearchForm()}>Clear</Button>
                    </Col>

                </Row>
            </Form.Group>
        </Form>
    )
}

export default ClientSearchForm
