import {
    GET_BUSINESS_ACCOUNT_STARTED,
    GET_BUSINESS_ACCOUNT_SUCCESS,
    GET_BUSINESS_ACCOUNT_FAILURE,
    ADD_BUSINESS_ACCOUNT_STARTED,
    ADD_BUSINESS_ACCOUNT_SUCCESS,
    ADD_BUSINESS_ACCOUNT_FAILURE,

    // UPDATE_BUSINESS_ACCOUNT_STARTED,
    // UPDATE_BUSINESS_ACCOUNT_SUCCESS,
    // UPDATE_BUSINESS_ACCOUNT_FAILURE,
    FOUND_DUPLICATED_ACCOUNTS,
    REMOVE_DUPLICATED_ACCOUNTS,
    REMOVE_BUSINESS_ACCOUNT_STARTED,
    REMOVE_BUSINESS_ACCOUNT_SUCCESS,
    REMOVE_BUSINESS_ACCOUNT_FAILURE,
    SEND_WELCOME_EMAIL_STARTED,
    SEND_WELCOME_EMAIL_SUCCESS,
    SEND_WELCOME_EMAIL_FAILURE,
    ADD_DUPLICATED_ACCOUNT_STARTED,
    ADD_DUPLICATED_ACCOUNT_SUCCESS,
    ADD_DUPLICATED_ACCOUNT_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business/business-account";

import { LOGOUT } from "../../constants/employee-account/login";

import API from "../../../utils/API/API";

export const getBusinessAccount = (businessId) => {
    return (dispatch) => {
        dispatch(getBusinessAccountStarted());
        API.get(`/api/businesses/accounts/${businessId}`)
            .then((res) => {
                dispatch(getBusinessAccountSuccess(res.data));
            })
            .catch((error) => {
                catchError(error, dispatch, getBusinessAccountFailure);
                
            })
            .finally(() =>
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000)
            );
    };
};

const getBusinessAccountStarted = () => ({
    type: GET_BUSINESS_ACCOUNT_STARTED,
});

const getBusinessAccountSuccess = (accounts) => ({
    type: GET_BUSINESS_ACCOUNT_SUCCESS,
    payload: { accounts },
});

const getBusinessAccountFailure = (error) => ({
    type: GET_BUSINESS_ACCOUNT_FAILURE,
    payload: { error },
});

/**
 * Add new account action
 * @param {*} account
 */
export const addAccount = (account) => {
    return (dispatch) => {
        dispatch(addAccountStart());
        API.post(`/api/accounts/create`, account)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addAcountSuccess(res.data));
                } else if (res.status === 200) {
                    dispatch(foundDuplicatedAccounts(res.data.foundAccounts));
                }
            })
            .catch((error) => {
                catchError(error, dispatch, addAccountFailure);
               
            })
            .finally(() =>
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000)
            );
    };
};

const addAccountStart = () => ({
    type: ADD_BUSINESS_ACCOUNT_STARTED,
});

const addAcountSuccess = (account) => ({
    type: ADD_BUSINESS_ACCOUNT_SUCCESS,
    payload: { account },
});

const addAccountFailure = (error) => ({
    type: ADD_BUSINESS_ACCOUNT_FAILURE,
    payload: { error },
});

const foundDuplicatedAccounts = (duplicatedAccounts) => ({
    type: FOUND_DUPLICATED_ACCOUNTS,
    payload: { duplicatedAccounts },
});

/**
 * Remove duplicated accounts data from redux
 */
export const removeDuplicatedAccounts = () => {
    return {
        type: REMOVE_DUPLICATED_ACCOUNTS,
    };
};

/**
 * update a business account
 * @param {*} business contain _id: business id and new account: account id
 */
// export const updateBusinessAccount = business => {
//     return dispatch => {
//         dispatch(updateBusinessAccountStarted())
//         API.patch(`/api/businesses/update-account`, business).then(res => {
//             dispatch(updateBusinessAccountSuccess(res.data))
//         }).catch(error => {
//             if (error.response) {
//                 //Logout user if not authenticated
//                 if (error.response.status === 401) {
//                     localStorage.clear();
//                 }
//                 return dispatch(
//                     updateBusinessAccountFailure(error.response.data.message)
//                 );
//             }
//             dispatch(updateBusinessAccountFailure(error.message))
//         }).finally(() => setTimeout(function () {
//             dispatch(removeErrorMessage());
//         }, 3000))
//     }
// }
// const updateBusinessAccountStarted = () => ({
//     type: UPDATE_BUSINESS_ACCOUNT_STARTED
// })

// const updateBusinessAccountSuccess = (account) => ({
//     type: UPDATE_BUSINESS_ACCOUNT_SUCCESS,
//     payload: { account }
// })

// const updateBusinessAccountFailure = (error) => ({
//     type: UPDATE_BUSINESS_ACCOUNT_FAILURE,
//     payload: { error }
// })

/**
 * Remove an account from a business
 * @param {*} business contain _id: business id and new account: account id
 */
export const removeBusinessAccount = (accountId, businessId) => {
    return (dispatch) => {
        dispatch(removeBusinessAccountStarted());
        API.post(`/api/accounts/remove-account`, { accountId, businessId })
            .then((res) => {
                dispatch(removeBusinessAccountSuccess(accountId));
            })
            .catch((error) => {
                catchError(error, dispatch, removeBusinessAccountFailure);
               
            })
            .finally(() =>
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000)
            );
    };
};
const removeBusinessAccountStarted = () => ({
    type: REMOVE_BUSINESS_ACCOUNT_STARTED,
});

const removeBusinessAccountSuccess = (accountID) => ({
    type: REMOVE_BUSINESS_ACCOUNT_SUCCESS,
    payload: { accountID },
});

const removeBusinessAccountFailure = (error) => ({
    type: REMOVE_BUSINESS_ACCOUNT_FAILURE,
    payload: { error },
});

export const sendWelcomeEmail = (email, business) => {
    return (dispatch) => {
        dispatch(sendWelcomeEmailStarted());
        API.post(`/api/accounts/send-welcome-email`, { email, business })
            .then((res) => {
                dispatch(sendWelcomeEmailSuccess());
            })
            .catch((error) => {
                catchError(error, dispatch, sendWelcomeEmailFailure);
                
            })
            .finally(() =>
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000)
            );
    };
};
const sendWelcomeEmailStarted = () => ({
    type: SEND_WELCOME_EMAIL_STARTED,
});

const sendWelcomeEmailSuccess = () => ({
    type: SEND_WELCOME_EMAIL_SUCCESS,
});

const sendWelcomeEmailFailure = (error) => ({
    type: SEND_WELCOME_EMAIL_FAILURE,
    payload: { error },
});

export const addDuplicatedAccount = (accountId, businessId) => {
    return (dispatch) => {
        dispatch(addDuplicatedAccountStarted());
        API.post(`/api/accounts/add-duplicated-account`, {
            accountId,
            businessId,
        })
            .then((res) => {
                dispatch(addDuplicatedAccountSuccess(res.data));
            })
            .catch((error) => {
                catchError(error, dispatch, addDuplicatedAccountFailure);
              
            })
            .finally(() =>
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000)
            );
    };
};
const addDuplicatedAccountStarted = () => ({
    type: ADD_DUPLICATED_ACCOUNT_STARTED,
});

const addDuplicatedAccountSuccess = (account) => ({
    type: ADD_DUPLICATED_ACCOUNT_SUCCESS,
    payload: { account },
});

const addDuplicatedAccountFailure = (error) => ({
    type: ADD_DUPLICATED_ACCOUNT_FAILURE,
    payload: { error },
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
