import React, { Component } from "react";
import { connect } from "react-redux";
import GeneralInformation from "./GeneralInformation";
import { Row, Col, Button } from "react-bootstrap";
import LocationInformation from "./LocationInformation";
import AboutInformation from "./AboutInformation";
import Images from "./Images";
import Menu from "./Menu";
import Hour from "./Hour";
import Staff from "./Staff";
import Taxes from "./Taxes"
import { getBusinessSummary } from "../../../redux/actions/business/business-summary";

import Loading from "../../../layouts/Loading/Loading";
import ErrorSuccess from "../../ErrorSuccess/ErrorSuccess";
import PublishingApprovalForm from "./approval/PublishingApprovalForm";
class PublishingContainer extends Component {
    state = {
        currentStep: 0,
        maximumStep: 8,
    };

    componentDidMount() {
        if (this.props.currentBusiness.urlName) {
            this.props.getBusinessSummary(this.props.currentBusiness.urlName)
        }
    }

    nextStep = () => {
        let { currentStep, maximumStep } = this.state;
        currentStep < maximumStep && currentStep++;

        this.setState({
            currentStep,
        });
    };

    back = () => {
        let { currentStep } = this.state;
        currentStep > 0 && currentStep--;
        this.setState({
            currentStep,
        });
    };

    handleSkip = () => {
        let { currentStep } = this.state;
        currentStep = 8;
        this.setState({
            currentStep,
        });
    };

    render() {
        const {
            business,
            businessTypes,
            businessStatus,
            province,
            city,
            categories,
            services,
            businessHour,
            staffs,
            taxes,
            loading,
            staffHours,
            component,
            message,
            error,
        } = this.props;
        const { currentStep } = this.state;

        if (loading) {
            return <Loading />;
        }
        return (
            <div>
                {/* <h5>Business Detail Review</h5> */}
                <p className="text-danger">
                    *** Review all business information carefully before send it
                    to the business owner for approval ***
                </p>
                <Row className="mb-3">
                    <Col>
                        {currentStep !== 0 && (
                            <Button variant="secondary" onClick={this.back}>
                                {" "}
                                <span className="oi oi-chevron-left"></span>{" "}
                                Back{" "}
                            </Button>
                        )}
                        {currentStep !== 8 && (
                            <Button
                                className="ml-5 float-right"
                                onClick={this.nextStep}
                            >
                                Next{" "}
                                <span className="oi oi-chevron-right"></span>
                            </Button>
                        )}
                    </Col>
                </Row>
                {currentStep === 0 && (
                    <Row>
                        <Col>
                            <GeneralInformation
                                business={business}
                                businessTypes={businessTypes}
                                businessStatus={businessStatus}
                            />
                        </Col>
                    </Row>
                )}
                {currentStep === 1 && (
                    <Row>
                        <Col>
                            <LocationInformation
                                business={business}
                                province={province}
                                city={city}
                            />
                        </Col>
                    </Row>
                )}
                {currentStep === 2 && (
                    <Row>
                        <Col>
                            <AboutInformation business={business} />
                        </Col>
                    </Row>
                )}

                {currentStep === 3 && (
                    <Row>
                        <Col>
                            <Images business={business} />
                        </Col>
                    </Row>
                )}

                {currentStep === 4 && (
                    <Row>
                        <Col>
                            <Hour
                                business={business}
                                businessHour={businessHour}
                            />
                        </Col>
                    </Row>
                )}

                {currentStep === 5 && (
                    <Row>
                        <Col>
                            <Taxes taxes = {taxes} />
                        </Col>
                    </Row>
                )}

                {currentStep === 6 && (
                    <Row>
                        <Col>
                            <Menu
                                business={business}
                                categories={categories}
                                services={services}
                            />
                        </Col>
                    </Row>
                )}

                {currentStep === 7 && (
                    <Row>
                        <Col>
                            <Staff
                                staffs={staffs}
                                staffHours={staffHours}
                                services={services}
                            />
                        </Col>
                    </Row>
                )}

                {currentStep === 8 && (
                    <Row>
                        <Col>
                            <PublishingApprovalForm business={business}/>
                        </Col>
                    </Row>
                )}
                {currentStep !== 8 && (
                    <Row>
                        <Button variant="link" onClick={this.handleSkip}>
                            Skip all steps
                        </Button>
                    </Row>
                )}

                {component === "BusinessPreview" && (
                    <Row className="mt-3">
                        <ErrorSuccess error={error} message={message} />
                    </Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentBusiness: state.business.business,
    business: state.businessSummary.business,
    user: state.authenticate.user,
    businessTypes: state.businessSummary.business.businessType,
    businessStatus: state.businessSummary.business.status,
    province: state.businessSummary.business.province,
    city: state.businessSummary.city,
    categories: state.businessSummary.categories,
    services: state.businessSummary.services,
    businessHour: state.businessSummary.businessHour,
    staffHours: state.businessSummary.staffHours,
    staffs: state.businessSummary.staffs,
    taxes: state.businessSummary.taxes,
   
    loading: state.businessSummary.loading,
    component: state.businessSummary.component,
    error: state.businessSummary.error,
    message: state.businessSummary.message,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessSummary: (businessUrl) =>
            dispatch(getBusinessSummary(businessUrl))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishingContainer);
