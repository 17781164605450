import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Row, Col, Badge } from 'react-bootstrap'
import moment from 'moment'

const statusColor = {
    "Pending": "#faad14",
    "Confirmed": "#108ee9",
    "Checked-in": "#722ed1",
    "Cancel": "#8c8c8c",
    "No-show": "#fa541c",
    "Completed": "#87d068",
}

class AppointmentDetail extends Component {

    render() {
        const appointment = this.props.appointmentDetail
        return (
            <Modal size="lg" show={this.props.modalVisible} onHide={this.props.handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Appointment #{appointment?.number}</Modal.Title>
                </Modal.Header>
                {appointment && <Modal.Body>
                    <Row>
                        <Col>
                            <h4>{moment(appointment.date, "YYYY-MM-DD").format("dddd, DD MMM YYYY")}</h4>
                        </Col>
                        <Col >
                            <Badge style={{ backgroundColor: statusColor[appointment.status], float: "right" }}>{appointment.status}</Badge>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>Client Name: {appointment.clientName}</div>
                            <div>Phone: {appointment.phone}</div>
                            <div>History:</div>
                            <div>
                                {appointment.history.map(his => {
                                    return <Row key={his.date + his.type} >
                                        <Col style={{ textAlign: "end" }}>{moment(his.date).format("dddd MMM DD, YYYY hh:mm a")} :</Col>
                                        <Col>{his.type}</Col>
                                    </Row>
                                })}
                            </div>
                            <div>Services:</div>
                            <ul>
                                {appointment.services.map(service => {
                                    return <li key={service.name}>
                                        {service.name}
                                    </li>
                                })}
                            </ul>
                        </Col>
                    </Row>
                </Modal.Body>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail)
