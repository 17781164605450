import {
    SEND_SYSTEM_NOTIFICATION_STARTED,
    SEND_SYSTEM_NOTIFICATION_SUCCESS,
    SEND_SYSTEM_NOTIFICATION_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/notification/notification"

import API from "../../../utils/API/API";


export const sendSystemNotification = (notification) => {
    return dispatch => {
        dispatch(sendSystemNotificationStarted())
        API.post(`/api/notification/send-system-notification`, notification)
            .then(res => {
                dispatch(sendSystemNotificationSuccess())
            })
            .catch((err) => {
                catchError(err, dispatch, sendSystemNotificationFailure);
            })
    }
}

const sendSystemNotificationStarted = () => ({
    type: SEND_SYSTEM_NOTIFICATION_STARTED
})

const sendSystemNotificationSuccess = () => ({
    type: SEND_SYSTEM_NOTIFICATION_SUCCESS,
})

const sendSystemNotificationFailure = (error) => ({
    type: SEND_SYSTEM_NOTIFICATION_FAILURE,
    payload: { error }
})



const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
    }

    dispatch(nextFunction(message));
    setTimeout(function () {
        dispatch(removeErrorMessage());
    }, 2000);
}